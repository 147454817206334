import React, { FC } from 'react';

interface IColor {
  [key: number] : string,
}

const COLORS: IColor = {
  895450001: '#E54322',
  895450002: '#FFC000',
  895450003: '#88CC00',
  895450004: '#6C6F80',
};

interface Props {
  color: number,
  cx?: string
}

const NO_COLOR = 895450004;

const FlagIcon: FC<Props> = ({ color, cx }) => {
  const isNoFlag = color === NO_COLOR;
  const noFlagIcon = (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx}>
      <path d="M1.5 0.25C1.69891 0.25 1.88968 0.329018 2.03033 0.46967C2.17098 0.610322 2.25 0.801088 2.25 1V1.66C3.045 1.33 4.125 1 5.25 1C7.5 1 7.5 2.5 9 2.5C11.25 2.5 12 1 12 1V7C12 7 11.25 8.5 9 8.5C6.75 8.5 6.75 7 5.25 7C3 7 2.25 8.5 2.25 8.5V13.75H0.75V1C0.75 0.801088 0.829018 0.610322 0.96967 0.46967C1.11032 0.329018 1.30109 0.25 1.5 0.25ZM2.25 3.4375V6.625C2.25 6.625 3.75 5.5 5.25 5.5C6.75 5.5 7.5 7 9 7C10.5 7 10.5 6.25 10.5 6.25V3.625C10.5 3.625 9.75 4 9 4C7.5 4 6.75 2.5 5.25 2.5C3.75 2.5 2.25 3.4375 2.25 3.4375Z" fill={COLORS[NO_COLOR]} />
    </svg>
  );

  const coloredFlag = (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="" xmlns="http://www.w3.org/2000/svg" className={cx}>
      <path d="M1.5 0.25C1.69891 0.25 1.88968 0.329018 2.03033 0.46967C2.17098 0.610322 2.25 0.801088 2.25 1V1.66C3.045 1.33 4.125 1 5.25 1C7.5 1 7.5 2.5 9 2.5C11.25 2.5 12 1 12 1V7C12 7 11.25 8.5 9 8.5C6.75 8.5 6.75 7 5.25 7C3 7 2.25 8.5 2.25 8.5V13.75H0.75V1C0.75 0.801088 0.829018 0.610322 0.96967 0.46967C1.11032 0.329018 1.30109 0.25 1.5 0.25Z" fill={COLORS[color]} />
    </svg>
  );

  return !isNoFlag ? coloredFlag : noFlagIcon;
};

export default FlagIcon;
