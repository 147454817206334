import { AxiosResponse } from 'axios';
import api from './api';

export interface IEditSaveResponse {
  errors: {
    id: string,
    name: string,
    errors: string[]
  }[],
  successfullySaved: string[],
}

const EDIT_REVENUE = '/revenue/edit-mode';

const saveEditedOpportunities = (
  reqBody: any,
): Promise<AxiosResponse<IEditSaveResponse, any>> => api.post(EDIT_REVENUE, reqBody);

export default saveEditedOpportunities;
