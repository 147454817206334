import api from './api';

const API_OPP = 'opportunities/';

export interface INoteBody {
  subject?: string,
  noteText?: string,
}

export interface INote {
  createdBy?: string,
  createdByUser?: string,
  createdOn?: string,
  fileName?: string | null,
  id?: string,
  modifiedBy?: string,
  modifiedByUser?: string,
  modifiedOn?: string,
  noteText?: string,
  subject?: string,
  currentUser?: string, 
}

export const fetchNotes = (oppId: string, order: boolean) => {
  const address = `${API_OPP}${oppId}/notes?take=30&isAscending=${order}`;
  return api
    .get(address)
    .then(({ data }): {
      items: INote[]
    } => ({
      items: data,
    }));
};

export const deleteNote = (noteId: string) => {
  const address = `${API_OPP}notes/${noteId}`;
  return api.delete(address);
};

export const editNote = (noteId: string, body: INoteBody) => {
  const address = `${API_OPP}notes/${noteId}`;
  return api.put(address, body);
};

export const postNote = (oppId: string, body: INoteBody) => {
  const address = `${API_OPP}${oppId}/notes`;
  return api.post(address, body);
};
