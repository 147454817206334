import React, { useState } from 'react';
import { Text } from '@epam/promo';
import { useLazyDataSource } from '@epam/uui-core';
import { useRecoilState } from 'recoil';
import { ImportHistoryItemInfo, importHistoryAPI } from '../../../../api/importHistory';
import Loader from '../../../Loader';
import activeImportsAtom, { refreshIncrementAtom } from '../../../../state/atoms/activeImportsAtoms';
import ImportHistoryList from './ImportHistoryList';
import ImportHistoryStatus from './ImportHistoryStatus';
import css from '../ImportActualModal.module.scss';

const sortImportHistoryByPeriod = (importHistory: ImportHistoryItemInfo[]) => [...importHistory]
  .sort((a, b) => {
    if (a.year === b.year) {
      return b.month - a.month;
    }
    return b.year - a.year;
  });

const getIsRemoveLastMonthDisabled = (importHistory: ImportHistoryItemInfo[]): boolean => !['InProgress', 'Scheduled', 'DeleteScheduled', 'DeleteInProgress', 'DeleteSuccessful']
  .includes(sortImportHistoryByPeriod(importHistory)[0].importStatus);

interface Props {
  setCanBeDeleted: (val: boolean) => void;
}

const ImportHistoryTab = (props: Props) => {
  const { setCanBeDeleted } = props;
  const [incrementRefresh, setIncrementRefresh] = useRecoilState(refreshIncrementAtom);
  const [activeImports, setActiveImports] = useRecoilState(activeImportsAtom);
  const isImporting = !!activeImports.importing.length;
  const isDeleting = (!isImporting && !!activeImports.deleting.length) 
    || activeImports.isActive;
  const isImportingInProgress = isImporting && !activeImports.deleting.length;

  const [state, setState] = useState({
    topIndex: 0,
    visibleCount: 100,
    isFolded: true,
  });

  const dataSource = useLazyDataSource<ImportHistoryItemInfo, string, string>({
    api(request) {
      return importHistoryAPI.getHistory(request.range?.count, request.range?.from)
        .then((res) => {
          setActiveImports((prev) => ({
            ...prev,
          }));
          setCanBeDeleted(getIsRemoveLastMonthDisabled(res));
          return { items: res };
        });
    },
    getId: (i) => i.id,
  }, [incrementRefresh]);

  const view = dataSource.getView(state, setState as any);

  const getStatusToShow = () => {
    if (activeImports.isDeleteComplete) return 'completed';
    if (isDeleting) return 'inProgress';
    return null;
  };
  
  return (
    <>
      <ImportHistoryStatus
        type={getStatusToShow()}
        onDone={() => {
          setActiveImports((prev) => ({
            ...prev,
            isActive: false,
            isDeleteComplete: false,
          }));
          setIncrementRefresh((prev) => prev + 1);
        }}
      />
      {!view.getVisibleRows().some((el) => el.id.includes('loading')) 
        ? (
          <div>
            {isImportingInProgress && (
              <div className={css.loadingItemWrap}>
                <Text>New portion of data is being loaded, please see the details later...</Text>
              </div>
            )}
            <ImportHistoryList view={view} state={state} onChange={setState} />
          </div>
        )
        : <div style={{ padding: '1.5rem' }}><Loader /></div>}
    </>
  );
};

export default ImportHistoryTab;
