import { useEffect } from 'react';
import { IEditable } from '@epam/uui-core';

type TPickerParentOptionsProps = {
  isHierarchy: boolean,
  fieldProps: IEditable<any>,
};

export const usePickerParentOptionsRemove = ({
  isHierarchy,
  fieldProps,
}: TPickerParentOptionsProps) => {
  useEffect(() => {
    if (isHierarchy) {
      if (fieldProps.value && Array.isArray(fieldProps.value) && fieldProps.value?.some((el: any) => !el?.parentId && el.id && Object.hasOwn(el, 'parentId'))) {
        fieldProps.onValueChange(fieldProps.value?.filter((el: any) => {
          if (!el.id && !el.parentId) {
            return true;
          }
          if (!Object.hasOwn(el, 'parentId')) {
            return true;
          }
          return !!el.parentId && fieldProps.value?.every((i:any) => i.parentId !== el.id);
        }));
      }
    }
  }, [fieldProps.value]);
};
