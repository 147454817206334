import { atom, selector } from 'recoil';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';

export const foldedAtom = atom({
  key: 'foldedStore',
  default: selector({
    key: 'foldedAtom/default',
    get: ({ get }) => {
      const { id } = get(defaultViewPresetSelector);
      const localDataString = window.localStorage.getItem(id);
      const parsed = localDataString ? JSON.parse(localDataString) : [];

      return parsed;
    },
  }),
});
