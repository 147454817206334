/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC, memo, useEffect, useRef, 
} from 'react';
import cx from 'classnames';
import {
  Checkbox, IconButton, Text, Tooltip, 
} from '@epam/promo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DataRowProps } from '@epam/uui-core';
import { ReactComponent as LinkIcon } from '@epam/assets/icons/common/action-external_link-12.svg';
import { FlexRow } from '@epam/uui-components';
import css from '../VirtualList.module.scss';
import OppFlagPicker from './OppFlagPicker';
import OppTagPicker from './OppTagPicker';
import OppNotesButton from './OppNotesButton';
import { ProcessedResponseItem } from '../../../../api/query';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import oppDetailsSlideoutAtom from '../../../../state/atoms/oppDetailsSlideoutAtom';
import OppWarning from './OppWarning';
import { formatPluralForm } from '../../../../const/COLUMNS';
import { multiGroupingAttributes } from '../../../../const/GROUPINGS';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';
import markedForUpdateAtom, { accountChildrenAtom, accountsForUpdateAtom } from '../../../../state/atoms/markedForUpdateAtom';
import useSlideout from '../../../../hooks/useSlideout';
import OppHistoryCell from './OppHistoryCell';
import { configState } from '../../../../providers/ConfigProvider';

interface Props {
  item: ProcessedResponseItem,
  rowProps: DataRowProps<ProcessedResponseItem, string>,
}

const OppColumnItem: FC<Props> = ({ item, rowProps }) => {
  const { 
    name: initialName, type, count, values, 
  } = item;

  const config = useRecoilValue(configState);

  const { onFold } = rowProps;
  const ref = useRef<HTMLDivElement>(null);
  const { mode } = useRecoilValue(viewModeAtom);
  const [
    {
      toUpdate: markedForUpdate, updated, isUpdating, 
    },
    setMarkedForUpdate,
  ] = useRecoilState(markedForUpdateAtom);

  const [accountChildren, setAccountChildren] = useRecoilState(accountChildrenAtom);
  const [accountsToUpdate, setAccountsToUpdate] = useRecoilState(accountsForUpdateAtom);

  const { openSlideout } = useSlideout();
  const valuesKeys = Object.keys(values);
  const isTagsAndFlagsRequested = valuesKeys.includes('oppTags') 
    && valuesKeys.includes('oppTagFlag');
  const isWarningsRequested = valuesKeys.includes('oppCorrections');
  const { groupingStore } = useRecoilValue(queryParamsAtom);
  const { warnings, tagsFlagsNotes } = groupingStore;
  const setOppDetailsSlideout = useSetRecoilState(oppDetailsSlideoutAtom);

  const uneditableOpps = values?.oppState === 'Closed' || values?.oppState === 'Lost' || values?.oppState === 'Merged';

  const isOpp = type === 'oppName';
  const isAcc = type === 'accName';
  const isProb = type === 'prob';
  const isMarkableAcc = groupingStore.multiGrouping[groupingStore.multiGrouping.findIndex((el) => el?.id === 'accName') + 1]?.id === 'oppName';
  
  const onlyLevel = groupingStore.multiGrouping.length === 1;
  const isLast = type === groupingStore.multiGrouping[groupingStore.multiGrouping.length - 1].id;
  const withoutName = `Without ${multiGroupingAttributes
    .filter((el) => el.id === type)[0].value}`;

  const displayName = groupingStore.multiGrouping
    .reduce((acc: string, el, idx) => {
      if (el.id === type && !isLast) {
        acc = groupingStore.multiGrouping[idx + 1].value;
      }
      return acc;
    }, '');

  // eslint-disable-next-line no-nested-ternary
  const name = typeof initialName === 'number'
    ? `${initialName}`
    : Array.isArray(initialName) ? initialName.join() : initialName;

  useEffect(() => {
    ref?.current?.parentElement?.setAttribute('cell-for', 'grouping');
    ref?.current?.parentElement?.parentElement?.parentElement?.setAttribute('grouping-level', type as string);
  }, []);

  useEffect(() => {
    if (isAcc && isMarkableAcc) {
      setAccountChildren((prev) => ({
        ...prev,
        [rowProps.id]: [],
      }));
    }
    if (
      isOpp 
      && rowProps.path 
      && rowProps.path[rowProps.path.length - 1]?.value.type === 'accName'
      && !accountChildren?.[rowProps.parentId as string]?.includes(rowProps.id)) {
      setAccountChildren((prev) => {
        const accId = rowProps.parentId as string;
        return {
          ...prev,
          [accId]: [...prev[accId], values.oppId as string],
        };
      });
    }
  }, []);

  useEffect(() => {
    if (
      isOpp
      && rowProps.path
      && rowProps.path?.length > 0
      && accountsToUpdate.includes(rowProps.parentId as string) 
      && !markedForUpdate.includes(values.oppId as string)
    ) {
      setMarkedForUpdate((prev) => ({
        ...prev,
        toUpdate: [...prev.toUpdate, values.oppId as string],
      }));
    }
  }, [accountsToUpdate]);

  const handleOnClick = () => {
    if (isOpp && mode === 'view') {
      setOppDetailsSlideout((prev) => ({
        ...prev,
        open: true,
        oppName: name,
        oppId: values.oppId,
        currName: values.currName,
        details: name !== prev.oppName ? undefined : prev.details,
      }));
      openSlideout('oppDetails');
    }
  };

  const handleMarkAsUpdated = () => {
    setMarkedForUpdate((prev) => {
      if (values.oppId) {
        if (prev.toUpdate.includes(values.oppId)) {
          const newToUpdate = prev.toUpdate.filter((el) => el !== values.oppId);
          
          return {
            ...prev,
            toUpdate: newToUpdate,
          };
        }
        return {
          ...prev,
          toUpdate: [...prev.toUpdate, values.oppId],
        };
      }
      return prev;
    });
    if (rowProps.path && rowProps.path[rowProps.path.length - 1]?.value.type === 'accName') {
      const restOfChildren = accountChildren?.[rowProps.parentId as string]
        ?.filter((el) => el !== values.oppId);

      if (
        markedForUpdate.includes(values.oppId as string)
        && accountsToUpdate.includes(rowProps.parentId as string) 
      ) {
        setAccountsToUpdate((prev) => prev.filter((el) => el !== rowProps.parentId));
      }

      if (
        !accountsToUpdate.includes(rowProps.parentId as string)
        && restOfChildren.every((el) => markedForUpdate.includes(el))
        && !markedForUpdate.includes(values.oppId as string)
      ) {
        setAccountsToUpdate((prev) => [...prev, rowProps.parentId as string]);
      }
    }
  };

  const handleMarkAllAccChildren = () => {
    if (rowProps.isFolded && onFold) {
      onFold(rowProps);
    }
    if (accountsToUpdate.includes(rowProps.id)) {
      setMarkedForUpdate((prev) => ({
        ...prev,
        toUpdate: prev.toUpdate
          .filter((el) => !accountChildren[rowProps.id].includes(el)),
      }));
      setAccountsToUpdate((prev) => prev.filter((el) => el !== rowProps.id));
      return;
    }

    setAccountsToUpdate((prev) => [...prev, rowProps.id]);
  };
  
  const getCountText = () => {
    if (displayName === 'Linked Projects') {
      return count === 1 ? displayName.slice(0, -1) : displayName;
    }
    return count === 1 ? displayName : formatPluralForm(displayName);
  };

  const renderCheckbox = () => {
    if (isOpp && values.oppId) {
      return (
        <Checkbox
          cx={css.markAsUpdatedCheckbox}
          value={markedForUpdate.includes(values.oppId)} 
          onValueChange={handleMarkAsUpdated}
          isDisabled={isUpdating}
        />
      );
    }
    if (isAcc && isMarkableAcc) {
      return (
        <Checkbox
          cx={css.markAsUpdatedCheckbox}
          value={accountsToUpdate.includes(rowProps.id)} 
          onValueChange={handleMarkAllAccChildren}
          isDisabled={isUpdating}
        />
      );
    }
    if (isAcc && !isMarkableAcc) {
      return (
        <Tooltip content="Opportunity must be on the next level after Account">
          <Checkbox
            cx={css.markAsUpdatedCheckbox}
            value={false} 
            onValueChange={handleMarkAsUpdated}
            isDisabled
          />
        </Tooltip>
      );
    }
  };

  return (
    <div className={mode === 'edit' ? css.oppRootEdit : css.oppRoot} ref={ref}>
      {isOpp && warnings && isWarningsRequested && (
        ((!!values.oppOutdated && !updated.includes(values.oppId as string))
          || !!values.oppUnexpectedProbability)
      ) ? (
        <OppWarning
          oppId={values.oppId}
          oppAging={values.oppAging}
          oppOutdated={values.oppOutdated}
          oppUnexpectedProbability={values.oppUnexpectedProbability}
        />
        ) : <div className={`${css.oppWarning} ${!isOpp && css.oppWarningEmpty}`} />}
      {mode === 'edit' && renderCheckbox()}
      <div
        className={cx(
          css.oppRootContentWrapper,
          {
            [css.oppRootContentWrapperWithCheckbox]: mode === 'edit' && isAcc && isMarkableAcc,
          },
        )}
      >
        <FlexRow cx={css.oppNameContainer}>
          <Tooltip
            content={isOpp && (
            <>
              <p>{name}</p>
              {
                values.oppDescriptionShort && <p>{values.oppDescriptionShort}</p>
              }
            </>
            )}
            placement="bottom-start"
          >
            <Text cx={isOpp ? css.oppName : css.rowName} onClick={handleOnClick}>
              <span className={isOpp ? css.oppTitle : css.title}>
                {!isProb ? name ?? withoutName : `${name}%`}
                {(!isLast && !onlyLevel) && (
                <span className={css.countText}>
                  {`, ${count} ${getCountText()}`}
                </span>
                )}
              </span>
              
            </Text>
          </Tooltip>
          {isOpp && (
          <div className={css.iconsContainer}>
            {(mode === 'view' || (mode === 'edit' && !values?.isBaseRequiredFieldsSet)) && (
            <Tooltip color="white" content="Open opportunity in CRM">
              <IconButton 
                color="blue" 
                icon={LinkIcon} 
                target="_blank" 
                href={`${config?.baseCrmUrl}main.aspx?etn=opportunity&id=${values.oppId}&pagetype=entityrecord`}
              />
            </Tooltip>
            )}
            {mode === 'view' && values.hasRevenueHistory && (
            <OppHistoryCell id={values.oppId!} isOpp />
            )}
          </div>
          )}
        </FlexRow>
        {(isOpp && tagsFlagsNotes && isTagsAndFlagsRequested) && (
        <div className={css.lowerRow}>
          <OppFlagPicker 
            color={values.oppTagFlag ?? 895450004}
            oppId={values.oppId}
            disabled={(!values.canEditOpp && mode === 'view') || uneditableOpps}
          />
          <OppNotesButton
            oppId={values.oppId}
            oppName={values.oppName}
            hasNotes={values.hasNotes}
          />
          <OppTagPicker
            tags={values.oppTags ?? []} 
            oppId={values.oppId}
            disabled={(!values.canEditOpp && mode === 'view') || uneditableOpps}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default memo(OppColumnItem);
