import React, { useState } from 'react';
import {
  DataTableHeaderRow, DataTableRow, VirtualList,
} from '@epam/promo';
import { useArrayDataSource } from '@epam/uui-core';
import { useRecoilValue } from 'recoil';
import { VirtualListRenderRowsParams } from '@epam/uui';
import { IForecastsItem, OneCrmColumnsDictionary } from './OneCrmGridColumns';
import { BREAKDOWN_IDS } from '../modals/OppHistoryModal/OppHistoryGrid';

import css from './OneCrmGrid.module.scss';
import { oneCrmGridAtom } from '../../state/atoms/oneCrmAtoms';
import { OneCrmSectionType } from '../../api/oneCrm';

interface IOneCrmGridProps {
  items: IForecastsItem[];
  year: number;
  form: any;
  canEdit: boolean;
}
const fullYearCols = ['gttl', 'ytd', 'frcst', 'gttlPlus'];
const COLUMNS_IDS = ['record', ...BREAKDOWN_IDS, ...fullYearCols];

const OneCrmGrid = (props: IOneCrmGridProps) => {
  const {
    items,
    year, form,
    canEdit,
  } = props;
  const { lens } = form;

  const columns = COLUMNS_IDS.map((el) => OneCrmColumnsDictionary[el](
    fullYearCols.includes(el) ? year : year % 100,
  ));
  const forecastColumns = COLUMNS_IDS.map((el) => OneCrmColumnsDictionary[el](el === 'gttl' ? year : year % 100, lens));
  const bsrvColumns = COLUMNS_IDS.map((el) => {
    if (el === 'record') {
      return OneCrmColumnsDictionary[el](year % 100, lens);
    }
    return OneCrmColumnsDictionary[el](el === 'gttl' ? year : year % 100);
  });

  const forecastVal = items.filter((el) => el.type === OneCrmSectionType.Forecast);
  const actualVal = items.filter((el) => el.type === OneCrmSectionType.Actual);
  const bsrvVal = items.filter((el) => el.type === OneCrmSectionType.BsrvRoot);
  const wlbVal = items.filter((el) => el.type === OneCrmSectionType.BsrvProject
    || el.type === OneCrmSectionType.BsrvStaffing);

  const [value, setValue] = useState({});

  const gridType = useRecoilValue(oneCrmGridAtom);

  const historyDS = useArrayDataSource({
    items: items.filter((el) => el.type === 'History'),
  }, [items]);

  const wlbDS = useArrayDataSource({
    items: wlbVal,
  }, [items]);

  const {
    getListProps: historyProps,
    getVisibleRows: historyRows,
  } = historyDS.useView(value, setValue);

  const {
    getListProps: wlbProps,
    getVisibleRows: wlbRows,
  } = wlbDS.useView(value, setValue);

  const renderRow = (row: any) => (
    <DataTableRow
      {...row}
      columns={columns}
      key={row.id}
      value={{ ...row.value, canEdit }}
    />
  );

  const renderHistoryRows = (
    {
      listContainerRef,
      offsetY,
    }: VirtualListRenderRowsParams,
  ) => (
    <div>
      <div
        ref={listContainerRef}
        role="rowgroup"
        style={{ marginTop: offsetY }}
        children={historyRows().map(renderRow)}
      />
    </div>
  );

  const renderWlbRows = (
    {
      listContainerRef,
      offsetY,
    }: VirtualListRenderRowsParams,
  ) => (
    <div>
      <div
        ref={listContainerRef}
        role="rowgroup"
        style={{ marginTop: offsetY }}
        children={wlbRows().map(renderRow)}
      />
    </div>
  );

  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <DataTableHeaderRow
        key="header"
        columns={columns}
        value={value}
        onValueChange={setValue}
      />
      <DataTableRow
        key="forecast"
        rowKey="forecast"
        id="forecast"
        index={0}
        value={forecastVal?.[0] ? {
          ...forecastVal?.[0],
          canEdit,
        } : {
          id: 'forecast',
          type: 'Forecast',
          changedFields: [],
          year,
          canEdit,
        }}
        columns={forecastColumns}
      />
      {gridType === 'history' && (
        <VirtualList
          cx={css.historyList}
          rows={historyRows().map(renderRow)}
          rowsCount={historyProps().rowsCount}
          renderRows={renderHistoryRows}
          value={value}
          onValueChange={setValue}
          role="table"
          rawProps={{
            style: {
              maxHeight: '300px',
              position: 'relative',
              overflow: 'auto',
            },
          }}
        />
      )}
      <DataTableRow
        key="actual"
        rowKey="actual"
        id="actual"
        index={2}
        value={actualVal?.[0] ?? {
          id: 'actual',
          type: 'Actual',
          changedFields: [],
          canEdit,
        }}
        columns={columns}
      />
      <DataTableRow
        key="wlb"
        rowKey="wlb"
        id="wlb"
        index={3}
        value={bsrvVal?.[0] ? {
          ...bsrvVal?.[0],
          canEdit,
        } : {
          id: 'wlb',
          type: 'Workload Based',
          changedFields: [],
          canEdit,
        }}
        columns={bsrvColumns}
      />
      {gridType === 'wlb' && (
        <VirtualList
          cx={css.historyList}
          rows={wlbRows().map(renderRow)}
          rowsCount={wlbProps().rowsCount}
          renderRows={renderWlbRows}
          value={value}
          onValueChange={setValue}
          role="table"
          rawProps={{
            style: {
              maxHeight: '300px',
              position: 'relative',
              overflow: 'auto',
            },
          }}
        />
      )}
    </div>
  );
};

export default OneCrmGrid;
