import { IconContainer } from '@epam/uui-components';
import React, { FC } from 'react';
import { ReactComponent as ChevronIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import css from '../GroupingSettings.module.scss';

interface Props {
  isLevelOne: boolean,
}

const LevelIcon: FC<Props> = (
  { isLevelOne } : { isLevelOne: boolean },
) => (
  <IconContainer
    icon={ChevronIcon} 
    cx={css.levelIcon}
    style={{
      display: !isLevelOne ? 'block' : 'none',
    }}
  />
);

export default LevelIcon;
