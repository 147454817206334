import {
  Button, FlexRow, Switch, Text, WarningAlert,
} from '@epam/promo';
import React, { useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { ReactComponent as cancelIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
import { ReactComponent as tickIcon } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as loadingIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import saveEditedOpportunities from '../../api/editRevenue';
import editedCellsAtom from '../../state/atoms/editedCellsAtom';
import gridFormAtom, { IBreakdown, gridShowEditedAtom } from '../../state/atoms/gridFormAtom';
import viewModeAtom from '../../state/atoms/viewModeAtom';
import css from './editModePanel.module.scss';
import markedForUpdateAtom, { accountsForUpdateAtom } from '../../state/atoms/markedForUpdateAtom';
import MarkUpdatedIcon from '../../assets/components/UpdateMarkedIcon';
import markAsUpdated from '../../api/markAsUpdated';
import { probabilityDictionary } from '../EditModeGrid/const';
import { useNotification } from '../../hooks/useNotification';
import updateAtomsForQuerySelector from '../../state/selectors/updateAtomsForQuerySelector';
import { createInitialBreakdown } from '../../state/atoms/breakdownAtom';
import editModeErrorsAtom, { VALUE_ERROR_MSG } from '../../state/atoms/editModeErrorsAtom';
import queryParamsAtom from '../../state/atoms/queryParamsAtom';
import Filters from '../FiltersPanel/components/Filters';
import { getActiveImport } from '../../api/importGetActive';
import { useMaintenanceNotificationState } from '../../hooks/useMaintanceNotificationState';

interface IReqBody {
  [key: string]: {
    oppId: string,
    prob?: number,
    gttl?: number,
    breakdown?: IBreakdown,
  }
}

type TResMarkAsUpdated = { data: Array<{ id: string, isSuccess: boolean }> };

const TEXT_ON_SUCCESS = 'Changes saved';
const TEXT_ERROR = 'Failed to get a response from CRM. The data has not been saved.';
const TEXT_IMPORT_ERROR = 'Not possible to save forecasts during actual revenue import/deletion. Please try again later.';
const TEXT_ON_UPDATE = 'Opportunities are updated';

interface EditModePanelProps {
  saveLens: () => void;
}

const EditModePanel = (props: EditModePanelProps) => {
  const { saveLens } = props;
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const { paramsBeforeEdit } = useRecoilValue(viewModeAtom);
  const { filtersStore } = useRecoilValue(queryParamsAtom);
  const formState = useRecoilValue(gridFormAtom);
  const [isShowEdited, setIsShowEdited] = useRecoilState(gridShowEditedAtom);
  const editedCells = useRecoilValue(editedCellsAtom);
  const [{ toUpdate: marked, isUpdating }, setMarked] = useRecoilState(markedForUpdateAtom);
  const setAccountsToUpdate = useSetRecoilState(accountsForUpdateAtom);
  const { showNotification } = useNotification();
  const setAtoms = useSetRecoilState(updateAtomsForQuerySelector);
  const [{ isLoading }, setIsLoading] = useRecoilState(viewModeAtom);
  const [errors, setErrors] = useRecoilState(editModeErrorsAtom);
  const editedOppIds = Object.keys(editedCells);
  const isValueValidationError = errors
    .some((el) => el.message
      .includes(VALUE_ERROR_MSG));
  const { maintenanceNotification } = useMaintenanceNotificationState();

  const getCaptionForUpdateBtn = () => {
    const itemsNumber = marked.length;
    const itemWord = itemsNumber === 1 ? 'item' : 'items';
    return `${itemsNumber} ${itemWord} selected`;
  };

  const renderUpdateMarkedIcon = () => (
    <MarkUpdatedIcon 
      fill={marked.length ? '#008ACE' : '#ACAFBF'} 
    />
  );

  // reset query params and breakdowns back to the values before entering edit mode
  const setParamsBack = () => {
    if (paramsBeforeEdit) {
      setAtoms((prev) => ({
        ...prev,
        breakdownAtom: createInitialBreakdown(
          paramsBeforeEdit.filtersStore.revenueYear,
          paramsBeforeEdit.filtersStore.breakdown,
        ),
        queryParamsAtom: paramsBeforeEdit,
        viewModeAtom: {
          ...prev.viewModeAtom,
          mode: 'view',
          isLoading: false,
        },
        markedForUpdateAtom: {
          ...prev.markedForUpdateAtom,
          toUpdate: [],
        },
        editModeErrorsAtom: [],
      }));
    }
  };

  const handleSave = () => {
    const { items: value } = formState;
    const { revenueYear } = filtersStore;

    const requestBody = editedOppIds.reduce((obj: IReqBody, oppId) => {
      const gttlKey = new Date().getFullYear() + 1 === revenueYear ? 'gttl_next' : 'gttl';
      const gttl = value[oppId].gttl_p;

      // get months breakdown in the correct format for request
      const getBreakdown = () => {
        let result: IBreakdown | false = false;
        const editedBreakdowns = editedCells[oppId]
          .join(' ')
          .match(/(m\d*_p_\d*)\w+/g);
        let changedBreakdowns: string[] | false = false;

        if (editedBreakdowns) {
          changedBreakdowns = editedBreakdowns
            .filter((month) => value[oppId][month] !== value[oppId].breakdown[month]);

          if (changedBreakdowns.length) {
            result = changedBreakdowns.reduce((months, i) => ({
              ...months,
              [i.replace('_p_', '_')]: value[oppId][i],
            }), {});
          }
        }

        return result;
      };
      
      const breakdown = getBreakdown();
      
      // in case breakdown has been changed
      if (breakdown) {
        obj = {
          ...obj,
          [oppId]: {
            oppId,
            breakdown,
          }, 
        };
      }

      // in case where gttl has been changed
      if (editedCells[oppId].includes('gttl_p')) {
        obj = {
          ...obj,
          [oppId]: {
            ...obj[oppId],
            oppId,
            [gttlKey]: gttl,
          },
        };
      }

      // in case where probability has been changed
      if (value[oppId].prob !== value[oppId].values.prob) {
        obj = {
          ...obj,
          [oppId]: {
            ...obj[oppId],
            oppId,
            prob: Object.values(probabilityDictionary)
              .filter((el) => Number(el.name.replace('%', '')) === value[oppId].prob)[0].id,
          },
        };
      }
  
      return obj;
    }, {});

    setIsLoading((prev) => ({
      ...prev,
      isLoading: true,
    }));

    setIsSaveDisabled(false);

    saveEditedOpportunities(Object.values(requestBody))
      .then(({ data }) => {
        if (!data.errors.length){
          setParamsBack();
          setIsShowEdited(false);
          showNotification(TEXT_ON_SUCCESS, undefined, 'green');
          setAtoms((prev) => ({
            ...prev,
            gridFormAtom: {
              items: {},
            },
            editedCellsAtom: {},
          }));
          saveLens();
          return data;
        }

        if (data.errors.length) {
          setIsLoading((prev) => ({
            ...prev,
            isLoading: false,
          }));

          const errorsToSet = data.errors.map((el) => ({
            oppId: el.id,
            oppName: el.name,
            message: el.errors, 
          }));

          const notificationText = `Failed to save following opportunities: ${errorsToSet.map((el) => el.oppName).join(', ')}`;

          showNotification(notificationText, undefined, 'red');
          setErrors(errorsToSet);
        }
      })
      .catch(() => {
        setIsLoading((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const handleCancel = () => {
    setParamsBack();
    setIsShowEdited(false);
    setAtoms((prev) => ({
      ...prev,
      gridFormAtom: {
        items: {},
      },
      editedCellsAtom: {},
    }));
    saveLens();
  };

  const handleMarkAsUpdated = () => {
    setMarked((prev) => ({
      ...prev,
      isUpdating: true,
    }));
    setIsLoading((prev) => ({
      ...prev,
      isLoading: true,
    }));
    markAsUpdated(marked)
      .then(({ data }: TResMarkAsUpdated) => {
        const successfullyUpdated = data.filter(({ isSuccess }) => isSuccess).map(({ id }) => id);
        if (!successfullyUpdated.length) {
          throw new Error();
        }
        showNotification(TEXT_ON_UPDATE, undefined, 'green');
        // uncheck marked but store them to update opportunities warnings state
        setMarked((prev) => ({
          toUpdate: [],
          updated: Array.from(new Set([...prev.updated, ...prev.toUpdate])),
          isUpdating: false,
        }));
        setAccountsToUpdate([]);
      })
      .catch(() => {
        showNotification(TEXT_ERROR, undefined, 'red');
        setMarked((prev) => ({
          ...prev,
          isUpdating: false,
        }));
      })
      .finally(() => {
        setIsLoading((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  return (
    <div className={css.editModePanel} id="filterPanel">
      <div className={css.upperRow}>
        <Text fontSize="24" cx={css.editModeTitle}>Editing Forecast</Text>
        <FlexRow spacing="6">
          <Switch
            cx={css.switchRoot}
            isDisabled={isLoading} 
            label="Show only edited"
            size="18"
            value={isShowEdited}
            onValueChange={() => {
              setIsShowEdited((prev) => !prev);
            }}
          />
          <Button
            isDisabled={isLoading} 
            caption="Cancel"
            color="gray"
            size="30"
            icon={cancelIcon}
            iconPosition="left"
            onClick={handleCancel}
            fill="white"
            cx={css.cancelBtn}
          />
          <Button
            isDisabled={
              !editedOppIds.length
              || isLoading
              || isValueValidationError
              || isSaveDisabled
            }
            caption="Save"
            color="green"
            size="30"
            icon={isLoading ? loadingIcon : tickIcon}
            iconPosition="left"
            onClick={() => {
              setIsSaveDisabled(true);
              getActiveImport().then(({ data }) => {
                if (data.importing.length && data.deleting.length) {
                  showNotification('There is an active actuals import in progress. Please wait and try again.', undefined, 'red');
                  return;
                }
                handleSave();
              });
            }}
          />
        </FlexRow>
      </div>
      <Filters />
      <FlexRow cx={css.lowerRow} spacing="12">
        <Button
          isDisabled={!marked.length || isLoading}
          caption="Mark as updated"
          size="30" 
          fill="none"
          icon={isUpdating ? loadingIcon : renderUpdateMarkedIcon}
          onClick={() => {
            getActiveImport().then(({ data }) => {
              if (data.importing.length && data.deleting.length) {
                showNotification(TEXT_IMPORT_ERROR, undefined, 'red');
                return;
              }
              handleMarkAsUpdated();
            });
          }}
        />
        <Text>{getCaptionForUpdateBtn()}</Text>
      </FlexRow>
      {maintenanceNotification && (
        <WarningAlert size="36" rawProps={{ id: 'global-maintenance-alert' }}>
          <Text size="30">Ongoing maintenance on the Pipeline might temporarily lead to some amounts being displayed incorrectly. We apologise for any inconvenience this may cause.</Text>
        </WarningAlert>
      )}
    </div>
  );
};

export default EditModePanel;
