import React from 'react';
import { 
  IconButton,
  Tooltip,
  Text,
  FlexRow,
} from '@epam/promo';
import { RenderCellProps } from '@epam/uui-core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import css from '../editModeGrid.module.scss';
import { bsrvIcon, bsrvIconDisabled } from '../../../assets/components/BsrvIcon';
import updateAtomsForQuerySelector from '../../../state/selectors/updateAtomsForQuerySelector';
import useGridForm from '../../../hooks/useOppDetailsModal';
import gridFormAtom from '../../../state/atoms/gridFormAtom';
import { probabilityDictionary } from '../const';

const monthsDictionary: { [key: string]: string } = {
  m1: 'JAN',
  m2: 'FEB',
  m3: 'MAR',
  m4: 'APR',
  m5: 'MAY',
  m6: 'JUN',
  m7: 'JUL',
  m8: 'AUG',
  m9: 'SEP',
  m10: 'OCT',
  m11: 'NOV',
  m12: 'DEC',
};

const BsrvCell = (props: RenderCellProps<any, any>) => {
  const { 
    rowProps: { value: { bsrv, values: { oppId, currName, isBaseRequiredFieldsSet } } },
  } = props;
  const setAtoms = useSetRecoilState(updateAtomsForQuerySelector);
  const { showModal } = useGridForm();
  const { items } = useRecoilValue(gridFormAtom);
  const { prob } = items[oppId];

  const isBsrv = Object.values(bsrv).some((el) => el != null);
  const bsrvKeys = Object.keys(bsrv);
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear(); 

  const mapBsrvToCells = bsrvKeys
    .map((el) => el.replace('b', 'p'))
    .filter((el, idx) => {
      const elYear = Number(el.split('_')[2]);
      return elYear > currentYear || (currentMonth <= idx && elYear === currentYear);
    })
    .reduce((acc: {
      [key: string]: number
    }, el) => ({
      ...acc,
      [el]: bsrv[el.replace('p', 'b')],
    }), {});

  const handleClick = async () => {
    showModal(
      oppId,
      probabilityDictionary[prob].id,
      {
        apply: () => {
          setAtoms(({ editedCellsAtom, gridFormAtom: gridForm, ...prev }) => ({
            ...prev,
            editedCellsAtom: {
              ...editedCellsAtom,
              [oppId]: editedCellsAtom[oppId] 
                ? [...editedCellsAtom[oppId], ...Object.keys(mapBsrvToCells)] 
                : Object.keys(mapBsrvToCells),
            },
            gridFormAtom: {
              items: {
                ...gridForm.items,
                [oppId]: {
                  ...gridForm.items[oppId],
                  ...mapBsrvToCells,
                },
              },
            },
          }));
        },
      },
    );
  };

  const renderBSRVTooltip = () => {
    const bsrvData = bsrvKeys
      .filter((el) => bsrv[el] != null)
      .map((el) => {
        const splittedEl = el.split('_');
        const monthId = splittedEl[0];
        const year = splittedEl[2];
        return (
          <div className={css.bsrvTooltipMonth} key={el}>
            <Text cx={css.bsrvMonthName}>{`${monthsDictionary[monthId]}-${year.slice(2)}`}</Text>
            <Text>{`${bsrv[el] ?? 0}k ${currName}`}</Text>
          </div>
        );
      });

    return (
      <div className={css.bsrvTooltip}>
        <Text cx={css.bsrvTitle}>Copy Workload Based Revenue to Forecast</Text>
        <FlexRow>
          {bsrvData}
        </FlexRow>
      </div>
    );
  };

  return (
    <div className={css.configCellContainer}>
      {isBsrv && (
        isBaseRequiredFieldsSet
          ? (
            <Tooltip
              renderContent={renderBSRVTooltip}
              placement="left"
              cx={css.bsrvTooltipRoot}
            >
              <IconButton 
                icon={bsrvIcon}
                onClick={handleClick}
              />
            </Tooltip>
          )
          : (
            <IconButton 
              icon={bsrvIconDisabled}
              isDisabled
            />
          )
      )}
    </div>
  );
};

export default BsrvCell;
