import { atom, selector } from 'recoil';
import { listOfCheckboxes, setColumnsFromPresetColsList } from '../../const/COLUMNS';
import queryParamsAtom from './queryParamsAtom';

const columnsSettingsAtom = atom({
  key: 'columnsSettings',
  default: selector({
    key: 'columnsSettings/default',
    get: ({ get }) => {
      const { columnsStore } = get(queryParamsAtom);
      return setColumnsFromPresetColsList(listOfCheckboxes, columnsStore);
    },
  }),
});

export default columnsSettingsAtom;
