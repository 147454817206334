import { atom, selector } from 'recoil';
import { ISortingItem } from '../../api/query';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';

export const initialSorting: ISortingItem[] = [{
  columnName: 'grouping',
  direction: 0,
}];

const sortingAtom = atom({
  key: 'sorting',
  default: selector({
    key: 'sorting/default',
    get: ({ get }) => {
      const { columnSettings: { sort } } = get(defaultViewPresetSelector);
      if (!sort.length) {
        return initialSorting;
      }

      return sort.map((el) => ({
        ...el,
        direction: el.direction === 'Descending' || el.direction === 1 ? 1 : 0,
      }) as ISortingItem);
    },
  }),
});

export default sortingAtom;
