import React from 'react';
import { DataColumnProps } from '@epam/uui-core';
import { Text } from '@epam/promo';
import { IOppRevenueHistoryResponseItem } from '../../../api/revenueHistory';
import OppHistoryRecord from './OppHistoryRecord';
import css from './OppHistoryModal.module.scss';
import OppHistoryCell from './OppHistoryCell';

export interface IProcessedItem extends IOppRevenueHistoryResponseItem {
  changedFields: (keyof IOppRevenueHistoryResponseItem)[],
  sumPlus: number,
  sum: number,
}

export const OppHistoryColumnsDictionary: {
  [key: string]: (year: number) => DataColumnProps<IProcessedItem>
} = {
  record: () => ({
    key: 'record',
    info: 'History',
    caption: <Text fontWeight="600" fontSize="12" cx="hideTooltip">REVENUE 1K</Text>,
    render: (item) => <OppHistoryRecord item={item} />,
    grow: 1,
    shrink: 0,
    width: 280,
    textAlign: 'left',
    fix: 'left',
  }),
  m1: (year) => ({
    key: `jan${year}`,
    info: `January-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`JAN-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m1}
        isChanged={item.changedFields.includes('m1')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m2: (year) => ({
    key: `feb${year}`,
    info: `February-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`FEB-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m2}
        isChanged={item.changedFields.includes('m2')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m3: (year) => ({
    key: `mar${year}`,
    info: `March-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`MAR-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m3}
        isChanged={item.changedFields.includes('m3')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m4: (year) => ({
    key: `apr${year}`,
    info: `April-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`APR-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m4}
        isChanged={item.changedFields.includes('m4')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m5: (year) => ({
    key: `may${year}`,
    info: `May-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`MAY-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m5}
        isChanged={item.changedFields.includes('m5')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m6: (year) => ({
    key: `jun${year}`,
    info: `June-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`JUN-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m6}
        isChanged={item.changedFields.includes('m6')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m7: (year) => ({
    key: `jul${year}`,
    info: `July-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`JUL-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m7}
        isChanged={item.changedFields.includes('m7')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m8: (year) => ({
    key: `aug${year}`,
    info: `August-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`AUG-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m8}
        isChanged={item.changedFields.includes('m8')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m9: (year) => ({
    key: `sep${year}`,
    info: `September-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`SEP-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m9}
        isChanged={item.changedFields.includes('m9')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m10: (year) => ({
    key: `oct${year}`,
    info: `October-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`OCT-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m10}
        isChanged={item.changedFields.includes('m10')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m11: (year) => ({
    key: `nov${year}`,
    info: `November-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`NOV-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m11}
        isChanged={item.changedFields.includes('m11')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  m12: (year) => ({
    key: `dec${year}`,
    info: `December-${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`DEC-${year}`}</Text>,
    render: (item) => (
      <OppHistoryCell
        value={item.m12}
        isChanged={item.changedFields.includes('m12')}
      />
    ),
    grow: 1,
    shrink: 0,
    width: 64,
    minWidth: 64,
    textAlign: 'left',
  }),
  sum: (year) => ({
    key: 'sum',
    info: `SUM ${year}`,
    caption: <Text cx={`${css.columnCaption} hideTooltip`}>{`SUM ${year}`}</Text>,
    render: (item) => <OppHistoryCell value={item.sum} />,
    grow: 1,
    shrink: 0,
    minWidth: 80,
    width: 80,
    textAlign: 'left',
  }),
  sumPlus: (year) => ({
    key: 'sumPlus',
    info: `SUM+ ${year}`,
    caption: <Text cx={`${css.columnCaption} ${css.sumPlus} hideTooltip`}>{`SUM+ ${year}`}</Text>,
    render: (item) => <OppHistoryCell value={item.sumPlus} />,
    grow: 1,
    shrink: 0,
    minWidth: 84,
    width: 84,
    textAlign: 'left',
  }),
};
