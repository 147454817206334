import { atom } from 'recoil';

const activeImportsAtom = atom<{
  deleting: string[],
  importing: string[],
  isActive: boolean,
  isDeleteComplete: boolean,
  canBeDeleted: boolean,
}>({
  key: 'activeImports',
  default: {
    deleting: [],
    importing: [],
    isActive: false,
    isDeleteComplete: false,
    canBeDeleted: false,
  },
});

export const refreshIncrementAtom = atom<number>({
  key: 'deletedImportsAtom',
  default: 0,
});

export default activeImportsAtom;

// 
