import React, { useState } from 'react';
import cx from 'classnames';
import { FlexRow, IconButton } from '@epam/promo';
import { ReactComponent as WarningIcon } from '@epam/assets/icons/common/notification-warning-fill-18.svg';
import { ReactComponent as UnfoldedIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import { ReactComponent as FoldedIcon } from '@epam/assets/icons/common/navigation-chevron-up-18.svg';
import css from '../../ImportActualModal.module.scss';
import GridSwitchContainer, { TGridId } from '../GridSwitchContainer/GridSwitchContainer';
import { IErrorItem } from '../ErrorsGrid/ErrorsGrid';
import { IUnlinkedItem } from '../UnlinkedAmountGrid/UnlinkedAmountGrid';

interface Props {
  totalActual: number,
  linkedAmount: number,
  lines: number,
  unlinkedAmount: number,
  period: string,
  errors?: IErrorItem[],
  unlinked?: IUnlinkedItem[],
}

const FileCheckResults = (props: Props) => {
  const {
    totalActual, linkedAmount, lines, unlinkedAmount, period,
    errors,
    unlinked,
  } = props;
  const [unfoldedGridId, setUnfoldedGridId] = useState<TGridId>(null);

  const handleGridToggle = (id: TGridId) => {
    if (id === unfoldedGridId) {
      setUnfoldedGridId(null);
    } else {
      setUnfoldedGridId(id);
    }
  };

  const isAmountUnfolded = unfoldedGridId === 'unlinkedAmount';
  const isErrorsUnfolded = unfoldedGridId === 'errors';

  const linesWithErrors = Array.from(new Set(errors?.map((el) => el.lineNumber))).length;

  return (
    <>
      <div className={css.fileCheckResults}>

        <FlexRow cx={css.row}>
          <span className={css.label}>Total Actual Amount: </span>
          <span>
            {` ${Math.round(totalActual * 100) / 100} ${totalActual ? '1K, USD' : ''}`} 
          </span>
        </FlexRow>

        <FlexRow cx={css.row}>
          <span className={css.label}>{'Amount Linked: '}</span>
          <span>
            {` ${Math.round(linkedAmount * 100) / 100} ${linkedAmount ? '1K, USD' : ''}`}            
          </span>
        </FlexRow>
        <FlexRow cx={css.row}>
          <span className={css.label}>Total Lines: </span>
          <span>{lines}</span>
        </FlexRow>
        <FlexRow cx={css.row}>
          <span className={css.label}>Period: </span>
          <span>{period}</span>
        </FlexRow>
        <FlexRow 
          cx={css.row} 
          onClick={unlinked?.length 
            ? () => handleGridToggle('unlinkedAmount') : undefined}
        >
          <span className={cx(css.label, { [css.active]: isAmountUnfolded })}>
            Amount Unlinked:
          </span>
        &nbsp;
          <span className={cx({ [css.active]: isAmountUnfolded })}>
            {` ${Math.round(unlinkedAmount * 100) / 100} ${unlinkedAmount ? '1K, USD' : ''}`} 
          </span>
          {!!unlinked?.length && (
            <IconButton
              cx={cx({ [css.activeIcon]: isAmountUnfolded })}
              icon={!isAmountUnfolded ? UnfoldedIcon : FoldedIcon}
            />
          )}
        </FlexRow>
        <FlexRow 
          cx={css.row} 
          onClick={errors?.length ? () => handleGridToggle('errors') : undefined}
        >
          <div className={cx(css.label, { [css.active]: isErrorsUnfolded })}>
            Lines with Errors:
          </div>
        &nbsp;
          <div className={cx(css.count, { [css.active]: isErrorsUnfolded })}>
            {linesWithErrors}
          </div>
          {!!linesWithErrors && <WarningIcon />}
          {!!linesWithErrors && (
            <IconButton
              cx={cx({ [css.activeIcon]: isErrorsUnfolded })}
              icon={!isErrorsUnfolded ? UnfoldedIcon : FoldedIcon}
            />
          )}
        </FlexRow>
      </div>
      <GridSwitchContainer 
        gridId={unfoldedGridId} 
        errors={errors ?? []} 
        unlinked={unlinked ?? []}
      />
    </>
  );
};

export default FileCheckResults;
