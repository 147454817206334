import React, { FC } from 'react';
import { IHierarchicalItems } from '../../../../state/atoms/advancedFiltersConstructorAtom';
import HierarchicalFiltersItem from './HierarchicalFiltersItem';

interface HierarchicalListProps {
  items: IHierarchicalItems,
  isSubgroup?: boolean,
}

const HierarchicalFiltersList: FC<HierarchicalListProps> = ({ items, isSubgroup }) => (
  <>
    {
      items.map((item) => (
        <HierarchicalFiltersItem
          key={item.id}
          item={item}
          isSubgroup={isSubgroup}
        />
      ))
    }
  </>
);
export default HierarchicalFiltersList;
