import { DataTableCell, PickerInput, PickerToggler } from '@epam/promo';
import { RenderEditorProps, useArrayDataSource } from '@epam/uui-core';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useOppDetailsModal from '../../../hooks/useOppDetailsModal';
import editedCellsAtom, { addToEdited } from '../../../state/atoms/editedCellsAtom';
import gridFormAtom from '../../../state/atoms/gridFormAtom';
import viewModeAtom from '../../../state/atoms/viewModeAtom';
import updateAtomsForQuerySelector from '../../../state/selectors/updateAtomsForQuerySelector';
import { TProbIds } from '../../modals/EditProbabilityModal/const';
import { probabilityDictionary } from '../const';
import css from '../editModeGrid.module.scss';

const ProbabilityGridCell = ({ value, ...props }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const { rowLens, rowProps: { value: { values } } } = props;
  const { items } = useRecoilValue(gridFormAtom);
  const [state, setState] = useState(
    items[values.oppId] && items[values.oppId].prob
      ? probabilityDictionary[items[values.oppId].prob].id 
      : probabilityDictionary[Math.abs(value)].id,
  );
  const originalValState = useState(probabilityDictionary[Math.abs(value)].id || null);
  const editedCells = useRecoilValue(editedCellsAtom);
  const setAtoms = useSetRecoilState(updateAtomsForQuerySelector);
  const { isLoading } = useRecoilValue(viewModeAtom);
  const { showModal } = useOppDetailsModal();
  
  const dataSource = useArrayDataSource({
    items: Object.values(probabilityDictionary),
    getId: (i: any) => i.id,
  }, []);

  const changeFormVal = (
    newVal: TProbIds | number,
    editorProps: RenderEditorProps<unknown, unknown, unknown>,
    isReverted?: boolean,
  ) => {
    setState(newVal);
    editorProps.onValueChange(newVal);
    setAtoms(({ editedCellsAtom: prevEditedCells, gridFormAtom: gridFormAtoms, ...prev }) => ({
      ...prev,
      editedCellsAtom: !isReverted 
        ? addToEdited(prevEditedCells, values.oppId, 'prob') 
        : {
          ...prevEditedCells,
          [values.oppId]: prevEditedCells[values.oppId].filter((el) => el !== 'prob'),
        },
      gridFormAtom: {
        items: {
          ...gridFormAtoms.items,
          [values.oppId]: {
            ...gridFormAtoms.items[values.oppId],
            prob: Number(newVal.toString().slice(newVal.toString().length - 4)),
          },
        },
      },
    }));
  };

  const handleChange = async (
    newVal: TProbIds, 
    editorProps: RenderEditorProps<unknown, unknown, unknown>,
  ) => {
    changeFormVal(newVal, editorProps);
    
    showModal(
      values.oppId,
      newVal,
      {
        editorProps,
        originalValState,
        reseter: changeFormVal,
      },
    );
  };

  useEffect(() => {
    ref.current?.parentElement?.parentElement?.setAttribute(
      'cell-for',
      'prob',
    );
  }, []);

  return (
    <DataTableCell
      {...rowLens.prop('prob').toProps()}
      isDisabled={!values.isBaseRequiredFieldsSet || isLoading}
      renderEditor={(editorProps) => (
        
        <PickerInput
          valueType="id" 
          selectionMode="single"
          dataSource={dataSource}
          isDisabled={!values.isBaseRequiredFieldsSet}
          renderToggler={(togglerProps) => (
            <div ref={ref}>
              <PickerToggler
                {...togglerProps}
                disableSearch
                cx={css.probToggler}
              />
            </div>
          )}
          disableClear
          {...editorProps}
          onValueChange={(newVal: TProbIds) => {
            handleChange(newVal, editorProps);
          }}
        />
      )}
      {...props}
      value={state}
      cx={[
        css.probCell,
        !values.isBaseRequiredFieldsSet && css.disabledCell,
        editedCells?.[values.oppId]?.includes('prob') && css.changedCell,
      ]}
    />
  );
};

export default ProbabilityGridCell;
