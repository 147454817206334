import React, { FC } from 'react';
import {
  Button, FlexRow, IconContainer, Tooltip, 
} from '@epam/promo';
import { ReactComponent as NotificationIcon } from '@epam/assets/icons/common/notification-error-fill-18.svg';
import { ReactComponent as ResetIcon } from '@epam/assets/icons/common/navigation-refresh-18.svg';
import css from './SlidingPanel.module.scss';

interface Props {
  onApply: () => void,
  onClose: () => void,
  onReset?: () => void,
  isApplyDisabled: boolean,
  isError?: boolean,
  errorMessage?: string,
}

const Footer: FC<Props> = ({
  onApply, onClose, isApplyDisabled, isError, errorMessage, onReset, 
}) => (
  <div className={css.footer}>
    <FlexRow>
      <div className={css.icon}>
        {
        isError && (
        <Tooltip content={errorMessage || ''} placement="top-start">
          <IconContainer icon={NotificationIcon} color="red" />
        </Tooltip>
        )
      }
      </div>
      {onReset && <Button caption="Reset" icon={ResetIcon} fill="light" onClick={onReset} />}
    </FlexRow>
    
    <div className={css.buttons}>
      <Button
        color="gray" 
        fill="white" 
        caption="Cancel" 
        onClick={onClose}
        size="36"
        cx={css.btn}
      />
      <Button
        caption="Apply"
        onClick={onApply}
        isDisabled={isApplyDisabled}
        size="36"
        cx={css.btn}
      />
    </div>
  </div>
);

export default Footer;
