import React, { FC } from 'react';

const PowerBIIcon: FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2858_213318)">
      <path d="M5.87685 14.4529C5.87685 15.0353 5.40038 15.5647 4.76508 15.5647C4.12979 15.5647 3.65332 15.0882 3.65332 14.4529V9.68824C3.65332 9.10589 4.12979 8.57648 4.76508 8.57648C5.40038 8.57648 5.87685 9.05295 5.87685 9.68824V14.4529Z" fill="white" />
      <path d="M9.0534 15.7235C9.0534 16.3059 8.57693 16.8353 7.94164 16.8353C7.30634 16.8353 6.88281 16.3588 6.88281 15.7235V8.47059C6.88281 7.88824 7.35928 7.35883 7.99458 7.35883C8.62987 7.35883 9.10634 7.8353 9.10634 8.47059V15.7235H9.0534Z" fill="white" />
      <path d="M2.64736 13.3941C2.64736 13.9765 2.17089 14.5059 1.53559 14.5059C0.900299 14.5059 0.423828 14.0294 0.423828 13.3941V10.8C0.423828 10.2176 0.900299 9.68823 1.53559 9.68823C2.17089 9.68823 2.64736 10.2176 2.64736 10.8V13.3941Z" fill="white" />
      <path d="M12.2302 16.8882C12.2302 17.4706 11.7537 18 11.1184 18C10.536 18 10.0596 17.4706 10.0596 16.8882V7.25294C10.0596 6.67059 10.536 6.14117 11.1713 6.14117C11.7537 6.14117 12.2831 6.61764 12.2831 7.25294V16.8882H12.2302Z" fill="white" />
      <path d="M15.7235 16.4118C15.5117 16.4118 15.3529 16.3588 15.1412 16.3059L13.8176 15.8824C13.6059 15.8294 13.447 15.5647 13.5529 15.3529C13.6059 15.1412 13.8706 14.9824 14.0823 15.0882L15.4588 15.5118C15.7235 15.6176 16.0412 15.5647 16.3059 15.3529C16.5706 15.1941 16.6764 14.8765 16.6764 14.6118V5.77059C16.6764 5.34706 16.4117 4.97647 16.0412 4.87058L3.07057 0.952938C2.80586 0.847056 2.48821 0.899997 2.22351 1.05882C2.01174 1.27059 1.85292 1.53529 1.85292 1.85294V7.94117C1.85292 8.20588 1.64115 8.3647 1.42939 8.3647C1.16468 8.3647 1.00586 8.15294 1.00586 7.94117V1.85294C1.00586 1.27059 1.27057 0.741173 1.74704 0.423526C2.22351 0.0529381 2.80586 -3.09199e-06 3.33527 0.15882L16.2529 4.07647C16.9941 4.28823 17.5235 5.02941 17.5235 5.82353V14.6647C17.5235 15.2471 17.2588 15.7765 16.7823 16.1471C16.4647 16.2529 16.0941 16.4118 15.7235 16.4118Z" fill="white" />
      <path d="M5.87685 14.4529C5.87685 15.0353 5.40038 15.5647 4.76508 15.5647C4.12979 15.5647 3.65332 15.0882 3.65332 14.4529V9.68824C3.65332 9.10589 4.12979 8.57648 4.76508 8.57648C5.40038 8.57648 5.87685 9.05295 5.87685 9.68824V14.4529Z" fill="white" />
      <path d="M9.0534 15.7235C9.0534 16.3059 8.57693 16.8353 7.94164 16.8353C7.30634 16.8353 6.88281 16.3588 6.88281 15.7235V8.47059C6.88281 7.88824 7.35928 7.35883 7.99458 7.35883C8.62987 7.35883 9.10634 7.8353 9.10634 8.47059V15.7235H9.0534Z" fill="white" />
      <path d="M2.64736 13.3941C2.64736 13.9765 2.17089 14.5059 1.53559 14.5059C0.900299 14.5059 0.423828 14.0294 0.423828 13.3941V10.8C0.423828 10.2176 0.900299 9.68823 1.53559 9.68823C2.17089 9.68823 2.64736 10.2176 2.64736 10.8V13.3941Z" fill="white" />
      <path d="M12.2302 16.8882C12.2302 17.4706 11.7537 18 11.1184 18C10.536 18 10.0596 17.4706 10.0596 16.8882V7.25294C10.0596 6.67059 10.536 6.14117 11.1713 6.14117C11.7537 6.14117 12.2831 6.61764 12.2831 7.25294V16.8882H12.2302Z" fill="white" />
      <path d="M15.7235 16.4118C15.5117 16.4118 15.3529 16.3588 15.1412 16.3059L13.8176 15.8824C13.6059 15.8294 13.447 15.5647 13.5529 15.3529C13.6059 15.1412 13.8706 14.9824 14.0823 15.0882L15.4588 15.5118C15.7235 15.6176 16.0412 15.5647 16.3059 15.3529C16.5706 15.1941 16.6764 14.8765 16.6764 14.6118V5.77059C16.6764 5.34706 16.4117 4.97647 16.0412 4.87058L3.07057 0.952938C2.80586 0.847056 2.48821 0.899997 2.22351 1.05882C2.01174 1.27059 1.85292 1.53529 1.85292 1.85294V7.94117C1.85292 8.20588 1.64115 8.3647 1.42939 8.3647C1.16468 8.3647 1.00586 8.15294 1.00586 7.94117V1.85294C1.00586 1.27059 1.27057 0.741173 1.74704 0.423526C2.22351 0.0529381 2.80586 -3.09199e-06 3.33527 0.15882L16.2529 4.07647C16.9941 4.28823 17.5235 5.02941 17.5235 5.82353V14.6647C17.5235 15.2471 17.2588 15.7765 16.7823 16.1471C16.4647 16.2529 16.0941 16.4118 15.7235 16.4118Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2858_213318">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PowerBIIcon;
