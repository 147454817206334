import api from './api';

interface MappedDetails {
  [key: string]: string | boolean | undefined,
}

export interface IOppDetails extends MappedDetails {
  id: string,
  name: string,
  crmUrl: string,
  oneCrmUrl: string,
  accountManager: string,
  salesManager: string,
  solutionPractice: string,
  technologyPractice: string,
  gbu: string,
  description: string,
  dealPartnerRelated: boolean,
  partnerName: string,
  commissionable?: string,
}

export const API_OPPID = 'opportunities';

export const getOppDetails = (id: string) => api
  .get(`${API_OPPID}/${id}`)
  .then((response): IOppDetails => response.data);
