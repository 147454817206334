import React, { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import axios from 'axios';

type ConfigState = {
  apiUrl: string,
  authRealm: string,
  authUrl: string,
  authClientId: string,
  authOnLoad: string,
  checkLoginIframe: boolean,
  baseCrmUrl: string,
  EfficacyMapeUrl: string,
  HealthCheckUrl: string,
  environment: string,
  apmUrl: string,
  appInsightConnectionString: string,
  transactionSampleRate: number,
  apmInitParams: string,
  apmLabels: string,
  globalMenuUrl: string,
  oneCrmProjectLink: boolean,
} | null;

type ConfigProviderProps = { children: React.ReactNode };

export const configState = atom<ConfigState>({
  key: 'configState',
  default: null,
});

const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [config, setConfig] = useRecoilState(configState);

  useEffect(() => {
    const fetchConfig = () => axios.get('./config.json');

    fetchConfig().then((response) => setConfig(response.data));
  }, [setConfig]);

  if (!config) return null;

  return (
    <div>{children}</div>
  );
};

export default ConfigProvider;
