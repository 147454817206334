import React, { useState, FC, useEffect } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import {
  TextInput, PickerInput, TextPlaceholder, VirtualList, RangeDatePicker, ControlGroup,
} from '@epam/promo';
import {
  useArrayDataSource, DataSourceState, useLazyDataSource,
} from '@epam/uui-core';
import { ReactComponent as SearchIcon } from '@epam/assets/icons/common/action-search-18.svg';
import { InputAddon } from '@epam/uui';
import { pickerItems } from './Fields';
import fetchAudit, { IAuditData, IAuditRequestBody } from '../../../../api/audit';
import AuditItem from './AuditItem';
import css from './AuditTab.module.scss';

interface AuditTabProps {
  oppId: string,
}

const defaultDateRangeValue = {
  from: dayjs().subtract(2, 'week').format('YYYY-MM-DD'), to: dayjs().format('YYYY-MM-DD'),
};

const AuditTab:FC<AuditTabProps> = ({ oppId }) => {
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string | undefined>('');
  const [fieldValue, setFieldValue] = useState<any>(null);
  const [dateRangeValue, setDateRangeValue] = useState<{ from: string | null, to: string | null }>(
    defaultDateRangeValue,
  );
  const [
    debouncedDateRangeValue,
    setDebouncedDateRangeValue,
  ] = useState<{ from: string | null, to: string | null }>(
    defaultDateRangeValue,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (dateRangeValue.from && dateRangeValue.to) {
      setDebouncedDateRangeValue({ from: dateRangeValue.from, to: dateRangeValue.to });
    } else {
      timer = setTimeout(() => {
        if (!dateRangeValue.from && !dateRangeValue.to) {
          setDebouncedDateRangeValue(defaultDateRangeValue);
          setDateRangeValue(defaultDateRangeValue);
        }
        if (!dateRangeValue.from && dateRangeValue.to) {
          setDebouncedDateRangeValue({ from: dateRangeValue.to, to: dateRangeValue.to });
          setDateRangeValue({ from: dateRangeValue.to, to: dateRangeValue.to });
        }
        if (dateRangeValue.from && !dateRangeValue.to) {
          setDebouncedDateRangeValue({ from: dateRangeValue.from, to: dateRangeValue.from });
          setDateRangeValue({ from: dateRangeValue.from, to: dateRangeValue.from });
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [dateRangeValue.from, dateRangeValue.to]);

  const [asyncVal, setAsyncVal] = React.useState<DataSourceState>({
    topIndex: 0,
    visibleCount: 10,
  });

  const dataSource = useLazyDataSource<IAuditData, string, string>({
    api(request: any) {
      const filter = fieldValue ?? null;
      const search = debouncedSearchValue || '';
      const requestBody: IAuditRequestBody = {
        opportunityId: oppId,
        range: debouncedDateRangeValue,
        filter,
        search,
        take: request.range.count,
        skip: request.range?.from ?? 0,
      };

      return fetchAudit(requestBody);
    },
    getId: (i: any) => i.id,
  }, [
    oppId,
    debouncedSearchValue,
    fieldValue,
    debouncedDateRangeValue.from,
    debouncedDateRangeValue.to,
  ]);

  const { getVisibleRows, getListProps } = dataSource.useView(asyncVal, setAsyncVal, {});
  const { rowsCount } = getListProps();

  const visibleRows = getVisibleRows().map((row) => (
    row.value ? (
      <AuditItem
        key={row.id + row.key + String(row.index)}
        userId={row.value.userId}
        userName={row.value.userName}
        actedOn={row.value.actedOn}
        actionType={row.value.actionType}
        appName={row.value.appName}
        changes={row.value.changes}
      />
    )
      : <TextPlaceholder wordsCount={7} />
  ));

  const fieldsData = useArrayDataSource({
    items: pickerItems,
    selectAll: false,
  }, []);

  return (
    <div className={css.auditTab}>
      <div className={css.filtersRow}>
        <TextInput
          cx={css.filterItem}
          inputMode="search"
          placeholder="Search"
          value={searchValue}
          onValueChange={setSearchValue}
          size="30"
          icon={SearchIcon}
        />
        <RangeDatePicker
          value={dateRangeValue}
          onValueChange={setDateRangeValue}
          format="MMM D, YYYY"
          size="30"
          disableClear
        />
      </div>
      <div className={cx(css.picker, { [css.emptyPicker]: !fieldValue })}>
        <ControlGroup>
          <InputAddon content="Fields:" />
          <PickerInput
            valueType="id"
            dataSource={fieldsData}
            value={fieldValue}
            onValueChange={setFieldValue}
            selectionMode="multi"
            size="30"
            maxItems={2}
            placeholder="All"
          />
        </ControlGroup>
      </div>
      <VirtualList
        cx={css.list}
        rows={visibleRows}
        rowsCount={rowsCount}
        value={asyncVal}
        onValueChange={setAsyncVal}
        role="listbox"
      />
    </div>
  );
};

export default AuditTab;
