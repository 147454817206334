import React from 'react';
import { DataColumnProps } from '@epam/uui-core';
import { Text } from '@epam/promo';
import ColumnCaptionText from '../ColumnCaptionText/ColumnCaptionText';
import css from './HistoryTabSettingsGrid.module.scss';

const columns: DataColumnProps[] = [
  {
    key: 'groupAccNumber',
    info: 'Group Acc Number',
    caption: <ColumnCaptionText caption="Group Acc Number" />,
    render: (item: any) => (
      <Text>{item.groupAccNumber}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
    cx: `${css.headerCell} ${css.firstHeaderCell}`,
  },
  {
    key: 'revenueType',
    info: 'Type',
    caption: <ColumnCaptionText caption="Type" />,
    render: (item: any) => (
      <Text>{item.revenueType}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
    cx: `${css.headerCell}`,
  },
];

export default columns;
