import api from './api';
import { TLogicalOperator } from '../const/ADVANCED_FILTERS';
import { FilterSettings } from './views';

export interface IAdvancedFilterValue { id: string | number, name: string }

export interface IAdvancedFilter {
  key: TQueryValuesKey,
  operator: string,
  order?: string,
  kind?: string,
  subgroupId?: string,
  value?: string | number | IAdvancedFilterValue[] | IAdvancedFilterValue,
}

export interface IAdvancedFilters {
  operator: TLogicalOperator,
  predicates: (IAdvancedFilter | IAdvancedFilters)[],
}

export interface ISortingItem {
  columnName: TQueryValuesKey,
  direction: 0 | 1 | 'Ascending' | 'Descending',
}

export interface IQueryRequestBody {
  columns: TQueryValuesKey[],
  aggregate: {
    currentGroupColumnName: TQueryValuesKey,
    nextGroupColumnName: TQueryValuesKey | null,
    groupColumnNames: TQueryValuesKey[],
  },
  aggregateFilters: {
    groupName: TQueryValuesKey,
    groupValue: any,
  }[] | null,

  predicate?: IAdvancedFilters,

  advancedFilters?: IAdvancedFilters,

  sort: ISortingItem[] | [] | null,

  search?: {
    value: string,
    columns: TQueryValuesKey[],
  },

  parameters: FilterSettings,
  
  viewId?: string,
  take: number,
  skip: number,
}

export interface IWarningData {
  name: string,
  text: string,
}

export interface ITagData {
  name: string,
  id: number,
}

export interface IndexableResponseValues {
  [key: string]: string | number | boolean | null | IWarningData[] | string[] | undefined;
}

export interface IQueryResponseValues extends IndexableResponseValues {
  oppName?: string,
  accName?: string,
  flag?: string,
  warnings?: {
    name: string,
    text: string,
  }[] | null,
  oppTags?: string[],
  oppTagFlag? : number,
  hasNotes? : number,
  prob?: string,
  frcst_a?: number | null,
  frcst_p?: number | null,
  gttl_a?: number | null,
  gttl_p?: number | null,
  ytd_a?: number | null,
  ytd_p?: number | null,
  GBU?: string | null,
  actualCloseDate?: string,
  estimatedCloseDate?: string,
  closeDate?: string,
  oppState?: string,
  RevenueTypeName?: string,
  MergedToOppName?: string,
  AnnualRevenue?: string,
  ITServices1KUSD?: string,
  EPAMWallet1KUSD?: string,
  EPAMShare1KUSD?: string,
  accState?: string,
  modBy?: string,
  projstr?: string,
  modDate?: string,
  oppAM?: string,
  oppAcquisitionName?: string,
  oppSM?: string,
  oppStream?: string,
  accAM?: string,
  accSM?: string,
  oppSecondarySM?: string,
  accDigitalSM?: string,
  RegionalGBU?: string,
  GBUPortfolio?: string,
  SuppRegionalGBU?: string,
  SuppGBUPortfolio?: string,
  SuppGBU?: string,
  accExtRepCountry?: string,
  accExtRepInd?: string,
  SS?: string,
  SU?: string,
  commisionable?: string,
  oppSalesType?: string,
  globalAccName?: string,
  oppCreatedOn?: string,
  oppCreatedBy?: string,
  SC?: string,
  PC?: string,
  OSC?: string[],
  oppAging?: number,
  oppCorrections?: string,
  accAcquisitionName?: string,
  oppTotalContractValue?: string,
  oppTargetCloseDate?: string,
  oppTargetCloseQuarter?: string,
  oppLeadSourceCode?: string,
  oppOrigin?: string,
  oppPartnerAccounts?: string[],
  oppId?: string,
  oppOutdated?: boolean,
  oppUnexpectedProbability?: boolean,
  oppDescription?: string,
  oppDescriptionShort?: string,
  oppIndustry?: string,
  oppPlatforms?: string[],
  oppPractices?: string[],
  oppContinuumOfferings?: string[],
  oppDealType?: string,
  commercialModel?: string,
  accSuppAM?: string[],
  currName?: string,
  oppPartnerSalesManagers?: string[],
  oppEngagementLeads?: string[],
  canEditOpp?: boolean,
}

export type TQueryValuesKey = keyof IQueryResponseValues;

export interface IQueryResponseBreakdown {
  [key: string]: number
}

export type TQueryBreakdownKey = keyof IQueryResponseBreakdown;

export interface IQueryResponseItem {
  values: IQueryResponseValues,
  breakdown: IQueryResponseBreakdown,
  count: number,
}

export interface ProcessedResponseItem extends IQueryResponseItem {
  type: TQueryValuesKey,
  name: string | number,
}

const API_QUERY = '/query';
const API_QUERY_EDIT = '/query/edit-mode';

const getType = (el: TQueryValuesKey) => {
  if (el === 'oppName') return 'oppId';
  return el;
};

const getParentId = (item: IQueryResponseItem, req: IQueryRequestBody) => {
  const index = req.aggregate.groupColumnNames
    .findIndex((el) => el === req.aggregate.currentGroupColumnName);

  if (index === 0) return null;
  const res = req.aggregate.groupColumnNames.slice(0, index).map((el) => item.values[getType(el)]?.toString() ?? 'null').join('-');

  return res;
};

const getId = (item: IQueryResponseItem, req: IQueryRequestBody) => {
  const type: TQueryValuesKey = req.aggregate.currentGroupColumnName;

  const index = req.aggregate.groupColumnNames
    .findIndex((el) => el === type);

  if (index === 0) return item.values[getType(type)]?.toString() || 'null';

  const res = req.aggregate.groupColumnNames.slice(0, index + 1).map((el) => item.values[getType(el)] ?? 'null').join('-');
  return res;
};

const fetchQuery = (reqBody: IQueryRequestBody, mode: 'edit' | 'view') => {
  const type: TQueryValuesKey = reqBody.aggregate.currentGroupColumnName;
  const address = mode === 'view' ? API_QUERY : API_QUERY_EDIT;
  return api
    .post(
      address,
      reqBody,
      {
        headers: {
          'Access-Control-Max-Age': 10, 
        },
      },
    )
    .then(({ data }) => ({
      items: data.items.map((item: IQueryResponseItem) => ({
        ...item,
        type,
        name: item.values[type],
        id: getId(item, reqBody),
        parentId: getParentId(item, reqBody),
      })), 
    }));
};

export default fetchQuery;
