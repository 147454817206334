import { selector } from 'recoil';
import { ISortingItem } from '../../api/query';
import { IBreakdownItem } from '../../components/Grid/VirtualList/VirtualListColumns';
import breakdownAtom from '../atoms/breakdownAtom';
import editedCellsAtom, { IEditedCells } from '../atoms/editedCellsAtom';
import editModeErrorsAtom, { TEditModeErrorsAtom } from '../atoms/editModeErrorsAtom';
import gridFormAtom, { IGridFormAtom } from '../atoms/gridFormAtom';
import markedForUpdateAtom, { IMarkedForUpdateAtom } from '../atoms/markedForUpdateAtom';
import queryParamsAtom, { ISettings } from '../atoms/queryParamsAtom';
import searchAtom from '../atoms/searchAtom';
import settingsSlideoutAtom, { intialSettings } from '../atoms/settingsSlideoutAtom';
import sortingAtom from '../atoms/sortingAtom';
import viewModeAtom, { IViewModeAtom } from '../atoms/viewModeAtom';
import advancedFiltersConstructorAtom, { IAdvancedFiltersConstructorAtom } from '../atoms/advancedFiltersConstructorAtom';

export interface IAtoms {
  queryParamsAtom: ISettings,
  breakdownAtom: IBreakdownItem[],
  sortingAtom: ISortingItem[],
  searchAtom: string,
  viewModeAtom: IViewModeAtom,
  markedForUpdateAtom: IMarkedForUpdateAtom,
  gridFormAtom: IGridFormAtom,
  editedCellsAtom: IEditedCells,
  settingsSlideoutAtom: typeof intialSettings,
  editModeErrorsAtom: TEditModeErrorsAtom,
  advancedFiltersConstructorAtom: IAdvancedFiltersConstructorAtom,  
}

const updateAtomsForQuerySelector = selector<IAtoms>({
  key: 'updateMultipleAtoms',
  get: ({ get }) => ({
    queryParamsAtom: get(queryParamsAtom),
    breakdownAtom: get(breakdownAtom),
    sortingAtom: get(sortingAtom),
    searchAtom: get(searchAtom),
    viewModeAtom: get(viewModeAtom),
    markedForUpdateAtom: get(markedForUpdateAtom),
    gridFormAtom: get(gridFormAtom),
    editedCellsAtom: get(editedCellsAtom),
    settingsSlideoutAtom: get(settingsSlideoutAtom),
    editModeErrorsAtom: get(editModeErrorsAtom),
    advancedFiltersConstructorAtom: get(advancedFiltersConstructorAtom),
  }),
  set: ({ set }, value: any) => {
    set(queryParamsAtom, value.queryParamsAtom);
    set(breakdownAtom, value.breakdownAtom);
    set(sortingAtom, value.sortingAtom);
    set(searchAtom, value.searchAtom);
    set(viewModeAtom, value.viewModeAtom);
    set(markedForUpdateAtom, value.markedForUpdateAtom);
    set(gridFormAtom, value.gridFormAtom);
    set(editedCellsAtom, value.editedCellsAtom);
    set(settingsSlideoutAtom, value.settingsSlideoutAtom);
    set(editModeErrorsAtom, value.editModeErrorsAtom);
    set(advancedFiltersConstructorAtom, value.advancedFiltersConstructorAtom);
  },
});

export default updateAtomsForQuerySelector;
