import React from 'react';
import { LazyListView } from '@epam/uui-core';
import { VirtualList } from '@epam/promo';
import { ImportHistoryItemInfo } from '../../../../api/importHistory';
import ImportHistoryItem from './ImportHistoryItem';

interface ImportHistoryListProps {
  view: LazyListView<ImportHistoryItemInfo, string, string>;
  state: any;
  onChange: any;
}

const ImportHistoryList = (props: ImportHistoryListProps) => {
  const { view, state, onChange } = props;

  const { rowsCount } = view.getListProps();

  const visibleRows = view.getVisibleRows()
    .map((row: any) => (
      <ImportHistoryItem 
        key={row.value.id} 
        rowValues={row.value}
      />
    ));

  return (
    <VirtualList
      value={state}
      onValueChange={onChange}
      role="listbox"
      rows={visibleRows}
      rowsCount={rowsCount}
    />
  );
};

export default ImportHistoryList;
