import api from './api';

export interface ImportHistoryItemInfo {
  id: string,
  createdByUser: string,
  createdOn: string,
  name: string,
  fileSize: number,
  allRecords: number,
  importStatus: string,
  importedRecords: number,
  year: number,
  month: number,
  amountLinked: number,
  amountUnlinked: number,
  actualRevenueImportErrors: Array<{
    lineNumber: number,
    columnName: string,
    value: string,
    errorText: string,
  }>,
  actualRevenueImportUnlinked: Array<{
    projectName: string,
    revenueType: string,
    amountInUsd: number,
  }>,
}

export type ImportHistoryResponse = ImportHistoryItemInfo[];

const BASE_URL = '/actual-revenue-import';

export const importHistoryAPI = {
  async getHistory(take?: number, skip?: number, id?: string): Promise<ImportHistoryResponse> {
    const baseRequest = `/history?take=${take}&skip=${skip}`;
    const res = await api.get<ImportHistoryItemInfo[]>(
      id ? (`${BASE_URL}${baseRequest}&id=${id}`) : `${BASE_URL}${baseRequest}`,
    );
    return res.data;
  },
  async getOne(id: string): Promise<ImportHistoryResponse> {
    const baseRequest = `/history?id=${id}`;
    const res = await api.get<ImportHistoryItemInfo[]>(`${BASE_URL}${baseRequest}`);
    return res.data;
  },
  async getFileFromHistory(id: string): Promise<Blob> {
    const res = await api.get<File>(
      `${BASE_URL}/${id}/file`,
      {
        responseType: 'arraybuffer',
      },
    );
    const blob = new Blob([res.data], {
      type: res.headers['content-type'],
    });
    return blob;
  },
};
