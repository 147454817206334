import { 
  DataPickerRow,
  FlexRow,
  IconButton,
  PickerInput,
  Text, 
} from '@epam/promo';
import { useArrayDataSource } from '@epam/uui-core';
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import { useRecoilState } from 'recoil';
import { changeFlag } from '../../../../api/editFlagsAndTags';
import FlagIcon from '../../../../assets/components/flagIcon';
import { useNotification } from '../../../../hooks/useNotification';
import css from '../VirtualList.module.scss';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';
import oppTagsFlagNotesAtom from '../../../../state/atoms/oppTagsFlagNotesAtom';

interface Props {
  color: number,
  disabled: boolean,
  oppId?: string,
  canEditOpp?: boolean,
}

interface Item {
  id: number,
  name: string,
}

const flagsData: Item[] = [
  {
    id: 895450001,
    name: 'Red',
  },
  {
    id: 895450002,
    name: 'Yellow',
  },
  {
    id: 895450003,
    name: 'Green',
  },
  {
    id: 895450004,
    name: 'No Flag',
  },
];

const TEXT_ON_SUCCESS = 'Flag is set';
const TEXT_ERROR = 'Flag cannot be set';

const OppFlagPicker: FC<Props> = ({
  color, oppId, disabled, canEditOpp = true, 
}) => {
  const [{ isLoading, mode }, setIsLoading] = useRecoilState(viewModeAtom);
  const [state, setState] = useRecoilState(oppTagsFlagNotesAtom);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      [oppId!]: {
        ...prev[oppId!],
        oppFlag: color,
      },
    }));
  }, []);

  const value = state?.[oppId!]?.oppFlag || color;
  const [localLoading, setLocalLoading] = useState(false);

  const { showNotification } = useNotification();
  
  const dataSource = useArrayDataSource({
    items: flagsData,
  }, []);

  const renderTogglerIcon = () => <FlagIcon color={value} cx={css.flagBtn} />;

  const handleChange = (newVal: number) => {
    setLocalLoading(true);
    setIsLoading((prev) => ({
      ...prev,
      isLoading: true,
    }));
    if (oppId) {
      changeFlag(oppId, newVal)
        .then(() => {
          setLocalLoading(false);
          setIsLoading((prev) => ({
            ...prev,
            isLoading: false,
          }));
          setState((prev) => ({
            ...prev,
            [oppId!]: {
              ...prev[oppId!],
              oppFlag: newVal,
            },
          }));
          showNotification(TEXT_ON_SUCCESS, undefined, 'green');
        })
        .catch(() => {
          setLocalLoading(false);
          setIsLoading((prev) => ({
            ...prev,
            isLoading: false,
          }));
          showNotification(TEXT_ERROR, undefined, 'red');
        });
    } 
  };

  const renderRow = (item: Item) => (
    <FlexRow spacing="6">
      <FlagIcon color={item.id} cx={css.flagBtn} />
      <Text>{item.name}</Text>
    </FlexRow>
  );

  return (
    <PickerInput
      valueType="id"
      selectionMode="single"
      disableClear
      dataSource={dataSource}
      value={value}
      isDisabled={disabled || (!canEditOpp && mode === 'view') || localLoading || isLoading}
      onValueChange={handleChange}
      renderToggler={(props) => (
        <IconButton 
          {...props}
          icon={localLoading ? LoaderIcon : renderTogglerIcon}
        />
      )}
      renderRow={(props) => (
        <DataPickerRow
          {...props} 
          renderItem={renderRow}
        />
      )}
    />
  );
};

export default OppFlagPicker;
