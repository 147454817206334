import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Switch, Text, WarningAlert } from '@epam/promo';
// import ViewPresetsMenu from './ViewPresetsMenu';
import css from './FiltersPanel.module.scss';
import ExportDatePicker from './ExportDatePicker/ExportDatePicker';
import EditButton from './components/EditButton';
import Filters from './components/Filters';
import { PresetsPanel } from './PresetsPanel/PresetsPanel';
import { useViewPresets } from '../../hooks/useViewPresets';
import { useNotification } from '../../hooks/useNotification';
import defaultViewPresetSelector from '../../state/selectors/defaultViewPresetSelector';
import { viewStateAtom, viewStateSnapshotAtom } from '../../state/atoms/viewStateAtom';
import { useMaintenanceNotificationState } from '../../hooks/useMaintanceNotificationState';

const FiltersPanel = () => {
  const { switchAutosave } = useViewPresets();
  const { showNotification } = useNotification();
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  const viewState = useRecoilValue(viewStateAtom);
  const setViewStateSnapshot = useSetRecoilState(viewStateSnapshotAtom);
  const { maintenanceNotification } = useMaintenanceNotificationState();

  const onAutosaveSwitch = async () => {
    if (defaultViewPreset.isViewAutosaved) {
      setViewStateSnapshot(viewState);
    }
    await switchAutosave();
    const notificationText = !defaultViewPreset.isViewAutosaved
      ? 'Changes of filters and columns are saved and will be saved in automatic mode'
      : 'From this point on the filters and columns will not change';
    showNotification(notificationText);
  };
  
  return (
    <div className={css.root} id="filterPanel">
      <div className={css.header}>
        {/* <ViewPresetsMenu /> */}
        <div>
          <Text color="gray80" fontWeight="600" lineHeight="30" fontSize="24">
            {defaultViewPreset.gridType !== 'Goals' ? 'Revenue Forecast' : defaultViewPreset.name}
          </Text>
          <Switch
            cx={css.autosaveSwitch}
            size="24"
            label="Filter autosaving"
            value={defaultViewPreset.isViewAutosaved}
            onValueChange={onAutosaveSwitch}
          />
        </div>
        {
          defaultViewPreset.gridType !== 'Goals'
            ? <PresetsPanel />
            : null
        }
        <div className={css.presetsBtns}>
          <ExportDatePicker />
          <EditButton cx={css.editBtn} />
        </div>
      </div>
      <Filters />
      {maintenanceNotification && (
        <WarningAlert size="36" rawProps={{ id: 'global-maintenance-alert' }}>
          <Text size="30">Ongoing maintenance on the Pipeline might temporarily lead to some amounts being displayed incorrectly. We apologise for any inconvenience this may cause.</Text>
        </WarningAlert>
      )}
    </div>
  );
};

export default FiltersPanel;
