import api from './api';
import { IQueryRequestBody } from './query';

const exportExcel = async (reqBody: IQueryRequestBody): Promise<Blob> => {
  const res = await api.post<File>(
    'export/excel',
    reqBody,
    {
      responseType: 'arraybuffer',
    },
  );
  const blob = new Blob([res.data], {
    type: res.headers['content-type'],
  });
  return blob;
};

export default exportExcel;
