import React, { FC } from 'react';
import { Tooltip } from '@epam/promo';
import { ReactComponent as WarningIcon } from '@epam/assets/icons/common/notification-warning-fill-18.svg';
import { useRecoilValue } from 'recoil';
import css from '../VirtualList.module.scss';
import markedForUpdateAtom from '../../../../state/atoms/markedForUpdateAtom';

interface Props {
  oppId?: string,
  oppAging?: number,
  oppOutdated?: boolean,
  oppUnexpectedProbability?: boolean
}

const OppWarning: FC<Props> = ({
  oppOutdated, oppUnexpectedProbability, oppAging, oppId, 
}) => {
  const { updated } = useRecoilValue(markedForUpdateAtom);
  const isUpdated = oppId && updated.includes(oppId);
  const renderWarningTooltipContent = () => {
    const probabilityCriteria = [
      { title: 'Past', value: '80% +' },
      { title: 'Current/Next', value: '80% +' },
      { title: 'Next + 1', value: '60% +' },
      { title: 'Next + 2', value: '40% +' },
    ];

    return (
      <div className={css.oppTooltip}>
        {
          !!oppUnexpectedProbability && (
            <>
              <h4>Probability doesn’t meet the criteria</h4>
              <div className={css.row}>
                <h5>Month</h5>
                <h5>Min acceptable %</h5>
              </div>
              <hr />
              {
                probabilityCriteria.map((el) => (
                  <div className={css.row} key={el.title}>
                    <p>{el.title}</p>
                    <p>{el.value}</p>
                  </div>
                ))
              }
            </>
          )
        }
        {
          (!!oppOutdated && !isUpdated) && !!oppUnexpectedProbability && !!oppAging
          && <div className={css.separator} />
        }
        {
          !!oppOutdated && !!oppAging && !isUpdated
          && (
          <>
            <h4>Outdated</h4>
            <p>{`${oppAging} days without update`}</p>
          </>
          )
        }
      </div>
    );
  };

  return (
    <Tooltip
      content={renderWarningTooltipContent()}
      placement={oppOutdated && oppUnexpectedProbability ? 'right-end' : 'right'}
    >
      <div className={css.oppWarning}>
        <WarningIcon />
      </div>
    </Tooltip>
  );
};

export default OppWarning;
