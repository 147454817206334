import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { forIn } from 'lodash';
import HierarchicalFiltersList from './components/HierarchicalFiltersList';
import { SlidingPanel } from '../common/SlidingPanel/SlidingPanel';
import advancedFiltersConstructorAtom, { IHierarchicalItem, IHierarchicalItems } from '../../../state/atoms/advancedFiltersConstructorAtom';
import PanelWithBtns from '../common/SlidingPanel/PanelWithBtns';
import css from './AdvancedFiltersSlideOut.module.scss';
import { useViewPresets } from '../../../hooks/useViewPresets';
import defaultViewPresetSelector from '../../../state/selectors/defaultViewPresetSelector';
// import dndCursorStyleAtom from '../../../state/atoms/dndCursorStyleAtom';
import { View } from '../../../api/views';
import { parseAdvancedFiltersToView } from '../../../const/ADVANCED_FILTERS';
import useSlideout from '../../../hooks/useSlideout';
import { IAdvancedFilter, TQueryValuesKey } from '../../../api/query';

export const getOperandsCountInsideSubgroup = (subgroup: IHierarchicalItem): number => {
  let count = 0;
  if (subgroup.children && subgroup.children.length) {
    count += subgroup.children.length;
  }
  count += Object.keys(subgroup.filters).length;
  return count;
};

export const hasEmptyFiltersValues = (
  filters: { [key: TQueryValuesKey]: IAdvancedFilter },
  hasError?: boolean,
): boolean => {
  let flag = hasError ?? false;
  forIn(filters, (elem) => {
    if (elem.value === undefined) {
      flag = true;
    }
  });
  return flag;
};

const AdvancedFiltersSlideOut = () => {
  const [
    { constructor: items, cache }, setSlideoutState,
  ] = useRecoilState(advancedFiltersConstructorAtom);
  const { currentSlideout, closeSlideout } = useSlideout();
  const [isError, setIsError] = useState(false);

  const isSame = JSON.stringify(items) === JSON.stringify(cache);
  
  const { updateViewPresetInList } = useViewPresets();
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  // const cursorStyle = useRecoilValue(dndCursorStyleAtom);

  useEffect(() => {
    let hasError = false;
    const validate = (filterItems: IHierarchicalItems) => {
      filterItems.forEach((item) => {
        if (defaultViewPreset.gridType === 'Goals' ? getOperandsCountInsideSubgroup(item) < 2 && (
          !Object.keys(item.filters).every((el) => el === 'accName' || el === 'globalAccName')
        ) : (
          getOperandsCountInsideSubgroup(item) < 2
        )) {
          hasError = true;
        }
        if (item.filters) {
          hasError = hasEmptyFiltersValues(item.filters, hasError);
        }
        if (item.children && item.children.length) {
          validate(item.children);
        }
      });
    };
    if (getOperandsCountInsideSubgroup(items[0]) === 0 || (defaultViewPreset.gridType === 'Goals' && getOperandsCountInsideSubgroup(items[0]) === 1)) {
      hasError === false;
    } else {
      validate(items);
    }
    setIsError(hasError);
  }, [JSON.stringify(items)]);

  const handleOnClose = () => {
    closeSlideout();
    setSlideoutState((prev) => ({
      ...prev,
      constructor: prev.cache,
    }));
  };

  const handleOnApply = async () => {
    if (getOperandsCountInsideSubgroup(items[0]) === 0) { 
      setSlideoutState((prev) => ({
        ...prev,
        changeCounter: prev.changeCounter + 1,
        isOpen: false,
        isConstructor: false,
        constructor: [{
          id: '1',
          operator: prev.constructor[0].operator ?? 'And',
          filters: {},
          children: [],
          kind: 'subgroup',
        }],
      }));
    } else {
      setSlideoutState((prev) => ({
        ...prev,
        changeCounter: prev.changeCounter + 1,
        isOpen: false,
        cache: prev.constructor,
      }));
    }

    closeSlideout();

    if (defaultViewPreset.isViewAutosaved) {
      const updatedPreset: View = {
        ...defaultViewPreset,
        advancedFiltersSettings: parseAdvancedFiltersToView(items),
      };
      await updateViewPresetInList(updatedPreset);
    }
  };

  return (
    <SlidingPanel
      width={450}
      position="right"
      isVisible={currentSlideout === 'advFiltersConstructor'}
      overflow="auto"
      style={{
        maxHeight: '100%',
      }}
    >
      <PanelWithBtns
        onApply={handleOnApply}
        onClose={handleOnClose}
        isApplyDissabled={isError || isSame}
        caption="Advanced Filters"
        cx={css.rootPanel}
        isError={isError}
        errorMessage="Each logical operator must have at least 2 elements AND each filter must have selected value(s)"
      >
        <HierarchicalFiltersList items={items} />
      </PanelWithBtns>
    </SlidingPanel>
  );
};

export default AdvancedFiltersSlideOut;
