import { pick, pickBy, isEmpty } from 'lodash';
import { IEditable } from '@epam/uui-core';
import { TModalData, TModalFieldsData, TModalFieldsDataKeys } from '../../../api/editProbability';

type IFields = {
  [key in TModalFieldsDataKeys]: string;
};

export type TProbIds = 895450000 | 895450010 | 895450020 | 895450040
| 895450060 | 895450080 | 895450090 | 895450100 | number;

export const fieldsLabels: IFields = {
  oppDealType: 'Deal Type',
  oppEngagementType: 'Engagement Type',
  dealIsPartnerRelated: 'Deal is Partner related',
  oppPartnerAccounts: 'Partner Accounts',
  oppPlatforms: 'Platforms',
  oppContinuumOfferings: 'Continuum Offerings',
  gttl: 'GTTL (Current FY), 1K',
  gttl1k: 'GTTL (Next FY), 1K',
  oppDescription: 'Description',
  commercialModel: 'Commercial Model',
  closeDate: 'Est. Start Date',
  durationMonths: 'Duration, months',
  oppAM: 'Account Manager',
  oppLeadPractice: 'Lead Practice',
  oppPractices: 'Practices',
  oppIndustry: 'Industry',
};

export const applyNotApplicableLogic = (
  fieldProps: IEditable<Array<{ id: string, name: string }>>,
  notApplicableId: string,
) => {
  if (
    fieldProps.value
    && fieldProps.value?.some((val) => val.id === notApplicableId)
    && fieldProps.value.length !== 1
  ) {
    if (fieldProps.value[0].id === notApplicableId) {
      fieldProps.onValueChange([fieldProps.value[1]]);
    }
    if (fieldProps.value[fieldProps.value.length - 1].id === notApplicableId) {
      fieldProps.onValueChange([{ id: notApplicableId, name: 'Not Applicable' }]);
    }
  }
};

export const mapDataForEditProbModal = (data: TModalData, probId: TProbIds) :TModalData | null => {
  let mappedFields: TModalFieldsData | null;
  // we take fields that relate to a specific percentage of probability
  switch (probId) {
    case 895450010:
      mappedFields = pick(data.fields, ['oppDealType', 'oppEngagementType', 'gttl', 'gttl1k', 'oppPractices', 'oppLeadPractice', 'oppIndustry']);
      break;
    case 895450020:
      mappedFields = pick(data.fields, [
        'oppDealType', 'oppEngagementType', 'oppPlatforms',
        'oppContinuumOfferings', 'dealIsPartnerRelated', 'oppPartnerAccounts', 'oppPractices',
        'oppLeadPractice', 'gttl', 'gttl1k', 'oppDescription', 'oppIndustry',
      ]);
      break;
    case 895450040:
      mappedFields = pick(data.fields, [
        'oppDealType', 'oppEngagementType', 'oppPlatforms',
        'oppContinuumOfferings', 'dealIsPartnerRelated', 'oppPartnerAccounts',
        'gttl', 'gttl1k', 'oppDescription', 'oppPractices', 'oppLeadPractice',
        'oppIndustry',
      ]);
      break;
    case 895450060:
    case 895450080:
    case 895450090:
    case 895450100:
      mappedFields = pick(data.fields, [
        'oppDealType', 'oppEngagementType', 'oppPlatforms',
        'oppContinuumOfferings', 'dealIsPartnerRelated', 'oppPartnerAccounts',
        'gttl', 'gttl1k', 'oppDescription', 'commercialModel', 'closeDate',
        'durationMonths', 'oppAM', 'oppPractices', 'oppLeadPractice',
        'oppIndustry', 
      ]);
      break;
    case 895450000:
      mappedFields = pick(data.fields, ['oppDealType']);
      break;
    default: 
      mappedFields = null;
  }
  // take only empty fields
  mappedFields = pickBy(mappedFields, (value, key) => {
    if (key === 'oppPartnerAccounts' && mappedFields?.dealIsPartnerRelated === 895450000) {
      return false;
    }
    if (key === 'gttl' && mappedFields?.gttl1k !== null) {
      return false;
    }
    if (key === 'gttl1k' && mappedFields?.gttl !== null) {
      return false;
    }
    return value === null;
  });
  if (isEmpty(mappedFields)) {
    return null;
  } 
  return {
    ...data,
    fields: { ...mappedFields },
  };
};
