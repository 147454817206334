import { uniqBy } from 'lodash';
import api from './api';
import { defaultColumnsIds } from '../components/FiltersPanel/ViewPresetsMenu/const';
import { IAdvancedFilters, TQueryValuesKey } from './query';
import { Filters } from '../const/FILTERS';
import { getOrderStr } from '../state/atoms/viewStateAtom';

export interface IColumn {
  id: TQueryValuesKey,
  show: boolean,
  order: string,
  visible?: boolean,
  width?: number | null,
  fix?: 'left' | 'right',
}

export interface IColumns {
  col: Array<IColumn>,
  accAttr: Array<IColumn>,
  oppAttr: Array<IColumn>,
}

export interface FilterSettings extends Omit<Filters, 'search' | 'revenueYear'> {
  year: number,
}

export interface View {
  id: string,
  name: string,
  isDefault: boolean,
  isPersonalView: boolean,
  isViewAutosaved: boolean,
  gridType: 'RevenueForecast' | 'Goals',
  columnSettings: {
    columns: Omit<IColumns, 'tagsAndFlags' | 'warnings'>,
    sort: Array<{
      columnName: TQueryValuesKey,
      direction: 0 | 1 | 'Ascending' | 'Descending',
    }>
  },
  multiGrouping: {
    groups: Array<TQueryValuesKey>,
    collapse: boolean,
    tagsFlagsNotes: boolean,
    warnings: boolean,
    width: number | null,
  },
  permanentFiltersSettings: FilterSettings,
  advancedFiltersSettings: IAdvancedFilters,
  state: 'Active' | 'Inactive',
  isNew: boolean,
}

export type OmittedView = Omit<View, 'id' | 'isDefault' | 'isPersonalView' | 'isNew'>;

const processColumns = (arr: IColumn[], defaultList: TQueryValuesKey[]) => {
  const columnsIds = arr.map((el) => el.id);
  const missingCols = defaultList.filter((el) => !columnsIds.includes(el))
    .map((el, i) => ({
      id: el,
      show: false,
      order: getOrderStr(i, 'e') as string,
    }));

  const processedData = arr.filter((el) => el.id != null);

  return [...processedData, ...missingCols];
};

export const viewsAPI = {
  async getViews(gridType: 'RevenueForecast' | 'Goals' = 'RevenueForecast'): Promise<Array<View>> {
    const { data } = await api.get<Array<View>>(`/views/list?gridType=${gridType}`);
    const isDefaultPresent = data.length && data.some((el) => el.isDefault);

    const getIsDefault = (value: boolean, idx: number) => {
      if (!isDefaultPresent && idx === 0) return true;
      return value;
    };

    const processedData = data.map((view, idx) => ({
      ...view,
      isDefault: getIsDefault(view.isDefault, idx),
      columnSettings: { 
        ...view.columnSettings,
        columns: {
          col: processColumns(uniqBy(view.columnSettings.columns.col, 'id'), defaultColumnsIds.col),
          oppAttr: processColumns(uniqBy(view.columnSettings.columns.oppAttr, 'id'), defaultColumnsIds.oppAttr),
          accAttr: processColumns(uniqBy(view.columnSettings.columns.accAttr, 'id'), defaultColumnsIds.accAttr),
        },
      },
    }));
    return processedData;
  },

  async saveNewView(newView: OmittedView): Promise<string> {
    const res = await api.post<string>('/views', newView);
    return res.data;
  },
  async deleteView(id: string): Promise<void> {
    return api.delete<void, any>(`/views/${id}`);
  },
  async updateView(id: string, view:OmittedView): Promise<void> {
    return api.put<void, any>(`/views/${id}`, view);
  },
  async markAsDefault(id: string): Promise<void> {
    return api.put<void, any>(`/views/${id}/markasdefault`);
  },
  async shareView(
    id: string,
    userIds: Array<string> | null,
    teamIds: Array<string> | null,
  ): Promise<void> {
    return api.put<void, any>(`/views/${id}/share`, { userIds, teamIds });
  },
};
