import React, { FC } from 'react';

const OppEmptyNotesIcon: FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8 0H1.2C0.54 0 0 0.54 0 1.2V12L2.4 9.6H10.8C11.46 9.6 12 9.06 12 8.4V1.2C12 0.54 11.46 0 10.8 0ZM10.8 8.4H1.92L1.2 9.12V1.2H10.8V8.4Z" fill="#6C6F80" />
  </svg>
);

const OppNotesIcon: FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 1.2C0 0.534 0.54 0 1.2 0H10.8C11.4627 0 12 0.537258 12 1.2V8.4C12 9.06274 11.4627 9.6 10.8 9.6C10.8 9.6 5.77719 9.6 3.26579 9.6C2.97719 9.6 2.4 9.6 2.4 9.6L0 12V1.2ZM10.2 3H2.4V1.8H10.2V3ZM10.2 5.4H2.4V4.2H10.2V5.4ZM7.8 7.8H2.4V6.6H7.8V7.8Z" fill="#6C6F80" />
  </svg>
);

export { OppEmptyNotesIcon, OppNotesIcon };
