import { RenderEditorProps, useUuiContext } from '@epam/uui-core';
import React from 'react';
import { TModalFieldsData, editProbabilityAPI } from '../api/editProbability';
import { mapDataForEditProbModal, TProbIds } from '../components/modals/EditProbabilityModal/const';
import EditProbabilityModal from '../components/modals/EditProbabilityModal/EditProbabilityModal';
import { useNotification } from './useNotification';

interface IShowModalParams {
  editorProps?: RenderEditorProps<unknown, unknown, unknown>,
  originalValState?: [number | null, React.Dispatch<React.SetStateAction<number | null>>],
  reseter?: any,
  apply?: () => void,
  changedCellsPrevValues?: Array<{ id: string, value: number | null }>,
  setChangedCellsPrevValues?: React.Dispatch<React.SetStateAction<Array<{
    id: string, value: number | null
  }>>>
}

const useOppDetailsModal = () => {
  const { uuiModals } = useUuiContext();
  const { showNotification } = useNotification();

  const showModal = async (
    oppId: string,
    probId: TProbIds,
    params: IShowModalParams,
  ) => {
    // probability chosen on the grid
    const modalDataFromServer = await editProbabilityAPI.getModalData(oppId);
    const modalData = mapDataForEditProbModal(modalDataFromServer, probId);
    const {
      originalValState, reseter, editorProps,
      changedCellsPrevValues, setChangedCellsPrevValues,
    } = params;

    const canEdit = modalDataFromServer.canEditEngDetails;

    const handleReset = () => {
      if (originalValState) {
        reseter(originalValState[0], editorProps, true);
        if (changedCellsPrevValues && changedCellsPrevValues.length) {
          changedCellsPrevValues.forEach((prevValue) => {
            reseter(prevValue.value, undefined, true, prevValue.id);
          });
          setChangedCellsPrevValues && setChangedCellsPrevValues([]);
        }
      }
    };

    if (modalData && ([
      'oppEngagementType',
      'oppPlatforms',
      'oppContinuumOfferings',
      'oppPractices',
    ] as (keyof TModalFieldsData)[]).some(
      (el) => Object.keys(modalData.fields).includes(el),
    ) && !canEdit) {
      handleReset();
      showNotification('You do not have permissions to edit required fields in Engagement Details section. Please contact Account Manager, Principal Account Manager or Operations Manager for assistance.', undefined, 'red');
      return false;
    }

    // if empty, then you don't need to open the modal
    if (modalData) {
      uuiModals
        .show((modalProps: any) => (
          <EditProbabilityModal
            {...modalProps}
            modalData={modalData}
            probId={probId}
            setValBack={handleReset}
            canEdit={canEdit}
            {...params}
          />
        ))
        .catch(() => null);
    }

    if (!modalData && params.apply) {
      params.apply();
    }

    return false;
  };

  return {
    showModal,
  };
};

export default useOppDetailsModal;
