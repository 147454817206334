import React, {
  FC, useState, useEffect, 
} from 'react';
import cx from 'classnames';
import { mapValues, maxBy } from 'lodash';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { IconContainer } from '@epam/promo';
import {
  DndActor, DndActorRenderParams, DropParams,
} from '@epam/uui-core';
import { ReactComponent as DragNDropIcon } from '@epam/assets/icons/common/action-drag_indicator-18.svg';
import ConstructorSubgroup from './ConstructorSubgroup';
import advancedFiltersConstructorAtom, { IHierarchicalItem, IHierarchicalItems } from '../../../../state/atoms/advancedFiltersConstructorAtom';
import HierarchicalFiltersList from './HierarchicalFiltersList';
import { getOperandsCountInsideSubgroup, hasEmptyFiltersValues } from '../AdvancedFiltersSlideOut';
import css from '../AdvancedFiltersSlideOut.module.scss';
import useSlideout from '../../../../hooks/useSlideout';
import { getNewId, parseAdvancedFiltersToView, TLogicalOperator } from '../../../../const/ADVANCED_FILTERS';
import { useViewPresets } from '../../../../hooks/useViewPresets';
import defaultViewPresetSelector from '../../../../state/selectors/defaultViewPresetSelector';
import { View } from '../../../../api/views';

const replaceItemById = (
  arr: IHierarchicalItems,
  newItem: IHierarchicalItem,
): IHierarchicalItems => arr.map((el) => {
  if (el.id === newItem.id) {
    return newItem;
  }
  if (el.children && el.children.length) {
    return {
      ...el,
      children: replaceItemById(el.children, newItem),
    };
  }
  return el;
});

const findParentAndDeleteSubgroup = (
  arr: IHierarchicalItems,
  subgroupId: string, 
  parentId: string,
): IHierarchicalItems => arr.map((el) => {
  if (el.children){
    if (parentId === el.id) {
      return {
        ...el,
        children: el.children.filter((child) => child.id !== subgroupId),
      };
    }
    return {
      ...el,
      children: findParentAndDeleteSubgroup(el.children, subgroupId, parentId),
    };
  }
  return el;
});

// This field is needed to stop the bubbling of the onDrop event
let isAllowDrop = false;
interface HierarchicalItemProps {
  item: IHierarchicalItem,
  isSubgroup?: boolean,
}

const HierarchicalFiltersItem: FC<HierarchicalItemProps> = ({ item, isSubgroup }) => {
  const { constructor } = useRecoilValue(advancedFiltersConstructorAtom);
  const [filtersOpeningValue, setFiltersOpeningValue] = useState<{ [key: string]: boolean }>({});
  const [connectingLineHeigh, setConnectingLineHeigh] = useState(0);
  const [isSubgroupError, setIsSubgroupError] = useState(false);
  const [operandsCountInsideSubgroup, setOperandsCountInsideSubgroup] = useState(0);
  const [droppedSubgroupId, setDroppedSubgroupId] = useState<string | null>(null);
  const setAdvancedFiltersConstructor = useSetRecoilState(advancedFiltersConstructorAtom);
  const { closeSlideout } = useSlideout();
  const { updateViewPresetInList } = useViewPresets();
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);

  const isDisabled = defaultViewPreset.gridType === 'Goals' && (Object.hasOwn(item.filters, 'accName') || Object.hasOwn(item.filters, 'globalAccName'));

  useEffect(() => {
    const updateConnectingLineHeight = () => {
      const filtersGroup = document.getElementById(`filters_group${item.id}}`);
      if (filtersGroup) {
        const filtersGroupChildren = filtersGroup.children;
        const lastSubgroup = filtersGroupChildren[filtersGroupChildren.length - 1];
        let lastSubGroupHeight = 0;
        let heightShift = 66;
        if (filtersGroupChildren.length > 1) {
          lastSubGroupHeight = lastSubgroup.clientHeight;
          heightShift = 2;
        }
        setConnectingLineHeigh(
          filtersGroup.clientHeight - lastSubGroupHeight - heightShift,
        );
      }
    };

    updateConnectingLineHeight();
  });
  
  useEffect(() => {
    const operandsCount = getOperandsCountInsideSubgroup(item);
    setOperandsCountInsideSubgroup(operandsCount);
    if ((defaultViewPreset.gridType === 'Goals' && (item.id === '1' || item.id === '1.1') ? false : operandsCount < 2) || hasEmptyFiltersValues(item.filters)) {
      setIsSubgroupError(true);
    } else {
      setIsSubgroupError(false);
    }
  }, [item.children?.length, Object.keys(item.filters)]);

  const handleSubgroupChange = (
    subgroupVal: IHierarchicalItem,
  ) => {
    setAdvancedFiltersConstructor((prev) => ({
      ...prev,
      constructor: replaceItemById(prev.constructor, subgroupVal),
    }));
  };

  const handleSubgroupDelete = (subgroupId: string) => {
    const parentId = subgroupId.split('.').slice(0, -1).join('.');

    if (!parentId) {
      setAdvancedFiltersConstructor((prev) => ({
        ...prev,
        constructor: [{
          id: '1',
          operator: 'And',
          filters: {},
          children: [],
          kind: 'subgroup',
        }],
      }));
      return;
    }

    setAdvancedFiltersConstructor((prev) => ({
      ...prev,
      constructor: findParentAndDeleteSubgroup(prev.constructor, subgroupId, parentId),
    }));
  };

  // effect is needed to synchronously update the state of the recoil.js
  useEffect(() => {
    if (droppedSubgroupId) {
      handleSubgroupDelete(droppedSubgroupId);
      setDroppedSubgroupId(null);
    }
  }, [JSON.stringify(constructor)]);

  const handleSwitchBack = async (operator: TLogicalOperator) => {
    setAdvancedFiltersConstructor((prev) => {
      const isChanged = JSON.stringify(prev.constructor) !== JSON.stringify(prev.cache);
      return {
        ...prev,
        isConstructor: false,
        changeCounter: isChanged ? prev.changeCounter + 1 : prev.changeCounter,
        constructor: operator === 'Or' 
          ? [{
            id: '1',
            operator: 'And',
            filters: {},
            children: [],
            kind: 'subgroup',
          }] 
          : prev.constructor,
      };
    });
    
    closeSlideout();

    if (defaultViewPreset.isViewAutosaved) {
      const updatedPreset: View = {
        ...defaultViewPreset,
        advancedFiltersSettings: parseAdvancedFiltersToView(constructor),
      };
      await updateViewPresetInList(updatedPreset);
    }
  };

  const handleOnDrop = ({ srcData, dstData }: DropParams<IHierarchicalItem, IHierarchicalItem>) => {
    if (isDisabled) return;
    let isSrcDataContainsDstData = false;
    const checkIsSrcDataContainsDstData = (sourceData: IHierarchicalItems, dstId: string) => {
      sourceData.forEach((el) => {
        if (el.id === dstId) {
          isSrcDataContainsDstData = true;
        } else if (el.children) {
          checkIsSrcDataContainsDstData(el.children, dstId);
        }
      });
    };
    if (dstData) {
      checkIsSrcDataContainsDstData([srcData], dstData.id);
    }

    if (
      isAllowDrop && dstData && !isSrcDataContainsDstData
      && !dstData.children?.some((el) => el.id === srcData.id)
    ) {
      const newId = dstData.children && dstData.children.length 
        ? getNewId(dstData.id, maxBy(dstData.children, 'id')?.id)
        : getNewId(dstData.id);

      const getChangedData = (data: IHierarchicalItem, id: string): IHierarchicalItem => ({
        ...data,
        id,
        filters: mapValues(data.filters, (filter) => ({ ...filter, subgroupId: id })),
        children: data.children && data.children.length
          ? data.children.map((child, index) => (
            getChangedData(child, `${id}.${index + 1}`)
          ))
          : [],
      });
      
      const changedDraggableSubgroup = {
        ...getChangedData(srcData, newId),
      };
      setDroppedSubgroupId(srcData.id);
      handleSubgroupChange({
        ...dstData,
        children: dstData?.children
          ? [changedDraggableSubgroup, ...dstData.children]
          : [changedDraggableSubgroup],
      });
    }
    isAllowDrop = false;
  };

  const handleCanAcceptDrop = ({ srcData }: DropParams<IHierarchicalItem, IHierarchicalItem>) => {
    if (isDisabled) return null;
    if (srcData.kind === 'subgroup') {
      if (!isAllowDrop) {
        isAllowDrop = true;
      }
      return {
        inside: true,
      };
    }
    return null;
  };

  return (
    <DndActor
      key={item.id}
      srcData={isSubgroup && !Object.values(filtersOpeningValue).includes(true) ? item : undefined}
      dstData={item}
      canAcceptDrop={handleCanAcceptDrop}
      onDrop={handleOnDrop}
      render={(props: DndActorRenderParams) => (
        <div
          {...(!isDisabled ? props.eventHandlers : {})}
          ref={props.ref}
          className={cx(
            css.filtersGroupContainer,
            isSubgroup && css.dragElement,
            props.isDraggedOut && css.draggedOutElement,
          )}
        >
          {
            isSubgroup && (
            <div className={css.dndIcon}>
              <IconContainer icon={DragNDropIcon} />
            </div>
            )
          }
          {
            operandsCountInsideSubgroup !== 0
            && (
              <div
                style={{ height: `${connectingLineHeigh}px` }}
                className={cx(
                  css.connectingLine,
                  { [css.connectingLineWithError]: isSubgroupError },
                )}
              />
            )
          }
          <div
            key={item.id}
            id={`filters_group${item.id}}`}
            className={css.filtersGroup}
          >
            <ConstructorSubgroup 
              data={item} 
              onSubgroupChange={handleSubgroupChange} 
              onSubgroupDelete={handleSubgroupDelete}
              onSwitchToBasic={handleSwitchBack}
              setFiltersOpeningValue={setFiltersOpeningValue}
              filtersOpeningValue={filtersOpeningValue}
              isDisabled={isDisabled}
              isDeleteDisabled={item.id === '1' && defaultViewPreset.gridType === 'Goals'}
            />
            {
              item.children && item.children.length
                ? (
                  <HierarchicalFiltersList
                    items={item.children}
                    isSubgroup
                  />
                )
                : null
            }
          </div>
        </div>
      )}
    />
  );
};

export default HierarchicalFiltersItem;
