const ORDER_UNIT = 'a';

interface IOrderedItem {
  order?: string,
}

function createOrderString(unit: string, length: number) {
  const resultArr: string[] = [];
  resultArr.length = length;
  resultArr.fill(unit);
  return resultArr.join('');
}

export function provideOrderPropertyToArr<T extends IOrderedItem>(arr: T[], subgroupId: string) {
  return arr.map((el, idx) => ({
    ...el,
    order: createOrderString(ORDER_UNIT, idx + 1),
    subgroupId,
  }));
}

export function provideOrderForNewItem<T extends IOrderedItem>(arr: T[]) {
  const sortedArr = arr.sort((a, b) => (
    (a.order && b.order) 
      ? a.order.localeCompare(b.order) 
      : -1));

  const prevItem = sortedArr[sortedArr.length - 1];

  return prevItem.order + ORDER_UNIT; 
}
