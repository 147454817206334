import api from './api';

export const changeFlag = (oppId: string, flag: number) => {
  const adress = `/opportunities/${oppId}/flag`;
  return api.put(adress, flag, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const changeTags = (oppId: string, tags: string[]) => {
  const adress = `/opportunities/${oppId}/tags`;
  return api.post(adress, tags, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
