import React from 'react';

const icon = (color: string) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.59 5.09L8 6.67V0H6V6.67L4.41 5.09L3 6.5L7 10.5L11 6.5L9.59 5.09Z" fill={color} />
    <path d="M12 12V7H14V12.4444C14 13.3 13.3 14 12.4444 14H1.55556C0.7 14 0 13.3 0 12.4444V7H2V12H12Z" fill={color} />
  </svg>
);

const bsrvIconDisabled = () => icon('#ACAFBF');
const bsrvIcon = () => icon('#6C6F80');

export { bsrvIcon, bsrvIconDisabled };
