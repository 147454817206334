import React from 'react';
import { Text } from '@epam/promo';

interface ColumnCaptionTextProps {
  caption: string;
}

const ColumnCaptionText = (props: ColumnCaptionTextProps) => {
  const { caption } = props;

  return (
    <Text rawProps={{ style: { fontWeight: '600', fontSize: '.75rem' } }} cx="hideTooltip">
      {caption.toUpperCase()}
    </Text>
  );
};

export default ColumnCaptionText;
