import React, { useMemo, useState, useEffect } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientTokens } from '@react-keycloak/core/lib/types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Keycloak from 'keycloak-js';
import { configState } from './ConfigProvider';
import tokenInfoAtom from '../state/atoms/tokenInfoAtom';
import api from '../api/api';

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

type ConfigProviderProps = { children: React.ReactNode };

const AuthProvider = ({ children }: ConfigProviderProps) => {
  const [jwtToken, setToken] = useState<string | null>(null);
  const [config] = useRecoilState(configState);
  const setTokenInfo = useSetRecoilState(tokenInfoAtom);

  const authClient = useMemo(() => new Keycloak({
    realm: config!.authRealm,
    url: config!.authUrl,
    clientId: config!.authClientId,
  }), [config]);

  useEffect(() => {
    authClient
      .updateToken(180)
      .catch((e) => {
        console.log('Failed to refresh the token, or the session has expired', e);
      });
  }, [authClient.onTokenExpired]);

  const keycloakInitOptions = {
    onLoad: config!.authOnLoad,
    checkLoginIframe: config!.checkLoginIframe,
  };

  const onTokens = (tokens: AuthClientTokens) => {
    const { token } = tokens;

    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      api.defaults.baseURL = config!.apiUrl;

      setToken(token);
      setTokenInfo(parseJwt(token));
    }
  };
  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={keycloakInitOptions}
      onTokens={onTokens}
    >
      {jwtToken ? children : null}
    </ReactKeycloakProvider>
  );
};

export default AuthProvider;
