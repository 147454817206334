import React from 'react';
import { Text } from '@epam/promo';
import { DataColumnProps } from '@epam/uui-core';
import ColumnCaptionText from '../ColumnCaptionText/ColumnCaptionText';
import css from './ErrorsGrid.module.scss';

const columns: DataColumnProps[] = [
  {
    key: 'lineNumber',
    caption: <ColumnCaptionText caption="Line number" />,
    render: (item: any) => (
      <Text>{item.lineNumber}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
    cx: `${css.headerCell}`,
  },
  {
    key: 'columnName',
    caption: <ColumnCaptionText caption="Column name" />,
    render: (item: any) => (
      <Text>{item.columnName}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
    cx: `${css.headerCell}`,
  },
  {
    key: 'value',
    caption: <ColumnCaptionText caption="Value" />,
    render: (item: any) => (
      <Text>{item.value}</Text>
    ),
    grow: 1,
    width: 90,
    textAlign: 'left',
    cx: `${css.headerCell}`,
  },
  {
    key: 'errorText',
    caption: <ColumnCaptionText caption="Error Text" />,
    render: (item: any) => (
      <Text>{item.errorText}</Text>
    ),
    grow: 1,
    width: 200,
    textAlign: 'left',
    cx: `${css.lastHeaderCell}`,
  },
];

export default columns;
