import api from './api';

export interface IOppRevenueHistoryResponseItem {
  probability: number,
  revisionId: string,
  modifiedOn: string,
  modifiedBy: string,
  modifiedByName: string,
  transactionCurrencyId: string,
  transactionCurrencyName: string,
  frcst: number | null,
  gttl: number | null,
  ytd: number | null,
  id: string,
  year: number,
  m1: number | null,
  m2: number | null,
  m3: number | null,
  m4: number | null,
  m5: number | null,
  m6: number | null,
  m7: number | null,
  m8: number | null,
  m9: number | null,
  m10: number | null,
  m11: number | null,
  m12: number | null
}

const API = 'revenue/history?opportunityId=';

const getOppRevenueHistory = (id: string) => api.get(`${API}${id}`)
  .then(({ data }: {
    data: {
      year: number;
      history: IOppRevenueHistoryResponseItem[];
    }[] }) => data);

export default getOppRevenueHistory;
