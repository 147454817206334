import {
  Button, DataPickerFooter, DataPickerRow, FlexRow, Text,
} from '@epam/promo';
import {
  useArrayDataSource, DndActor, DndActorRenderParams, DropParams, getOrderBetween, 
} from '@epam/uui-core';
import React, {
  FC, ReactNode, useEffect, useState, 
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import cx from 'classnames';
import { PickerTogglerProps } from '@epam/uui-components';
import { ReactComponent as AddIcon } from '@epam/assets/icons/common/action-add-12.svg';
import FilterInput from './FilterInput';
import css from './filterbar.module.scss';
import { IAdvancedFilter, TQueryValuesKey } from '../../../../../api/query';
import FilterbarPicker from './FilterbarPicker';
import { IFilterbarItem, singlePickerOptions } from '../../../../../const/ADVANCED_FILTERS';
import advancedFiltersConstructorAtom, { IHierarchicalItem, IHierarchicalItems } from '../../../../../state/atoms/advancedFiltersConstructorAtom';
import dndCursorStyleAtom from '../../../../../state/atoms/dndCursorStyleAtom';
import defaultViewPresetSelector from '../../../../../state/selectors/defaultViewPresetSelector';

interface Props {
  value: {
    [key: TQueryValuesKey]: IAdvancedFilter,
  },
  setValue: (value: IAdvancedFilter) => void,
  onAddFilter: (filterIds: string[]) => void,
  removeFilter: (val: string, hierarchicalItem?: IHierarchicalItem) => void,
  filters: IFilterbarItem[],
  renderToggler?: (props: PickerTogglerProps<IFilterbarItem, string>) => ReactNode,
  direction?: 'row' | 'column',
  isDraggable?: boolean,
  isDisabled?: boolean,
  isWithConstructor?: boolean,
  setFiltersOpeningValue?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
  filtersOpeningValue?: { [key: string]: boolean },
}

const extractAccFilterNames = (data: IHierarchicalItems): string[] => {
  const targetKeys = ['accName', 'globalAccName'];
  const filterNames: string[] = [];
  const traverse = ({ filters = {}, children = [] }: IHierarchicalItem): void => {
    filterNames.push(...Object.keys(filters).filter((key) => targetKeys.includes(key)));
    children.forEach(traverse);
  };
  data.forEach(traverse);
  return filterNames;
};

const Filterbar: FC<Props> = ({
  filters,
  value,
  setValue,
  renderToggler,
  removeFilter,
  onAddFilter,
  direction = 'column',
  isDraggable = false,
  isDisabled,
  isWithConstructor,
  setFiltersOpeningValue,
  filtersOpeningValue,
}) => {
  const { constructor } = useRecoilValue(advancedFiltersConstructorAtom);
  const setCursorStyle = useSetRecoilState(dndCursorStyleAtom);
  const { gridType } = useRecoilValue(defaultViewPresetSelector);
  const accFilterNames = extractAccFilterNames(constructor);

  const dataSource = useArrayDataSource({
    items: filters.map((el) => {
      if (gridType === 'Goals' && (accFilterNames.includes(el.field) || el.field === 'globalAccName')) {
        return {
          ...el,
          name: el.title,
          isAlwaysVisible: true,
        } as typeof el;
      }
      return {
        ...el,
        name: el.title,
      };
    }),
    getId: (i) => i.columnKey,
  }, []);

  const sortedArr = Object.values(value).sort((a, b) => (
    (a.order && b.order) 
      ? a.order.localeCompare(b.order) 
      : -1));
  
  const sortedIds = sortedArr.map((el) => el.key);

  const [droppedFilter, setDroppedFilter] = useState<IAdvancedFilter | null>(null);

  // effect is needed to synchronously update the state of the recoil.js
  useEffect(() => {
    if (droppedFilter) {
      setValue(droppedFilter);
      setDroppedFilter(null);
    }
  }, [JSON.stringify(constructor)]);

  const getSubgroupById = (
    data: IHierarchicalItems,
    id: string,
    tempTargetSubgroup?: IHierarchicalItem,
  ) => {
    let targetSubgroup = tempTargetSubgroup ?? data[0];
    data.forEach((item) => {
      if (item.id === id) {
        targetSubgroup = item;
      }
      if (item.children && item.children.length) {
        targetSubgroup = getSubgroupById(item.children, id, targetSubgroup);
      }
    });
    return targetSubgroup;
  };

  const handleOnDrop = (
    params: DropParams<IAdvancedFilter, IAdvancedFilter>,
    prevItem?: IAdvancedFilter,
    nextItem?: IAdvancedFilter,
  ) => {
    const { srcData, dstData, position } = params;

    const newOrder = position === 'bottom'
      ? getOrderBetween(
        dstData && dstData.order ? dstData.order : null,
        nextItem && nextItem?.order ? nextItem?.order : null,
      )
      : getOrderBetween(
        prevItem && prevItem?.order ? prevItem?.order : null,
        dstData && dstData.order ? dstData.order : null,
      );

    const updatedSrcItem = { ...srcData, order: newOrder };
    if (srcData.subgroupId === dstData?.subgroupId) {
      setValue(updatedSrcItem);
    } else {
      setDroppedFilter({ ...updatedSrcItem, subgroupId: dstData?.subgroupId });
      removeFilter(srcData.key as string, getSubgroupById(constructor, srcData.subgroupId ?? ''));
    }
    setCursorStyle('');
  };

  const handleCanAcceptDrop = (
    { srcData, dstData }: DropParams<IAdvancedFilter, IAdvancedFilter>,
  ) => {
    if (srcData.kind !== 'subgroup') {
      if (srcData.subgroupId !== dstData?.subgroupId) {
        const dstSubgroup = getSubgroupById(constructor, dstData?.subgroupId ?? '');
        if (Object.prototype.hasOwnProperty.call(dstSubgroup.filters, srcData.key)) {
          setCursorStyle(css.blockedDrop);
          return null;
        } 
        setCursorStyle(css.allowedDrop);
      }
      setCursorStyle(css.allowedDrop);
      return {
        top: true,
        bottom: true,
      };
    }
    return null;
  };

  const renderFilter = (
    filter: IFilterbarItem,
    index: number,
    filtersArray: Array<IFilterbarItem>,
  ) => {
    if (isDraggable) {
      return (
        <DndActor
          key={value[filter.field].key}
          srcData={value[filter.field]}
          dstData={value[filter.field]}
          canAcceptDrop={handleCanAcceptDrop}
          onDrop={
            (props) => handleOnDrop(
              props,
              filtersArray[index - 1] ? value[filtersArray[index - 1].field] : undefined,
              filtersArray[index + 1] ? value[filtersArray[index + 1].field] : undefined,
            )
          }
          render={(props: DndActorRenderParams) => (
            <div
              className={cx(
                props.isDraggedOut && css.draggedOutElement,
                css.dragElement,
              )}
            >
              <FlexRow spacing="6" cx={css.draggableRow} key={filter.field}>
                <FilterInput 
                  filter={filter} 
                  value={value[filter.field]} 
                  onValueChange={(val) => {
                    setValue({
                      ...val,
                      order: value[filter.field].order,
                      kind: value[filter.field].kind,
                      subgroupId: value[filter.field].subgroupId,
                    });
                  }} 
                  onRemove={(val) => {
                    removeFilter(val);
                  }}
                  direction={direction}
                  isDisabled={isDisabled}
                  options={singlePickerOptions[filter.field]}
                  isDraggable
                  dndRef={props.ref}
                  dndEventHandlers={props.eventHandlers}
                  setFiltersOpeningValue={setFiltersOpeningValue}
                  filtersOpeningValue={filtersOpeningValue}
                />
              </FlexRow>
            </div>
          )}
        />
      );
    }
    return (
      <FilterInput
        key={filter.field} 
        filter={filter} 
        value={value[filter.field]} 
        onValueChange={(val) => {
          setValue({
            ...val,
            order: value[filter.field].order,
            kind: value[filter.field].kind,
            subgroupId: value[filter.field].subgroupId,
          });
        }}
        direction={direction}
        isDisabled={isDisabled || (gridType === 'Goals' && filter.field === 'accName')} 
        onRemove={(val) => {
          removeFilter(val);
        }}
        options={singlePickerOptions[filter.field]}
        isDraggable={false}
      />
    );
  };

  const renderDefaultToggler = (togglerProps: PickerTogglerProps<IFilterbarItem, string>) => (
    <Button 
      {...togglerProps} 
      color={direction === 'column' ? 'gray50' : 'blue'} 
      fill="light" 
      size="30"
      icon={AddIcon} 
      caption="Add filter"
      isDropdown={false}
      isDisabled={isDisabled}
      onClear={undefined}
      cx={cx(
        css.filterbarToggler,
        isDisabled && css.disabledFilterbarToggler,
      )}
      rawProps={{
        style: {
          marginLeft: isDraggable ? '21px' : '0',
          marginBottom: '.375rem',
        }, 
      }}
    />
  );

  return (
    <>
      {/* Render Active Filters */}
      {sortedIds.map((el) => filters.filter((i) => i.field === el)[0]).map(renderFilter)}

      <FilterbarPicker
        dataSource={dataSource} 
        value={Object.keys(value)}
        onValueChange={(val) => {
          onAddFilter(val ?? []);
        }}
        renderToggler={(togglerProps) => (
          renderToggler 
            ? renderToggler(togglerProps) 
            : renderDefaultToggler(togglerProps))}
        isDisabled={isDisabled}
        renderFooter={({ view: { selectAll, ...view }, ...footerProps }) => (
          <DataPickerFooter 
            {...footerProps}
            view={view}
            cx={css.pickerFooter}
          />
        )}
        renderRow={(props) => (
          <DataPickerRow
            {...props}
            renderItem={(el) => (
              <Text>{el.title}</Text>
            )}
          />
        )}
        isWithConstructor={isWithConstructor && gridType !== 'Goals'}
      />
    </>
  );
};

export default React.memo(Filterbar);
