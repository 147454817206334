/* eslint-disable @typescript-eslint/naming-convention */
import { DataTableRow, Tooltip } from '@epam/promo';
import { DataTableRowProps } from '@epam/uui-core';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import editModeErrorsAtom from '../../../state/atoms/editModeErrorsAtom';
import css from '../editModeGrid.module.scss';
import listCss from '../../Grid/VirtualList/VirtualList.module.scss';
import { IGridItem } from '../../../state/atoms/gridFormAtom';
import queryParamsAtom from '../../../state/atoms/queryParamsAtom';

export interface IBreakdown {
  [key: string]: number
}

interface EditableRowProps extends DataTableRowProps<IGridItem, string> {
  foldedArr: string[];
  setFoldedArr: (val: string[]) => void;
}

const EditableRow = (props: EditableRowProps) => {
  const {
    id, value, onFold, isFolded, foldedArr, setFoldedArr, 
  } = props;
  const errors = useRecoilValue(editModeErrorsAtom);
  const isOpp = !id?.includes('loading') && (value && value.type === 'oppName');
  const oppErrors = errors.filter((el) => isOpp && el.oppId === value.values.oppId);
  const isInvalid = !!oppErrors.length;

  const { groupingStore } = useRecoilValue(queryParamsAtom);
  const onlyLevel = groupingStore.multiGrouping.length === 1;

  const foldId = `${value?.name}_${value?.type}`;

  const fn = () => new Promise((res) => {
    res(true);
  });

  useEffect(() => {
    if (foldedArr.includes(foldId) && isFolded){
      fn().then(() => onFold?.(props));
    }
  }, []);

  useEffect(() => {
    if (!foldedArr.includes(foldId) && !isFolded) fn().then(() => onFold?.(props));
  }, [foldedArr]);

  const toggleFoldedArr = () => {
    onFold?.(props);
    if (foldedArr.includes(foldId)) {
      setFoldedArr(foldedArr.filter((el) => el !== foldId));
      return;
    }
    setFoldedArr([...foldedArr, foldId]);
  };

  if (isOpp && (value && !value.values.isBaseRequiredFieldsSet)) {
    return (
      <Tooltip
        content="This opportunity is editable in CRM only" 
        placement="bottom"
      >
        <DataTableRow
          {...props}
          cx={`${!onlyLevel ? listCss.dataRowWithPadding : ''} ${css.uneditableRow}`}
          onSelect={() => {}}
          onClick={toggleFoldedArr}
          onFold={toggleFoldedArr}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      content={isInvalid && (
        oppErrors[0].message.join(' ') || 'Something went wrong. Try again later.'
      )} 
      placement="bottom"
      color="red"
    >
      <DataTableRow
        {...props}
        cx={!onlyLevel ? listCss.dataRowWithPadding : ''}
        isInvalid={isInvalid}
        onSelect={() => {}}
        onClick={toggleFoldedArr}
        onFold={toggleFoldedArr}
      />
    </Tooltip>
  );
};

export default EditableRow;
