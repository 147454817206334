import React, { useState, useEffect } from 'react';
import { Button, Blocker, Text } from '@epam/promo';
import { Dropdown } from '@epam/uui-components';
import { ReactComponent as ExportIcon } from '@epam/assets/icons/common/file-file_excel-24.svg';
import DatePicker from 'react-datepicker';
import { useRecoilValue } from 'recoil';
import { DropdownBodyProps } from '@epam/uui-core';
import dayjs from 'dayjs';
import { useExportExcel } from '../../../hooks/useExportExcel';

import 'react-datepicker/dist/react-datepicker.css';
import css from './ExportDatePicker.module.scss';
import queryParamsAtom from '../../../state/atoms/queryParamsAtom';
import { DatePickerHeader } from './DatePickerHeader';
import userProfileAtom from '../../../state/atoms/userProfileAtom';
import FilterInput from '../../slide-outs/AdvancedFiltersSlideOut/components/Filterbar/FilterInput';
import { IFilterbarItem, useCreateDataSource } from '../../../const/ADVANCED_FILTERS';
import { IAdvancedFilter } from '../../../api/query';

type TDropdownStage = 'regular' | 'pivot' | 'select';

const ExportDatePicker = () => {
  const { generateExcelFile, generatePivotFile, loading } = useExportExcel();

  const {
    filtersStore: {
      revenueYear,
    },
  } = useRecoilValue(queryParamsAtom);

  const { roles } = useRecoilValue(userProfileAtom);

  const isPivotAvailable = roles.some((el) => el.name === 'System Administrator' || el.name === 'EPAM Data Master');

  const [dropdownStage, setDropdownStage] = useState<TDropdownStage>(isPivotAvailable ? 'select' : 'regular');

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [pickerMode, setPickerMode] = useState<'monthly' | 'quarterly'>('monthly');

  const { createDataSource } = useCreateDataSource();

  const filter: IFilterbarItem = {
    field: 'oppState',
    columnKey: 'oppState',
    title: 'Opportunity Status',
    type: 'multiPicker',
    isAlwaysVisible: false,
    dataSource: createDataSource('oppState'),
    predicates: [
      { predicate: 'eq', name: '=', isDefault: true },
      { predicate: 'neq', name: '≠' },
    ],
  };

  const [oppState, setOppState] = useState<IAdvancedFilter>({
    key: 'oppState',
    operator: 'Equal',
    value: [
      {
        id: 2,
        name: 'Won',
      },
      {
        id: 1,
        name: 'Open',
      },
    ],
  });

  const isQuarterlyMode = pickerMode === 'quarterly';
  const isMonthlyMode = pickerMode === 'monthly';

  const clearPicker = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDate(null);
  };

  const changeDOM = () => {
    if (isQuarterlyMode) {
      const quartersNames = [
        ['Q1', 'Jan', 'Feb', 'Mar'],
        ['Q2', 'Apr', 'May', 'Jun'],
        ['Q3', 'Jul', 'Aug', 'Sep'],
        ['Q4', 'Oct', 'Nov', 'Dec'],
      ];
      const quarterElements = document.getElementsByClassName('react-datepicker__quarter-text');
      for (let i = 0; i < quarterElements.length; i++) {
        const j = i > 3 ? i - 4 : i;
        quarterElements[i].innerHTML = `
          <div>
            <div>${quartersNames[j][0]}</div>
            <div>${quartersNames[j][1]}</div>
            <div>${quartersNames[j][2]}</div>
            <div>${quartersNames[j][3]}</div>
          </div>
        `;
      }
    }
    if (isMonthlyMode) {
      const monthElements = document.getElementsByClassName('react-datepicker__month-text');
      for (let i = 0; i < monthElements.length; i++) {
        const monthName = monthElements[i].innerHTML;
        monthElements[i].innerHTML = `<div>${monthName}</div>`;
      }
    }
  };

  useEffect(() => {
    clearPicker();
    changeDOM();
  }, [pickerMode, dropdownStage]);

  const onDatePickerOpenChange = (isOpen: boolean) => {
    setIsDatePickerOpen(isOpen);
    if (isOpen) {
      setTimeout(() => changeDOM());
    }
    if (!isOpen) {
      clearPicker();
    }
  };

  const setDate = (date: Date) => {
    if (!selectedDate && !startDate && !endDate) {
      setSelectedDate(date);
      setStartDate(date);
      setEndDate(date);
    }

    if (selectedDate) {
      const currentSelectedDate = selectedDate;
      if (selectedDate > date) {
        setStartDate(date);
        setEndDate(currentSelectedDate);
        setSelectedDate(null);
      }
      if (selectedDate < date) {
        setStartDate(currentSelectedDate);
        setEndDate(date);
        setSelectedDate(null);
      }
    }

    if (startDate && endDate) {
      if (date < endDate) {
        setStartDate(date);
      }
      if (date > endDate) {
        setEndDate(date);
      }
    }

    if (startDate?.getTime() === endDate?.getTime() && date.getTime() === selectedDate?.getTime()) {
      setStartDate(null);
      setEndDate(null);
      setSelectedDate(null);
      return;
    }

    if (startDate && startDate.getTime() === date.getTime()) {
      const currentEndDate = endDate;
      setStartDate(currentEndDate);
      setSelectedDate(currentEndDate);
    }
    if (endDate && endDate.getTime() === date.getTime()) {
      const currentStartDate = startDate;
      setEndDate(currentStartDate);
      setSelectedDate(currentStartDate);
    }
  };

  const renderFooterText = () => {
    const formatDate = (date: Date | null | undefined) => {
      if (!date) {
        return '...';
      }
      if (isMonthlyMode) {
        return `${date.toLocaleString('en', { month: 'short' })}, ${date.getFullYear()}`;
      }
      if (isQuarterlyMode) {
        return `Q${Math.floor(date.getMonth() / 3 + 1)}, ${date.getFullYear()}`;
      }
    };

    if (!startDate && !endDate) {
      return `Select ${isMonthlyMode ? 'months' : 'quarters'} please`;
    }
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  const generateXls = async (closeMenu: () => void) => {
    if (startDate && endDate) {
      await generateExcelFile(startDate, endDate, isMonthlyMode);
    }
    clearPicker();
    setPickerMode('monthly');
    closeMenu();
  };

  const generatePivot = async (closeMenu: () => void) => {
    if (startDate && endDate && oppState) {
      const startOfTheDay = 'T00:00:00.000Z';
      const endOfTheDay = 'T23:59:59.999Z';
      await generatePivotFile({
        from: `${dayjs(startDate).format('YYYY-MM-DD')}${startOfTheDay}`,
        to: `${dayjs(endDate).endOf('month').format('YYYY-MM-DD')}${endOfTheDay}`,
        oppStatus: {
          eq: oppState.operator,
          values: Array.isArray(oppState.value) ? oppState.value.map((el) => el.id as number) : [],
        },
      });
    }
    clearPicker();
    closeMenu();
  };

  const renderDropdownBody = ({ onClose }: DropdownBodyProps) => {
    const dateBody = (
      <div className={css.body}>
        <DatePicker
          selected={selectedDate}
          onChange={setDate}
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy, QQQ"
          showQuarterYearPicker={isQuarterlyMode}
          showMonthYearPicker={isMonthlyMode}
          onSelect={(date) => {
            if (date.getTime() === startDate?.getTime() 
            && startDate?.getTime() === endDate?.getTime()) {
              setStartDate(null);
              setEndDate(null);
              setSelectedDate(null);
            }
          }}
          renderCustomHeader={(params) => (
            <DatePickerHeader {...params} year={Math.abs(revenueYear)} />
          )}
          inline
        />
      </div>
    );

    if (dropdownStage === 'select') {
      return (
        <div className={css.root}>
          <Text
            cx={css.menuItem} 
            onClick={() => {
              setDropdownStage('regular');
              setPickerMode('monthly');
            }}
          >
            Regular Export
          </Text>
          <Text 
            cx={css.menuItem}
            onClick={() => {
              setDropdownStage('pivot');
              setPickerMode('monthly');
            }}
          >
            Revenue Data for Pivot Export
          </Text>
        </div>
      );
    }
    if (dropdownStage === 'regular') {
      return (
        <div className={css.root}>
          <div className={css.header}>
            <button
              type="button"
              className={isMonthlyMode ? `${css.headerItem} ${css.activeHeaderItem}` : css.headerItem}
              onClick={() => setPickerMode('monthly')}
            >
              Monthly
            </button>
            <button
              type="button"
              className={isQuarterlyMode ? `${css.headerItem} ${css.activeHeaderItem}` : css.headerItem}
              onClick={() => setPickerMode('quarterly')}
            >
              Quarterly
            </button>
          </div>
          {dateBody}
          <div className={css.footer}>
            <div className={css.text}>{renderFooterText()}</div>
            <Button
              color="blue"
              size="30"
              caption="Generate xls"
              isDisabled={!startDate || !endDate}
              onClick={(() => onClose && generateXls(onClose))}
            />
          </div>
          <Blocker isEnabled={loading} />
        </div>
      );
    }
    if (dropdownStage === 'pivot') {
      return (
        <div className={css.root}>
          {dateBody}
          <div className={css.filterRow}>
            <FilterInput
              key="oppStatus" 
              filter={filter} 
              value={oppState}
              direction="column"
              showSearch={false}
              isDraggable={false}
              bodyCx={css.filterBody}
              containerWidth={264}
              onValueChange={({ value, operator }) => {
                setOppState((prev) => ({
                  ...prev,
                  value,
                  operator,
                }));
              }}
            />
          </div>
          <div className={css.footer}>
            <div className={css.text}>{renderFooterText()}</div>
            <Button
              color="blue"
              size="30"
              caption="Generate xls"
              isDisabled={
                !startDate 
                || !endDate 
                || !(Array.isArray(oppState.value) && oppState.value.length)
              }
              onClick={(() => onClose && generatePivot(onClose))}
            />
          </div>
          <Blocker isEnabled={loading} />
        </div>
      );
    }
    return null;
  };

  return (
    <Dropdown
      renderBody={renderDropdownBody}
      renderTarget={(props) => (
        <Button
          {...props}
          color="blue"
          fill="light"
          caption="Export"
          icon={ExportIcon}
          size="30"
          cx={isDatePickerOpen && css.activeTargetBtn}
          isDropdown={false}
          onClick={(e) => {
            setDropdownStage(isPivotAvailable ? 'select' : 'regular');
            props?.onClick?.(e);
          }}
        />
      )}
      value={isDatePickerOpen}
      onValueChange={onDatePickerOpenChange}
    />
  );
};

export default ExportDatePicker;
