import React, { FC } from 'react';
import { ReactComponent as ConfigIcon } from '@epam/assets/icons/common/action-settings-18.svg';
import css from '../VirtualList.module.scss';
import useSlideout from '../../../../hooks/useSlideout';

const ColumnsBtn: FC = () => {
  const { toggleSlideout } = useSlideout();

  return (
    <div className={`${css.configBtn} hideTooltip`}>
      <ConfigIcon 
        fill="#6C6F80" 
        onClick={() => {
          toggleSlideout('columnsSettings');
        }}
      />
    </div>
  );
};

export default ColumnsBtn;
