import { atom } from 'recoil';

interface IOppTagsFlagNotesAtom {
  [key: string]: {
    oppTags: {
      id: string,
      name: string,
    }[],
    oppFlag: number,
    hasNotes: boolean,
  }
}

const oppTagsFlagNotesAtom = atom<IOppTagsFlagNotesAtom>({
  key: 'oppTagsFlagNotes',
  default: {},
});

export default oppTagsFlagNotesAtom;
