import { atom, selector } from 'recoil';
import { IAdvancedFilter, TQueryValuesKey } from '../../api/query';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';
import { TLogicalOperator, parseAdvancedFiltersFromView } from '../../const/ADVANCED_FILTERS';

export interface IHierarchicalItem {
  id: string, // childId = `${parentId}.${children.length + 1}`
  operator: TLogicalOperator, // OR | AND
  filters: {
    [key: TQueryValuesKey]: IAdvancedFilter,
  },
  children?: Array<IHierarchicalItem>,
  kind: 'subgroup', // field needed for DnD
}

export interface IHierarchicalItems extends Array<IHierarchicalItem>{}

export interface IAdvancedFiltersConstructorAtom {
  isConstructor: boolean,
  changeCounter: number,
  constructor: IHierarchicalItems,
  cache: IHierarchicalItems | [],
}

const advancedFiltersConstructorAtom = atom<IAdvancedFiltersConstructorAtom>({
  key: 'advancedFiltersConstructorStore',
  default: selector<IAdvancedFiltersConstructorAtom>({
    key: 'advancedFiltersConstructorStore/selector',
    get: ({ get }) => {
      const { advancedFiltersSettings } = get(defaultViewPresetSelector);

      const parsedConstructor = parseAdvancedFiltersFromView(advancedFiltersSettings);
      
      return {
        isConstructor: (!!parsedConstructor.children && !!parsedConstructor.children.length)
          || (parsedConstructor.operator === 'Or' && !!Object.keys(parsedConstructor.filters).length),
        changeCounter: 0, // value to observe for updates in queryAPI and summary request
        constructor: [parsedConstructor],
        cache: [],
      };
    },
  }),
});

export default advancedFiltersConstructorAtom;
