import { IconButton, NumericInput } from '@epam/promo';
import React, { useEffect, useState } from 'react';
import { ReactComponent as clearIcon } from '@epam/assets/icons/common/navigation-close-12.svg';
import css from './FilterNumericBody.module.scss';

// TODO: value: unknown; -> value: number | undefined;

interface Props {
  value: unknown;
  onValueChange: (value: number | undefined) => void;
}

const FilterNumericBody = (props: Props) => {
  const { value, onValueChange } = props;
  const [state, setState] = useState<number | undefined>(typeof value === 'number' ? value : undefined);

  useEffect(() => {
    if (value !== state){
      const timer = setTimeout(() => {
        onValueChange(state);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [state, value]);

  return (
    <div className={css.root}>
      <NumericInput 
        disableArrows 
        value={state || state === 0 ? state : null} 
        onValueChange={(v) => setState(v || v === 0 ? v : undefined)}
        placeholder="Not selected"
        disableLocaleFormatting
        min={Number.NEGATIVE_INFINITY}
        cx={css.input}
        size="24"
      />
      <IconButton
        color="gray60"
        cx={css.clearIcon} 
        icon={clearIcon} 
        onClick={() => {
          setState(undefined);
        }} 
      />
    </div>
  );
};

export default FilterNumericBody;
