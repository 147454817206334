import api from './api';

const MAINTENANCE_NOTIFICATION = '/maintenance-notification';

export const getNotificationVisibility = () => api.get<boolean>(MAINTENANCE_NOTIFICATION);

export const saveNotificationVisibility = (
  body: boolean,
) => api.post(MAINTENANCE_NOTIFICATION, JSON.stringify(body), {
  headers: {
    'Content-Type': 'application/json',
  },
});
