import {
  cx, DndActor, DndActorRenderParams, DropParams, IColumnConfig, uuiDndState, 
} from '@epam/uui-core';
import React, { FC, memo } from 'react';
import css from '../ColumnsSettings.module.scss';
import ColumnCheckbox from './ColumnCheckbox';

type IColumn = IColumnConfig & { id: string };

interface Props {
  item: IColumn,
  prevItem: IColumn,
  nextItem: IColumn,
  handleOnDrop: (
    item: DropParams<IColumn, IColumn>, 
    prevItem: IColumn, 
    nextItem: IColumn,
  ) => void,
  handleChange: (newItem: IColumn) => void,
  disabled?: boolean,
  draggable?: boolean,
  pinnable?: boolean,
}

const DraggableCheckbox: FC<Props> = ({
  item, prevItem, nextItem, handleOnDrop, handleChange, draggable, disabled, pinnable,
}) => {
  const canAcceptDrop = () => ({
    top: true,
    bottom: true,
  });
  
  return (
    <DndActor 
      key={item.id}
      srcData={item}
      dstData={item}
      canAcceptDrop={canAcceptDrop}
      onDrop={(params) => handleOnDrop(params, nextItem, prevItem)}
      render={(params: DndActorRenderParams) => (
        <div
          {...params.eventHandlers}
          ref={params.ref} 
          className={cx(
            css.dragItem, 
            params.classNames,
            {
              [uuiDndState.dragGhost]: params.isDragGhost,
            },
          )}
        >
          <ColumnCheckbox
            key={item.id}
            item={item}
            onChange={handleChange}
            draggable={draggable}
            disabled={disabled}
            pinnable={pinnable}
          />
        </div>
      )}
    />
  );
};

export default memo(DraggableCheckbox);
