import React, { FC, useEffect } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import {
  LinkButton,
  WarningAlert,
  Text,
  FlexRow,
  FlexCell,
  Switch,
} from '@epam/promo';
import { useRecoilState } from 'recoil';
import dnDIcon from '../../../../assets/svg/dragAndDrop.svg';
import css from './ImportTab.module.scss';
import ImportDetails from './ImportDetails';
import { importAPI } from '../../../../api/import';
import importAtom, { acceptedFileAtom, importStatusAtom } from '../../../../state/atoms/importActual/importAtom';
import { useMaintenanceNotificationState } from '../../../../hooks/useMaintanceNotificationState';

interface Props {
  navigateToHistory: () => void,
}

const ImportTab: FC<Props> = ({ navigateToHistory }) => {
  const [importInfo, setImportInfo] = useRecoilState(importAtom);
  const [acceptedFile, setAcceptedFile] = useRecoilState(acceptedFileAtom);
  const [{
    isLoading,
    isError,
    fileForImport,
    errorMessage,
  }, setImportStatus] = useRecoilState(importStatusAtom);
  const {
    maintenanceNotification,
    toggleMaintenanceNotification,
  } = useMaintenanceNotificationState();

  const linesWithErrors = Array.from(
    new Set(importInfo?.actualRevenueImportErrors?.map((el) => el.lineNumber)),
  ).length;

  useEffect(() => {
    if (importInfo?.allRecords && linesWithErrors === importInfo?.allRecords) {
      setImportStatus((prev) => ({
        ...prev,
        isError: true,
        errorMessage: 'All file records are incorrect',
      }));
    }
  }, [importInfo?.actualRevenueImportErrors]);

  const onDrop = async (acceptedFiles: Array<FileWithPath>) => {
    try {
      const file = acceptedFiles[0];
      setAcceptedFile(file);
      const formData = new FormData();
      formData.append('file', file);

      setImportStatus((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const data = await importAPI.validate(formData);
      
      setImportInfo(data);
      
      setImportStatus((prev) => ({
        ...prev,
        fileForImport: formData,
      }));
    } catch (error: any) {
      setImportStatus((prev) => ({
        ...prev,
        errorMessage: error.response?.data?.message ?? 'Error',
        isError: true,
      }));
    } finally {
      setImportStatus((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleNavigateToHistory = () => {
    setAcceptedFile(null);
    setImportInfo(null);
    navigateToHistory();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const backToFileUpload = () => {
    setAcceptedFile(null);
    setImportStatus((prev) => ({
      ...prev,
      isError: false,
    }));
  };

  const renderMaintenanceNotification = () => (
    <FlexRow columnGap={12} cx={css.notification}>
      <FlexCell width="auto">
        <FlexRow columnGap={12}>
          <FlexCell grow={1}>
            <Text size="30">Show maintenance warning?</Text>
          </FlexCell>
          <FlexCell width="auto">
            <Switch label="Yes" value={maintenanceNotification} onValueChange={toggleMaintenanceNotification} />
          </FlexCell>
        </FlexRow>
      </FlexCell>
      <FlexCell grow={1}>
        {maintenanceNotification && (
          <WarningAlert size="36" rawProps={{ id: 'panel-maintenance-alert' }}>
            <Text size="30">Ongoing maintenance on the Pipeline</Text>
          </WarningAlert>
        )}
      </FlexCell>
    </FlexRow>
  );

  if (acceptedFile) {
    return (
      <>
        {renderMaintenanceNotification()}
        <ImportDetails
          file={acceptedFile}
          isError={isError}
          isLoading={isLoading}
          backToFileUpload={backToFileUpload}
          navigateToHistory={handleNavigateToHistory}
          fileForImport={fileForImport}
          errorMessage={errorMessage}
          importInfo={importInfo}
        />
      </>
    );
  }

  return (
    <>
      {renderMaintenanceNotification()}
      <div className={css.dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <img src={dnDIcon} alt="Drag And Drop Icon" />
        <span className={css.header}>
          Drag files here
        </span>
        <div className={css.description}>
          <span>or</span>
          &nbsp;
          <LinkButton
            caption="browse"
            onClick={open}
            size="24"
            cx={css.link}
          />
          &nbsp;
          <span>CSV file from your computer</span>
        </div>
      </div>
    </>
  );
};

export default ImportTab;
