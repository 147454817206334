import { useCallback } from 'react';
import { DataRowOptions } from '@epam/uui-core';
import { hierarchicalPickers } from '../const/ADVANCED_FILTERS';

export const useHierarchyPickerInput = (selectionType?: 'single' | 'multi') => {
  const getRowOptions = useCallback((
    item: { id: string, name: string, parentId?: string | null, hasChild: boolean },
  ): DataRowOptions<any, any> => ({
    checkbox: {
      isVisible: !item.hasChild,
    },
    isSelectable: selectionType !== 'single' || !item.hasChild,
  }), []);

  const isHierarchy = (fieldKey: string) => hierarchicalPickers.includes(fieldKey);
  
  return { getRowOptions, isHierarchy };
};
