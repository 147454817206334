import {
  Button,
  PickerInput, Tooltip, Text, IconButton, 
} from '@epam/promo';
import { PickerTogglerProps } from '@epam/uui-components';
import { useArrayDataSource } from '@epam/uui-core';
import React, { FC, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import { changeTags } from '../../../../api/editFlagsAndTags';
import { tagIconBig, tagIcon } from '../../../../assets/components/tagIcon';
import { useNotification } from '../../../../hooks/useNotification';
import css from '../VirtualList.module.scss';
import { fetchAdvancedFilterOptions } from '../../../../api/filters';
import tagIconFilled from '../../../../assets/components/tagIconFilled';

import oppTagsOptionsAtom from '../../../../state/atoms/oppTagsOptionsAtom';
import oppTagsFlagNotesAtom from '../../../../state/atoms/oppTagsFlagNotesAtom';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';

interface Props {
  tags: string[],
  oppId?: string,
  disabled: boolean,
  iconOnly?: boolean,
  canEditOpp?: boolean,
}

interface IOption {
  id: string,
  name: string,
}

const TEXT_ON_SUCCESS = 'Operational Labels are set';
const TEXT_ERROR = 'Operational Labels cannot be set';
const TAGS_MIN_LENGTH = 1;

const OppTagPicker: FC<Props> = ({
  tags: initialTags, oppId, disabled, iconOnly, canEditOpp = true,
}) => {
  const [tagsOptions, setTagsOptions] = useRecoilState(oppTagsOptionsAtom);
  const [state, setState] = useRecoilState(oppTagsFlagNotesAtom);
  
  const initialIds = tagsOptions
    .filter((el: IOption) => initialTags.includes(el.name));

  useEffect(() => {
    if (!tagsOptions.length) {
      fetchAdvancedFilterOptions('oppTags').then((res) => {
        setTagsOptions(res.items);
      });
    }
    setState((prev) => ({
      ...prev,
      [oppId!]: {
        ...prev[oppId!],
        oppTags: initialIds,
      },
    }));
  }, []);

  const value = state?.[oppId!]?.oppTags || initialIds;

  const [{ isLoading, mode }, setIsLoading] = useRecoilState(viewModeAtom);
  const [localLoading, setLocalLoading] = useState(false);

  const dataSource = useArrayDataSource({
    items: tagsOptions.filter((el: IOption) => !!el.id),
    getId: (i: IOption) => i.id,
  }, []);

  const { showNotification } = useNotification();

  const sortedValues = value?.length ? [...value].sort((a, b) => (
    (a.name && b.name)
      ? a.name.localeCompare(b.name)
      : -1)) : [];

  const firstTag = sortedValues?.length ? sortedValues[0] : null;
  const tags = sortedValues.map(({ name }) => name);
  const isExtraTags = value && value.length > TAGS_MIN_LENGTH
    ? value.length - TAGS_MIN_LENGTH : false;

  let icon: React.FC<{ cx: string }>;
  if (iconOnly && value.length) {
    icon = tagIconFilled;
  } else if (iconOnly) {
    icon = tagIconBig;
  } else {
    icon = tagIcon;
  }

  const renderTags = firstTag && (
    <Tooltip content={firstTag.name}>
      <Text key={firstTag.id} cx={css.tagItem}>{firstTag.name}</Text>
    </Tooltip>
  );

  const getIconBtn = (props: PickerTogglerProps<any, any>) => (value.length ? (
    <Tooltip content={`All Operational Labels: ${tags.join(', ')}`}>
      <IconButton {...props} icon={localLoading ? LoaderIcon : icon} />
    </Tooltip>
  ) : (
    <IconButton {...props} icon={localLoading ? LoaderIcon : icon} />
  ));

  const renderExtraTags = isExtraTags && (
    <Tooltip content={`All Operational Labels: ${tags.join(', ')}`}>
      <Text cx={css.tagItem}>{`+${isExtraTags} more`}</Text>
    </Tooltip>
  );

  const renderToggler = (props: PickerTogglerProps<any, any>) => (
    (value.length && !localLoading) && !iconOnly ? (
      <Button
        caption={(
          <div>
            {renderTags}
            {renderExtraTags}
          </div>
      )}
        {...props}
        isDropdown={false}
        onClear={undefined}
        fill="light"
        captionCX={css.tagsContainer}
        size="18"
        cx={css.tagsBtn}
      />
    )
      : getIconBtn(props)
  );

  return (
    <PickerInput 
      dataSource={dataSource}
      valueType="entity"
      entityName="Operational Label"
      value={value}
      isDisabled={disabled || (!canEditOpp && mode === 'view') || localLoading || isLoading}
      renderToggler={renderToggler}
      onValueChange={(newVal) => {
        setLocalLoading(true);
        setIsLoading((prev) => ({
          ...prev,
          isLoading: true,
        }));
        const reqBody = newVal ? newVal.map((el) => el.id) : [];
        oppId && changeTags(oppId, reqBody)
          .then(() => {
            setState((prev) => ({
              ...prev,
              [oppId!]: {
                ...prev[oppId!],
                oppTags: newVal ?? [], 
              },
            }));
            setLocalLoading(false);
            setIsLoading((prev) => ({
              ...prev,
              isLoading: false,
            }));
            showNotification(TEXT_ON_SUCCESS, undefined, 'green');
          })
          .catch(() => {
            setLocalLoading(false);
            setIsLoading((prev) => ({
              ...prev,
              isLoading: false,
            }));
            showNotification(TEXT_ERROR, undefined, 'red');
          });
      }}
      selectionMode="multi"
      editMode="modal"
    />
    
  );
};

export default OppTagPicker;
