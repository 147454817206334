import React from 'react';
import { SetterOrUpdater, useRecoilValue } from 'recoil';
import { ColumnsConfig, DataColumnProps, LazyListView } from '@epam/uui-core';
import GridHeader from './GridHeader/GridHeader';
import css from './Grid.module.scss';
import VirtualList from './VirtualList/VirtualList';
import SettingsSlideOut from '../slide-outs/SettingsSlideOut/SettingsSlideOut';
import OppDetailsSlideOut from '../slide-outs/OppDetailsSlideOut/OppDetailsSlideOut';
import AdvancedFiltersSlideOut from '../slide-outs/AdvancedFiltersSlideOut/AdvancedFiltersSlideOut';
import slideoutStateAtom from '../../state/atoms/slideoutStateAtom';
import { TableDataSource } from '../../state/atoms/viewStateAtom';
import { QueryItem } from '../../hooks/useQueryApi';

interface GridProps {
  lazyView: LazyListView<QueryItem, string, unknown>; 
  viewState: TableDataSource; 
  setViewState: SetterOrUpdater<TableDataSource>;
  config: ColumnsConfig;
  columns: DataColumnProps<any, any, any>[];
  refreshGrid: () => void;
}

const Grid = (props: GridProps) => {
  const { refreshGrid } = props;
  const currentSlideout = useRecoilValue(slideoutStateAtom);
  return (
    <div className={css.root}>
      <div className={css.gridPanel}>
        <GridHeader refresh={refreshGrid} />
        <VirtualList {...props} />
      </div>
      {currentSlideout === 'columnsSettings' && <SettingsSlideOut />}
      {currentSlideout === 'oppDetails' && <OppDetailsSlideOut />}
      {currentSlideout === 'advFiltersConstructor' && <AdvancedFiltersSlideOut />}
    </div>
  );
};

export default Grid;
