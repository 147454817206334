export const getMappedStatus = (status: string | number) => {
  if (status === 'Scheduled' || status === 'InProgress') return 'In Progress';
  if (status === 'Unsuccessful') return status;
  if (status === 'DeleteSuccessful') return 'Deleted';
  if (status === 'DeleteScheduled' || status === 'DeleteInProgress') {
    return 'Deletion in progress';
  }
  if (status === 'DeleteFailed') return 'Deletion Failed';
  if (status === 'Successful' || typeof status === 'number') return 'Successful';
  if (status === 'PartiallySuccessful') return 'Partially Successful';
  return status;
};

export const getStatusColor = (status: string | number) => {
  if (status === 'Successful') return 'successColor';
  if (status === 'PartiallySuccessful') return 'partiallySuccessColor';
  return 'unsuccessColor';
};
