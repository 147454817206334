import React from 'react';
import {
  FlexRow, IconButton, IconContainer, Text, Tooltip, 
} from '@epam/promo';
// import { probabilityDictionary } from '../EditModeGrid/const';
import { ReactComponent as ChevronDown } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import { ReactComponent as ChevronUp } from '@epam/assets/icons/common/navigation-chevron-up-18.svg';
import { ReactComponent as PersonIcon } from '@epam/assets/icons/common/social-person-12.svg';
import { ReactComponent as GearIcon } from '@epam/assets/icons/common/action-settings-18.svg';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { IForecastsItem } from './OneCrmGridColumns';
import css from './OneCrmGrid.module.scss';
import { OneCrmSectionType } from '../../api/oneCrm';
import { oneCrmGridAtom } from '../../state/atoms/oneCrmAtoms';
import { probabilityDictionary } from '../EditModeGrid/const';
import oneCrmProjectIcon from '../../assets/components/oneCrmProjectIcon';
import oneCrmStaffingIcon from '../../assets/components/oneCrmStaffingIcon';

interface OneCrmGridRecordProps {
  item: IForecastsItem,
  lens?: any,
}

const OneCrmGridRecord = (props: OneCrmGridRecordProps) => {
  const { item, lens } = props;
  const prob = Object.values(probabilityDictionary)
    .find((el) => el.id === item?.probability);

  const [gridType, setGridType] = useRecoilState(oneCrmGridAtom);
  
  const historyNode = (
    // TODO: Use valid sequence
    <FlexRow cx={css.recordContainer} size="48">
      <div className={css.recordMainData}>
        <div>{dayjs(item?.modifiedOn).format('M/D/YYYY H:mm:ss')}</div>
      </div>
      <FlexRow cx={css.recordSecondRow}>
        <FlexRow cx={css.recordData}>
          <div className={
            item?.changedFields?.includes('probability') 
              ? css.recordDataChanged 
              : css.recordData
            }
          >
            {prob?.name}
          </div>
          <div className={
            item?.changedFields?.includes('transactionCurrencyName') 
              ? css.recordDataChanged 
              : css.recordData
            }
          >
            {item?.transactionCurrencyName}
          </div>
        </FlexRow>
        <Tooltip content={item?.modifiedByName}>
          <IconContainer icon={PersonIcon} />
        </Tooltip>
      </FlexRow>
    </FlexRow>
  );

  // TODO: Add icons (proj/staff)
  const bsrvProjNode = (
    <FlexRow size="48" spacing="12">
      <IconContainer icon={oneCrmProjectIcon} />
      <Text rawProps={{ style: { lineHeight: '10px' } }}>{item?.projectName}</Text>
    </FlexRow>
  );
  const bsrvStaffingNode = (
    <FlexRow size="48" spacing="12">
      <IconContainer icon={oneCrmStaffingIcon} />
      <Text>{item?.staffingOpportunityCode}</Text>
    </FlexRow>
  );

  const actualNode = (<FlexRow size="48">{OneCrmSectionType.Actual}</FlexRow>);
  const bsrvNode = (
    <FlexRow cx={css.forecastContainer} size="48">
      <Tooltip content="Copy Workload Based Revenue to Forecast">
        <IconButton 
          icon={GearIcon}
          isDisabled={item.isDisabled} 
          onClick={() => {
            const {
              changedFields, ytd, frcst, gttl, gttlPlus, id, type, isDisabled, ...bsrv 
            } = item;
            lens.prop('items').prop('0').handleValueChange(bsrv);
          }} 
        />
      </Tooltip>
      <Text>{item?.type}</Text>
      <IconButton 
        icon={gridType !== 'wlb' ? ChevronDown : ChevronUp}
        isDisabled={item.isDisabled}
        onClick={() => {
          setGridType((prev) => (prev === 'wlb' ? '' : 'wlb'));
        }}
      />
    </FlexRow>
  );

  const rootNode = (
    <FlexRow cx={css.forecastContainer} size="48">
      <Text>{item?.type}</Text>
      <IconButton 
        icon={gridType !== 'history' ? ChevronDown : ChevronUp}
        isDisabled={item.isDisabled}
        onClick={() => {
          setGridType((prev) => (prev === 'history' ? '' : 'history'));
        }}
      />
    </FlexRow>
  );

  const nodeToRender: JSX.Element = rootNode;

  if (item?.type === OneCrmSectionType.History) {
    return historyNode;
  } if (item?.type === OneCrmSectionType.BsrvProject) {
    return bsrvProjNode;
  } if (item?.type === OneCrmSectionType.BsrvStaffing) {
    return bsrvStaffingNode;
  } if (item?.type === OneCrmSectionType.Actual) {
    return actualNode; 
  } if (item?.type === OneCrmSectionType.BsrvRoot) {
    return bsrvNode;
  }

  return nodeToRender;
};

export default OneCrmGridRecord;
