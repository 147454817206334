import React, { useCallback, useState, useEffect } from 'react';
import { FlexCell, TextInput, Text } from '@epam/promo';
import css from './PresetInput.module.scss';
import { View } from '../../../api/views';
import { useViewPresets } from '../../../hooks/useViewPresets';

interface PresetInputProps {
  onCancel: () => void;
  onSuccess?: (name: string) => Promise<any>;
  preset?: View;
  placeholder?: string;
}
const VIEW_NAME_MAX_LENGTH = 300;

export function PresetInput({
  onCancel, onSuccess, preset, placeholder,
}: PresetInputProps) {
  const { viewPresets } = useViewPresets();
  const [presetCaption, setPresetCaption] = useState(preset?.name || '');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setIsErrorMessage] = useState('');
  const [readonly, setReadonly] = useState(false);
  const [isInputElemOnHover, setIsInputElemOnHover] = useState(false);

  useEffect(() => {
    const isNotUniqueName = viewPresets.some(
      (item) => {
        if (preset && preset.id === item.id) return false;
        return item.name.toLowerCase() === presetCaption.toLowerCase();
      },
    );
    const validate = () => {
      if (presetCaption.length > VIEW_NAME_MAX_LENGTH) {
        setIsError(true);
        setIsErrorMessage(`View name must not exceed ${VIEW_NAME_MAX_LENGTH} symbols`);
      } else if (isNotUniqueName) {
        setIsError(true);
        setIsErrorMessage('View name must be unique');
      } else {
        setIsError(false);
        setIsErrorMessage('');
      }
    };
    validate();
  }, [presetCaption]);

  const cancelActionHandler = useCallback(() => {
    setPresetCaption('');
    onCancel();
  }, [onCancel]);

  const acceptActionHandler = useCallback(async () => {
    if (!isError) {
      setReadonly(() => true);
      if (presetCaption && onSuccess) {
        await onSuccess(presetCaption);
      }
      onCancel();
      setReadonly(() => false);
    }
  }, [presetCaption, onCancel, onSuccess, isError]);

  const newPresetOnBlurHandler = useCallback(() => {
    if (isInputElemOnHover) {
      return;
    }
    onCancel();
  }, [isInputElemOnHover, onCancel]);

  return (
    <FlexCell cx={css.presetInputCell} minWidth={180}>
      <div
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOver={() => setIsInputElemOnHover(true)}
        onMouseLeave={() => setIsInputElemOnHover(false)}
      >
        <TextInput
          cx={isError ? `${css.presetInput} ${css.presetInputWithError}` : css.presetInput}
          onValueChange={(val) => val !== undefined && setPresetCaption(val)}
          value={presetCaption}
          onCancel={cancelActionHandler}
          onAccept={acceptActionHandler}
          onBlur={newPresetOnBlurHandler}
          autoFocus
          isReadonly={readonly}
          isInvalid={isError}
          placeholder={placeholder}
        />
      </div>
      {
        isError
        && (
          <Text fontSize="12" font="sans" lineHeight="18" cx={css.errorMessage}>
            {errorMessage}
          </Text>
        )
      }
    </FlexCell>
  );
}
