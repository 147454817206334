import React, { FC } from 'react';
import { ReactComponent as sendIcon } from '@epam/assets/icons/common/action-send-24.svg';
import {
  Button,
  FlexRow,
  IconButton,
  TextArea,
  TextInput, 
} from '@epam/promo';
import { ReactComponent as saveIcon } from '@epam/assets/icons/common/notification-done-12.svg';
import { ReactComponent as cancelIcon } from '@epam/assets/icons/common/navigation-close-12.svg';
import { ReactComponent as loaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import css from '../NotesModal.module.scss';

export interface IForm {
  oppId: string,
  noteId?: string,
  subject?: string,
  noteText?: string,
}

interface Props {
  initialText: string,
  initialSubject: string,
  handleSend: () => void,
  form: IForm,
  setForm: React.Dispatch<React.SetStateAction<IForm>>,
  isLoading: boolean,
}

const NotesForm: FC<Props> = ({
  initialText,
  initialSubject,
  handleSend,
  form,
  setForm,
  isLoading,
}) => {
  const handleSetForm = (newVal: string, field: 'noteText' | 'subject') => {
    setForm((prev) => ({
      ...prev,
      [field]: newVal,
    }));
  };
  const isFormEmpty = !form.noteText?.length && !form.subject?.length;
  const isBtnDisabled = isFormEmpty || isLoading;

  return (
    <FlexRow cx={css.sendForm}>
      <TextInput
        placeholder="Title"
        value={initialSubject}
        onValueChange={
          (newVal) => (
            newVal?.trim() ? handleSetForm(newVal, 'subject') : handleSetForm('', 'subject')
          )
        }
        cx={css.textInput}
        maxLength={500}
      />
      <TextArea
        rows={1}
        value={initialText}
        placeholder="Enter note"
        onValueChange={
          (newVal) => (
            newVal.trim() ? handleSetForm(newVal, 'noteText') : handleSetForm('', 'noteText')
          )
        }
        cx={css.textArea}
        inputCx={css.textAreaInput}
        maxLength={100000}
      />
      {
        form.noteId 
          ? (
            <FlexRow cx={css.editingControlsRow} spacing="6"> 
              <Button 
                size="24" 
                icon={cancelIcon} 
                fill="white" 
                color="gray50"
                onClick={() => setForm((prev) => ({
                  ...prev,
                  noteId: undefined,
                  noteText: '',
                  subject: '',
                }))}
                isDisabled={isLoading} 
                cx={css.button}
              />
              <Button 
                size="24" 
                icon={isLoading ? loaderIcon : saveIcon} 
                fill="white" 
                color="blue" 
                onClick={() => handleSend()}
                isDisabled={isBtnDisabled} 
              />
            </FlexRow>
          )
          : (
            <IconButton 
              icon={isLoading ? loaderIcon : sendIcon} 
              color={isBtnDisabled ? 'gray50' : 'blue'} 
              cx={css.sendBtn} 
              onClick={() => handleSend()}
              isDisabled={isBtnDisabled}
            />
          )
      } 
    </FlexRow>
  );
};

export default NotesForm;
