import { useRecoilValue } from 'recoil';
import { createInitialBreakdown } from '../state/atoms/breakdownAtom';
import queryParamsAtom from '../state/atoms/queryParamsAtom';

export const useBreakdown = () => {
  const queryParams = useRecoilValue(queryParamsAtom);
  const currentYear = new Date().getFullYear();
  const yearForRequest = queryParams.filtersStore.revenueYear < currentYear 
    ? currentYear : queryParams.filtersStore.revenueYear;

  const createBreakdownForEditMode = () => createInitialBreakdown(yearForRequest, 'Monthly');

  return { yearForRequest, createBreakdownForEditMode };
};
