import {
  FlexCell, FlexRow, Spinner,
} from '@epam/uui-components';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Panel, TabButton } from '@epam/promo';
import oppDetailsSlideoutAtom from '../../../state/atoms/oppDetailsSlideoutAtom';
import { SlidingPanel } from '../common/SlidingPanel/SlidingPanel';
import DetailsTab from './DetailsTab';
import { getOppDetails } from '../../../api/oppDetails';
import Header from './Header';
import AuditTab from './AuditTab/AuditTab';
import css from './OppDetailsSlideOut.module.scss';
import useSlideout from '../../../hooks/useSlideout';

const OppDetailsSlideOut = () => {
  const [{
    oppName, tab, details, oppId,
  }, setSlideout] = useRecoilState(oppDetailsSlideoutAtom);

  const { currentSlideout } = useSlideout();
  
  const fetchDetails = async () => {
    if (oppId) {
      const detailsResponse = await getOppDetails(oppId);
      setSlideout((prev) => ({
        ...prev,
        details: detailsResponse,
      }));
    }
  };

  useEffect(() => {
    if (oppName) { fetchDetails(); }
  }, [oppName]);

  const handleOnClick = (val: 'details' | 'audit') => {
    setSlideout((prev) => ({
      ...prev,
      tab: val,
    }));
  };

  const renderTab = () => {
    if (details) {
      if (tab === 'details') {
        return (
          <DetailsTab />
        );
      }
    }
    if (tab === 'audit' && oppId) {
      return (
        <AuditTab oppId={oppId} />
      );
    }
    return <Spinner />;
  };

  return (
    <SlidingPanel
      width={582}
      position="right"
      isVisible={currentSlideout === 'oppDetails'}
      overflow="initial"
    >
      <Panel cx={css.root}>
        <Header />
        <FlexCell rawProps={{
          style: {
            margin: '0',
            borderBottom: '1px solid #ced0db',
          }, 
        }}
        >
          <FlexRow>
            <TabButton
              caption="Details"
              isLinkActive={tab === 'details'}
              onClick={() => handleOnClick('details')}
              rawProps={{
                style: {
                  marginLeft: '.75rem',
                },
              }}
            />
            <TabButton
              caption="Audit History"
              isLinkActive={tab === 'audit'}
              onClick={() => handleOnClick('audit')}
              rawProps={{
                style: {
                  marginLeft: '.75rem',
                },
              }}
            />
          </FlexRow>
        </FlexCell>
        {renderTab()}
      </Panel>
    </SlidingPanel>
  );
};

export default OppDetailsSlideOut;
