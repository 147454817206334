import { useRecoilState } from 'recoil';
import { TQueryValuesKey } from '../api/query';
import settingsSlideoutAtom from '../state/atoms/settingsSlideoutAtom';

const useSettingsSlideout = () => {
  const [settings, setSettings] = useRecoilState(settingsSlideoutAtom);

  const toggleOppCheckbox = () => {
    setSettings((prev) => ({
      ...prev,
      tagsFlagsNotes: !prev.tagsFlagsNotes,
    }));
  };

  const toggleChanged = () => {
    if (!settings.changed) {
      setSettings((prev) => ({
        ...prev,
        changed: !prev.changed,
      }));
    }
  };

  const setGroupings = (data: TQueryValuesKey[]) => {
    setSettings((prev) => ({
      ...prev,
      groupingStore: data,
    }));
  };

  const toggleTagsFlagsNotes = () => {
    setSettings((prev) => ({
      ...prev,
      tagsFlagsNotes: !prev.tagsFlagsNotes,
    }));
  };

  const toggleWarnings = () => {
    setSettings((prev) => ({
      ...prev,
      warnings: !prev.warnings,
    }));
  };
  
  return {
    setGroupings,
    toggleTagsFlagsNotes,
    toggleChanged,
    toggleOppCheckbox,
    toggleWarnings,
  };
};

export default useSettingsSlideout;
