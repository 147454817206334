import { atom } from 'recoil';
import { IOppDetails } from '../../api/oppDetails';

interface IOppDetailsSlideout {
  oppName: string,
  oppId?: string,
  tab: 'details' | 'audit',
  details?: IOppDetails,
  currName?: string,
}

const oppDetailsSlideoutAtom = atom<IOppDetailsSlideout>({
  key: 'oppDetailsSlideout',
  default: {
    oppName: '',
    oppId: '',
    tab: 'details',
  },
});

export default oppDetailsSlideoutAtom;
