import { atom, selector } from 'recoil';
import { fetchOptionList } from '../../api/filters';
import { Options } from '../../components/FiltersPanel/PermanentFilters/const';

const filtersOptionsAtom = atom<Options>({
  key: 'filtersOptionsStore',
  default: selector({
    key: 'filtersOptionsStore/Default',
    get: async () => fetchOptionList(),
  }),
});

export default filtersOptionsAtom;
