import React from 'react';
import { useUuiContext } from '@epam/uui-core';
import { ReactComponent as HistoryIcon } from '@epam/assets/icons/common/action-history-12.svg';
import { IconButton, Tooltip } from '@epam/promo';
import OppHistoryModal from '../../../modals/OppHistoryModal/OppHistoryModal';
import css from '../VirtualList.module.scss';

interface Props {
  id: string,
  isOpp: boolean,
}

const OppHistoryCell = (props: Props) => {
  const { id, isOpp } = props;
  const { uuiModals } = useUuiContext();

  const handleOnClick = () => uuiModals.show((modalProps) => (
    <OppHistoryModal id={id} {...modalProps} />
  ));
  if (isOpp) {
    return (
      <Tooltip
        cx={css.historyBtnTooltip}
        color="white"
        content={<span className={css.historyBtnTooltipMessage}>View forecast history</span>}
        placement="bottom"
      >
        <div className={css.historyBtnContainer}>
          <IconButton icon={HistoryIcon} onClick={handleOnClick} cx={css.historyBtn} />
        </div>
      </Tooltip>
    );
  }
  return null;
};

export default OppHistoryCell;
