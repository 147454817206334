import { useRecoilValue } from 'recoil';
import editedCellsAtom from '../state/atoms/editedCellsAtom';
import gridFormAtom, { gridShowEditedAtom } from '../state/atoms/gridFormAtom';

const useGridForm = () => {
  const isShowEdited = useRecoilValue(gridShowEditedAtom);
  const editedCells = useRecoilValue(editedCellsAtom);
  const editedOppIds = Object.keys(editedCells);
  const form = useRecoilValue(gridFormAtom);

  const getRows = (view: any) => {
    const { rows } = view;
    const oppsToShow = editedOppIds.map((el: string) => form.items[el]);

    if (isShowEdited) {
      const editedRows = rows.filter((row: any) => {
        if (!row.id.includes('loading')) {
          const isOppRelatedRow = oppsToShow
            .some((el: any) => el.values[row.value.type]
              === row.value.values[row.value.type]);
  
          return isOppRelatedRow;
        }
        return true;
      });
      return editedRows;
    }

    return view.getVisibleRows();
  };
  
  return {
    getRows,
    isShowEdited,
  };
};

export default useGridForm;
