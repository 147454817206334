import { atom, selector } from 'recoil';
import { View, viewsAPI } from '../../api/views';
import { accountView, defaultView } from '../../components/FiltersPanel/ViewPresetsMenu/const';
import { fetchAdvancedFilterOptions } from '../../api/filters';
import { IAdvancedFilter, IAdvancedFilters } from '../../api/query';

const viewLoadingAtom = atom({
  key: 'viewLoadingStore',
  default: false,
});

const viewPresetsAtom = atom<Array<View>>({
  key: 'viewPresetsStore',
  default: selector({
    key: 'viewPresetsStore/Default',
    get: async () => {
      const { location } = window;
      if (location.search.includes('accId')) {
        const moreViews = await viewsAPI.getViews('Goals');
        const search = location.search.split('=')[1];
        const { items: accounts } = await fetchAdvancedFilterOptions('accName', undefined, false, false, 0, 999999);
        const { items: globalAccounts } = await fetchAdvancedFilterOptions('globalAccName', undefined, false, false, 0, 999999);
        const acc = accounts.find((el: Record<string, string>) => el.id && el.id === search);
        const globalAcc = globalAccounts
          .find((el: Record<string, string>) => el.id && el.id === search);

        const accName: IAdvancedFilter = {
          key: 'accName',
          operator: 'Equal',
          value: [
            acc,
          ],
        };

        const globalAccName: IAdvancedFilter = {
          key: 'globalAccName',
          operator: 'Equal',
          value: [
            globalAcc,
          ],
        };

        const techSubgroup: IAdvancedFilters = {
          operator: 'Or',
          predicates: [
            ...(acc ? [accName] : []),
            ...(globalAcc ? [globalAccName] : []),
          ], 
        };

        if (moreViews.length === 0) {
          const newView = {
            ...accountView,
            advancedFiltersSettings: {
              operator: 'And',
              predicates: [
                techSubgroup,
              ],
            },
            permanentFiltersSettings: {
              ...accountView.permanentFiltersSettings,
              year: new Date().getFullYear(),
            },
          } as View;
        
          const id = await viewsAPI.saveNewView(newView);

          return [{
            ...newView,
            id,
            isDefault: true,
            isNew: false,
            isPersonalView: true,  
          }];
        }

        const filteredPredicates = moreViews[0].advancedFiltersSettings.predicates.filter((el) => (
          !Object.hasOwn(el, 'key') || ((el as IAdvancedFilter).key !== 'accName' && (el as IAdvancedFilter).key !== 'globalAccName')));

        const checkForSubGroup = (el: IAdvancedFilters | IAdvancedFilter) => Object.hasOwn(el, 'predicates')
        && (el as IAdvancedFilters).predicates.every((i) => (
          Object.hasOwn(i, 'key') && (
            (i as IAdvancedFilter).key === 'accName' || (i as IAdvancedFilter).key === 'globalAccName'
          )
        ));

        let preparedPredicates = filteredPredicates;
        
        const techviewIdx = filteredPredicates.findIndex(checkForSubGroup);

        if (techviewIdx >= 0) {
          preparedPredicates = [
            ...filteredPredicates.slice(0, techviewIdx),
            techSubgroup,
            ...filteredPredicates.slice(techviewIdx + 1),
          ];
        }

        if (techviewIdx < 0) {
          preparedPredicates.unshift(techSubgroup);
        }

        const techView: View = {
          ...moreViews[0],
          advancedFiltersSettings: {
            operator: 'And',
            predicates: preparedPredicates,
          },
          permanentFiltersSettings: {
            ...moreViews[0].permanentFiltersSettings,
            year: new Date().getFullYear(),
          },
        };

        if (techView.advancedFiltersSettings.predicates.some((el) => Object.hasOwn(el, 'predicates'))) {
          const { predicates } = techView.advancedFiltersSettings.predicates.find((el) => Object.hasOwn(el, 'predicates')) as IAdvancedFilters;

          if (predicates.some((el) => Object.hasOwn(el, 'key') && (el as IAdvancedFilter).key === 'accName') 
            || predicates.some((el) => Object.hasOwn(el, 'key') && (el as IAdvancedFilter).key === 'globalAccName')) {
            return [techView];
          }
        }
        
        await viewsAPI.updateView(techView.id, techView);
        
        return [{
          ...techView,
          isDefault: true,
          isNew: false,
          isPersonalView: true,    
        } as View];
      }

      const views = await viewsAPI.getViews();

      if (!views.length) {
        const newId = await viewsAPI.saveNewView(defaultView);
        return [{
          ...defaultView, id: newId, isDefault: true, isPersonalView: true, isNew: false,
        }];
      }
      
      return views;
    },
  }),
});

export { viewLoadingAtom, viewPresetsAtom };
