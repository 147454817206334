import { Filters } from '../../../const/FILTERS';

export interface Options {
  [key: string] : FilterOption[]
}

export interface FilterOption {
  id: string | number,
  name: string | Array<string>,
  description?: string,
  nameId?: string,
}

interface IPermanentFilter {
  key: keyof Filters,
  listWidth: number,
  items: FilterOption[],
  label?: string,
  footer?: string,
  title?: string,
  dropdownHeight?: number,
}

export const getPermanentFilters = (options: Options): IPermanentFilter[] => [
  {
    key: 'revenueYear',
    listWidth: 88,
    label: 'Year',
    items: options.revenueYear,
  },
  {
    key: 'currency',
    listWidth: 66,
    label: 'Currency',
    items: options.currency,
  },
  {
    key: 'breakdown',
    listWidth: 120,
    label: 'Breakdown',
    items: options.breakdown,
  },
  {
    key: 'revenueScenario',
    listWidth: 206,
    title: 'Revenue Scenario',
    footer: 'Combination of Forecast / Actual revenue to display in view',
    label: 'Revenue Scenario:',
    items: [
      {
        name: 'Forecast only',
        id: 'ForecastOnly',
      },
      {
        name: ['Actual', '/Forecast separately'],
        id: 'ActualForecastSeparately',
      },
      {
        name: ['Actual', '/Forecast mixed'],
        id: 'ActualForecastMixed',
      },
      {
        name: ['Actual', '\u00A0vs Forecast, $'],
        id: 'ActualVsForecastDollars',
      },
      {
        name: ['Actual', '\u00A0vs Forecast, %'],
        id: 'ActualVsForecastPercent',
      },
    ],
  },
  {
    key: 'probabilityScenario',
    listWidth: 354,
    title: 'Probability Scenario',
    items: [
      {
        name: 'Pessimistic',
        id: 'Pessimistic',
        description: 'Shows revenue for opportunities with 100% probability only',
      },
      {
        name: 'Standard',
        id: 'Standard',
        description: `
        Past and current quarters - revenue of 80%+ opportunities,
        next quarter - revenue of 60%+ opportunities,
        next quarter+1 - revenue of 40%+ opportunities,
        future quarters - revenue of 0%+ opportunities
        `,
      },
      {
        name: 'Optimistic',
        id: 'Optimistic',
        description: 'Revenue shown for all opportunities',
      },
      {
        name: 'Weighted',
        id: 'Weighted',
        description: 'Revenue amounts are multiplied by opportunity probability',
      },
      {
        name: 'Monthly',
        id: 'Monthly',
        description: `
          Past and current months — 80%+ opportunities revenue,
          next month (m+1) — opportunities 60%+ revenue,
          for the (m+2), (m+3), (m+4) and (m+5) months 40%+ revenue,
          (m+6), (m+7) and (m+8) months 20%+ revenue, for the future — 0%+ revenue
        `,
      },
    ],
    dropdownHeight: 400,
  },
  {
    key: 'forecastGranularity',
    listWidth: 230,
    title: 'Forecast Granularity',
    footer: 'Display options for the monthly opportunity forecast view',
    items: [
      {
        name: 'With Monthly Forecast Only',
        id: 'WithMonthlyForecastOnly',
      },
      {
        name: 'Without Monthly Forecast Only',
        id: 'WithoutMonthlyForecastOnly',
      },
      {
        name: 'With and without Monthly Forecast',
        id: 'WithAndWithoutMonthlyForecast',
      },
    ],
  },
  {
    key: 'ownership',
    listWidth: 280,
    title: 'Ownership',
    footer: `
      Opportunities to display in the view:
      those where you are the account AM/SM/Digital SM,
      where you are opportunity AM/SM/Secondary SM or show all
    `,
    items: [
      {
        name: 'My Opportunities only',
        id: 'MyOpportunitiesOnly',
      },
      {
        name: 'My Accounts only',
        id: 'MyAccountsOnly',
      },
      {
        name: 'All',
        id: 'All',
      },
    ],
  },
];
