import React from 'react';

const NotesIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6062_315823)">
      <circle cx="32" cy="32" r="32" fill="#EBEDF5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.333 20.3333C15.333 18.4833 16.833 17 18.6663 17H45.333C47.174 17 48.6663 18.4924 48.6663 20.3333V40.3333C48.6663 42.1743 47.174 43.6667 45.333 43.6667C45.333 43.6667 31.3808 43.6667 24.4046 43.6667C23.603 43.6667 21.9997 43.6667 21.9997 43.6667L15.333 50.3333V20.3333ZM43.6663 25.3333H21.9997V22H43.6663V25.3333ZM43.6663 32H21.9997V28.6667H43.6663V32ZM36.9997 38.6667H21.9997V35.3333H36.9997V38.6667Z" fill="#6C6F80" />
    </g>
    <defs>
      <clipPath id="clip0_6062_315823">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NotesIcon;
