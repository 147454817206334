import React, { FC, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Button,
  DropdownMenuBody, DropdownMenuButton, DropdownSubMenu, FlexSpacer,
  GlobalMenu, MainMenu, MainMenuAvatar, MainMenuButton, MainMenuIcon,
} from '@epam/promo';
import { useKeycloak } from '@react-keycloak/web';
import { MainMenuCustomElement, Dropdown } from '@epam/uui-components';
import { useUuiContext } from '@epam/uui-core';
import { ReactComponent as HelpIcon } from '@epam/assets/icons/common/notification-help-outline-24.svg';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as ChevronRightIcon } from '@epam/assets/icons/common/navigation-chevron-right-18.svg';
import { ReactComponent as GearIcon } from '@epam/assets/icons/common/action-settings-24.svg';
import { configState } from '../../providers/ConfigProvider';
import tokenInfoAtom from '../../state/atoms/tokenInfoAtom';
import PipelineIcon from '../../assets/components/PipelineIcon';
import PipelineSmallIcon from '../../assets/components/PipelineSmallIcon';
import PowerBIIcon from '../../assets/components/PowerBIIcon';
import css from './AppHeader.module.scss';
import newOppIcon from '../../assets/components/newOppIcon';
import viewModeAtom from '../../state/atoms/viewModeAtom';
import ImportActualModal from '../modals/ImportActualModal/ImportActualModal';
import userProfileAtom from '../../state/atoms/userProfileAtom';

interface Props {
  withAccessError?: boolean;
}

const AppHeader:FC<Props> = ({ withAccessError }) => {
  const { uuiModals } = useUuiContext();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const config = useRecoilValue(configState);
  const profile = useRecoilValue(userProfileAtom);
  const tokenInfo = useRecoilValue(tokenInfoAtom);
  const [isMenuOpen, onIsMenuOpenChange] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const { mode } = useRecoilValue(viewModeAtom);

  useEffect(() => {
    const testAvatarUrl = () => {
      const tester = new Image();
      tester.addEventListener('error', () => {
        setAvatarUrl('');
      });
      tester.addEventListener('load', () => {
        setAvatarUrl(tokenInfo.picture);
      });
      tester.src = tokenInfo.picture;
    };
    if (tokenInfo.picture) {
      testAvatarUrl();
    }
  }, [tokenInfo.picture]);

  const onImportActualClick = () => {
    uuiModals.show((modalProps) => (
      <ImportActualModal {...modalProps} />
    )).catch(() => null);
  };

  return (
    <MainMenu
      cx={css.root}
    >
      <MainMenuCustomElement>
        <Dropdown
          renderTarget={(props) => (
            <Button
              cx={isMenuOpen ? `${css.dropdownBtn} ${css.activeDropdownBtn}` : css.dropdownBtn}
              caption={(
                <>
                  <h1 className={css.title}>PIPELINE</h1>
                  <h2 className={css.subtitle}>Digital Platform</h2>
                </>
              )}
              fill="none"
              icon={PipelineIcon}
              {...props}
            />
          )}
          renderBody={(props) => (
            <DropdownMenuBody 
              cx={`${css.dropdownMenu} ${css.mainMenu}`} 
              {...props} 
              focusLock={false}
            >
              <DropdownMenuButton
                isDisabled
                caption={(
                  <>
                    Revenue Pipeline
                    <CheckIcon />
                  </>
                )}
                icon={PipelineSmallIcon}
              />
              <DropdownMenuButton
                caption="Back to CRM"
                icon={PipelineSmallIcon}
                href={`${config?.baseCrmUrl}main.aspx?etn=opportunity&pagetype=entitylist&web=true`}
              />
              <DropdownMenuButton
                caption="AM Performance Monitor"
                icon={PipelineSmallIcon}
                href={`${config?.baseCrmUrl}main.aspx?page=Dashboards&area=nav_am_peformance_monitor&web=true`}
              />
              <DropdownSubMenu
                cx={`${css.dropdownMenu} ${css.mainMenu}`}
                caption={(
                  <>
                    Power BI Reports
                    <ChevronRightIcon />
                  </>
                )}
                icon={PowerBIIcon}
                iconPosition="left"
              >
                <DropdownMenuButton caption="Efficacy & MAPE" href={config?.EfficacyMapeUrl} />
                <DropdownMenuButton caption="Health Check Summary" href={config?.HealthCheckUrl} />
              </DropdownSubMenu>
            </DropdownMenuBody>
          )}
          value={isMenuOpen}
          onValueChange={onIsMenuOpenChange}
        />
      </MainMenuCustomElement>

      <Link to="/">
        <MainMenuButton cx={css.buttonCaption} caption="Revenue Forecast" isLinkActive={location.pathname === '/'} />
      </Link>
      <FlexSpacer />
      <div className={css.navContainer}>
        {
          !withAccessError && mode === 'view' && (
          <Button
            color="blue"
            icon={newOppIcon}
            caption="New Opportunity"
            size="36"
            cx={css.addBtn}
            href={`${config?.baseCrmUrl}main.aspx?etn=opportunity&newWindow=true&pagetype=entityrecord`}
            target="_blank"
          />
          )
        }
        {profile.canImportActuals && (
        <MainMenuCustomElement>
          <Dropdown
            renderTarget={(props) => (
              <MainMenuIcon icon={GearIcon} {...props} />
            )}
            renderBody={(props) => (
              <DropdownMenuBody 
                cx={`${css.dropdownMenu} ${css.secondaryMenu}`} 
                {...props}
                focusLock={false}
              >
                
                <DropdownMenuButton
                  onClick={() => { onImportActualClick(); }}
                  caption="Import Actual"
                />
                
              </DropdownMenuBody>
            )}
            placement="bottom-end"
          />
        </MainMenuCustomElement>
        )}
        <MainMenuCustomElement>
          <Dropdown
            renderTarget={(props) => (
              <MainMenuIcon icon={HelpIcon} {...props} />
            )}
            renderBody={(props) => (
              <DropdownMenuBody 
                cx={`${css.dropdownMenu} ${css.secondaryMenu}`} 
                {...props} 
                focusLock={false}
              >
                <DropdownMenuButton
                  onClick={() => window.open('https://kb.epam.com/display/EPMCRMFAQ/CRM+Help', '_blank')}
                  caption="Knowledge base"
                />
                <DropdownMenuButton caption="Contact Support" href="mailto:AskPipeline@epam.com" />
                <DropdownMenuButton
                  onClick={() => window.open('https://privacy.epam.com/core/interaction/showpolicy?type=CommonPrivacyNotice&country=EN', '_blank')}
                  caption="Privacy Policy"
                />
              </DropdownMenuBody>
            )}
            placement="bottom-end"
          />
        </MainMenuCustomElement>
        <MainMenuCustomElement>
          <Dropdown
            renderTarget={(props) => (
              <MainMenuAvatar
                avatarUrl={avatarUrl}
                isDropdown
                {...props}
              />
            )}
            renderBody={(props) => (
              <DropdownMenuBody 
                cx={`${css.dropdownMenu} ${css.secondaryMenu}`} 
                {...props} 
                focusLock={false}
              >
                <DropdownMenuButton 
                  caption="Logout"
                  onClick={() => keycloak.logout()}
                />
              </DropdownMenuBody>
            )}
            placement="bottom-end"
          />
        </MainMenuCustomElement>
      </div>
      <GlobalMenu />
    </MainMenu>
  );
};

export default AppHeader;
