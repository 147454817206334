import { selector } from 'recoil';
import queryParamsAtom, { Grouping } from '../atoms/queryParamsAtom';

export const getCurrentGroupingIds = (groups: Array<Grouping>) => groups.map((group) => group.id);

const currentGroupingIdsSelector = selector({
  key: 'listOfCurrentGroupings',
  get: ({ get }) => {
    const { groupingStore } = get(queryParamsAtom);
    return getCurrentGroupingIds(groupingStore.multiGrouping);
  },
});

export default currentGroupingIdsSelector;
