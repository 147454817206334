import React, {
  FC, useRef, useEffect, useState, 
} from 'react';
import { Text, Tooltip } from '@epam/promo';
import { useRecoilValue } from 'recoil';
import css from '../VirtualList.module.scss';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';
import { columnsWithNames, getInitials } from '../../../../const/COLUMNS';

interface Props {
  data: string | Array<string>,
  textContainerWidth: number,
  isBaseRequiredFieldsSet: boolean,
  isList?: boolean,
  cellId: string,
  isTooltip?: boolean,
  isPermanentTooltip?: boolean,
}

const GridTextCell: FC<Props> = ({
  data, textContainerWidth, isList = true,
  isBaseRequiredFieldsSet, cellId, isTooltip, isPermanentTooltip,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const { mode } = useRecoilValue(viewModeAtom);

  const isWithName = columnsWithNames.includes(cellId);

  const processedData = isWithName ? getInitials(data) : data;

  const getProbClass = () => {
    if (cellId !== 'prob') return '';    
    if (Array.isArray(data)) return '';
    const isDataTypeOfNumber = !Number.isNaN(parseFloat(data));
    
    if (!isDataTypeOfNumber || !data || mode === 'edit') return '';
    if (data === '40%') return css.probForty;
    if (data === '60%') return css.probSixty;
    if (data === '80%' || data === '90%' || data === '100%') return css.probHigh;
    return css.probLow;
  };

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    ref.current?.parentElement?.setAttribute(
      'cell-for',
      cellId,
    );
  }, []);

  const dataArray = Array.isArray(data) ? data : data.split(',');
  const processedDataArray = Array.isArray(processedData) ? processedData : processedData?.split(',');

  const getTooltipContent = () => {
    if (width >= textContainerWidth - 26 || isPermanentTooltip) {
      if (!isList) {
        return data;
      }
      return dataArray.map((el, idx, arr) => (
        <p style={{ margin: 0 }} key={el}>
          { arr.length - 1 === idx ? `${el}` : `${el},` }
        </p>
      ));
    } return '';
  };

  const renderContent = isTooltip || isPermanentTooltip ? (
    <Tooltip
      content={data ? getTooltipContent() : ''}
      placement={!isBaseRequiredFieldsSet && mode === 'edit' 
        ? 'top-start' : 'bottom-start'}
    >
      <Text fontSize="14" cx={`${css.textCell} ${css.truncatedText} ${cellId === 'prob' ? css.probText : ''}`} rawProps={{ 'data-cell-for': cellId }}>
        {!isList ? processedData : processedDataArray?.join(', ')}
      </Text>
    </Tooltip>
  ) : (
    <Text fontSize="14" cx={`${css.textCell} ${css.truncatedText} ${cellId === 'prob' && mode === 'view' ? css.probText : ''} ${getProbClass()}`} rawProps={{ 'data-cell-for': cellId }}>
      {!isList ? data : dataArray.join(', ')}
    </Text>
  );

  return (
    <div ref={ref} className={css.truncatedText}>
      {renderContent}
    </div>
  );
};

export default GridTextCell;
