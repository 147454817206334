import { atom } from 'recoil';

// You can add here the necessary data from the token
export const intialSettings = {
  picture: '',
  commonname: '',
};

const tokenInfoAtom = atom({
  key: 'tokenInfo',
  default: intialSettings,
});

export default tokenInfoAtom;
