import React, { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { Button } from '@epam/promo';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import queryParamsAtom from '../../../state/atoms/queryParamsAtom';
import updateAtomsForQuerySelector from '../../../state/selectors/updateAtomsForQuerySelector';
import viewModeAtom from '../../../state/atoms/viewModeAtom';
import { virtualListErrorsAtom } from '../../../state/atoms/virtualListErrorsAtom';
import { useBreakdown } from '../../../hooks/useBreakdown';
import userProfileAtom from '../../../state/atoms/userProfileAtom';
import { viewStateAtom } from '../../../state/atoms/viewStateAtom';

interface Props { 
  cx: string 
}

const EditButton: FC<Props> = ({ cx }) => {
  const error = useRecoilValue(virtualListErrorsAtom);
  const { canEdit } = useRecoilValue(userProfileAtom);
  const setAtoms = useSetRecoilState(updateAtomsForQuerySelector);
  const queryParams = useRecoilValue(queryParamsAtom);
  const { groupingStore: { multiGrouping: groups } } = queryParams;
  const { isLoading } = useRecoilValue(viewModeAtom);
  const { createBreakdownForEditMode, yearForRequest } = useBreakdown();
  const isOppNameInGroupping = groups[groups.length - 1]?.id === 'oppName';
  const setViewState = useSetRecoilState(viewStateAtom);

  const breakdownBeforeEdit = queryParams.filtersStore.breakdown;

  const handleClick = () => {
    const newBreakdowns = breakdownBeforeEdit === 'Quarterly'
      ? {
        [`yyyyyy${yearForRequest}m1`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m1` },
        [`yyyyyy${yearForRequest}m2`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m2` },
        [`yyyyyy${yearForRequest}m3`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m3` },
        [`yyyyyy${yearForRequest}m4`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m4` },
        [`yyyyyy${yearForRequest}m5`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m5` },
        [`yyyyyy${yearForRequest}m6`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m6` },
        [`yyyyyy${yearForRequest}m7`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m7` },
        [`yyyyyy${yearForRequest}m8`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m8` },
        [`yyyyyy${yearForRequest}m9`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}m9` },
        [`yyyyyy${yearForRequest}mm1`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}mm1` },
        [`yyyyyy${yearForRequest}mm2`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}mm2` },
        [`yyyyyy${yearForRequest}mm3`]: { isVisible: true, width: 80, order: `yyyyyy${yearForRequest}mm3` },
      }
      : {};
    setAtoms((prev) => ({
      ...prev,
      queryParamsAtom: {
        ...prev.queryParamsAtom,
        filtersStore: {
          ...prev.queryParamsAtom.filtersStore,
          revenueYear: yearForRequest,
          breakdown: 'Monthly',
        },
      },
      breakdownAtom: createBreakdownForEditMode(),
      viewModeAtom: {
        ...prev.viewModeAtom,
        mode: 'edit',
        paramsBeforeEdit: queryParams,
      },
    }));
    setViewState((prev) => ({
      ...prev,
      columnsConfig: {
        ...prev.columnsConfig,
        ...newBreakdowns,
        currName: {
          ...prev?.columnsConfig?.currName,
          isVisible: true,          
        },
      },
    }));
  };

  const isDisabled = !isOppNameInGroupping || isLoading || !canEdit || error === 'emptyListError';

  return (
    <Button
      isDisabled={isDisabled}
      color="blue"
      caption="Edit"
      icon={isLoading ? LoaderIcon : editIcon}
      iconPosition="left"
      size="30"
      cx={cx}
      onClick={handleClick}
    />
  );
};

export default EditButton;
