import { useRecoilState } from 'recoil';
import slideoutStateAtom, { TSlideout } from '../state/atoms/slideoutStateAtom';

const useSlideout = () => {
  const [currentSlideout, setCurrentSlideout] = useRecoilState(slideoutStateAtom);

  const toggleSlideout = (val: TSlideout) => {
    if (val === currentSlideout) {
      setCurrentSlideout('');
    } else {
      setCurrentSlideout(val);
    }
  };

  const openSlideout = (val: TSlideout) => {
    setCurrentSlideout(val);
  };

  const closeSlideout = () => {
    setCurrentSlideout('');
  };

  return {
    currentSlideout,
    openSlideout,
    closeSlideout,
    toggleSlideout,
  };
};

export default useSlideout;
