import { DataColumnProps } from '@epam/uui-core';
import { atom } from 'recoil';

interface MappedItem {
  [key: string]: number 
  | string 
  | IBreakdown 
  | IValues 
  | DataColumnProps<any, any, any>[] 
  | undefined,
}

export interface IBreakdown {
  [name: string]: number | null
}

interface IValues {
  [name: string]: string | number | null | undefined,
}

export interface IGridItem extends MappedItem {
  breakdown: IBreakdown,
  values: IValues,
  'gttl_p'?: number,
  prob: number,
  type: string,
  columns: DataColumnProps<any, any, any>[],
}

export interface IGridFormAtom {
  items: {
    [key: string]: IGridItem
  },
}

const gridFormAtom = atom<IGridFormAtom>({
  key: 'gridFormAtom',
  default: { items: {} },
});

export const gridShowEditedAtom = atom({
  key: 'gridShowEdited',
  default: false,
});

export default gridFormAtom;
