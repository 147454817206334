import React, { FC, useState } from 'react';
import { 
  Button, DataPickerFooter, DataPickerRow, PickerInput, Text,
} from '@epam/promo';
import { useArrayDataSource } from '@epam/uui-core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import css from '../PermanentFilters.module.scss';
import { FilterOption } from '../const';
import defaultViewPresetSelector from '../../../../state/selectors/defaultViewPresetSelector';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import { useViewPresets } from '../../../../hooks/useViewPresets';
import { Filters, TBreakdown } from '../../../../const/FILTERS';
import updateAtomsForQuerySelector from '../../../../state/selectors/updateAtomsForQuerySelector';
import { createInitialBreakdown } from '../../../../state/atoms/breakdownAtom';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';
import {
  getConfigFromView,
  viewStateAtom, 
} from '../../../../state/atoms/viewStateAtom';

const CURRENCY = 'currency';
const YEAR = 'revenueYear';
const EXTRA_ROWS = ['label', 'footer'];
const ASYNC_FILTERS = ['revenueYear', 'currency', 'breakdown'];

interface Props {
  id: keyof Filters,
  listWidth: number,
  title?: string,
  footer?: string,
  label?: string,
  items: FilterOption[],
  dropdownHeight?: number,
}

const PermanentFilterPicker:FC<Props> = ({
  id,
  items,
  listWidth,
  title,
  footer,
  label,
  dropdownHeight,
}) => {
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  const { filtersStore } = useRecoilValue(queryParamsAtom);
  const value = filtersStore[id];
  const [opened, setOpened] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const { updateViewPresetInList, loading } = useViewPresets();
  const { revenueYear } = filtersStore;
  const setAtoms = useSetRecoilState(updateAtomsForQuerySelector);
  const { mode } = useRecoilValue(viewModeAtom);
  const setViewState = useSetRecoilState(viewStateAtom);

  const isEditMode = mode === 'edit';

  const isYearPlus = revenueYear < 0;
  const isBreakdownDisabled = isYearPlus && id === 'breakdown';

  const filtersData = useArrayDataSource({
    items,
    getId: (i) => i.id,
  }, []);

  const displayedFilterValue = items
    .filter((el) => {
      if (EXTRA_ROWS.includes(el.id.toString())) return false;
      return el.id === value;
    })[0];

  const getItemName = (itemId: string, name: string | string[]) => {
    if (EXTRA_ROWS.includes(itemId) || !ASYNC_FILTERS.includes(id)) {
      return name;
    }
    if (id === 'revenueYear' && Number(itemId) < 0) {
      return `${itemId.slice(1)}+`;
    }
    return itemId;
  };

  const isWithAndWithoutMonthlyName = value === 3 && id === 'forecastGranularity';

  const onClickHandler = (e: Event, onClick?: ((e?:Event) => void)) => {
    onClick?.(e);
    opened ? setOpened(false) : setOpened(true);
  };

  const onValueChangeHandler = async (newValue: string) => {
    setAtoms(({
      queryParamsAtom: prevQueryParamsAtom,
      breakdownAtom: prevBreakdownAtom,
      ...prev
    }) => {
      const getMonthsToSet = () => {
        switch (id){ 
          case YEAR: 
            return createInitialBreakdown(
              Number(newValue),
              Number(newValue) < 0 
                ? 'Monthly' 
                : prevQueryParamsAtom.filtersStore.breakdown,
            );
          case 'breakdown': 
            return createInitialBreakdown(
              prevQueryParamsAtom.filtersStore.revenueYear,
              newValue === 'Monthly' ? 'Monthly' : 'Quarterly', 
            );
          default:
            return prevBreakdownAtom;
        }
      };

      return {
        ...prev,
        queryParamsAtom: {
          ...prevQueryParamsAtom,
          filtersStore: {
            ...prevQueryParamsAtom.filtersStore,
            breakdown: id === YEAR && Number(newValue) < 0 
              ? 'Monthly' 
              : prevQueryParamsAtom.filtersStore.breakdown,
            [id]: newValue,
          },
        },
        breakdownAtom: getMonthsToSet(),
      };
    });

    let updatedKey: keyof Filters | 'year' = id;
    if (id === YEAR) {
      updatedKey = 'year';
    }
    const getBreakdown = (): TBreakdown => {
      if (id === YEAR) {
        if (Number(newValue) < 0) return 'Monthly';
      }
      if (id === 'breakdown') return newValue === 'Monthly' ? 'Monthly' : 'Quarterly';
      return defaultViewPreset.permanentFiltersSettings.breakdown;
    };

    const updatedPreset = {
      ...defaultViewPreset,
      permanentFiltersSettings: {
        ...defaultViewPreset.permanentFiltersSettings,
        [updatedKey]: newValue,
        breakdown: getBreakdown(),
      },
    };

    setViewState((prev) => ({
      ...prev,
      columnsConfig: {
        ...prev.columnsConfig,
        ...getConfigFromView(updatedPreset, mode === 'edit', prev.columnsConfig),
      },
    }));
    
    if (defaultViewPreset.isViewAutosaved) {
      setLocalLoading(true);
      await updateViewPresetInList(updatedPreset);
      setLocalLoading(false);
    }
    
    setOpened(false);
  };

  let bodyClassName;
  if (label && footer) {
    bodyClassName = `${css.pickerLabel} ${css.pickerFooter} ${css.pickerLabelAndFooterBoth}`;
  } else if (label) {
    bodyClassName = `${css.pickerLabel} ${css.pickerRoot}`;
  } else if (footer) {
    bodyClassName = `${css.pickerFooter} ${css.pickerRoot}`;
  } else {
    bodyClassName = css.pickerRoot;
  }

  return (
    <div className={css.picker} aria-label={`${id}-permanent-filter`}>
      <PickerInput
        rawProps={{
          body: {
            className: bodyClassName,
          },
        }}
        renderToggler={({ onClick, ref }) => (
          <Button
            ref={ref}
            isDropdown={!localLoading}
            onClick={(e) => onClickHandler(e, onClick)}
            caption={(
              <div className={css.pickerCaption}>
                {title && <Text cx={css.pickerTitle}>{`${title}: `}</Text>}
                <span className={loading || isEditMode 
                  ? ` ${css.disabledValue} ${css.value} ${title ? css.boldValue : ''}` 
                  : `${css.value} ${title ? css.boldValue : ''}`}
                >
                  {
                    !isWithAndWithoutMonthlyName 
                      ? getItemName(
                        displayedFilterValue.id.toString(),
                        displayedFilterValue.name,
                      ) 
                      : 'With & w/o Monthly Forecast'
                  }
                  {
                      id === CURRENCY
                      && (
                      <span className={loading || isEditMode ? `${css.disabledTitle} ${css.currencyExtraText}` : css.currencyExtraText}>
                        , 1K
                      </span>
                      )
                    }
                </span>
              </div>
              )}
            fill="white"
            color="gray50"
            cx={css.customToggler}
            size="30"
            isDisabled={loading || isBreakdownDisabled || isEditMode}
            icon={localLoading ? LoaderIcon : undefined}
            iconPosition="right"
          />
        )}
        dataSource={filtersData}
        value={value}
        onValueChange={onValueChangeHandler}
        selectionMode="single"
        searchPosition="none"
        getName={(el) => el.id.toString()}
        valueType="id"
        disableClear
        dropdownHeight={dropdownHeight}
        minBodyWidth={listWidth}
        renderRow={({ isDisabled, ...props }) => (
          <DataPickerRow
            key={props.value?.id}
            {...props}
            isDisabled
            cx={css.row}
            renderItem={(item) => (
              <div key={item.id} className={css.itemRow}>
                <Text rawProps={{ style: { padding: `.5rem .5rem ${item.description ? '0' : '.5rem'} 0` } }}>
                  {
                    Array.isArray(item.name)
                      ? (
                        <>
                          <span className={css.pickerMainPartOfName}>{item.name[0]}</span>
                          {item.name[1]}
                        </>
                      )
                      : getItemName(item.id.toString(), item.name)
                  }
                </Text>
                {item.description && (
                  <Text
                    color="gray60" 
                    rawProps={{ style: { fontSize: '12px', padding: '0 .5rem .5rem 0' } }}
                  >
                    {item.description}
                  </Text>
                )}
              </div>
            )}
          />
        )}
        renderFooter={(props) => (
          <DataPickerFooter {...props} children={<Text cx={css.pickerFooter}>{footer}</Text>} />
        )}
      />
    </div>
  );
};

export default PermanentFilterPicker;
