import { DataTableRow } from '@epam/promo';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ColumnsConfig, useColumnsConfig } from '@epam/uui-core';
import useQueryApi from '../../../../hooks/useQueryApi';
import useVirtualListColumns from '../../../../hooks/useVirtualListColumns';
import breakdownAtom from '../../../../state/atoms/breakdownAtom';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import css from '../VirtualList.module.scss';
import advancedFiltersConstructorAtom from '../../../../state/atoms/advancedFiltersConstructorAtom';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';

interface GridFooterProps {
  config: ColumnsConfig;
}

const GridFooter = (props: GridFooterProps) => {
  const { config } = props;
  const { summary, getSummary } = useQueryApi();
  const { getSummaryColumns } = useVirtualListColumns();

  const breakdownStore = useRecoilValue(breakdownAtom);
  const queryParams = useRecoilValue(queryParamsAtom);
  const { changeCounter } = useRecoilValue(advancedFiltersConstructorAtom);
  const { isRefreshed } = useRecoilValue(viewModeAtom);

  useEffect(() => {
    getSummary();
  }, [
    JSON.stringify(queryParams),
    JSON.stringify(breakdownStore).length,
    changeCounter,
    isRefreshed,
  ]);

  const summaryColumns = getSummaryColumns(summary);
  const { columns } = useColumnsConfig(summaryColumns, config);

  return (
    <DataTableRow
      cx={css.footer}
      columns={columns}
      value={summary}
      id="footer"
      index={100500}
      rowKey="footer"
      isLoading={summary.isLoading}
    />
  );
};

export default GridFooter;
