import api from './api';

const BASE_URL = '/actual-revenue-import/settings';

export type TSettingsItemType = 'Consulting' | 'License';

export interface IImportSettingsResponseItem {
  groupAccNumber: string;
  varSubGrouping: string;
  varGrouping: string;
  type: TSettingsItemType;
}

export const getImportSettings = () => api.get<IImportSettingsResponseItem[]>(BASE_URL)
  .then(({ data }) => data.map((el) => ({
    ...el,
    groupAccNumber: el.groupAccNumber.toString(),
  })));

export const postImportSettings = (body: {
  settings: IImportSettingsResponseItem[],
  deleted: number[],
}) => api
  .post(BASE_URL, {
    ...body,
    settings: body.settings.map((el) => ({
      ...el,
      groupAccNumber: Number(el.groupAccNumber),
    })),
  });
