import {
  Button, DataPickerRow, FlexRow, PickerInput, Text, Tooltip, 
} from '@epam/promo';
import { DataRowProps, useArrayDataSource } from '@epam/uui-core';
import React, { FC, useState } from 'react';
import { maxBy } from 'lodash';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/delete-outline-36.svg';
import { ReactComponent as ResetIcon } from '@epam/assets/icons/common/media-replay-18.svg';
import { useRecoilValue } from 'recoil';
import css from '../AdvancedFiltersSlideOut.module.scss';
import Filterbar from './Filterbar/Filterbar';
import SubgroupIcon from '../../../../assets/components/SubgroupIcon';
import { IHierarchicalItem } from '../../../../state/atoms/advancedFiltersConstructorAtom';
import { provideOrderForNewItem } from '../../../../helpers/provideOrderValue';
import {
  TLogicalOperator, getNewId, predicateDictionary, useCreateDataSource,
} from '../../../../const/ADVANCED_FILTERS';
import { IAdvancedFilter } from '../../../../api/query';
import basicModeAvailabilitySelector from '../../../../state/selectors/advancedFiltersBasicModeAvabilitySelector';

interface IOperator {
  id: TLogicalOperator,
  name: 'AND' | 'OR',
  description: string,
}

const operators: IOperator[] = [
  {
    id: 'And',
    name: 'AND',
    description: `The operator checks each condition. The table is affected
    by all the conditions of the group for which there is a result`,
  },
  {
    id: 'Or',
    name: 'OR',
    description: 'The table is affected by one condition from the group, for which the result will be detected first',
  },
];

interface Props {
  data: IHierarchicalItem,
  onSubgroupChange: (subgroupVal: IHierarchicalItem) => void;
  onSubgroupDelete: (subgroupId: string) => void;
  onSwitchToBasic: (operator: TLogicalOperator) => void;
  setFiltersOpeningValue: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  filtersOpeningValue: { [key: string]: boolean };
  isDisabled?: boolean;
  isDeleteDisabled?: boolean;
}

const ConstructorSubgroup: FC<Props> = ({
  data, onSubgroupChange, onSubgroupDelete, isDeleteDisabled,
  onSwitchToBasic, setFiltersOpeningValue, filtersOpeningValue, isDisabled,
}) => {
  const dataSource = useArrayDataSource({
    items: operators,
  }, []);
  const { 
    isBasicModeDisabled, 
    selectedFiltersLimit, 
  } = useRecoilValue(basicModeAvailabilitySelector);
  const { getAdvancedFilters } = useCreateDataSource();
  const filters = getAdvancedFilters();

  const isSwitchToBasic = data.id === '1' && !data.children?.length;

  const isAddGroupAllowed = data.id.split('.').length < 5;
  
  const [limitCounter, setLimitCounter] = useState(
    typeof data.children !== 'undefined'
      ? data.children.length + Object.keys(data.filters).length 
      : Object.keys(data.filters).length,
  );

  const isLimitReached = limitCounter >= 50;

  const handleAddFilter = (filterIds: string[]) => {
    const newFilters = filterIds.reduce((acc, el) => {
      if (data.filters[el]) {
        acc = {
          ...acc,
          [el]: data.filters[el],
        };

        return acc;
      }
      
      const predicate = predicateDictionary[filters.filter((i) => i.field === el)[0]
        .predicates
        .filter((i) => i.isDefault)[0].predicate].id;
      
      const currentFilters = Object.values(data.filters);

      acc = {
        ...acc,
        [el]: {
          key: el,
          operator: predicate,
          order: currentFilters.length 
            ? provideOrderForNewItem<IAdvancedFilter>(currentFilters) 
            : 'a',
          value: undefined,
          subgroupId: data.id,
        },
      };

      return acc;
    }, {});

    setLimitCounter(data.children 
      ? filterIds.length + data.children.length 
      : filterIds.length);

    onSubgroupChange({
      ...data,
      filters: newFilters,
    });
  };

  const handleFilterChange = (filter: IAdvancedFilter) => {
    onSubgroupChange({
      ...data,
      filters: {
        ...data.filters,
        [filter.key]: filter,
      },
    });
  };

  const handleRemoveFilter = (field: string, hierarchicalItem?: IHierarchicalItem) => {
    const subgroup = hierarchicalItem || data;
    const {
      filters: {
        [field]: filterToDelete,
        ...remainingFilters
      }, 
    } = subgroup;

    if (!hierarchicalItem) {
      setLimitCounter(subgroup.children 
        ? Object.keys(remainingFilters).length + subgroup.children.length 
        : Object.keys(remainingFilters).length);
    }

    onSubgroupChange({
      ...subgroup,
      filters: remainingFilters,
      operator: Object.values(remainingFilters).length ? subgroup.operator : 'And',
    });
  };

  const handleAddSubgroup = () => {
    const newSubgroup: IHierarchicalItem = {
      id: data.children && data.children.length 
        ? getNewId(data.id, maxBy(data.children, ({ id }) => Number(id.replace(/\./g, '')))?.id)
        : getNewId(data.id),
      operator: 'And',
      filters: {},
      children: isAddGroupAllowed ? [] : undefined,
      kind: 'subgroup',
    };

    setLimitCounter((prev) => prev + 1);

    onSubgroupChange({
      ...data,
      children: data.children ? [...data.children, newSubgroup] : undefined,
    });
  };

  const handleDeleteSubgroup = () => {
    setLimitCounter((prev) => prev - 1);
    onSubgroupDelete(data.id);
  };

  const handleOperatorChange = (newVal: TLogicalOperator) => {
    onSubgroupChange({
      ...data,
      operator: newVal,
    });
  };

  const renderOperatorRow = (props: DataRowProps<IOperator, unknown>) => (
    <DataPickerRow
      {...props}
      cx={css.operatorRow}
      renderItem={(item) => (
        <div className={css.operatorRowContainer}>
          <Text cx={css.operatorRowName}>{item.name}</Text>
          <Text cx={css.operatorRowDescription}>{item.description}</Text>
        </div>
      )}
    />
  );

  return (
    <div className={css.subgroupContainer}>
      <FlexRow cx={css.controlsRow} spacing="6">
        <PickerInput 
          dataSource={dataSource} 
          value={data.operator}
          renderRow={renderOperatorRow}
          disableClear
          searchPosition="none" 
          onValueChange={handleOperatorChange} 
          selectionMode="single"
          valueType="id"
          size="24"
          isDisabled={isDisabled}
        />
        {isAddGroupAllowed && (
          <Button 
            caption="Add subgroup" 
            size="18" 
            color="gray" 
            fill="light"
            icon={SubgroupIcon}
            captionCX={css.addSubgroupBtnCaption}
            onClick={handleAddSubgroup}
            isDisabled={isLimitReached || isDisabled}
          />
        )}
        <Button 
          caption="Delete" 
          size="18" 
          color="gray" 
          fill="light"
          icon={DeleteIcon}
          onClick={handleDeleteSubgroup} 
          cx={css.deleteSubgroupBtn}
          isDisabled={isDisabled || isDeleteDisabled}
        />
        {isSwitchToBasic && (
        <Tooltip
          content={isBasicModeDisabled
            ? `Basic mode is enabled only when max. ${selectedFiltersLimit} advanced filters selected`
            : ''}
          placement="top-start"
        >
          <Button 
            caption="Switch to Basic Mode" 
            size="18" 
            color="gray" 
            fill="light"
            icon={ResetIcon}
            onClick={() => onSwitchToBasic(data.operator)} 
            isDisabled={
              isBasicModeDisabled
              || !!data.children?.length 
                || (data.operator === 'Or' && Object.keys(data.filters).length !== 0)
                || isDisabled
            }
            cx={css.switchBtn}
          />
        </Tooltip>
        )}
      </FlexRow>
      <div className={css.filterbarContainer}>
        <Filterbar 
          filters={filters} 
          value={data.filters} 
          setValue={handleFilterChange}
          onAddFilter={handleAddFilter}
          removeFilter={handleRemoveFilter}
          isDraggable
          isDisabled={isLimitReached || isDisabled}
          setFiltersOpeningValue={setFiltersOpeningValue}
          filtersOpeningValue={filtersOpeningValue}
        />
      </div>
    </div>
  );
};

export default ConstructorSubgroup;
