import React from 'react';
import { useUuiContext, INotification } from '@epam/uui-core';
import { Text, NotificationCard, EpamPrimaryColor } from '@epam/promo';
import { ReactComponent as NotificationInfoIcon } from '@epam/assets/icons/common/notification-info-fill-24.svg';
import { ReactComponent as NotificationDoneIcon } from '@epam/assets/icons/common/notification-check-fill-24.svg';

export const useNotification = () => {
  const { uuiNotifications } = useUuiContext();

  const showNotification = (
    text: string,
    action?: () => void,
    color?: EpamPrimaryColor | 'gray60',
  ) => {
    uuiNotifications.show((props: INotification) => (
      (
        props.onSuccess && (
          <NotificationCard
            {...props}
            color={color ?? 'blue'}
            icon={color === 'green' ? NotificationDoneIcon : NotificationInfoIcon}
            actions={
              action && [{
                name: 'RESTORE',
                action: props.onSuccess,
              }]
            }    
          >
            <Text size="36" font="sans" fontSize="14">{text}</Text>
          </NotificationCard>
        ))
    ))
      .then(() => {
        if (action) {
          action();
        }
      }).catch(() => null);
  };
  
  return { showNotification };
};
