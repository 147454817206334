import React from 'react';
import UnlinkedAmountGrid, { IUnlinkedItem } from '../UnlinkedAmountGrid/UnlinkedAmountGrid';
import ErrorsGrid, { IErrorItem } from '../ErrorsGrid/ErrorsGrid';
import css from './GridSwitchContainer.module.scss';

export type TGridId = 'unlinkedAmount' | 'errors' | null;
interface GridSwitchContainerProps {
  gridId: TGridId,
  errors: IErrorItem[],
  unlinked: IUnlinkedItem[],
}

const GridSwitchContainer = (props: GridSwitchContainerProps) => {
  const { gridId, errors, unlinked } = props;
  switch (gridId) {
    case 'unlinkedAmount': {
      return (
        <div className={css.container}>
          <UnlinkedAmountGrid items={unlinked} />
        </div>
      );
    }
    case 'errors': {
      return (
        <div className={css.container}>
          <ErrorsGrid items={errors} />
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default GridSwitchContainer;
