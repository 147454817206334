import {
  Button, IconButton, Text, Tooltip, 
} from '@epam/promo';
import React from 'react';
import { useRecoilState } from 'recoil';
import { ReactComponent as linkIcon } from '@epam/assets/icons/common/action-external_link-12.svg';
import { ReactComponent as closeIcon } from '@epam/assets/icons/common/navigation-close-24.svg';
import oppDetailsSlideoutAtom from '../../../state/atoms/oppDetailsSlideoutAtom';
import css from './OppDetailsSlideOut.module.scss';
import useSlideout from '../../../hooks/useSlideout';

const Header = () => {
  const [{ details, oppName }, setSlideout] = useRecoilState(oppDetailsSlideoutAtom);
  const { closeSlideout } = useSlideout();
  const close = () => {
    closeSlideout();
    setSlideout((prev) => ({
      ...prev,
      open: false,
      oppName: '',
      details: undefined,
    }));
  };

  return (
    <div className={css.header}>
      <div className={css.oppTitle}>
        <Text>{oppName}</Text>
        {details?.oneCrmUrl ? (
          <Button
            caption="Open in OneCRM"
            href={details?.oneCrmUrl}
            target="_blank"
            size="24"
            fill="light"
            icon={linkIcon}
            iconPosition="right"
            cx={css.crmLink}
          />
        ) : (
          <Tooltip placement="bottom" content="Link to OneCRM is not available">
            <Button
              isDisabled
              caption="Open in OneCRM"
              href={details?.oneCrmUrl}
              target="_blank"
              size="24"
              fill="light"
              icon={linkIcon}
              iconPosition="right"
              cx={css.crmLink}
            />
          </Tooltip>
        )}
        
      </div>
      <IconButton icon={closeIcon} onClick={close} />
    </div>
  );
};

export default Header;
