import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ReactComponent as SearchIcon } from '@epam/assets/icons/common/action-search-12.svg';
import { TextInput, Tooltip } from '@epam/promo';
import css from '../GridHeader.module.scss';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import searchAtom from '../../../../state/atoms/searchAtom';

const SearchInput = () => {
  const [value, setValue] = useRecoilState(searchAtom);
  const setQueryParams = useSetRecoilState(queryParamsAtom);

  // Debounce filterAtom update to avoid calling API every time onChange event is called
  useEffect(() => {
    const timer = setTimeout(() => {
      setQueryParams((prev) => ({
        ...prev,
        filtersStore: {
          ...prev.filtersStore,
          search: value, 
        },
      }));
    }, 500);
    return () => clearTimeout(timer);
  }, [value, setQueryParams]);

  const handleSearchChange = (newVal: string) => {
    setValue(newVal);
  };

  return (
    <Tooltip
      content="Search by Account, Opportunity and/or Linked Projects columns of the Revenue Forecast page"
      placement="bottom"
    >
      <TextInput
        cx={css.seacrhInput}
        inputMode="search"
        placeholder="Search by table"
        value={value}
        onValueChange={handleSearchChange}
        size="24"
        icon={SearchIcon}
        iconPosition="left"
        maxLength={300}
      />
    </Tooltip>
  );
};

export default SearchInput;
