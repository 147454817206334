import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useRecoilValue } from 'recoil';
import userProfileAtom from '../state/atoms/userProfileAtom';

dayjs.extend(utc);

const formatUnitsToUpperCase = ['dd', 'd', 'yy', 'yyyy'];

const useUserProfile = () => {
  const { userSettings, userId } = useRecoilValue(userProfileAtom);
  
  const formatDate = (
    date: string | number,
    params?: {
      isShort?: boolean,
      isAuditUTC?: boolean,
      isNotes?: boolean,
      isWithSec?: boolean,
    }, 
  ): string => {
    const { dateFormatString, timeFormatString, timeZoneBias } = userSettings;
    let processedDateFormat = dateFormatString.split('/').map((el) => {
      if (formatUnitsToUpperCase.includes(el)) return el.toUpperCase();
      return el;
    }).join('/');

    if (processedDateFormat.includes('MMM')) {
      processedDateFormat = 'dd MMMM YYYY';
    }

    const processedTimeFormat = timeFormatString.replace('tt', 'A');

    const finalFormat = params?.isShort 
      ? `${processedDateFormat}` 
      : `${processedDateFormat} ${params?.isWithSec ? [`${processedTimeFormat.split(' ')[0]}:ss`, processedTimeFormat.split(' ')[1]].join(' ') : processedTimeFormat}`;

    const dateWithBias = dayjs(date).add(timeZoneBias, 'minute');
    const isToday = dateWithBias.isSame(new Date(), 'day') && params?.isNotes;

    if (params?.isAuditUTC) {
      return params?.isShort ? dayjs(date).format('YYYY-MM-DD') : dayjs(date).format('YYYY-MM-DD HH:mm');
    }

    return isToday 
      ? `Today, ${dateWithBias.format(processedTimeFormat)}`
      : dateWithBias.format(finalFormat);
  };

  const getLocalTime = (): Dayjs => {
    const utcTime = new Date().toUTCString();
    return dayjs(utcTime);
  };

  return {
    userId,
    formatDate,
    getLocalTime,
  };
};

export default useUserProfile;
