/* eslint-disable no-confusing-arrow */
import { DataTable, FlexSpacer, SearchInput } from '@epam/promo';
import {
  ColumnsConfig, DataColumnProps, DataTableRowProps, LazyListView,
} from '@epam/uui-core';
import React, { useEffect, useRef } from 'react';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { QueryItem } from '../../hooks/useQueryApi';
import queryParamsAtom from '../../state/atoms/queryParamsAtom';
import { virtualListErrorsAtom } from '../../state/atoms/virtualListErrorsAtom';
import MonthsToolbar from '../Grid/GridHeader/components/MonthsToolbar';
import VirtualListError from '../Grid/VirtualList/components/VirtualListError';
import EditableRow from './components/EditableRow';
import css from './editModeGrid.module.scss';
import useGridForm from '../../hooks/useGridForm';
import defaultViewPresetSelector from '../../state/selectors/defaultViewPresetSelector';
import { foldedAtom } from '../../state/atoms/foldedAtom';
import useDebounce from '../../hooks/useDebounce';
import { View } from '../../api/views';
import { useViewPresets } from '../../hooks/useViewPresets';
import { TableDataSource } from '../../state/atoms/viewStateAtom';

interface EditModeGridProps {
  lazyView: LazyListView<QueryItem, string, unknown>; 
  viewState: TableDataSource; 
  setViewState: SetterOrUpdater<TableDataSource>;
  config: ColumnsConfig;
  columns: DataColumnProps<any, any, any>[];
  refreshGrid?: () => void;
}

const EditModeGrid = (props: EditModeGridProps) => {
  const {
    lazyView, viewState, setViewState, columns, config,
  } = props;

  const { filtersStore: { search } } = useRecoilValue(queryParamsAtom);
  const [foldedArr, setFoldedArr] = useRecoilState(foldedAtom);

  const error = useRecoilValue(virtualListErrorsAtom);
  const ref = useRef<HTMLDivElement>(null);

  const { getRows, isShowEdited } = useGridForm();

  const rows = getRows(lazyView);

  const listProps = lazyView.getListProps();

  const view = useRecoilValue(defaultViewPresetSelector);
  const { id: viewId } = view;

  const { updateViewPresetInList } = useViewPresets();
  
  const saveConfig = useDebounce(() => {
    const updatedView: View = {
      ...view,
      columnSettings: {
        ...view.columnSettings,
        columns: {
          col: view.columnSettings.columns.col.map((el) => ({
            ...el,
            width: config?.[el.id]?.width || null,
          })),
          oppAttr: view.columnSettings.columns.oppAttr.map((el) => ({
            ...el,
            width: config?.[el.id]?.width || null,
          })),
          accAttr: view.columnSettings.columns.accAttr.map((el) => ({
            ...el,
            width: config?.[el.id]?.width || null,
          })),
        },
      },
    };
    updateViewPresetInList(updatedView);
  });

  useEffect(() => {
    if (!view.isViewAutosaved) return; 
    saveConfig();
  }, [JSON.stringify(config)]);

  useEffect(() => {
    window.localStorage.setItem(viewId, JSON.stringify(foldedArr));
  }, [foldedArr]);

  if (error) {
    return <VirtualListError error={error} />;
  }

  return (
    <div 
      className={css.container} 
      ref={ref} 
      style={{
        height: 'calc(100% - var(--headerHeight))',
      }}
    >
      <div className={css.gridUpperRow}> 
        {search ? (
          <SearchInput 
            value={search}
            onValueChange={() => {}}
            isDisabled
            cx={css.gridSearch}
            size="30"
          />
        ) : <FlexSpacer />}
        <MonthsToolbar isEditMode />
      </div>
      <DataTable
        {...listProps}
        rowsCount={isShowEdited ? rows.length : listProps.rowsCount}
        getRows={() => rows}
        columns={columns}
        value={viewState}
        allowColumnsResizing
        selectAll={undefined}
        renderRow={(rowProps: DataTableRowProps<any, any>) => (
          <EditableRow 
            {...rowProps}
            key={rowProps.id}
            cx={css.row}
            foldedArr={foldedArr} 
            setFoldedArr={setFoldedArr}
          />
        )}
        onValueChange={setViewState}
      />
    </div>
  );
};

export default EditModeGrid;
