import React from 'react';
import { FlexCell, FlexRow, Text } from '@epam/promo';
import { useRecoilState } from 'recoil';
import oppDetailsSlideoutAtom from '../../../state/atoms/oppDetailsSlideoutAtom';
import css from './OppDetailsSlideOut.module.scss';

const detailsDictionary = [
  { id: 'accountManager', value: 'Account Manager' },
  { id: 'salesManager', value: 'Sales Manager' },
  { id: 'gbu', value: 'GBU' },
  { id: 'description', value: 'Description' },
  { id: 'currName', value: 'Currency' },
  { id: 'commissionable', value: 'Commissionable' },
  { id: 'engagementType', value: 'Engagement Type' },
  { id: 'leadPractice', value: 'Lead Practice' },
  { id: 'industry', value: 'Industry' },
  { id: 'advisoryAccountManager', value: 'Advisory Account Manager' },
  { id: 'dealIsPartnerRelated', value: 'Deal is Partner related' },
  { id: 'partnerAccounts', value: 'Partner Accounts' },
  { id: 'practices', value: 'Practices' },
  { id: 'platforms', value: 'Platforms' },
  { id: 'continuumOfferings', value: 'Continuum Offerings' },
];

const DetailsTab = () => {
  const [{ details, currName }] = useRecoilState(oppDetailsSlideoutAtom);
  const renderDetails = detailsDictionary.map((el) => {
    const getDetails = () => {
      if (details && details[el.id]) {
        const res = details[el.id]?.toString().includes('|')
          ? details[el.id]?.toString().split('|').join('; ')
          : details && details[el.id];
        return res;
      }
      if (details && typeof details[el.id] === 'boolean') {
        const res = details[el.id] ? 'Yes' : 'No';
        return res;
      }
      if (currName && el.id === 'currName') {
        return currName;
      } 

      return 'n/a';
    };

    return (
      <FlexRow key={el.id} cx={css.detailsRow}>
        <Text cx={css.title}>{el.value}</Text>
        <Text cx={css.value}>{getDetails()}</Text>
      </FlexRow>
    );
  });

  return (
    <FlexCell grow={1} cx={css.detailsTab}>
      {renderDetails}
    </FlexCell>
  );
};

export default DetailsTab;
