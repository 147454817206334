// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sortBy } from 'lodash';
import { cx } from '@epam/uui-core';
import {
  Button, DropdownContainer, FlexCell, FlexRow,
} from '@epam/promo';
import {
  AdaptiveItemProps, AdaptivePanel, Dropdown, ScrollBars, 
} from '@epam/uui-components';
import { ReactComponent as addIcon } from '@epam/assets/icons/common/action-add-18.svg';
import css from './PresetsPanel.module.scss';
import { Preset } from './Preset';
import { PresetInput } from './PresetInput';
import { DropdownPresetItem } from './DropdownPresetItem';
import { useViewPresets } from '../../../hooks/useViewPresets';
import { View } from '../../../api/views';
import defaultViewPresetSelector from '../../../state/selectors/defaultViewPresetSelector';
import { foldedAtom } from '../../../state/atoms/foldedAtom';
import { useNotification } from '../../../hooks/useNotification';

type PresetAdaptiveItem = AdaptiveItemProps<{ preset?: View }>;

export function PresetsPanel() {
  const {
    viewPresets, applyPreset, deletePreset,
    restoreDeletedView, addNewPreset,
  } = useViewPresets();
  const { showNotification } = useNotification();
  const { gridType } = useRecoilValue(defaultViewPresetSelector);
  const setFoldedArr = useSetRecoilState(foldedAtom);

  const [isAddingPreset, setIsAddingPreset] = useState(false);

  const setAddingPreset = useCallback(() => {
    setIsAddingPreset(true);
  }, []);

  const cancelAddingPreset = useCallback(() => {
    setIsAddingPreset(false);
  }, []);

  const onPresetApply = async (preset?: View) => {
    if (preset && (!preset.isDefault || gridType === 'Goals')) {
      window.history.pushState(null, document.title, window.location.origin);
      await applyPreset(preset);
      setFoldedArr(JSON.parse(window.localStorage.getItem(preset.id) || '[]'));
    }
  };

  const onPresetDelete = async (preset?: View) => {
    if (preset) {
      const presetIndex = viewPresets.findIndex((el) => el.id === preset.id);
      await deletePreset(preset);
      showNotification(
        `${preset.name} has been deleted`,
        () => restoreDeletedView(preset, presetIndex),
      );
    }
  };

  const onPresetCreate = async (name: string) => {
    await addNewPreset(name);
  };

  const renderPreset = (preset: View) => (
    <Preset
      key={preset.id}
      preset={preset}
      onPresetApply={onPresetApply}
      onPresetDelete={onPresetDelete}
      onPresetCreate={onPresetCreate}
      addPreset={setAddingPreset}
    />
  );

  const renderAddPresetButton = useCallback(() => (
    <div key="addingPresetBlock" className={css.addPresetContainer}>
      {!isAddingPreset ? (
        <Button
          size="36"
          onClick={setAddingPreset}
          caption="Add Preset"
          icon={addIcon}
          iconPosition="left"
          fill="light"
        />
      ) : (
        <PresetInput
          onCancel={cancelAddingPreset}
          onSuccess={onPresetCreate}
          placeholder="New Preset"
        />
      )}
    </div>
  ), [isAddingPreset]);

  const renderMoreButtonDropdown = (
    item: PresetAdaptiveItem,
    hiddenItems: PresetAdaptiveItem[],
  ) => {
    const hasNewViews = hiddenItems.some(
      (hiddenItem) => hiddenItem.preset && hiddenItem.preset.isNew,
    );
    return (
      <Dropdown
        key="more"
        renderTarget={(propsTarget) => (
          <FlexRow>
            <div className={css.divider} />
            <Button
              cx={cx(
                css.moreButton,
                hasNewViews && css.notificationDot,
              )}
              fill="light"
              color="gray50"
              caption={`${hiddenItems?.length || ''} more`}
              {...propsTarget}
            />
          </FlexRow>
        )}
        renderBody={(propsBody) => (
          <DropdownContainer cx={cx(css.dropContainer)} width={230} maxWidth={230} {...propsBody}>
            <ScrollBars>
              {hiddenItems.map((hiddenItem) => (
                <DropdownPresetItem
                  hiddenItem={hiddenItem}
                  onPresetApply={onPresetApply}
                  onPresetDelete={onPresetDelete}
                  propsBody={propsBody}
                />
              ))}
            </ScrollBars>
          </DropdownContainer>
        )}
      />
    );
  };

  const getPresetPriority = (preset: View, index: number) => (
    preset.isDefault ? 100499 : 1000 - index
  );

  const getPanelItems = (): PresetAdaptiveItem[] => [
    ...sortBy(viewPresets, (i) => i.order).map((preset, index) => ({
      id: preset.id.toString(),
      render: () => renderPreset(preset),
      priority: getPresetPriority(preset, index),
      preset,
    })),
    {
      id: 'collapsedContainer', render: renderMoreButtonDropdown, priority: 100501, collapsedContainer: true,
    },
    { id: 'addPreset', render: renderAddPresetButton, priority: 100501 },
  ];

  return (
    <FlexCell grow={1} minWidth={310}>
      <FlexRow size={null} columnGap="12" cx={css.presetsWrapper}>
        <AdaptivePanel items={getPanelItems()} />
      </FlexRow>
    </FlexCell>
  );
}
