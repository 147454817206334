import React, { useRef } from 'react';
import { DropdownMenuButton, Tooltip } from '@epam/promo';
import { AdaptiveItemProps } from '@epam/uui-components';
import { DropdownBodyProps } from '@epam/uui-core';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/action-deleteforever-18.svg';
import { View } from '../../../api/views';
import { useViewPresets } from '../../../hooks/useViewPresets';
import css from './PresetsPanel.module.scss';

type PresetAdaptiveItem = AdaptiveItemProps<{ preset?: View }>;

interface DropdownPresetItemProps {
  hiddenItem: PresetAdaptiveItem;
  onPresetApply: (preset?: View) => Promise<any>;
  onPresetDelete: (preset?: View) => Promise<any>;
  propsBody: DropdownBodyProps;
}

export function DropdownPresetItem({
  hiddenItem, onPresetApply, onPresetDelete, propsBody,
}: DropdownPresetItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { viewPresets, loading } = useViewPresets();

  const getTooltipContent = () => {
    let isOverflowName = false;
    if (ref.current) {
      const nameRef = ref.current.children[0];
      isOverflowName = nameRef.scrollWidth > nameRef.clientWidth;
    }
    return isOverflowName && hiddenItem.preset ? hiddenItem.preset.name : '';
  };

  return (
    <Tooltip content={getTooltipContent()} placement="top" color="white">
      <div>
        <DropdownMenuButton
          key={hiddenItem.preset && hiddenItem.preset.id}
          onClick={() => {
            onPresetApply(hiddenItem.preset);
            if (propsBody.onClose) {
              propsBody.onClose();
            }
          }}
          caption={hiddenItem.preset && hiddenItem.preset.name}
          icon={viewPresets.length > 1 ? DeleteIcon : undefined}
          iconPosition="right"
          cx={`
            ${css.dropdownMenuItem}
            ${css.dropdownDeleteIcon}
            ${hiddenItem.preset && hiddenItem.preset.isNew && css.newView}
          `}
          onIconClick={
            viewPresets.length > 1
              ? (() => {
                if (!loading) {
                  onPresetDelete(hiddenItem.preset);
                }
              })
              : undefined
          }
          ref={ref}
        />
      </div>
    </Tooltip>
  );
}
