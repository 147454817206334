import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import cx from 'classnames';
import { DataTableRow } from '@epam/promo';
import { DataTableRowProps } from '@epam/uui-core';
import css from '../VirtualList.module.scss';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';

interface TableRowProps extends DataTableRowProps {
  foldedArr: string[];
  setFoldedArr: (val: string[]) => void;
}

export const TableRow = (props: TableRowProps) => {
  const {
    id, value, columns, isFoldable, isFolded, onFold, setFoldedArr, foldedArr,
  } = props;

  const { groupingStore } = useRecoilValue(queryParamsAtom);
  const onlyLevel = groupingStore.multiGrouping.length === 1;

  const foldId = `${value?.name}_${value?.type}`;

  const fn = () => new Promise((res) => {
    res(true);
  });

  useEffect(() => {
    if (foldedArr.includes(foldId) && isFolded){
      fn().then(() => onFold?.(props));
    }
  }, []);

  useEffect(() => {
    if (!foldedArr.includes(foldId) && !isFolded) fn().then(() => onFold?.(props));
  }, [foldedArr]);

  return (
    <DataTableRow
    // eslint-disable-next-line react/no-array-index-key
      key={id}
      {...props}
      columns={columns}
      cx={cx({
        [css.dataRow]: isFoldable,
        [css.oppRow]: !isFoldable,
        [css.dataRowWithPadding]: !onlyLevel,
      })}
      onFold={() => {
        onFold?.(props);
        if (foldedArr.includes(foldId)) {
          setFoldedArr(foldedArr.filter((el) => el !== foldId));
          return;
        }
        setFoldedArr([...foldedArr, foldId]);
      }}
      rawProps={{
        style: {
          padding: '0',
        }, 
      }}
    />
  );
};
