import { atom } from 'recoil';

export interface IMarkedForUpdateAtom {
  toUpdate: string[],
  updated: string[],
  isUpdating: boolean,
}

const markedForUpdateAtom = atom<IMarkedForUpdateAtom>({
  key: 'markedForUpdateatom',
  default: {
    toUpdate: [],
    updated: [],
    isUpdating: false,
  },
});

export const accountChildrenAtom = atom<{ [key: string]: string[] }>({
  key: 'accountChildren',
  default: {},
});

export const accountsForUpdateAtom = atom<string[]>({
  key: 'accountsMarkedForUpdate',
  default: [],
});

export default markedForUpdateAtom;
