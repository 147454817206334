import React, { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Text, Button } from '@epam/promo';
import { ReactComponent as mailIcon } from '@epam/assets/icons/common/communication-mail-18.svg';
import { Error } from '../../../../state/atoms/virtualListErrorsAtom';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import useVirtualListColumns from '../../../../hooks/useVirtualListColumns';
import ColumnsBtn from './ColumnsBtn';
import emptyList from '../../../../assets/svg/errors/emptyList.svg';
import loadingError from '../../../../assets/svg/errors/loadingError.svg';
import noPermessions from '../../../../assets/svg/errors/noPermessions.svg';
import css from '../VirtualList.module.scss';

interface Props {
  error: Error;
}

const VirtualListError: FC<Props> = ({ error }) => {
  const { groupingStore } = useRecoilValue(queryParamsAtom);
  const { getGroupingName } = useVirtualListColumns();
  const groupingNames = groupingStore.multiGrouping.map((el) => el.value);

  const errorOptions = useMemo(() => {
    let image;
    let title;
    let description;
    let isSupportButton;
    if (error === 'emptyListError') {
      image = emptyList;
      title = 'No results found';
    }
    if (error === 'accessError') {
      image = noPermessions;
      title = 'You have no permissions for Opportunities';
    }
    if (error === 'loadError') {
      image = loadingError;
      title = 'Table loading error';
      description = 'Change filters, views or contact support';
      isSupportButton = true;
    }

    return {
      image,
      title,
      description,
      isSupportButton, 
    };
  }, [error]);

  return (
    <div className={css.errorRoot}>
      <div className={css.errorHeader}>
        <div className={css.grouping}>{getGroupingName(groupingNames)}</div>
        <div className={css.settingsBtn}>
          <ColumnsBtn />
        </div>
      </div>
      <div className={css.errorContent}>
        <img src={errorOptions.image} alt={errorOptions.title} />
        <Text cx={css.errorTitle} font="museo-sans">{errorOptions.title}</Text>
        {
          errorOptions.description
          && <Text cx={css.errorDescription}>{errorOptions.description}</Text>
        }
        {
          errorOptions.isSupportButton
          && (
            <Button
              cx={css.supportButton}
              icon={mailIcon}
              color="gray50"
              caption="Write to Support"
              href="mailto:AskCRM@epam.com"
            />
          )
        }
      </div>
    </div>
  );
};

export default VirtualListError;
