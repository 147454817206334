import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import exportExcel from '../api/export';
import { queryRequestBodyAtom } from './useQueryApi';
import currentColumnsIdsSelector from '../state/selectors/currentColumnsIdsSelector';
import queryParamsAtom from '../state/atoms/queryParamsAtom';
import sortingAtom from '../state/atoms/sortingAtom';
import {
  IQueryRequestBody, ISortingItem, TQueryValuesKey,
} from '../api/query';
import { useNotification } from './useNotification';
import exportPivot from '../api/exportPivot';

const TEXT_ERROR = 'Something went wrong during exporting your file.';

export const useExportExcel = () => {
  const [loading, setLoading] = useState(false);
  const queryRequestBody = useRecoilValue(queryRequestBodyAtom);
  const activeColumns = useRecoilValue(currentColumnsIdsSelector);
  const { groupingStore } = useRecoilValue(queryParamsAtom);
  const groupingCols = groupingStore.multiGrouping.map((el) => el.id);
  const sortingStore = useRecoilValue(sortingAtom);
  const { showNotification } = useNotification();

  const sortingForExport = sortingStore.reduce((acc: ISortingItem[], el) => {
    if (el.columnName === 'grouping') {
      acc = [
        ...acc,
        ...groupingCols.map((group) => ({
          columnName: group,
          direction: el.direction,
        })),
      ];
    } else if (el.columnName === 'hasNotes') {
      acc = [
        ...acc,
        {
          columnName: 'oppNotes',
          direction: el.direction,
        },
      ];
    } else acc = [el, ...acc];
    return acc;
  }, []);

  const getBreakdownData = (
    startDate: Date,
    endDate: Date,
    isMonthlyMode: boolean,
  ): Array<string> => {
    const dateRange = [];
    let startRange = dayjs(startDate);
    const endRange = dayjs(endDate);

    while (startRange <= endRange) {
      dateRange.push(startRange);
      startRange = startRange.add(isMonthlyMode ? 1 : 3, 'month');
    }

    return dateRange.map((date) => {
      if (isMonthlyMode) {
        return `m${date.month() + 1}_${date.year()}`;
      }
      return `q${Math.ceil((date.month() + 1) / 3)}_${date.year()}`;
    });
  };

  const generateExcelFile = async (startDate: Date, endDate: Date, isMonthlyMode: boolean) => {
    if (queryRequestBody) {
      setLoading(true);

      const {
        aggregate: {
          groupColumnNames,
        },
        parameters,
      } = queryRequestBody;

      const columns = [...activeColumns].map((column: TQueryValuesKey) => (column === 'hasNotes' ? 'oppNotes' : column));

      const reqBody: IQueryRequestBody = {
        ...queryRequestBody,
        aggregate: {
          nextGroupColumnName: null,
          currentGroupColumnName: groupColumnNames[groupColumnNames.length - 1],
          groupColumnNames,
        },
        aggregateFilters: [],
        parameters: {
          ...parameters,
          breakdown: isMonthlyMode ? 'Monthly' : 'Quarterly',
          year: startDate.getFullYear(),
        },
        columns: [
          ...groupingCols,
          ...columns,
          ...getBreakdownData(startDate, endDate, isMonthlyMode),
        ].filter((el) => !!el),
        sort: sortingForExport,
        take: 0,
        skip: 0,
      };
      let excelFile: Blob | null = null;
      delete reqBody.viewId;

      await exportExcel(reqBody)
        .then((data) => {
          excelFile = data;
        })
        .catch(() => {
          setLoading(false);
          showNotification(TEXT_ERROR, undefined, 'red');
        }); 

      if (excelFile !== null && excelFile !== undefined) {
        const fileURL = URL.createObjectURL(excelFile);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `CRM_Pipeline_(${dayjs().format('DD-MM-YYYY')}).xlsx`;
        link.click();
        link.remove();
        URL.revokeObjectURL(fileURL);
      }
      setLoading(false);
    }
  };

  const generatePivotFile = async (reqBody: {
    from: string,
    to: string,
    oppStatus: {
      eq: string,
      values: number[]
    }
  }) => {
    setLoading(true);

    let excelFile: Blob | null = null;

    await exportPivot(reqBody).then((data) => {
      excelFile = data;
    })
      .catch(() => {
        setLoading(false);
        showNotification(TEXT_ERROR, undefined, 'red');
      });

    if (excelFile !== null && excelFile !== undefined) {
      const fileURL = URL.createObjectURL(excelFile);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'Revenue Data for Pivot.xlsx';
      link.click();
      link.remove();
      URL.revokeObjectURL(fileURL);
    }
    setLoading(false);
  };
  
  return { generateExcelFile, generatePivotFile, loading };
};
