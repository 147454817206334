import React, { useRef } from 'react';
import { useUuiContext, INotification } from '@epam/uui-core';
import { Text, ErrorNotification } from '@epam/promo';

type ApiNotificationProps = {
  text: string,
  onAction: () => void,
};

export const useApiNotification = () => {
  const { uuiNotifications } = useUuiContext();
  const isNotificationVisible = useRef(false);

  const showApiNotification = ({
    text,
    onAction,
  }: ApiNotificationProps) => {
    if (isNotificationVisible.current) return;

    isNotificationVisible.current = true;

    uuiNotifications.show((props: INotification) => (
      (
        props.onSuccess && (
          <ErrorNotification
            {...props}
            actions={[
              {
                name: 'Refresh',
                action: props.onSuccess,
              },
            ]}
            onClose={() => {
              props.onClose && props.onClose();
              isNotificationVisible.current = false;
            }}
          >
            <Text size="36" fontSize="14">{text}</Text>
          </ErrorNotification>
        ))
    ), { duration: 'forever' })
      .then(() => {
        onAction && onAction();
      }).catch(() => null);
  };

  return { showApiNotification };
};
