import React from 'react';
import { Text } from '@epam/promo';
import { DataColumnProps } from '@epam/uui-core';
import ColumnCaptionText from '../ColumnCaptionText/ColumnCaptionText';

const columns: DataColumnProps[] = [
  {
    key: 'projectName',
    caption: <ColumnCaptionText caption="Project name" />,
    render: (item: any) => (
      <Text>{item.projectName}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
  },
  {
    key: 'revenueType',
    caption: <ColumnCaptionText caption="Revenue type" />,
    render: (item: any) => (
      <Text>{item.revenueType}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
  },
  {
    key: 'amountInUsd',
    caption: <ColumnCaptionText caption="Amount 1k, USD" />,
    render: (item: any) => (
      <Text>{item.amountInUsd}</Text>
    ),
    grow: 1,
    width: 150,
    textAlign: 'left',
  },
];

export default columns;
