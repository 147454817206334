import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, FlexRow } from '@epam/promo';
import { ReactComponent as TreeIcon } from '@epam/assets/icons/common/navigation-structure_alt-18.svg';
import PermanentFilters from '../PermanentFilters/PermanentFilters';
import AdvancedFilters from '../AdvancedFilters/AdvancedFilters';
import { useAdvancedFilters } from '../../../hooks/useAdvancedFilters';
import advancedFiltersConstructorAtom from '../../../state/atoms/advancedFiltersConstructorAtom';
import viewModeAtom from '../../../state/atoms/viewModeAtom';
import css from '../FiltersPanel.module.scss';
import useSlideout from '../../../hooks/useSlideout';
import defaultViewPresetSelector from '../../../state/selectors/defaultViewPresetSelector';

const Filters = () => {
  const [
    { constructor, isConstructor, changeCounter },
    setAdvancedFiltersConstructor,
  ] = useRecoilState(advancedFiltersConstructorAtom);
  const { mode } = useRecoilValue(viewModeAtom);
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  const { getFilterConstructorStringLayout } = useAdvancedFilters();
  const isEditMode = mode === 'edit';
  const [
    constructorString,
    setConstructorString,
  ] = useState(getFilterConstructorStringLayout(constructor));

  const { toggleSlideout } = useSlideout();

  useEffect(() => {
    setConstructorString(getFilterConstructorStringLayout(constructor));
  }, [constructor, changeCounter, defaultViewPreset.id]);

  return (
    <>
      <FlexRow vPadding="18" cx={css.filtersBar}>
        <PermanentFilters />
        {!isConstructor && <AdvancedFilters />}
      </FlexRow>
      {
        isConstructor
          ? (
            <FlexRow cx={css.filterConstructorString}>
              <Button
                fill="none"
                color="gray"
                size="30"
                caption={constructorString}
                icon={TreeIcon}
                iconPosition="right"
                isDisabled={isEditMode}
                onClick={() => {
                  setAdvancedFiltersConstructor((prev) => ({
                    ...prev,
                    cache: prev.constructor,
                  }));
                  toggleSlideout('advFiltersConstructor');
                }}
                cx={css.constructorBtn}
              />
            </FlexRow>
          )
          : null
      }
    </>
  );
};

export default Filters;
