import { atom } from 'recoil';

export type Error = 'loadError' | 'emptyListError' | 'accessError' | null;

const virtualListErrorsAtom = atom<Error>({
  key: 'virtualListErrorsAtom',
  default: null,
});

export { virtualListErrorsAtom };
