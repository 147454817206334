// @ts-nocheck
import { NumericInput, Tooltip } from '@epam/promo';
import { RenderEditorProps } from '@epam/uui-core';
import React, {
  FC, useEffect, useRef, useState, 
} from 'react';
import { useRecoilValue } from 'recoil';
import useOppDetailsModal from '../../../hooks/useOppDetailsModal';
import css from '../editModeGrid.module.scss';
import { getNavigatorLanguage } from '../../../helpers/getNavigatorLanguage';
import { convertNumber } from '../../../helpers/converNumber';
import gridFormAtom from '../../../state/atoms/gridFormAtom';
import { TData } from '../../Grid/VirtualList/components/GridDataCell';

interface Props {
  editorProps: RenderEditorProps<any, any, any>,
  getIsDisabled: () => boolean,
  changeCellVal: (
    newVal: number | null,
    editorProps?: RenderEditorProps<any, any, any>,
    isReverted?: boolean,
    stringUniqueId?: string,
  ) => void,
  handleFocus: () => void,
  handleBlur: () => void,
  item: {
    cellId: string;
    uniqueColumnName: string;
    uniqueId: string;
    oppId: string;
    probId: number;
    currName: string;
    originalValState: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
    isLastColumn: boolean;
    minVal: number;
    isFocused: boolean;
  },
  tooltipContent: TData;
}

const NumericEditor: FC<Props> = ({
  editorProps,
  getIsDisabled,
  changeCellVal,
  handleBlur,
  handleFocus,
  item,
  tooltipContent,
}) => {
  const { showModal } = useOppDetailsModal();
  const ref = useRef<HTMLDivElement>(null);
  const form = useRecoilValue(gridFormAtom);
  const [pasted, setPasted] = useState(false);
  const [
    changedCellsPrevValues,
    setChangedCellsPrevValues,
  ] = useState<Array<{ id: string, value: number | null }>>([]);
  const [isBlockedShowModal, setIsBlockedShowModal] = useState(false);

  const {
    cellId,
    uniqueColumnName,
    uniqueId,
    oppId,
    probId,
    currName,
    originalValState,
    isLastColumn,
    minVal,
    isFocused,
  } = item;

  const userLang = getNavigatorLanguage();

  // Validate completeness of the opportunity based on the probablity
  useEffect(() => {
    const modalCheck = isFocused
    && editorProps.value !== originalValState[0]
    && !isBlockedShowModal
    && setTimeout(async () => {
      setIsBlockedShowModal(true);
      await showModal(
        oppId,
        probId,
        {
          originalValState,
          editorProps,
          reseter: changeCellVal,
          changedCellsPrevValues,
          setChangedCellsPrevValues,
        },
      );
      setIsBlockedShowModal(false);
    }, 300);

    if (modalCheck) return () => clearTimeout(modalCheck);
  }, [editorProps.value, JSON.stringify(changedCellsPrevValues)]);

  useEffect(() => {
    ref.current?.parentElement?.parentElement.setAttribute(
      'cell-for',
      uniqueColumnName,
    );
  }, []);

  const FAULT_NUMBER = 999999999;
  // Copy-Paste from Excel
  useEffect(() => {
    const inputElement = ref.current?.children[0].children[0].children[0];
    const onPaste = (e: any) => {
      const clipboardData = e.clipboardData.getData('Text').split('\t');
      const resultArray: Array<number> = [];
      for (let i = 0; i < clipboardData.length; i++) {
        const isNegativeNumber = clipboardData[i].startsWith('-');
        if (clipboardData[i].includes('\r\n')) {
          const number = convertNumber(clipboardData[i].split('\r\n')[0], userLang);
          resultArray.push(isNegativeNumber ? -number : number);
          break;
        }
        if (!clipboardData[i] || (convertNumber(clipboardData[i], userLang) === 0 && clipboardData[i] !== '0')) {
          resultArray.push(FAULT_NUMBER);
        } else {
          const number = convertNumber(clipboardData[i], userLang);
          resultArray.push(isNegativeNumber ? -number : number);
        }
      }

      const setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      const event = new Event('input', { bubbles: true });

      const parsedUniqueId = uniqueId.split('_');
      let monthIndex = +parsedUniqueId[0].substring(1);
      let year = +parsedUniqueId[2];
      resultArray.forEach((value, index) => {
        if (index > 0) {
          setChangedCellsPrevValues((prev) => {
            const id = `m${monthIndex}_p_${year}`;
            return [...prev, { id, value: form.items[oppId].breakdown[id] }];
          });
        }

        const element = document.getElementById(`${oppId}___m${monthIndex}_p_${year}`);
        element && setValue && setValue.call(element, value);
        element && element.dispatchEvent(event);
        if ((monthIndex + 1) === 13) {
          monthIndex = 1;
          year += 1;
        } else {
          monthIndex += 1;
        }
      });
      setPasted(true);
    };

    inputElement && !getIsDisabled() && cellId !== 'gttl' && inputElement.addEventListener('paste', onPaste);

    return () => {
      inputElement && !getIsDisabled() && cellId !== 'gttl' && inputElement.removeEventListener('paste', onPaste);
    };
  }, []);

  return (
    <div className={css.numericEditorWrap} ref={ref}>
      <Tooltip content={tooltipContent}>
        <div className={isFocused ? css.inputWithCurrency : ''}>
          <NumericInput
            {...editorProps}
            id={`${oppId}___${uniqueId}`}
            placeholder=" "
            min={minVal}
            isDisabled={getIsDisabled()}
            onValueChange={(newVal) => {
              if (!pasted) {
                changeCellVal(newVal === FAULT_NUMBER ? null : newVal, editorProps);
              }
              setPasted(false);
            }}
            formatOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
            inputCx={cellId === 'gttl' && css.gttlCellInput}
            rawProps={{
              style: {
                paddingRight: isLastColumn ? '18px' : '5px',
              },
            }}
            onFocus={handleFocus}
            onBlur={() => {
              handleBlur();
            }}

          />
          {isFocused ? <div>{currName}</div> : null}
        </div>
      </Tooltip>
    </div>
  );
};

export default NumericEditor;
