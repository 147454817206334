import {
  Button,
  Dropdown, DropdownContainer, FlexRow, FlexSpacer, Panel,
} from '@epam/promo';
import {
  DataRowProps, DropdownBodyProps, IDropdownToggler,
} from '@epam/uui-core';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { ReactComponent as constructorIcon } from '@epam/assets/icons/common/navigation-structure_alt-18.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterPickerBody } from './filters/FilterPickerBody';
import advancedFiltersConstructorAtom from '../../../../../state/atoms/advancedFiltersConstructorAtom';
import css from './filterbar.module.scss';
import useSlideout from '../../../../../hooks/useSlideout';
import basicModeAvailabilitySelector from '../../../../../state/selectors/advancedFiltersBasicModeAvabilitySelector';
import { useAdvancedFiltersDropdownHeight } from '../../../../../hooks/useAdvancedFiltersDropdownHeight';

interface Props {
  value: string[],
  onValueChange: (val: string[]) => void,
  dataSource: any,
  renderRow?: (props: DataRowProps<any, string>) => React.ReactNode,
  renderToggler?: (
    props: any
  ) => React.ReactNode,
  renderFooter?: (props: any) => React.ReactNode,
  isDisabled?: boolean,
  isWithConstructor?: boolean,
}

const FilterbarPicker: FC<Props> = ({
  value,
  onValueChange,
  dataSource,
  renderRow,
  renderToggler,
  renderFooter,
  isWithConstructor,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [,setAdvancedFiltersConstructor] = useRecoilState(
    advancedFiltersConstructorAtom,
  );
  const { isBasicModeDisabled } = useRecoilValue(basicModeAvailabilitySelector);
  const { targetRef } = useAdvancedFiltersDropdownHeight(isOpen);

  const { openSlideout } = useSlideout();

  const handleSwitchToConstructor = (): void => {
    setIsOpen(false);
    setAdvancedFiltersConstructor((prev) => ({
      ...prev,
      isConstructor: true,
      cache: prev.constructor,
    }));
    openSlideout('advFiltersConstructor');
  };

  useEffect(() => {
    if (isBasicModeDisabled) {
      handleSwitchToConstructor();
    }
  }, [isBasicModeDisabled]);

  const renderTarget = (togglerProps: IDropdownToggler) => (
    <Button
      {...togglerProps}
      size="30"
      caption="Add filter"
      fill="light"
      color="blue"
      isDisabled={isDisabled}
    />
  );

  const getRowOptions = useCallback((item) => ({
    isDisabled: item.isAlwaysVisible,
    checkbox: {
      isVisible: true,
      isDisabled: item.isAlwaysVisible,
    },
  }), []);

  const renderBody = (dropdownProps: DropdownBodyProps) => (
    <DropdownContainer ref={targetRef} width={360}>
      <Panel
        background="white"
        cx={(!value || !value.length) && css.filterbarPickerPanel}
      >
        {isWithConstructor && (
          <FlexRow padding="12">
            <FlexSpacer />
            <Button
              color="blue"
              caption="Switch to constructor"
              fill="white"
              size="24"
              icon={constructorIcon}
              iconPosition="left"
              onClick={handleSwitchToConstructor}
            />
          </FlexRow>
        )}
        <FilterPickerBody
          {...dropdownProps}
          selectionMode="multi"
          valueType="id"
          key="newFilterId"
          value={value}
          onValueChange={onValueChange}
          dataSource={dataSource}
          renderRow={renderRow}
          renderFooter={renderFooter}
          renderToggler={renderToggler}
          emptyValue={[]}
          getRowOptions={getRowOptions}
        />
      </Panel>
    </DropdownContainer>
  );

  return (
    <Dropdown
      renderBody={renderBody}
      renderTarget={renderToggler ?? renderTarget}
      value={isOpen}
      onValueChange={setIsOpen}
      placement="bottom-start"
    />
  );
};

export default React.memo(FilterbarPicker);
