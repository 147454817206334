import React, { FC } from 'react';

const AddMonthLeftIcon: FC = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.06641 6.06643L7.06641 4.06643L10.0664 4.06643L10.0664 1.06643L12.0664 1.06643L12.0664 4.06643L15.0664 4.06643L15.0664 6.06643L12.0664 6.06643L12.0664 9.06643L10.0664 9.06643L10.0664 6.06643L7.06641 6.06643Z" fill="#6C6F80" />
    <path d="M5.49928 0.500732L0.933594 5.06642L5.49928 9.6321L6.63065 8.50073L3.19634 5.06642L6.63065 1.6321L5.49928 0.500732Z" fill="#6C6F80" />
  </svg>
);

export default AddMonthLeftIcon;
