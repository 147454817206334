import { Text } from '@epam/promo';
import React, { FC, useEffect, useRef } from 'react';
import useUserProfile from '../../../../hooks/useUserProfile';
import css from '../VirtualList.module.scss';

interface Props {
  date: string | null | undefined | Array<string | null | undefined>,
  cellId: string,
}

const GridDataCell: FC<Props> = ({ date, cellId }) => {
  const ref = useRef<HTMLElement>(null);
  const { formatDate } = useUserProfile();

  useEffect(() => {
    ref.current?.parentElement?.setAttribute(
      'cell-for',
      cellId,
    );
  }, []);
  
  if (cellId === 'closeDate' && Array.isArray(date)) {
    const [estimated, actual] = date;

    return (
      <Text
        fontSize="14"
        cx={css.textDate}
        ref={ref}
      >
        {
          actual 
            ? formatDate(actual, { isShort: true })
            : estimated && formatDate(estimated, { isShort: true }) 
        }
      </Text>
    );
  }
  return (
    <Text
      fontSize="14"
      cx={css.textDate}
      ref={ref}
    >
      {
        (typeof date === 'string' && formatDate(date, { isShort: true })) || null
      }
    </Text>
  );
};

export default GridDataCell;
