import { TProbIds } from '../modals/EditProbabilityModal/const';

export const probabilityDictionary: {
  [key: number]: {
    id: TProbIds,
    name: string,
  },
} = {
  0: {
    id: 895450000,
    name: '0%',
  },
  10: {
    id: 895450010,
    name: '10%',
  },
  20: {
    id: 895450020,
    name: '20%',
  },
  40: {
    id: 895450040,
    name: '40%',
  },
  60: {
    id: 895450060,
    name: '60%',
  },
  80: {
    id: 895450080,
    name: '80%',
  },
  90: {
    id: 895450090,
    name: '90%',
  },
  100: {
    id: 895450100,
    name: '100%',
  },
};
