import React, { FC } from 'react';

const AddMonthRightIcon: FC = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.93359 4.06641L8.93359 6.06641L5.93359 6.06641L5.93359 9.06641L3.93359 9.06641L3.93359 6.06641L0.933594 6.06641L0.933594 4.06641L3.93359 4.06641L3.93359 1.06641L5.93359 1.06641L5.93359 4.06641L8.93359 4.06641Z" fill="#6C6F80" />
    <path d="M10.5007 9.63211L15.0664 5.06642L10.5007 0.50074L9.36935 1.63211L12.8037 5.06642L9.36935 8.50074L10.5007 9.63211Z" fill="#6C6F80" />
  </svg>
);

export default AddMonthRightIcon;
