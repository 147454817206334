import React, { FC } from 'react';

interface Props {
  cx: string
}

const tagIconFilled: FC<Props> = ({ cx }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx}>
    <path d="M4.125 5.25C3.82663 5.25 3.54048 5.13147 3.3295 4.9205C3.11853 4.70952 3 4.42337 3 4.125C3 3.82663 3.11853 3.54048 3.3295 3.3295C3.54048 3.11853 3.82663 3 4.125 3C4.42337 3 4.70952 3.11853 4.9205 3.3295C5.13147 3.54048 5.25 3.82663 5.25 4.125C5.25 4.42337 5.13147 4.70952 4.9205 4.9205C4.70952 5.13147 4.42337 5.25 4.125 5.25ZM16.0575 8.685L9.3075 1.935C9.0375 1.665 8.6625 1.5 8.25 1.5H3C2.1675 1.5 1.5 2.1675 1.5 3V8.25C1.5 8.6625 1.665 9.0375 1.9425 9.3075L8.685 16.0575C8.9625 16.3275 9.3375 16.5 9.75 16.5C10.1625 16.5 10.5375 16.3275 10.8075 16.0575L16.0575 10.8075C16.335 10.5375 16.5 10.1625 16.5 9.75C16.5 9.33 16.3275 8.955 16.0575 8.685Z" fill="#6C6F80" />
  </svg>

);

export default tagIconFilled;
