import { DropParams, getOrderBetween } from '@epam/uui-core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IColumns } from '../api/views';
import { 
  applyGroupingRulesToColumn,
  IColumnCheckbox, 
  listOfCheckboxes, 
  setColumnsFromPresetColsList, 
  sortCheckboxesByOrder, 
} from '../const/COLUMNS';
import columnsSettingsAtom from '../state/atoms/columnsSettingsAtom';
import queryParamsAtom from '../state/atoms/queryParamsAtom';
import { viewStateAtom } from '../state/atoms/viewStateAtom';

const useColumnsSettings = () => {
  const setColumnsSettings = useSetRecoilState(columnsSettingsAtom);
  const { groupingStore } = useRecoilValue(queryParamsAtom);
  const groupingIds = groupingStore.multiGrouping.map((el) => el.id);
  const { columnsConfig } = useRecoilValue(viewStateAtom);

  const changeCol = (column: IColumnCheckbox, data: IColumnCheckbox, isDragging?: boolean) => {
    if (isDragging) {
      if (column.id === data.id) {
        return {
          ...data, 
          show: true,
        };
      }
      return {
        ...column,
        show: columnsConfig?.[column.id]?.isVisible || false,
      };
    }
    if (column.id === data.id) return data;
    return column;
  };

  const setColumnsFromIdList = (
    columns: IColumns,
  ) => setColumnsSettings(() => {
    const {
      accAttr, 
      oppAttr, 
      col, 
    } = setColumnsFromPresetColsList(listOfCheckboxes, columns);
    const res = {
      accAttr: accAttr
        .map((el) => applyGroupingRulesToColumn(el, groupingIds)),
      oppAttr: oppAttr
        .map((el) => applyGroupingRulesToColumn(el, groupingIds)),
      col: col
        .map((el) => applyGroupingRulesToColumn(el, groupingIds)),
    };
    return res;
  });

  const setColumn = (type: string, newItem: IColumnCheckbox, isDragging?: boolean) => {
    if (isDragging) {
      setColumnsSettings((prev) => ({
        ...prev,
        col: prev.col.map((column) => (
          { ...column, show: !!columnsConfig?.[column.id]?.isVisible }
        )),
        accAttr: prev.accAttr.map((column) => (
          { ...column, show: !!columnsConfig?.[column.id]?.isVisible }
        )),
        oppAttr: prev.oppAttr.map((column) => (
          { ...column, show: !!columnsConfig?.[column.id]?.isVisible }
        )),
        [type]: prev[type]
          .map((column) => changeCol(column, newItem, isDragging))
          .sort(sortCheckboxesByOrder),
      }));
    } else {
      setColumnsSettings((prev) => ({
        ...prev,
        [type]: prev[type]
          .map((column) => changeCol(column, newItem, isDragging))
          .sort(sortCheckboxesByOrder),
      }));
    }
  };

  const dragColumn = (
    column: DropParams<IColumnCheckbox, IColumnCheckbox>,
    nextCol?: IColumnCheckbox,
    prevCol?: IColumnCheckbox,
    isDragging?: boolean,
  ) => {
    const { dstData, srcData, position } = column;
    const isDstOrder = dstData ? dstData.order : null;

    setColumn(
      column.srcData.type, 
      {
        ...srcData,
        order: position === 'bottom' || position === 'right'
          ? getOrderBetween(isDstOrder, nextCol && nextCol?.order ? nextCol?.order : null)
          : getOrderBetween(prevCol && prevCol?.order ? prevCol?.order : null, isDstOrder),
      },
      isDragging,
    );
  };
  
  return {
    setColumn,
    dragColumn,
    setColumnsFromIdList,
  };
};

export default useColumnsSettings;
