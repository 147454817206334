import { atom } from 'recoil';

export interface IEditedCells {
  [key: string]: string[],
}

export const addToEdited = (prev: IEditedCells, id: string, editedCell: string) => {
  if (prev[id]) {
    return {
      ...prev,
      [id]: !prev[id].includes(editedCell) ? [...prev[id], editedCell] : prev[id],
    };
  }
  return {
    ...prev,
    [id]: [editedCell],
  };
};

const editedCellsAtom = atom<IEditedCells>({
  key: 'editedCellsAtom',
  default: {},
});

export default editedCellsAtom;
