/* eslint-disable max-len */
import React from 'react';

interface IColumnsNameTooltips {
  [key: string]: string | JSX.Element
}

export const getColumnsNameTooltip = (caption: string): string | JSX.Element => {
  if (caption.includes('GTTL-')) return 'Grand total = Year to Date + Forecast';
  if (caption.includes('YTD-')) return 'Year to date = sum of revenues over all closed months';
  if (caption.includes('FRCST-')) return 'Forecast = sum of revenues over all open months';
  
  const columnsNameTooltips: IColumnsNameTooltips = {
    Account: 'Name of the Account',
    Probability: 'Probability',
    GBU: 'Account Geographical Business Unit',
    'GBU Portfolio': 'Account Geographical Business Unit on the portfolio level',
    'GBU Regional': 'Account Geographical Business Unit on the regional level',
    'Acc Acquisition Name': 'Acquired company name (account level)',
    'Account AM': 'Account Account Manager',
    'Account SM': 'Account Sales Manager',
    'Account Status': 'State of the Account',
    'Annual Revenue, 1K USD': 'Annual revenue for the account, used as an indicator in financial performance analysis',
    'Digital SM': 'Account Digital Sales Manager',
    'EPAM Share, 1K USD': "EPAM's estimated potential attainment of the wallet share (20% of EPAM Wallet)",
    'EPAM Wallet, 1K USD': "Amount of the customer's IT budget that EPAM's expertise can cover",
    'Global Account': 'Name of the Global Account',
    'IT Services, 1K USD': 'Researched total annual budget for IT expences',
    'Reporting Country': 'Account External Reporting Country',
    'Reporting Industry': 'Account External Reporting Industry',
    'Sales Type': 'Sales Type of the Account',
    'Supporting GBU': 'Account supporting Geographical Business Unit',
    'Supporting GBU Portfolio': 'Account supporting Geographical Business Unit on the portfolio level',
    'Supporting GBU Regional': 'Account supporting Geographical Business Unit on the regional level',
    'Additional Competencies': 'Competencies assigned on the Opportunity as Additional Competencies',
    Commissionable: 'Flag, that shows Opportunity Commissionable or not',
    'Days w/o update': 'Number of days w/o Probability or Forecast updates',
    'Start Date': 'Estimated Start Date (if Final Start Date is empty)/Final Start Date',
    'FRCST/% Updated By': 'Opportunity or it Forecast and Probability Updater',
    'FRCST/% Updated On': 'Last time when the Opportunity or it Forecast and Probability were updated',
    Flag: 'Opportunity general situation',
    'GBD Type': 'GBD Type',
    'Industry Practice': 'Industry Practice of the Opportunity',
    'Linked Projects': 'Comma-separated list of the projects, linked to the Opportunity',
    'Lead/Opportunity Source': 'Source of the Lead of the contact',
    'Merged To': 'The opportunity current one was merged to',
    'Opp Acquisition Name': 'Acquired company name (opportunity level)',
    'Opportunity AM': 'Opportunity Account Manager',
    'Opportunity Created By': 'Opportunity Creator',
    'Opportunity Created On': 'Opportunity Creation Date',
    'Opportunity SM': 'Opportunity Sales Manager',
    'Opportunity Status': 'State of the Opportunity',
    'Partner Accounts': (
      <>
        <p>Opportunity Partner Accounts - hint to the column</p>
        <p>Comma-separated list of the Accounts, linked to the Opportunity - hint to the data in the column</p>
      </>
    ),
    'Opportunity Origin': 'Opportunity Origin',
    'Revenue Type': 'Revenue Type',
    'Sales Stage': 'Opportunity Sales Stage',
    'Sales Unit': "Opportunity Sales Manager's Sales Unit",
    'Secondary SM': 'Opportunity Secondary Sales Manager',
    'Solution Practice': 'Solution Practice of the Opportunity',
    Stream: 'Stream name',
    'Total Contract Value, 1K': 'Estimated revenue from the Contract',
    'Target Close Date': 'Estimated Contract signing date',
    'Target Close Quarter': 'Estimated Contract signing quarter',
    'Warning Type': (
      <>
        <p>Tooltip for icons in applicable row: (Outdated, n days w/o update)  or (Low probability)</p>
        <p>In case both warnings are applicable tooltip must be</p>
        <p>(Outdated, n days w/o update)</p>
      </>
    ),
    'Advisory AM': 'Advisory Account Manager',
    'Engagement Type': 'Engagement Type',
    Practices: 'Practices',
    'Lead Practice': 'Lead Practice',
    Industry: 'Industry',
    Platforms: 'Platforms',
    'Continuum Offerings': 'Continuum Offerings',
    Description: 'Opportunity Description',
    'Supporting AM': 'Supporting Account Manager',
    Currency: 'Currency',
    'Deal Type': 'Deal Type',
    'Commercial Model': 'Commercial Model',
    'Partner Sales Managers': 'Partner Sales Managers',
    'Engagement Leads': 'Engagement Leads',
    Notes: 'Opportunity Notes',
    'Operational Labels': 'Operational Labels',
    Warning: 'Opportunity Warnings',
  };

  return columnsNameTooltips[caption];
};
