import { Spinner, VirtualList } from '@epam/promo';
import { useLazyDataSource } from '@epam/uui-core';
import React, { FC, useEffect, useState } from 'react';
import { VirtualListRenderRowsParams } from '@epam/uui';
import { fetchNotes, INote } from '../../../../api/notes';
import NotesItem from './NotesItem';
import css from '../NotesModal.module.scss';
import { IForm } from './NotesForm';
import EmptyNotesList from './EmptyNotesList';

interface Props {
  oppId: string,
  isChanged: number,
  setIsChanged: React.Dispatch<React.SetStateAction<number>>,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  form: IForm,
  setForm: React.Dispatch<React.SetStateAction<IForm>>,
  sorting: number,
  isAdmin: boolean,
  userId: string,
  setIsNotesFilled: (value: boolean) => void,
}

const NotesList: FC<Props> = ({
  oppId,
  isChanged,
  setIsChanged,
  isLoading,
  setIsLoading,
  form,
  setForm,
  sorting,
  isAdmin,
  userId,
  setIsNotesFilled,
}) => {
  const [state, setState] = useState({});
  const dataSource = useLazyDataSource<INote, string, string>({
    api() {
      if (!isLoading) {
        setIsLoading(true);
      }
      return fetchNotes(oppId, !!sorting)
        .finally(() => setIsLoading(false));
    },
    getId: (i) => {
      if (i.id) return i.id;
      return 'undefined';
    },
  }, [isChanged, sorting]);

  const view = dataSource.useView(
    state,
    setState,
  );
  
  const visibleRows = view.getVisibleRows().map((row, i) => (
    <NotesItem
      {...row.value}
      key={row.value?.id || i}
      currentUser={userId}
      handleChange={() => setIsChanged((prev) => prev + 1)}
      form={form}
      setForm={setForm}
      isAdmin={isAdmin}
      setIsLoading={setIsLoading}
    />
  ));

  useEffect(() => {
    if (!isLoading && visibleRows.length <= 1) {
      setIsNotesFilled(visibleRows.length !== 0);
    }
  }, [visibleRows.length, isLoading]);

  const { exactRowsCount } = view.getListProps();

  const renderRowsContainer = (
    {
      listContainerRef,
      offsetY,
    }: VirtualListRenderRowsParams,
  ) => (
    <div>
      { exactRowsCount && exactRowsCount !== 0 && (
        <div>
          <div
            ref={listContainerRef}
            role="rowgroup"
            style={{ marginTop: offsetY }}
            children={visibleRows}
            className={css.notesList}
          />
        </div>
      )}
    </div>
  );

  if (!visibleRows.length && !isLoading) {
    return (
      <EmptyNotesList />
    );
  }

  return (
    !isLoading ? (
      <VirtualList
        rowsCount={exactRowsCount}
        rows={visibleRows}
        renderRows={renderRowsContainer}
        value={state}
        onValueChange={setState}
      />
    ) : <Spinner />
  );
};

export default NotesList;
