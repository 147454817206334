import { atom, selector } from 'recoil';
import { viewPresetsAtom } from '../atoms/viewPresetsAtom';

const defaultViewPresetSelector = atom({
  key: 'defaultViewSelector',
  default: selector({
    key: 'defaultViewPresetSelector',
    get: ({ get }) => {
      const presets = get(viewPresetsAtom);

      return presets.filter((element) => element.isDefault)[0];
    },

  }),
});

export default defaultViewPresetSelector;
