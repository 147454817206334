import { ColumnsConfig, DataColumnProps, IColumnConfig } from '@epam/uui-core';
import { IQueryResponseValues, TQueryValuesKey } from '../api/query';
import { IColumn, IColumns } from '../api/views';
import { ConfigArrayItem } from '../components/slide-outs/SettingsSlideOut/components/ColumnsSettings/ColumnsSettings';

export const formatPluralForm = (item: string) => {
  if (item[item.length - 1] === 'y') return `${item.slice(0, -1)}ies`;
  if (item[item.length - 1] === 's') return item;
  return `${item}s`;
};

interface IMappedColumns {
  [key: string]: IColumnCheckbox[],
}

export interface IColumnCheckbox {
  id: TQueryValuesKey,
  label: string,
  show: boolean,
  draggable: boolean,
  order: string,
  type: 'accAttr' | 'oppAttr' | 'col',
  disabled?: boolean,
  visible: boolean,
}

export interface IColumnsSettings extends IMappedColumns {
  col: IColumnCheckbox[],
  accAttr: IColumnCheckbox[],
  oppAttr: IColumnCheckbox[],
}

export const checkboxesDictionary: Record<string, string> = {
  oppName: 'Opportunity',
  accName: 'Account',
  prob: '%',
  GBU: 'GBU',
  GBUPortfolio: 'GBU Portfolio',
  RegionalGBU: 'GBU Regional',
  gttl: 'GTTL',
  ytd: 'YTD',
  frcst: 'FRCST',
  accAcquisitionName: 'Acc Acquisition Name',
  accAM: 'Account AM',
  accSM: 'Account SM',
  accState: 'Account Status',
  AnnualRevenue: 'Annual Revenue, 1K USD',
  accDigitalSM: 'Digital SM',
  EPAMShare1KUSD: 'EPAM Share, 1K USD',
  EPAMWallet1KUSD: 'EPAM Wallet, 1K USD',
  globalAccName: 'Global Account',
  ITServices1KUSD: 'IT Services, 1K USD',
  accExtRepCountry: 'Reporting Country',
  accExtRepInd: 'Reporting Industry',
  oppSalesType: 'Sales Type',
  accSuppAM: 'Supporting AM',
  SuppGBU: 'Supporting GBU',
  SuppGBUPortfolio: 'Supporting GBU Portfolio',
  SuppRegionalGBU: 'Supporting GBU Regional',
  OSC: 'Additional Competencies',
  commercialModel: 'Commercial Model',
  commisionable: 'Commissionable',
  oppAging: 'Days w/o update',
  oppDealType: 'Deal Type',
  oppEngagementLeads: 'Engagement Leads',
  closeDate: 'Start Date',
  modBy: 'FRCST/% Updated By',
  modDate: 'FRCST/% Updated On',
  oppLeadSourceCode: 'Lead/Opportunity Source',
  projstr: 'Linked Projects',
  MergedToOppName: 'Merged To',
  oppAcquisitionName: 'Opp Acquisition Name',
  oppAM: 'Opportunity AM',
  oppCreatedBy: 'Opportunity Created By',
  oppCreatedOn: 'Opportunity Created On',
  oppOrigin: 'Opportunity Origin',
  oppSM: 'Opportunity SM',
  oppState: 'Opportunity Status',
  oppPartnerAccounts: 'Partner Accounts',
  oppPartnerSalesManagers: 'Partner Sales Managers',
  RevenueTypeName: 'Revenue Type',
  SS: 'Sales Stage',
  SU: 'Sales Unit',
  oppSecondarySM: 'Secondary SM',
  oppStream: 'Stream',
  oppTargetCloseDate: 'Target Close Date',
  oppTargetCloseQuarter: 'Target Close Quarter',
  oppTotalContractValue: 'Total Contract Value, 1K',
  oppAdvisoryAM: 'Advisory AM',
  oppEngagementType: 'Engagement Type',
  oppPractices: 'Practices',
  oppLeadPractice: 'Lead Practice',
  oppIndustry: 'Industry',
  oppPlatforms: 'Platforms',
  oppContinuumOfferings: 'Continuum Offerings',
  oppDescription: 'Description',
  oppTags: 'Operational labels',
  oppTagFlag: 'Flag',
  oppCorrections: 'Warning',
  hasNotes: 'Notes',
};

export const listOfCheckboxes: IColumnsSettings = {
  col: [
    {
      label: 'Account',
      id: 'accName',
      show: false,
      draggable: true,
      order: 'a',
      visible: true,
      type: 'col',
    },
    {
      label: '%',
      id: 'prob',
      show: false,
      draggable: true,
      order: 'b',
      visible: true,
      type: 'col',
    },
    {
      label: 'GBU',
      id: 'GBU',
      show: false,
      draggable: true,
      order: 'c',
      visible: true,
      type: 'col',
    },
    {
      label: 'GBU Portfolio',
      id: 'GBUPortfolio',
      show: false,
      draggable: true,
      order: 'd',
      visible: true,
      type: 'col',
    },
    {
      label: 'GBU Regional',
      id: 'RegionalGBU',
      show: false,
      draggable: true,
      order: 'e',
      visible: true,
      type: 'col',
    },
    {
      label: 'GTTL',
      id: 'gttl',
      show: false,
      draggable: true,
      order: 'f',
      visible: true,
      type: 'col',
    },
    {
      label: 'YTD',
      id: 'ytd',
      show: false,
      draggable: true,
      order: 'g',
      visible: true,
      type: 'col',
    },
    {
      label: 'FRCST',
      id: 'frcst',
      show: false,
      draggable: true,
      order: 'h',
      visible: true,
      type: 'col',
    },
  ],
  accAttr: [
    {
      label: 'Acc Acquisition Name',
      id: 'accAcquisitionName',
      show: false,
      draggable: true,
      order: 'a',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Account AM',
      id: 'accAM',
      show: false,
      draggable: true,
      order: 'b',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Account SM',
      id: 'accSM',
      show: false,
      draggable: true,
      order: 'c',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Account Status',
      id: 'accState',
      show: false,
      draggable: true,
      order: 'd',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Annual Revenue, 1K USD',
      id: 'AnnualRevenue',
      show: false,
      draggable: true,
      order: 'e',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Digital SM',
      id: 'accDigitalSM',
      show: false,
      draggable: true,
      order: 'f',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'EPAM Share, 1K USD',
      id: 'EPAMShare1KUSD',
      show: false,
      draggable: true,
      order: 'g',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'EPAM Wallet, 1K USD',
      id: 'EPAMWallet1KUSD',
      show: false,
      draggable: true,
      order: 'h',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Global Account',
      id: 'globalAccName',
      show: false,
      draggable: true,
      order: 'i',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'IT Services, 1K USD',
      id: 'ITServices1KUSD',
      show: false,
      draggable: true,
      order: 'j',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Reporting Country',
      id: 'accExtRepCountry',
      show: false,
      draggable: true,
      order: 'k',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Reporting Industry',
      id: 'accExtRepInd',
      show: false,
      draggable: true,
      order: 'l',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Sales Type',
      id: 'oppSalesType',
      show: false,
      draggable: true,
      order: 'm',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Supporting AM',
      id: 'accSuppAM',
      show: false,
      draggable: true,
      order: 'n',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Supporting GBU',
      id: 'SuppGBU',
      show: false,
      draggable: true,
      order: 'o',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Supporting GBU Portfolio',
      id: 'SuppGBUPortfolio',
      show: false,
      draggable: true,
      order: 'p',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
    {
      label: 'Supporting GBU Regional',
      id: 'SuppRegionalGBU',
      show: false,
      draggable: true,
      order: 'q',
      visible: true,
      disabled: false,
      type: 'accAttr',
    },
  ],
  oppAttr: [
    {
      label: 'Additional Competencies',
      id: 'OSC',
      show: false,
      draggable: true,
      order: 'a',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Commercial Model',
      id: 'commercialModel',
      show: false,
      draggable: true,
      order: 'aa',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Commissionable',
      id: 'commisionable',
      show: false,
      draggable: true,
      order: 'b',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Days w/o update',
      id: 'oppAging',
      show: false,
      draggable: true,
      order: 'c',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Deal Type',
      id: 'oppDealType',
      show: false,
      draggable: true,
      order: 'ca',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Engagement Leads',
      id: 'oppEngagementLeads',
      show: false,
      draggable: true,
      order: 'cab',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Start Date',
      id: 'closeDate',
      show: false,
      draggable: true,
      order: 'd',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'FRCST/% Updated By',
      id: 'modBy',
      show: false,
      draggable: true,
      order: 'e',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'FRCST/% Updated On',
      id: 'modDate',
      show: false,
      draggable: true,
      order: 'f',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Lead/Opportunity Source',
      id: 'oppLeadSourceCode',
      show: false,
      draggable: true,
      order: 'i',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Linked Projects',
      id: 'projstr',
      show: false,
      draggable: true,
      order: 'j',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Merged To',
      id: 'MergedToOppName',
      show: false,
      draggable: true,
      order: 'k',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opp Acquisition Name',
      id: 'oppAcquisitionName',
      show: false,
      draggable: true,
      order: 'l',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opportunity AM',
      id: 'oppAM',
      show: false,
      draggable: true,
      order: 'm',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opportunity Created By',
      id: 'oppCreatedBy',
      show: false,
      draggable: true,
      order: 'n',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opportunity Created On',
      id: 'oppCreatedOn',
      show: false,
      draggable: true,
      order: 'o',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opportunity Origin',
      id: 'oppOrigin',
      show: false,
      draggable: true,
      order: 'p',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opportunity SM',
      id: 'oppSM',
      show: false,
      draggable: true,
      order: 'q',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Opportunity Status',
      id: 'oppState',
      show: false,
      draggable: true,
      order: 'r',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Partner Accounts',
      id: 'oppPartnerAccounts',
      show: false,
      draggable: true,
      order: 's',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Partner Sales Managers',
      id: 'oppPartnerSalesManagers',
      show: false,
      draggable: true,
      order: 'sa',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Revenue Type',
      id: 'RevenueTypeName',
      show: false,
      draggable: true,
      order: 't',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Sales Stage',
      id: 'SS',
      show: false,
      draggable: true,
      order: 'u',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Sales Unit',
      id: 'SU',
      show: false,
      draggable: true,
      order: 'v',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Secondary SM',
      id: 'oppSecondarySM',
      show: false,
      draggable: true,
      order: 'w',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Stream',
      id: 'oppStream',
      show: false,
      draggable: true,
      order: 'y',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Target Close Date',
      id: 'oppTargetCloseDate',
      show: false,
      draggable: true,
      order: 'z',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Target Close Quarter',
      id: 'oppTargetCloseQuarter',
      show: false,
      draggable: true,
      order: 'za',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Total Contract Value, 1K',
      id: 'oppTotalContractValue',
      show: false,
      draggable: true,
      order: 'zb',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Advisory AM',
      id: 'oppAdvisoryAM',
      show: false,
      draggable: true,
      order: 'zd',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Engagement Type',
      id: 'oppEngagementType',
      show: false,
      draggable: true,
      order: 'ze',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Practices',
      id: 'oppPractices',
      show: false,
      draggable: true,
      order: 'zf',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Lead Practice',
      id: 'oppLeadPractice',
      show: false,
      draggable: true,
      order: 'zg',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Industry',
      id: 'oppIndustry',
      show: false,
      draggable: true,
      order: 'zh',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Platforms',
      id: 'oppPlatforms',
      show: false,
      draggable: true,
      order: 'zi',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Continuum Offerings',
      id: 'oppContinuumOfferings',
      show: false,
      draggable: true,
      order: 'zj',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Description',
      id: 'oppDescription',
      show: false,
      draggable: true,
      order: 'zk',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Operational Labels',
      id: 'oppTags',
      show: false,
      draggable: true,
      order: 'zl',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Flag',
      id: 'oppTagFlag',
      show: false,
      draggable: true,
      order: 'zm',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Warning',
      id: 'oppCorrections',
      show: false,
      draggable: true,
      order: 'zn',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
    {
      label: 'Notes',
      id: 'hasNotes',
      show: false,
      draggable: true,
      order: 'zo',
      visible: true,
      disabled: false,
      type: 'oppAttr',
    },
  ],
};

export const colsIds = [
  'accName',
  'prob',
  'GBU',
  'GBUPortfolio',
  'RegionalGBU',
  'gttl',
  'ytd',
  'frcst',
];

export const oppAttrIds = [
  'OSC',
  'commercialModel',
  'commisionable',
  'oppAging',
  'oppDealType',
  'oppEngagementLeads',
  'closeDate',
  'modBy',
  'modDate',
  'oppLeadSourceCode',
  'projstr',
  'MergedToOppName',
  'oppAcquisitionName',
  'oppAM',
  'oppCreatedBy',
  'oppCreatedOn',
  'oppOrigin',
  'oppSM',
  'oppState',
  'oppPartnerAccounts',
  'oppPartnerSalesManagers',
  'RevenueTypeName',
  'SS',
  'SU',
  'oppSecondarySM',
  'oppStream',
  'oppTargetCloseDate',
  'oppTargetCloseQuarter',
  'oppTotalContractValue',
  'oppAdvisoryAM',
  'oppEngagementType',
  'oppPractices',
  'oppLeadPractice',
  'oppIndustry',
  'oppPlatforms',
  'oppContinuumOfferings',
  'oppDescription',
  'oppTags',
  'oppTagFlag',
  'oppCorrections',
  'hasNotes',
];

export const accAttrIds = [
  'accAcquisitionName',
  'accAM',
  'accSM',
  'accState',
  'AnnualRevenue',
  'accDigitalSM',
  'EPAMShare1KUSD',
  'EPAMWallet1KUSD',
  'globalAccName',
  'ITServices1KUSD',
  'accExtRepCountry',
  'accExtRepInd',
  'oppSalesType',
  'accSuppAM',
  'SuppGBU',
  'SuppGBUPortfolio',
  'SuppRegionalGBU',
];

export const columnsIds = [
  ...listOfCheckboxes.col
    .filter((el) => el.id !== 'accName')
    .map((el) => el.id),
]; 
export const accAttrColumnIds = [...listOfCheckboxes.accAttr.map((el) => el.id), 'accName'];
export const oppAttrColumnIds = [...listOfCheckboxes.oppAttr.map((el) => el.id), 'prob'];

export const numericColumnsIds: TQueryValuesKey[] = ['gttl', 'ytd', 'frcst'];

export const generalOppAttr: TQueryValuesKey[] = ['GBU', 'RegionalGBU', 'GBUPortfolio', 'prob'];

export const generalColumns: TQueryValuesKey[] = ['gttl', 'ytd', 'frcst'];

export const searchColumns: TQueryValuesKey[] = ['oppName', 'accName', 'projstr'];

export const columnsWithNames: TQueryValuesKey[] = ['oppAM', 'accAM', 'oppSM', 'accSM', 'accSuppAM'];

export const getInitials = (
  name: string | string[] | undefined,
): string | (string[]
  ) | undefined => {
  if (name) {
    if (Array.isArray(name)) {
      return name.map((el) => {
        const elSplit = el.split(' ');
  
        return `${elSplit[0][0]}${elSplit[elSplit.length - 1][0]}`;
      });
    } 
    const split = name.split(' ');
  
    return `${split[0][0]}${split[split.length - 1][0]}`;
  } 
  return name;
};

export const getConfigArr = (config: ColumnsConfig) => {
  const keys = Object.keys(config);
  const values = Object.values(config);

  return keys.map((el, idx) => ({
    id: el,
    ...values[idx],
  })) as (IColumnConfig & { id: string })[];
};

export const applyGroupingRulesToColumn = (
  el: IColumnCheckbox,
  groupings: TQueryValuesKey[],
  reset?: boolean,
  saveCheckedCheckboxes?: boolean,
) => {
  const lastGrouping = groupings[groupings.length - 1];
  const isGroupingCol = groupings.slice(0, -1).includes(el.id);
  const isAcc = lastGrouping === 'accName';
  const isLastGroupGenAttr = generalOppAttr.includes(lastGrouping);
  const isAccAttr = accAttrColumnIds
    .slice(0, accAttrColumnIds.length - 1)
    .includes(lastGrouping);
  const isOppAttr = oppAttrColumnIds.includes(lastGrouping);
  const isElGenAttr = generalOppAttr.includes(el.id);

  // Disable all oppAttributes if last grouping is Account
  const accNameRule = isAcc && (el.type === 'oppAttr' || el.id === 'prob');
  // Disable all attributes if last grouping is opp/acc attribute
  const attributesRule = (
    (isOppAttr || isAccAttr) 
      && (
        el.type === 'oppAttr' 
        || el.type === 'accAttr' 
        || isElGenAttr 
        || el.id === 'accName'
      )
  );

  const checkedDisabled = {
    ...el,
    visible: false,
    show: true,
    disabled: true,
  };

  const uncheckedDisabled = {
    ...el,
    disabled: true,
    show: false,
  };

  const getDisabledCheckbox = lastGrouping === el.id || isGroupingCol 
    ? checkedDisabled 
    : uncheckedDisabled;

  if (
    !saveCheckedCheckboxes && reset 
      && (el.type === 'accAttr' || el.type === 'oppAttr') 
      && !groupings.includes(el.id)
  ) {
    return {
      ...el,
      show: false,
      disabled: false,
      visible: true,
    };
  }

  if (accNameRule || attributesRule){
    return getDisabledCheckbox;
  }

  // Check and disable only the corresponding column if it's part of the groupings
  if (lastGrouping === el.id || isGroupingCol) {
    return checkedDisabled;
  }

  if (isLastGroupGenAttr && !generalColumns.includes(el.id)) {
    return uncheckedDisabled;
  }

  return {
    ...el,
    visible: true,
    disabled: false,
  };
};

export const getColumnsWithRules = (
  columnsState: IColumnsSettings,
  grouping: TQueryValuesKey[],
  reset?: boolean,
  saveCheckedCheckboxes?: boolean,
) => {
  const accAttributes = columnsState.accAttr
    .map((el) => applyGroupingRulesToColumn(el, grouping, reset, saveCheckedCheckboxes));
  const oppAttributes = columnsState.oppAttr
    .map((el) => applyGroupingRulesToColumn(el, grouping, reset, saveCheckedCheckboxes))
    .filter((el) => el.label); 
  const columns = columnsState.col
    .map((el) => applyGroupingRulesToColumn(el, grouping, reset));
  return {
    col: columns,
    accAttr: accAttributes,
    oppAttr: oppAttributes,
  };
};

export const getActiveColumnsForPreset = (config: (IColumnConfig & { id: string })[]) => {
  const getSpecificFields = ({
    id, isVisible, order, fix,
  }: IColumnConfig & { id: string }) => ({
    id, show: !!isVisible, order: order || '', visible: true, fix,
  });
  return {
    col: config.filter((el) => colsIds.includes(el.id)).map(getSpecificFields),
    accAttr: config.filter((el) => accAttrIds.includes(el.id)).map(getSpecificFields),
    oppAttr: config.filter((el) => oppAttrIds.includes(el.id)).map(getSpecificFields),
  };
};

export const sortCheckboxesByOrder = (a: IColumnConfig | IColumn, b: IColumnConfig | IColumn) => {
  if ((a?.order || 'a') > (b?.order || 'b')) return 1;
  return -1;
};

export const setColumnsFromPresetColsList = (
  checkboxList: IColumnsSettings,
  presetCols: Omit<IColumns, 'tagsAndFlags' | 'warnings'>,
): IColumnsSettings => {
  const changeColumns = (
    colsFromPreset: Array<IColumn>,
    checkboxListCols: Array<IColumnCheckbox>,
  ) => colsFromPreset.map((col) => {
    const correspondingCheckbox = checkboxListCols.filter((checkbox) => col.id === checkbox.id)[0];
    return {
      ...correspondingCheckbox,
      show: col.show,
      order: col.order,
    };
  });

  return {
    col: changeColumns(presetCols.col, checkboxList.col),
    accAttr: changeColumns(presetCols.accAttr, checkboxList.accAttr),
    oppAttr: changeColumns(presetCols.oppAttr, checkboxList.oppAttr),
  };
};

export const sortColumnsByConfig = (columnsArray: DataColumnProps[], configArray: ConfigArrayItem[])
  : (DataColumnProps<any, any, any>)[] => {
  const sortedColumns = configArray.map((configItem) => {
    const column = columnsArray.find((col) => col.key === configItem.id);
    return column;
  }).filter((column): column is DataColumnProps<any, any, any> => !!column);

  return [columnsArray[0], ...sortedColumns];
};

export const getConfigArray = (
  config: ColumnsConfig, 
  grouping: (keyof IQueryResponseValues)[], 
  sort?: boolean,
) => {
  const keys = Object.keys(config || {});
  const values = Object.values(config || {});

  const configArr = keys.map((el, idx) => ({
    id: el,
    ...values[idx],
  })).map((el) => ({
    ...el,
    isVisible: grouping.includes(el.id) ? false : el.isVisible,
  })).filter(
    (el) => el.id !== 'grouping'
    && !el.id.startsWith('yyyyyy'),
  );
  return sort ? configArr.sort(sortCheckboxesByOrder) : configArr;
};

export const moveOrderToNextStep = (order: string) => {
  const orderArr = order.split('');
  const lastChar = orderArr.pop();
  if (lastChar === 'z') {
    orderArr.push('a');
    orderArr.push('a');
  } else {
    orderArr.push(String.fromCharCode(((lastChar || '').charCodeAt(0)) + 1));
  }
  return orderArr.join('');
};
