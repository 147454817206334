import React, {
  CSSProperties, useEffect, useMemo, useState, 
} from 'react';
import { Panel } from '@epam/promo';
import css from './SlidingPanel.module.scss';

interface ISlidingPanelProps {
  isVisible: boolean;
  position: 'left' | 'right';
  width: number;
  children: React.ReactNode,
  overflow: 'initial' | 'auto',
  style?: CSSProperties,
  withBlocker?: boolean,
}

interface CustomCssProperties extends CSSProperties {
  '--sliding-panel-margin': string;
}

const SlidingPanelImpl: React.FC<ISlidingPanelProps> = ({
  isVisible, position, width, children, overflow = 'initial', style: styleProps, withBlocker,
}) => {
  const [isFirstMount, setIsFirstMount] = useState(true);
    
  useEffect(() => {
    if (isVisible && isFirstMount) {
      setIsFirstMount(false);
    }
  }, [isVisible]);

  const style: CustomCssProperties = useMemo(() => ({
    width: `${width}px`,
    '--sliding-panel-margin': `${-width}px`,
    overflow,
    ...styleProps,
  }), [width, position, isVisible]);

  const isVisibleClass = isVisible ? 'show' : 'hide';
  const classNameAction = !isFirstMount && css[`container-${position}-${isVisibleClass}`];
  const classNamePosition = css[`container-${position}`];
  const classNames = [
    css.container,
    classNameAction,
    classNamePosition || false,
  ];

  return isVisible ? (
    <>
      <Panel
        cx={classNames}
        style={style}
      >
        { children }
      </Panel>
      {isVisible && withBlocker && <div className={css.blocker} />}
    </>
  ) : null;
};

export const SlidingPanel = React.memo(SlidingPanelImpl);
