import { selector } from 'recoil';
import { TQueryValuesKey } from '../../api/query';
import { IColumn } from '../../api/views';
import queryParamsAtom from '../atoms/queryParamsAtom';

const getIds = (cols: Array<IColumn>) => cols
  .filter(({ show, visible }) => show && visible)
  .sort((elA, elB) => elA.order.localeCompare(elB.order, undefined, { numeric: true, sensitivity: 'base' }))
  .map(({ id }) => id);

const currentColumnsIdsSelector = selector<Array<TQueryValuesKey>>({
  key: 'listOfCurrentColumns',
  get: ({ get }) => {
    const { columnsStore } = get(queryParamsAtom);

    return [
      ...getIds([...columnsStore.col, ...columnsStore.accAttr, ...columnsStore.oppAttr]),
    ];
  },
});

export default currentColumnsIdsSelector;
