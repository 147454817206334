import {
  Accordion, Button, FlexRow, IconContainer, Text, 
} from '@epam/promo';
import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as FileIcon } from '@epam/assets/icons/common/file-file-24.svg';
import { ReactComponent as UnfoldedIcon, ReactComponent as accorditionUnfoldedIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import { ReactComponent as FoldedIcon } from '@epam/assets/icons/common/navigation-chevron-up-18.svg';

import { ReactComponent as accorditionFoldedIcon } from '@epam/assets/icons/common/navigation-chevron-right-18.svg';
import { ReactComponent as checkIcon } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as crossIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
import css from '../ImportActualModal.module.scss';
import useUserProfile from '../../../../hooks/useUserProfile';
import FileCheckResults from '../components/FileCheckResults/FileCheckResults';
import HistoryTabSettingsGrid, { ISettingsLogItem } from '../components/HistoryTabSettingsGrid/HistoryTabSettingsGrid';
import getMonthShortName from '../../../../helpers/getMonthShortName';
import { IErrorItem } from '../components/ErrorsGrid/ErrorsGrid';
import { IUnlinkedItem } from '../components/UnlinkedAmountGrid/UnlinkedAmountGrid';
import { formatBytes } from '../../../../helpers/formatBytes';
import { getMappedStatus, getStatusColor } from './helpers';
import { importHistoryAPI } from '../../../../api/importHistory';

interface Props {
  rowValues: {
    id: string,
    importStatus: string | number,
    createdByUser: string,
    createdOn: string,
    period: string,
    name: string,
    fileSize: number,
    amountLinked: number,
    amountUnlinked: number,
    month: number,
    year: number,
    allRecords: number,
    actualRevenueImportErrors: IErrorItem[],
    actualRevenueImportSettingsHistory: ISettingsLogItem[],
    actualRevenueImportUnlinked: IUnlinkedItem[],
  }
}

const ImportHistoryItem = (props: Props) => {
  const {
    rowValues: {
      id,
      importStatus,
      createdByUser,
      createdOn,
      name,
      fileSize,
      amountLinked,
      amountUnlinked,
      month,
      year,
      allRecords,
      actualRevenueImportErrors: errors,
      actualRevenueImportSettingsHistory: settings,
      actualRevenueImportUnlinked: unlinked,
    }, 
  } = props;

  const { formatDate } = useUserProfile();

  const [isFileloading, setIsFileLoading] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const period = month && year ? `${getMonthShortName(month)}, ${year}` : ' unknown';

  const isToDelete = importStatus === 'DeleteScheduled' || importStatus === 'DeleteInProgress';
  const isDeleted = importStatus === 'DeleteSuccessful' || importStatus === 'DeleteFailed';

  const onFileDownload = async () => {
    setIsFileLoading(true);
    let file: Blob | null = null;
    await importHistoryAPI.getFileFromHistory(id)
      .then((data) => file = data)
      .catch(() => setIsFileLoading(false));

    if (file !== null && file !== undefined) {
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = name;
      link.click();
      link.remove();
      URL.revokeObjectURL(fileURL);
    }
    setIsFileLoading(false);
  };
  
  return (
    <Accordion 
      mode="block"
      dropdownIcon={null} 
      renderTitle={(isOpen) => (
        <FlexRow cx={css.accordionTitle}>
          <FlexRow spacing="6">
            <IconContainer icon={isOpen ? accorditionUnfoldedIcon : accorditionFoldedIcon} />
            <Text cx={css.accordionName}>{createdByUser ?? 'System user'}</Text>
            <Text>imported actuals</Text>
            <Text cx={css.accordionDate}>{formatDate(createdOn)}</Text>
            <Text>| Period </Text>
            <Text cx={css.accordionDate}>{period}</Text>
          </FlexRow>
          {Boolean(isToDelete || isDeleted) && (
            <Text cx={css.deletionStatus}>{getMappedStatus(importStatus)}</Text>
          )}
        </FlexRow>
      )}
      cx={css.accordionContainer}
      rawProps={{
        style: {
          background: '#FAFAFC',
        }, 
      }}
    >
      <div className={css.importDetails}>
        <FlexRow>
          <IconContainer 
            cx={css[`${getStatusColor(importStatus)}`]}
            icon={getStatusColor(importStatus) === 'successColor' ? checkIcon : crossIcon}
          />
          <Text cx={css[`${getStatusColor(importStatus)}`]}>
            {`Import ${getMappedStatus(importStatus)}`}
          </Text>
        </FlexRow>
        <div>
          <Button
            cx={css.fileButton}
            fill="light"
            caption={(
              <div className={css.fileContainer}>
                <IconContainer icon={FileIcon} cx={css.fileIcon} />
                <div className={css.fileDetails}>
                  <Text cx={css.fileName}>{name.split('.csv')[0]}</Text>
                  <Text cx={css.fileDescription}>{`CSV, ${formatBytes(fileSize)}`}</Text>
                </div>
              </div>
          )}
            onClick={onFileDownload}
            isDisabled={isFileloading}
          />
        </div>
        <div>
          <Button
            fill="light"
            caption="Show Settings Logs"
            icon={isSettingsOpen ? FoldedIcon : UnfoldedIcon}
            iconPosition="right"
            onClick={() => {
              setIsSettingsOpen((prev) => !prev);
            }}
            isDisabled={!settings.length}
            cx={cx(css.showSettingsBtn, { [css.activeShowSettingsBtn]: isSettingsOpen })}
          />
        </div>
      </div>
      {isSettingsOpen && (
        <div className={css.settingsLogContainer}>
          <HistoryTabSettingsGrid items={settings} />
        </div>
      )}
      <FileCheckResults 
        totalActual={amountLinked + amountUnlinked} 
        linkedAmount={amountLinked} 
        lines={allRecords}
        unlinkedAmount={amountUnlinked} 
        period={period}
        errors={errors}
        unlinked={unlinked}
      />
    </Accordion>
  );
};

export default ImportHistoryItem;
