import { atom, selector } from 'recoil';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';

export const intialSettings = {
  tagsFlagsNotes: true,
  changed: false,
};

const settingsSlideoutAtom = atom({
  key: 'settingsSlideout',
  default: selector({
    key: 'settingsSlideout/default',
    get: ({ get }) => {
      const { multiGrouping: { warnings, tagsFlagsNotes } } = get(defaultViewPresetSelector);
      return {
        ...intialSettings,
        tagsFlagsNotes,
        warnings,
      };
    },
  }),
});

export default settingsSlideoutAtom;
