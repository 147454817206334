/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FlexRow, IconButton, Text } from '@epam/promo';
import { useRecoilValue } from 'recoil';
import { ReactComponent as RefreshIcon } from '@epam/assets/icons/common/navigation-refresh-18.svg';
import multilevelIcon from '../../../assets/svg/multilevel.svg';
import css from './GridHeader.module.scss';
import MonthsToolbar from './components/MonthsToolbar';
import SearchInput from './components/SearchInput';
import queryParamsAtom from '../../../state/atoms/queryParamsAtom';
import { virtualListErrorsAtom } from '../../../state/atoms/virtualListErrorsAtom';
import useSlideout from '../../../hooks/useSlideout';
import viewModeAtom from '../../../state/atoms/viewModeAtom';

interface GridHeaderProps {
  refresh: () => void;
}

const GridHeader = (props: GridHeaderProps) => {
  const { groupingStore: { multiGrouping: currentGroupings } } = useRecoilValue(queryParamsAtom);
  const { toggleSlideout } = useSlideout();
  const error = useRecoilValue(virtualListErrorsAtom);
  const { refresh: refreshGrid } = props;
  const { mode } = useRecoilValue(viewModeAtom);
  const groupingsBtnCaption = currentGroupings
    .reduce((acc, level, idx) => (idx > 0 ? `${acc} → ${level.value}` : `${level.value}`), '');

  return (
    <div className={css.root}>
      <div
        className={css.multilevel}
        onClick={() => {
          toggleSlideout('columnsSettings');
        }}
      >
        <img src={multilevelIcon} alt="multilevel" />
        <div className={css.multilevelText}>
          <Text cx={css.textGray}>Group by:</Text>
          <Text>{groupingsBtnCaption}</Text>
        </div>
      </div>
      <div className={css.controls}>
        {
          (!error || error === 'emptyListError') 
          && (
          <FlexRow spacing="6" size="30">
            {mode === 'view' && <SearchInput />}
            <IconButton 
              icon={RefreshIcon}
              onClick={refreshGrid}
            />
          </FlexRow>
          )
        }
        {
          !error && <MonthsToolbar />
        }
      </div>
    </div>
  );
};

export default GridHeader;
