import React, { useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import api from '../api/api';
import { useApiNotification } from '../hooks/useApiNotification';

type ApiProviderProps = { children: React.ReactNode };

const ApiProvider = ({ children }: ApiProviderProps) => {
  const { showApiNotification } = useApiNotification();

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => response,
      (error: AxiosError): Promise<AxiosError> => {
        let message = '';

        if (!error.response) {
          message = 'Please check your Internet connection and refresh this page';
        } else if (error.response.status === 401) {
          message = 'User session expired. Please refresh the screen';
        }

        if (message.length) {
          showApiNotification({
            text: message,
            onAction: () => window.location.reload(),
          });
        }

        return Promise.reject(error);
      },
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ApiProvider;
