import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { getNotificationVisibility, saveNotificationVisibility } from '../api/maintenanceNotifications';
import { maintenanceNotificationAtom } from '../state/atoms/notificationsAtom';

export function useMaintenanceNotificationState() {
  const [
    maintenanceNotification,
    setMaintenanceNotification,
  ] = useRecoilState(maintenanceNotificationAtom);

  useEffect(() => {
    getNotificationVisibility()
      .then((response) => setMaintenanceNotification(response.data));
  }, []);

  const toggleMaintenanceNotification = (newValue: boolean) => {
    saveNotificationVisibility(newValue)
      .then((response) => {
        if (response.status === 200) {
          setMaintenanceNotification(newValue);
        }
      });
  };

  return {
    maintenanceNotification,
    toggleMaintenanceNotification,
  };
}
