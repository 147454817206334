/* eslint-disable no-confusing-arrow */
import { useForm, useLazyDataSource } from '@epam/uui-core';
import { useState } from 'react';
import {
  useRecoilValue, useSetRecoilState, atom, useRecoilState, 
} from 'recoil';
import fetchQuery, { IQueryRequestBody, IQueryResponseItem } from '../api/query';
import fetchTotals, { ITotalRequestBody } from '../api/totals';
import { IBreakdownItem } from '../components/Grid/VirtualList/VirtualListColumns';
import { 
  accAttrColumnIds,
  generalOppAttr,
  numericColumnsIds,
  oppAttrColumnIds,
  searchColumns,
} from '../const/COLUMNS';
import breakdownAtom from '../state/atoms/breakdownAtom';
import queryParamsAtom from '../state/atoms/queryParamsAtom';
import sortingAtom from '../state/atoms/sortingAtom';
import currentColumnsIdsSelector from '../state/selectors/currentColumnsIdsSelector';
import defaultViewPresetSelector from '../state/selectors/defaultViewPresetSelector';
import { virtualListErrorsAtom } from '../state/atoms/virtualListErrorsAtom';
import settingsSlideoutAtom from '../state/atoms/settingsSlideoutAtom';
import viewModeAtom from '../state/atoms/viewModeAtom';
import gridFormAtom from '../state/atoms/gridFormAtom';
import advancedFiltersConstructorAtom from '../state/atoms/advancedFiltersConstructorAtom';
import { parseAdvancedFiltersToView } from '../const/ADVANCED_FILTERS';
import { accountsForUpdateAtom } from '../state/atoms/markedForUpdateAtom';
import { viewStateAtom } from '../state/atoms/viewStateAtom';

export const queryRequestBodyAtom = atom<IQueryRequestBody | null>({
  key: 'queryRequestBodyAtom',
  default: null,
});

export interface QueryItem extends IQueryResponseItem {
  type: string,
  name: string,
  id: string,
  parentId: string,
}

export interface ITotalsItem extends IQueryResponseItem {
  isLoading: boolean,
}

interface ISummaryReqState {
  isParent: boolean,
  isFrom: boolean,
}

const useQueryApi = () => {
  // Totals
  const [summaryReq, setSummaryReq] = useState<ISummaryReqState>({
    isParent: false,
    isFrom: false,
  });
  const [summary, setSummary] = useState<ITotalsItem>({
    values: {},
    breakdown: {},
    count: 0,
    isLoading: true,
  });

  const [viewState, setViewState] = useRecoilState(viewStateAtom);

  // Stores
  const [{ mode, isRefreshed }, setViewMode] = useRecoilState(viewModeAtom);
  const setQueryRequestBodyState = useSetRecoilState(queryRequestBodyAtom);
  const setError = useSetRecoilState(virtualListErrorsAtom);

  // edit mode lens
  const [formState] = useRecoilState(gridFormAtom);

  const activeColumns = useRecoilValue(currentColumnsIdsSelector);
  const queryParams = useRecoilValue(queryParamsAtom);
  const {
    groupingStore: {
      multiGrouping: activeGroupings,
    },
    filtersStore: {
      revenueYear,
      ownership,
      revenueScenario,
      probabilityScenario,
      currency,
      breakdown,
      forecastGranularity,
      search: searchValue,
    },
  } = queryParams;
  const columnsIds = useRecoilValue(currentColumnsIdsSelector);
  const groupingsIds = activeGroupings.map((el) => el.id);

  const parameters = {
    year: revenueYear,
    currency,
    breakdown,
    forecastGranularity,
    ownership,
    probabilityScenario,
    revenueScenario,
  };

  const breakdownStore = useRecoilValue(breakdownAtom);
  const breakdownData = breakdownStore.map((item: IBreakdownItem) => (
    `${item.type}${item.index}_${item.year}`
  ));
  const { tagsFlagsNotes, warnings } = useRecoilValue(settingsSlideoutAtom);

  const defaultView = useRecoilValue(defaultViewPresetSelector);
  const currentViewId = defaultView.id;

  const sortingStore = useRecoilValue(sortingAtom);
  const commonColSorting = sortingStore.filter((el) => el.columnName === 'grouping' || columnsIds.includes(el.columnName));
  const attrCol = sortingStore.find((el) => el.columnName !== 'grouping');
  const isOppAttrSort = attrCol?.columnName
    && oppAttrColumnIds.includes(attrCol?.columnName);
  const isAccAttrSort = attrCol?.columnName
    && accAttrColumnIds.includes(attrCol?.columnName);

  // Edit mode grid form setter
  const setForm = useSetRecoilState(gridFormAtom);

  const {
    constructor: advancedFilters,
    changeCounter: isAdvancedFiltersChanged,
    cache,
    isConstructor, 
  } = useRecoilValue(advancedFiltersConstructorAtom);

  const accToUpdate = useRecoilValue(accountsForUpdateAtom);

  const parsedAdvancedFilters = isConstructor && cache.length
    ? parseAdvancedFiltersToView(cache)
    : parseAdvancedFiltersToView(advancedFilters);

  const refreshGrid = () => {
    setViewMode((prev) => ({
      ...prev,
      isRefreshed: prev.isRefreshed + 1,
    }));
  };

  const search = {
    value: searchValue,
    columns: searchColumns.filter((el) => (groupingsIds.includes(el) || columnsIds.includes(el))),
  };

  // Data source utilising query request
  // New variable to bypass typescript (to handle errors inside 'api' method)
  const dataSource = useLazyDataSource({
    api(request: any, ctx: any) {
      // Grouping variables
      const groupColIdx = activeGroupings
        .findIndex((item) => item.id === ctx?.parent?.type);
      const currentGroup = activeGroupings[groupColIdx + 1]?.id;
      const isAcc = currentGroup === 'accName';
      const isOpp = currentGroup === 'oppName';
      const isTagsAndFlagsAndNotesActive = tagsFlagsNotes && isOpp;
      const isWarningsActive = warnings && isOpp;
      // Columns variables
      const activeColumnsForReq = activeColumns
        .filter((el) => activeGroupings
          .every((group) => group.id !== el))
        .filter((el) => {
          const isOppAttr = oppAttrColumnIds.includes(el);
          const isAccAttr = accAttrColumnIds.includes(el);
          if (isAcc && isAccAttr) return true;
          if (isOpp && (isAccAttr || isOppAttr)) return true;
          if (!isAccAttr && !isOppAttr) return true;
          return false;
        })
        .filter((el) => el);

      const getColumns = () => {
        const cols = [
          ...activeColumnsForReq,
          ...groupingsIds.slice(0, groupingsIds.indexOf(currentGroup) + 1),
        ];
        // request optional columns and fields for opportunity
        if (isOpp) {
          cols.push(...['currName', 'hasRevenueHistory']);

          if (!cols.includes('prob')) {
            cols.push('prob');
          }

          if (isTagsAndFlagsAndNotesActive) {
            cols.push(...[
              'oppTags',
              'oppTagFlag',
              'hasNotes',
            ].filter((el) => !activeColumnsForReq.includes(el)));
            
            if (!cols.includes('oppState')) cols.push('oppState');
          }

          if (isWarningsActive && !activeColumnsForReq.includes('oppCorrections')) {
            cols.push('oppCorrections');
          }

          if (mode === 'edit') {
            cols.push(...[
              'hasForecastRevenue',
              'isBaseRequiredFieldsSet',
              'hasActualRevenue',
            ]);
          }

          if (mode === 'view') cols.push('canEditOpp');
        }
        return cols;
      };

      const columns = Array.from(new Set([...breakdownData, ...getColumns()].filter((el) => el)));

      if (mode === 'edit' && isOpp) columns.push('exchangeRate');

      // Sorting
      const getSorting = () => {
        const defaultRes = commonColSorting.length > 1 
              && commonColSorting[1].columnName !== 'prob'
              && (numericColumnsIds.includes(commonColSorting[1].columnName)
                || generalOppAttr.includes(commonColSorting[1].columnName)) 
          ? [
            {
              columnName: commonColSorting[1].columnName,
              direction: commonColSorting[1].direction,
            },
            {
              columnName: currentGroup,
              direction: commonColSorting[0].direction,
            },
          ]
          : [
            {
              columnName: currentGroup,
              direction: commonColSorting[0].direction,
            },
          ];
        if (isAcc && isAccAttrSort) {
          return [
            {
              columnName: attrCol.columnName,
              direction: attrCol.direction,
            },
            {
              columnName: currentGroup,
              direction: commonColSorting[0].direction,
            },
          ];
        }
        if (isOpp && (isAccAttrSort || isOppAttrSort)) {
          return [
            {
              columnName: attrCol.columnName,
              direction: attrCol.direction,
            },
            {
              columnName: currentGroup,
              direction: commonColSorting[0].direction,
            },
          ];
        }
        return defaultRes;
      };

      // Aggregate filters
      const getAggregateFilters = () => {
        if (ctx.parent) {
          const currentLevelIdx = groupingsIds.findIndex((el) => ctx.parent.type === el);
          const levelsToTake = groupingsIds.slice(0, currentLevelIdx + 1);
          const res = levelsToTake.map((el) => ({
            groupName: el,
            groupValue: ctx.parent.values[el],
          }));
          return res;
        }

        return null;
      };

      const queryRequestBody: IQueryRequestBody = {
        aggregate: {
          nextGroupColumnName: !(groupColIdx + 2 >= activeGroupings.length)
            ? activeGroupings[groupColIdx + 2]?.id
            : null,
          currentGroupColumnName: currentGroup,
          groupColumnNames: activeGroupings.map((group) => group.id),
        },

        search,

        aggregateFilters: getAggregateFilters(),

        advancedFilters: parsedAdvancedFilters,

        sort: getSorting(),

        parameters,

        columns,

        viewId: currentViewId,
        take: isOpp && accToUpdate.includes(ctx?.parent?.id) 
          ? ctx.parent.count : request.range.count,
        skip: request.range?.from ?? 0,
      };
      setQueryRequestBodyState(queryRequestBody);

      setSummaryReq({
        isParent: !!ctx?.parent,
        isFrom: !!request.range?.from,
      });

      return fetchQuery(queryRequestBody, mode)
        .then((data) => {
          if (
            !data.items.length 
            && currentGroup === activeGroupings[0].id 
            && !queryRequestBody.skip
          ) {
            setError('emptyListError');
          } else {
            setError(null);
          }

          // Setting initial form state for the editable grid
          if (mode === 'edit' && isOpp) {
            setForm((prev) => {
              const { items } = data;
              let newForm = {
                ...prev,
              };

              items.forEach((el: any) => {
                // in case of adding a new breakdown month
                if (newForm.items[el.values.oppId]) {
                  const breakdownKeys = Object.keys(el.breakdown);
                  breakdownKeys.forEach((key) => {
                    if (
                      newForm.items[el.values.oppId][key] === null
                      || newForm.items[el.values.oppId][key] === undefined
                    ) {
                      newForm = {
                        items: {
                          ...newForm.items,
                          [el.values.oppId]: {
                            ...newForm.items[el.values.oppId],
                            [key]: el.breakdown[key],
                            breakdown: {
                              ...newForm.items[el.values.oppId].breakdown,
                              [key]: el.breakdown[key],
                            },
                            bsrv: el.bsrv,
                          },
                        },
                      };
                    }
                  }); 
                } else {
                  newForm = {
                    items: {
                      ...newForm.items,
                      [el.values.oppId]: {
                        ...el,
                        ...el.breakdown,
                        prob: el.values.prob,
                        gttl_p: el.values.gttl_p,
                      },
                    },
                  };
                }
              });
              return newForm;
            });
          }
          return data;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setError('accessError');
          } else {
            setError('loadError');
          }
          return { items: [] };
        });
    },
    getChildCount: (item: QueryItem) => {
      if (item.count) return item.count;
      return 0;
    },
    getId: (i) => i.id,
    getParentId: (i) => i.parentId,
  }, [
    JSON.stringify(queryParams),
    JSON.stringify(breakdownStore).length,
    JSON.stringify(sortingStore),
    mode,
    isAdvancedFiltersChanged,
    isRefreshed,
  ]);

  const {
    lens, save,
  } = useForm<any>({
    value: formState,
    onSave: async () => {},
  });

  const lazyView = dataSource.useView(
    viewState,
    setViewState,
    mode === 'edit' ? {
      getRowOptions: (item: any) => item.type === 'oppName' ? ({
        ...lens.prop('items').prop(item.values.oppId).default(item).toProps(),
        isSelectable: false,
        checkbox: { isVisible: false, isDisabled: true },
      }) : {
        isSelectable: false,
        checkbox: { isVisible: false, isDisabled: true },
      },
    } : {
      isFoldedByDefault: () => !!viewState.isFolded,
      cascadeSelection: false,
    },
    [],
  );

  const getSummary = async () => {
    const totalColumns = activeColumns.filter((el) => numericColumnsIds.includes(el));

    const totalsRequestBody: ITotalRequestBody = {
      columns: [...breakdownData, ...totalColumns],
      aggregate: {
        groupColumnName: 'oppName',
        currentGroupColumnName: 'oppName',
        groupColumnNames: activeGroupings.map((group) => group.id),
      },
      advancedFilters: parsedAdvancedFilters,
      parameters,
      search,
    };

    if (!summaryReq.isFrom && !summaryReq.isParent) {
      setSummary((prev) => ({ ...prev, isLoading: true }));
      const data = await fetchTotals(totalsRequestBody);
      setSummary({
        ...data,
        isLoading: false,
      });
    }
  };

  return {
    summary,
    lazyView,
    saveLens: save,
    dataSource,
    getSummary,
    viewState,
    setViewState,
    refreshGrid,
  };
};

export default useQueryApi;
