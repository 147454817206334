import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { IDropdownToggler, useUuiContext } from '@epam/uui-core';
import { ReactComponent as MenuIcon } from '@epam/assets/icons/common/navigation-more_vert-18.svg';
import { ReactComponent as DiscardChangesIcon } from '@epam/assets/icons/common/content-edit_undo-18.svg';
import { ReactComponent as RenameIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/action-deleteforever-18.svg';
import { ReactComponent as ShareIcon } from '@epam/assets/icons/common/social-share-18.svg';
import { ReactComponent as CopyIcon } from '@epam/assets/icons/common/action-copy_content-18.svg';
import { ReactComponent as SaveIcon } from '@epam/assets/icons/common/save-outline-18.svg';
import {
  DropdownMenuButton, FlexRow, IconButton, Panel, 
} from '@epam/promo';
import { Dropdown } from '@epam/uui-components';
import css from './PresetActionsDropdown.module.scss';
import { View } from '../../../api/views';
import { useViewPresets } from '../../../hooks/useViewPresets';
import { useNotification } from '../../../hooks/useNotification';
import { foldedAtom } from '../../../state/atoms/foldedAtom';
import SharePresetModal from '../../modals/SharePresetModal/SharePresetModal';

interface ActionsDropdownProps {
  preset: View;
  renamePreset: () => void;
  onPresetDelete: (preset: View) => Promise<any>;
  onPresetCreate: (name: string) => Promise<any>;
  hasPresetChanged: () => boolean;
  addPreset: () => void;
}

export function PresetActionsDropdown({
  preset, onPresetDelete, renamePreset, onPresetCreate, hasPresetChanged, addPreset,
}: ActionsDropdownProps) {
  const setFoldedArr = useSetRecoilState(foldedAtom);
  const {
    resetChanges, restoreResetedView, viewPresets,
    revertToLastSaved, saveInCurrentView, resetColumnsWidth,
  } = useViewPresets();
  const { showNotification } = useNotification();
  const { uuiModals } = useUuiContext();

  const resetToInitialHandler = useCallback(async () => {
    await resetChanges(preset);
    showNotification(
      'Filters and column settings are reset',
      () => restoreResetedView(preset),
    );
    window.localStorage.removeItem(preset.id);
    if (preset.isDefault) setFoldedArr([]);
  }, [preset]);

  const resetColumnsWidthHandler = useCallback(async () => {
    await resetColumnsWidth(preset);
  }, [preset]);

  const deleteHandler = useCallback(async () => {
    await onPresetDelete(preset);
  }, [onPresetDelete, preset]);

  const saveInCurrentHandler = useCallback(async () => {
    saveInCurrentView(preset);
  }, [preset]);

  const discardAllChangesHandler = useCallback(() => {
    revertToLastSaved(preset);
  }, [preset]);

  const duplicateHandler = useCallback(async () => {
    const initialName = `${preset.name}_copy`;
    let newName = initialName;
    const presetsIncludesInitialName = viewPresets.filter((viewPreset) => (
      viewPreset.name.toLowerCase().includes(initialName.toLowerCase())
    ));
    if (presetsIncludesInitialName.length) {
      let postfix = 0;
      let i = -1;
      do {
        i++;
        newName = postfix ? `${initialName}(${postfix})` : newName;
        if (presetsIncludesInitialName.some(
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          (item) => item.name.toLowerCase() === newName.toLowerCase(),
        )) {
          postfix++;
        }
      } while (i < presetsIncludesInitialName.length);
    }
    if (newName.length > 300) {
      showNotification(
        'Cannot duplicate view. Duplicated view name is longer than 300 symbols, please make it shorter',
        undefined,
        'red',
      );
    } else {
      await onPresetCreate(newName);
    }
  }, [preset]);

  const shareHandler = useCallback(() => {
    uuiModals.show((modalProps) => (
      <SharePresetModal
        {...modalProps}
        presetName={preset.name}
        presetId={preset.id}
      />
    )).catch(() => null);
  }, [preset]);

  const renderBody = () => (
    <Panel background="white" shadow cx={css.presetDropdownPanel}>
      {preset.isDefault && hasPresetChanged() && !preset.isViewAutosaved && (
        <>
          <FlexRow key={`${preset.id}-save-in-current`}>
            <DropdownMenuButton icon={SaveIcon} caption="Save in current" onClick={saveInCurrentHandler} />
          </FlexRow>
          <FlexRow key={`${preset.id}-save-as-new`}>
            <DropdownMenuButton icon={SaveIcon} caption="Save as new" onClick={addPreset} />
          </FlexRow>
          <FlexRow borderBottom key={`${preset.id}-revert`}>
            <DropdownMenuButton icon={DiscardChangesIcon} caption="Revert to last saved" onClick={discardAllChangesHandler} />
          </FlexRow>
        </>
      )}
      {preset.isDefault && (
        <FlexRow key={`${preset.id}-rename`}>
          <DropdownMenuButton icon={RenameIcon} caption="Rename" onClick={renamePreset} />
        </FlexRow>
      )}
      <FlexRow key={`${preset.id}-duplicate`}>
        <DropdownMenuButton icon={CopyIcon} caption="Duplicate" onClick={duplicateHandler} />
      </FlexRow>
      <FlexRow key={`${preset.id}-share`}>
        <DropdownMenuButton icon={ShareIcon} caption="Share" onClick={shareHandler} />
      </FlexRow>
      {preset.isDefault && (
        <FlexRow key={`${preset.id}-reset-columns`}>
          <DropdownMenuButton icon={DiscardChangesIcon} caption="Reset columns width" onClick={resetColumnsWidthHandler} />
        </FlexRow>
      )}
      <FlexRow borderBottom key={`${preset.id}-reset`}>
        <DropdownMenuButton icon={DiscardChangesIcon} caption="Reset to initial" onClick={resetToInitialHandler} />
      </FlexRow>
      {viewPresets.length > 1 && (
        <FlexRow key={`${preset.id}-delete`} cx={css.deleteRow}>
          <DropdownMenuButton icon={DeleteIcon} caption="Delete" cx={css.deleteButton} onClick={deleteHandler} />
        </FlexRow>
      )}
    </Panel>
  );

  const renderTarget = useCallback((dropdownProps: IDropdownToggler) => (
    <IconButton
      cx={[css.tabButton, dropdownProps.isOpen && css.targetOpen]}
      color={preset.isDefault ? 'blue' : 'gray60'}
      {...dropdownProps}
      icon={MenuIcon}
    />
  ), []);

  return (
    <Dropdown
      renderBody={renderBody}
      renderTarget={renderTarget}
      placement="bottom-end"
      modifiers={[{ name: 'offset', options: { offset: [0, 22] } }]}
    />
  );
}
