/* eslint-disable @typescript-eslint/default-param-last */
import api from './api';

export const API_OPTIONLIST = 'option-lists/query-parameters';
const API_BASE = 'option-lists/';

// let counter = 0;

export const fetchOptionList = () => api.get(API_OPTIONLIST)
  .then((response) => response.data);

export const fetchAdvancedFilterOptions = (
  key: string,
  search?: string,
  isHierarchy?: boolean,
  isEditMode: boolean = false,
  skip: number = 0,
  take: number = 999999,
): Promise<any> => {
  const searchParam = search ? `search=${search}` : '';
  return api.get(`${API_BASE}${key}/${isHierarchy ? 'hierarchy-items' : 'items'}/?${searchParam}&skip=${skip}&take=${take}&isEditMode=${isEditMode}`)
    .then((res) => {
      const items: {
        id: string,
        name: string,
        hasChild: boolean,
      }[] = res.data.map((item: any) => ({
        ...item,
        hasChild: !isHierarchy
          ? false
          : res.data.some((el: any) => (el.parentId && item.id === el.parentId)),
      }));
      if (skip) {
        return { items: items.filter((i) => i.id) };
      }

      return { items };
    });
};
