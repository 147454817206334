import React, { FC, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Button, IconContainer } from '@epam/promo';
import { ReactComponent as FileIcon } from '@epam/assets/icons/common/file-file-24.svg';
import { ReactComponent as ClockIcon } from '@epam/assets/icons/common/action-schedule-outline-18.svg';
import { ReactComponent as WarningIcon } from '@epam/assets/icons/common/notification-warning-fill-18.svg';
import { ReactComponent as DoneIcon } from '@epam/assets/icons/common/notification-done-18.svg';
import { useRecoilState } from 'recoil';
import FileCheckResults from '../components/FileCheckResults/FileCheckResults';
import css from './ImportTab.module.scss';
import cls from '../ImportActualModal.module.scss';
import { importAPI } from '../../../../api/import';
import { useNotification } from '../../../../hooks/useNotification';
import getMonthShortName from '../../../../helpers/getMonthShortName';
import { formatBytes } from '../../../../helpers/formatBytes';
import { ImportHistoryItemInfo } from '../../../../api/importHistory';
import activeImportsAtom from '../../../../state/atoms/activeImportsAtoms';
import { defaultImportStatus, importStatusAtom } from '../../../../state/atoms/importActual/importAtom';
import { getImportStatus } from '../../../../api/importGetActive';
import { getMappedStatus, getStatusColor } from '../ImportHistoryTab/helpers';

interface Props {
  file: FileWithPath,
  isLoading: boolean,
  isError: boolean,
  fileForImport: FormData | null,
  errorMessage: string,
  backToFileUpload: () => void,
  navigateToHistory: () => void,
  importInfo: ImportHistoryItemInfo | null,
}

const ImportDetails: FC<Props> = ({
  file, isLoading, isError, backToFileUpload,
  navigateToHistory, fileForImport, errorMessage, importInfo,
}) => {
  const { showNotification } = useNotification();
  const [{ importing }, setActiveImports] = useRecoilState(activeImportsAtom);
  const [{
    fileName,
    fileExtention,
    fileSize,
    isImporting,
    isImportSuccessful,
    importInProgress,
  }, setImportStatus] = useRecoilState(importStatusAtom);

  const [importResult, setImportResult] = useState('');

  useEffect(() => {
    if (isImportSuccessful && importInProgress) {
      getImportStatus(importInProgress).then(({ data }) => {
        setImportResult(data);
      });
    }
  }, [isImportSuccessful]);

  useEffect(() => {
    const lastDotIndex = file.name.lastIndexOf('.');
    setImportStatus((prev) => ({
      ...prev,
      fileName: file.name.substring(0, lastDotIndex),
      fileExtention: file.name.substring(lastDotIndex + 1).toUpperCase(),
      fileSize: formatBytes(file.size),
    }));
  }, [file.name, file.size]);

  const importFile = async () => {
    try {
      if (fileForImport) {
        setImportStatus((prev) => ({
          ...prev,
          isImporting: true,
        }));
        const uploadId = await importAPI.upload(fileForImport);
        setImportStatus((prev) => ({
          ...prev,
          importInProgress: uploadId,
        }));
        setActiveImports((prev) => ({
          ...prev,
          importing: [...prev.importing, uploadId],
        }));
      }
    } catch (error: any) {
      showNotification(
        error.response?.data?.data['']
          ? error.response.data.data['']
          : 'Error',
        undefined,
        'amber',
      );
      setImportStatus((prev) => ({
        ...prev,
        isImporting: false,
      }));
    }
  };

  useEffect(() => {
    if (!importing.length && isImporting && importInProgress) {
      setImportStatus((prev) => ({
        ...prev,
        isImporting: false,
        isImportSuccessful: true,
      }));
    }
  }, [importing]);

  const showLoader = (text: string) => (
    <div className={css.loader}>
      <ClockIcon />
      <div>{text}</div>
    </div>
  );

  return (
    <>
      <div className={css.importDetails}>
        <div className={css.fileDetails}>
          <FileIcon />
          <div>
            <div className={css.fileName}>
              {fileName}
            </div>
            <div className={css.fileSize}>
              {`${fileExtention}, ${fileSize}`}
            </div>
          </div>
        </div>
        {
          isLoading
          && showLoader('Loading...')
        }
        {
          isError
          && (
            <div className={css.error}>
              <WarningIcon />
              <div>
                <div>
                  File is incorrect.
                </div>
                <div>
                  {errorMessage}
                </div>
              </div>
              <Button color="blue" size="30" caption="Try Another File" onClick={backToFileUpload} />
            </div>
          )
        }
        {
          !isLoading && !isError && !isImporting && !isImportSuccessful
          && (
            <div className={css.importAvailable}>
              <Button cx={css.cancelBtn} fill="none" color="gray" size="30" caption="Cancel" onClick={backToFileUpload} />
              <Button color="blue" size="30" caption="Import" onClick={importFile} />
            </div>
          )
        }
        {
          isImporting
          && showLoader('Importing...')
        }
        {
          isImportSuccessful
          && (
            <div className={css.successfulImport}>
              <IconContainer cx={cls[getStatusColor(importResult)]} icon={DoneIcon} />
              <div className={cls[getStatusColor(importResult)]}>{getMappedStatus(importResult ?? '')}</div>
              <Button
                color="blue"
                size="30"
                caption="Done"
                onClick={() => {
                  navigateToHistory();
                  setImportStatus(defaultImportStatus);
                }}
              />
            </div>
          )
        }
      </div>
      {
        !isLoading && !isError && !isImporting && importInfo
        && (
          <FileCheckResults 
            totalActual={importInfo.amountLinked + importInfo.amountUnlinked} 
            linkedAmount={importInfo.amountLinked} 
            lines={importInfo.allRecords} 
            unlinkedAmount={importInfo.amountUnlinked} 
            period={importInfo.month && importInfo.year 
              ? `${getMonthShortName(importInfo.month)}, ${importInfo.year}` 
              : ' unknown'}
            errors={importInfo.actualRevenueImportErrors}
            unlinked={importInfo.actualRevenueImportUnlinked}
          />
        )
      }
    </>
  );
};

export default ImportDetails;
