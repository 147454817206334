import { DataTableHeaderRow, DataTableRow, VirtualList } from '@epam/promo';
import { VirtualListState } from '@epam/uui-core';
import React, { useState } from 'react';
import columns from './columns';

import css from '../../ImportActualModal.module.scss';

export interface ISettingsLogItem {
  groupAccNumber: number,
  revenueType: string
}

interface Props {
  items: ISettingsLogItem[],
}

let number = 0;

const HistoryTabSettingsGrid = (props: Props) => {
  const { items } = props;

  const [state, setState] = useState<VirtualListState>({
    visibleCount: 60, topIndex: 0, scrollTo: { index: 0 },
  });

  const visibleItems = items
    .map((el) => ({ ...el, id: number += 1 }))
    .slice(state.topIndex, state.topIndex as number + Number(state.visibleCount));

  const visibleRows = visibleItems.map((row: any, idx) => (
    <DataTableRow
      key={row.id}
      value={row}
      columns={columns}
      rowKey={row.id}
      id={row.id}
      index={idx}
      cx={css.gridRow}
    />
  ));

  return (
    <>
      <DataTableHeaderRow
        key="header"
        columns={columns}
        value={state}
        onValueChange={setState}
        cx={css.gridHeader}
      />
      <VirtualList 
        rawProps={{
          style: {
            maxHeight: '324px',
            overflow: 'auto',
            marginRight: '.5rem',
          },
        }}
        cx={css.gridContainer}
        rows={visibleRows}
        role="listbox"
        value={state}
        onValueChange={setState}
        rowsCount={items.length}
      />
    </>
  );
};

export default HistoryTabSettingsGrid;
