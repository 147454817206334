import api from './api';
import { IOppRevenueHistoryResponseItem } from './revenueHistory';

const FORECASTS_API = '/OneCRM/forecasts/';
const MARK_UPD_API = '/OneCRM/mark-as-updated/';
const LINKED_PROJECTS_API = '/opportunities/';

export enum OneCrmSectionType {
  Forecast = 'Forecast',
  History = 'History',
  Actual = 'Actual',
  BsrvRoot = 'Workload Based',
  BsrvProject = 'Workload Based (Project)',
  BsrvStaffing = 'Workload Based (Staffing)',
}

export interface IOppRevenueForOneCrmResponse {
  transactionCurrencyId: string,
  transactionCurrencyName: string,
  canEdit: boolean,
  breakdowns: IOppRevenueGroup[]
}

export interface IOppRevenueItem {
  transactionCurrencyId: string,
  transactionCurrencyName: string,
  frcst: number | null,
  gttl: number | null,
  ytd: number | null,
  id: string,  
  year: number,
  m1: number | null,
  m2: number | null,
  m3: number | null,
  m4: number | null,
  m5: number | null,
  m6: number | null,
  m7: number | null,
  m8: number | null,
  m9: number | null,
  m10: number | null,
  m11: number | null,
  m12: number | null
}

export interface IBsrvRevenueItem extends IOppRevenueItem {
  projectId: string,
  projectName: string,
  staffingOpportunityCode: string,
}

export interface IOppRevenueGroup {
  year: number,
  actual: IOppRevenueItem,
  forecast: IOppRevenueItem,
  bsrvProject: IBsrvRevenueItem[],
  bsrvStaffing: IBsrvRevenueItem[],
  history: IOppRevenueHistoryResponseItem[]
}

export const getForecastsForOneCrm = (id: string) => api.get<IOppRevenueForOneCrmResponse>(`${FORECASTS_API}${id}`)
  .then((res) => res.data);

export const saveForecastsForOneCrm = (id: string, reqBody: {
  breakdown: {
    [key: string]: number
  }
}) => api.post(`${FORECASTS_API}${id}`, reqBody);

export const markAsUpdatedForOneCrm = (id: string) => api.put<boolean>(`${MARK_UPD_API}${id}`, { id });

export const getLinkedProjects = (id:string) => api.get<{
  items: {
    id: string,
    name: string,
    showActuals: boolean,
    showWlb: boolean,
    canEditActuals: boolean,
    canEditWlb: boolean
  }[]
}>(`${LINKED_PROJECTS_API}${id}/project-links`);

export const saveLinkedProjects = (
  id: string,
  data: { id: string, showActuals: boolean, showWlb: boolean }[],
) => api.post(`${LINKED_PROJECTS_API}${id}/project-links`, data);
