import React, {
  FC, useCallback, useEffect, useState, 
} from 'react';
import {
  Button, 
  DataTable,
  DataTableRow,
  FlexCell,
  FlexRow,
  FlexSpacer,
  Panel,
  Text,
  useForm,
  IconContainer,
} from '@epam/promo';
import {
  Metadata, useArrayDataSource,
} from '@epam/uui-core';
import { ReactComponent as AddIcon } from '@epam/assets/icons/common/action-add-12.svg';
import { ReactComponent as WarningIcon } from '@epam/assets/icons/common/notification-error-fill-12.svg';
import { tableColumns } from './tableColumns';
import css from './ImportActualSettings.module.scss';
import { IImportSettingsResponseItem, getImportSettings, postImportSettings } from '../../../../api/importSettings';
import Loader from '../../../Loader';
import { useNotification } from '../../../../hooks/useNotification';

type TSettingsItemType = 'Consulting' | 'License';

export interface ISettingsItem extends IImportSettingsResponseItem {
  id: number;
}

interface FormState {
  items: ISettingsItem[];
}

interface IRevenueType {
  id: TSettingsItemType;
  name: 'Consulting' | 'License';
}

let number = 0;

const newSettingItem: Omit<ISettingsItem, 'id'> = {
  groupAccNumber: '',
  varSubGrouping: '',
  varGrouping: '',
  type: 'Consulting',
};

const revenueTypes: IRevenueType[] = [
  { id: 'Consulting', name: 'Consulting' },
  { id: 'License', name: 'License' },
];

const getMetadata = (state: FormState):Metadata<FormState> => ({
  props: {
    items: {
      all: {
        props: {
          groupAccNumber: {
            validators: [
              (val: string) => [
                !val && 'Value cannot be empty.',
                (val && (val.length !== 6 || !Number(val))) 
                  && 'Group Account Number must be a 6-digit unique numeric value.',
                state.items.filter((el) => el.groupAccNumber === val).length > 1 
                && 'Group Account Number must be a 6-digit unique numeric value.'],
            ], 
          },
          varSubGrouping: {
            validators: [
              (val) => [
                !val && 'Value cannot be empty.',
                val.length > 100 && 'Value must be a string of 100 symbols max.', 
              ],
            ],
          },
          varGrouping: {
            validators: [
              (val) => [
                !val && 'Value cannot be empty.',
                val.length > 100 && 'Value must be a string of 100 symbols max.', 
              ],
            ], 
          },
          type: { isRequired: true },
        },
      },
    },
  },
});

const ImportActualSettings: FC = () => {
  const [tableState, setTableState] = useState({});

  const { showNotification } = useNotification();

  const [data, setData] = useState<ISettingsItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getImportSettings()
      .then((res) => {
        const newRes: ISettingsItem[] = res.map((el) => ({ ...el, id: number += 1 }));
        setData(newRes);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const {
    lens, save, revert, value, setValue, isChanged,
  } = useForm<FormState>({
    value: { items: data },
    onSave: (state) => {
      const settings = state.items.map(({ id, ...rest }) => rest);
      const deleted = data.map((el) => Number(el.groupAccNumber));
      return postImportSettings({
        settings,
        deleted,
      }).then(() => {
        showNotification('Settings have been successfully saved!', undefined, 'green');
      }).catch(() => { showNotification('Failed to save settings. Try again later!', undefined, 'red'); });
    },
    getMetadata: (state) => getMetadata(state),
  });

  const handleNewItem = () => {
    setValue((current) => (
      { ...current, items: [...current.items, { ...newSettingItem, id: number += 1 }] }
    ));
  };
  
  const handleItemDelete = (itemId: number) => {
    setValue((current) => ({ ...current, items: current.items.filter((i) => i.id !== itemId) }));
  };
  const handleSave = useCallback(() => {
    save();
  }, [data]);
    
  const pickerDataSource = useArrayDataSource({ items: revenueTypes }, []);

  const columns = React.useMemo(() => tableColumns(pickerDataSource, handleItemDelete), []);

  const dataSource = useArrayDataSource({
    items: value.items,
  }, []);

  const view = dataSource.useView(tableState, setTableState, {
    getRowOptions: (_: ISettingsItem, index: number) => ({
      ...lens.prop('items').index(index).toProps(),
    }),
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Panel cx={css.container}>
      <FlexRow cx={css.gridContainer}>
        <DataTable
          {...view.getListProps()}
          getRows={view.getVisibleRows}
          value={tableState}
          onValueChange={setTableState}
          columns={columns}
          headerTextCase="upper"
          renderRow={(props) => (
            <DataTableRow
              {...props}
            />
          )}
        />
      </FlexRow>

      <FlexRow background="gray5" spacing="12" padding="12" vPadding="12" cx={css.controlsRow}>
        <FlexCell width="auto">
          <Button caption="Add Line" onClick={handleNewItem} fill="none" icon={AddIcon} size="30" cx={css.button} />
        </FlexCell>
        
        {isChanged 
        && (
        <>
          <div className={css.divider} />
          <FlexCell width="auto">
            <Button caption="Save" onClick={handleSave} size="30" cx={css.button} />
          </FlexCell>
          <FlexCell width="auto">
            <Button caption="Cancel" onClick={revert} fill="none" color="gray50" size="30" cx={css.button} />
          </FlexCell>

          <FlexSpacer />
          <FlexRow>
            <IconContainer cx={css.warningIcon} icon={WarningIcon} color="amber" size={18} />
            {' '}
            <Text color="gray90">Note! You have unsaved changes.</Text>
          </FlexRow>
        </>
        )}
      </FlexRow>
    </Panel>
  );
};

export default ImportActualSettings;
