import { FlexRow, IconButton, Text } from '@epam/promo';
import React, { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ReactComponent as CollapsedIcon } from '@epam/assets/icons/common/navigation-chevron-right-18.svg';
import { ReactComponent as FoldIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import { TQueryValuesKey } from '../../../../../api/query';
import queryParamsAtom from '../../../../../state/atoms/queryParamsAtom';
import css from '../../SettingsSlideOut.module.scss';
import LevelPicker from './components/LevelPicker';
import useSettingsSlideout from '../../../../../hooks/useSettingsSlideout';
import settingsSlideoutAtom from '../../../../../state/atoms/settingsSlideoutAtom';
import { Levels } from '../../../../../const/GROUPINGS';
import useSlideout from '../../../../../hooks/useSlideout';

interface Props {
  data: TQueryValuesKey[],
  setState: React.Dispatch<React.SetStateAction<TQueryValuesKey[]>>,
}

const {
  one,
  two,
  three,
  four,
} = Levels;

const GroupingSettings: FC<Props> = ({
  data, 
  setState,
}) => {
  const { groupingStore: { multiGrouping: currentGroupings } } = useRecoilValue(queryParamsAtom);
  const levelsFromGroupings = {
    [one]: currentGroupings[0]?.id,
    [two]: currentGroupings[1]?.id,
    [three]: currentGroupings[2]?.id,
    [four]: currentGroupings[3]?.id,
  };
  const { changed, warnings, tagsFlagsNotes } = useRecoilValue(settingsSlideoutAtom);
  const { toggleChanged, toggleTagsFlagsNotes, toggleWarnings } = useSettingsSlideout();
  const { currentSlideout } = useSlideout();
  const [levelsState, setlevelsState] = useState(levelsFromGroupings);
  const [isOpen, setIsOpen] = useState(false);

  const handleSetLevel = (
    val: TQueryValuesKey | undefined,
    level: string,
  ) => {
    setlevelsState((prev) => ({
      ...prev,
      [level]: val,
    }));
  };

  // change values or clear groupings
  const changeCurrentGroups = (clickedLevelIndex: number, value?: TQueryValuesKey) => {
    const levelsValues = Object.values(Levels);
    if (!changed) toggleChanged();
    for (let i = clickedLevelIndex; i < levelsValues.length; i++) {
      handleSetLevel(i === clickedLevelIndex ? value : undefined, levelsValues[i]);
    }
    setState((prevSelectedGroups: TQueryValuesKey[]) => {
      if (value) {
        return [
          ...prevSelectedGroups.slice(0, clickedLevelIndex),
          value,
        ];
      }
      return prevSelectedGroups.slice(0, clickedLevelIndex);
    });
  };

  useEffect(() => {
    if (currentSlideout === 'columnsSettings') {
      setlevelsState(levelsFromGroupings);
    }
  }, [currentSlideout]);

  // Data for rendering LevelPickers
  const levelsArr = [
    {
      level: one,
      value: levelsState[one],
      onChange: (val: TQueryValuesKey) => changeCurrentGroups(0, val),
      onClear: () => changeCurrentGroups(0),
      condition: true,
    },
    {
      level: two,
      value: levelsState[two],
      onChange: (val: TQueryValuesKey) => changeCurrentGroups(1, val),
      onClear: () => changeCurrentGroups(1),
      condition: levelsState[one],
    },
    {
      level: three,
      value: levelsState[three],
      onChange: (val: TQueryValuesKey) => changeCurrentGroups(2, val),
      onClear: () => changeCurrentGroups(2),
      condition: levelsState[two],
    },
    {
      level: four,
      value: levelsState[four],
      onChange: (val: TQueryValuesKey) => changeCurrentGroups(3, val),
      onClear: () => changeCurrentGroups(3),
      condition: levelsState[three],
    },
  ];

  const renderLevelPickers = levelsArr
    .map(({
      level, value, onChange, onClear, condition,
    }, index) => (
      levelsArr[index - 1]?.value !== 'oppName'
      && (condition && (
      <LevelPicker
        key={level}
        level={level}
        levelIndex={index}
        value={value}
        onChange={onChange}
        onClear={onClear}
        selected={data}
        setOppCheckbox={() => {
          toggleTagsFlagsNotes();
          if (!changed) toggleChanged();
        }}
        oppCheckbox={tagsFlagsNotes}
        setOppWarningsCheckbox={() => {
          toggleWarnings();
          if (!changed) toggleChanged();
        }}
        oppWarningsCheckbox={warnings}
      />
      ))
    ));
  
  return (
    <div className={css.groupingSettings}>
      <FlexRow size="24">
        <IconButton
          rawProps={{
            style: {
              maxHeight: '18px',
              maxWidth: '18px',
            },
          }}
          icon={isOpen ? FoldIcon : CollapsedIcon} 
          onClick={() => setIsOpen((prev) => !prev)}
        />
        <Text cx={css.text} color="gray60" fontWeight="600" lineHeight="24">Grouped by</Text>
      </FlexRow>
      
      {isOpen && renderLevelPickers}
    </div>
  );
};

export default GroupingSettings;
