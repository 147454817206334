import { Levels } from '../../../../const/GROUPINGS';

export const PADDING_LVL_THREE = '20px';
export const PADDING_LVL_FOUR = '40px';
export const DEFAULT_PADDING = '0';
export const COLOR_NIGHT_800 = '#303240';
export const COLOR_NIGHT_600 = '#6C6F80';

export const getPadding = (level: string) => {
  switch (level) {
    case Levels.three:
      return PADDING_LVL_THREE;
    case Levels.four:
      return PADDING_LVL_FOUR;
    default:
      return DEFAULT_PADDING;
  }
};
