import { 
  Button,
  FlexCell, 
  FlexRow, 
  IconContainer, 
  Text,
} from '@epam/promo';
import React, { FC } from 'react';
import { ReactComponent as fileIcon } from '@epam/assets/icons/common/file-file_excel-24.svg';
import { ReactComponent as editIcon } from '@epam/assets/icons/common/content-edit-12.svg';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-delete-12.svg';
import { deleteNote, INote } from '../../../../api/notes';
import css from '../NotesModal.module.scss';
import NotesIcon from '../../../../assets/components/NotesIcon';
import { IForm } from './NotesForm';
import { useNotification } from '../../../../hooks/useNotification';
import useUserProfile from '../../../../hooks/useUserProfile';

interface Props extends INote {
  handleChange: () => void,
  form: IForm,
  setForm: React.Dispatch<React.SetStateAction<IForm>>
  currentUser: string,
  isAdmin: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

const NotesItem: FC<Props> = ({
  currentUser,
  modifiedBy,
  modifiedByUser,
  createdOn,
  fileName,
  id,
  modifiedOn,
  noteText,
  subject,
  handleChange,
  form,
  setForm,
  isAdmin,
  setIsLoading,
}) => {
  const { noteId: editingNoteId } = form;
  const isSameUser = currentUser === modifiedBy;
  const isEditing = editingNoteId === id;
  const { showNotification } = useNotification();
  const { formatDate } = useUserProfile();

  const getRootCx = () => {
    const rootCx = [
      css.root,
    ];

    if ((isSameUser || isAdmin) && !isEditing) {
      rootCx.push(css.hoverableRoot);
    }

    if (isEditing) {
      rootCx.push(css.selectedNote);
    }

    return rootCx;
  };

  const handleDelete = () => {
    if (id) {
      setIsLoading(true);
      deleteNote(id)
        .then(() => {
          showNotification('Note is deleted', undefined, 'green');
          handleChange();
        })
        .catch(() => {
          setIsLoading(false);
          showNotification('Note cannot be deleted', undefined, 'red');
        });
    }
  };

  const handleEdit = () => {
    setForm((prev) => ({
      ...prev,
      noteId: id,
      subject,
      noteText,
    }));
  };
    
  return (
    <FlexRow cx={getRootCx()}>
      <IconContainer icon={NotesIcon} cx={css.notesItemIcon} />
      <FlexRow cx={css.notesItemContainer}>
        <FlexRow cx={css.infoUpperPart}>
          <Text cx={css.userName}>{modifiedByUser}</Text>
          <Text cx={css.subject}>{subject}</Text>
          <Text cx={css.content}>{noteText}</Text>
          {fileName && (
          <FlexRow cx={css.attachmentRow} spacing="6">
            <IconContainer icon={fileIcon} size={14} color="gray50" />
            <FlexCell cx={css.fileName}>{fileName}</FlexCell>
          </FlexRow>
          )}
        </FlexRow>
        <FlexRow cx={css.noteFooter}>
          {
          !isEditing && (
            <FlexRow cx={css.datesRow}>
              {
            modifiedOn 
              ? (
                <Text cx={css.dateText}>{`Created: ${createdOn && formatDate(createdOn)}`}</Text>
              )
              : <Text cx={css.dateText}>{createdOn && formatDate(createdOn)}</Text>
            }
              {modifiedOn && modifiedOn !== createdOn && <Text cx={css.dateText}>{`Edited: ${formatDate(modifiedOn)}`}</Text>}
            </FlexRow>
          )
        }
          {
          (isSameUser || isAdmin) && !isEditing && (
            <FlexRow
              cx={
                modifiedOn && modifiedOn !== createdOn
                  ? `${css.actionBtnsRow} ${css.actionBtnsRowWithMargin}`
                  : css.actionBtnsRow
              }
              spacing="6"
            >
              <Button
                caption="Edit" 
                icon={editIcon} 
                fill="light" 
                cx={`${css.actionBtn} ${css.button}`} 
                color="gray50"
                onClick={handleEdit} 
              />
              <Button
                caption="Delete" 
                icon={deleteIcon} 
                fill="light" 
                cx={`${css.actionBtn} ${css.button}`} 
                color="gray50"
                onClick={handleDelete} 
              />
            </FlexRow>
          )
        }
          {isEditing && (
          <FlexRow cx={css.editingMsgRow}>
            <Text cx={css.dateText}>Editing message...</Text>
          </FlexRow>
          )}
        </FlexRow>
      </FlexRow>
    </FlexRow>
  
  );
};

export default NotesItem;
