import React from 'react';
import {
  Button, FlexRow, ModalBlocker, ModalHeader, ModalWindow, Panel, Text, 
} from '@epam/promo';
import { IModal } from '@epam/uui-core';

import css from '../../ImportActualModal.module.scss';

interface ConfirmDeleteModalProps extends IModal<string> {
  onConfirm: () => void;
}

const ConfirmDeleteModal = ({ onConfirm, abort, ...modalProps }: ConfirmDeleteModalProps) => (
  <ModalBlocker
    {...modalProps}
    abort={() => abort()}
  >
    <ModalWindow>
      <ModalHeader
        cx={css.modalHeader}
        title="Delete Last Import"
        onClose={() => {
          abort();
        }}
      />
      <Panel cx={css.DeleteModalRoot}>
        <Text fontSize="16">
          Delete actual revenue for the last loaded period?
        </Text>
        <FlexRow spacing="12">
          <Button
            fill="none"
            caption="No, cancel"
            onClick={() => {
              abort();
            }}
          />
          <Button
            color="red"
            caption="Yes, delete"
            onClick={() => { 
              onConfirm();
              abort();
            }}
          />
        </FlexRow>
      </Panel>
    </ModalWindow>
  </ModalBlocker>
);

export default ConfirmDeleteModal;
