import { atom, selector } from 'recoil';
import { TQueryValuesKey } from '../../api/query';
import { multiGroupingAttributes } from '../../const/GROUPINGS';
import { Filters, setFiltersFromPresetFiltersList } from '../../const/FILTERS';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';
import { IColumns } from '../../api/views';
import {
  getActiveColumnsForPreset, 
  getConfigArr, 
} from '../../const/COLUMNS';
import { getConfigFromView } from './viewStateAtom';

export function setGroupingsFromPresetGroupsList(
  presetGroups: Array<TQueryValuesKey> | undefined,
): Array<Grouping> {
  if (presetGroups) {
    return presetGroups.map(
      (groupId) => multiGroupingAttributes.filter(
        (groupingAttribute) => groupingAttribute.id === groupId,
      )[0],
    );
  }
  return [];
}

export interface ISettings {
  groupingStore: {
    multiGrouping: Grouping[],
    tagsFlagsNotes: boolean,
    warnings: boolean
  },
  columnsStore: IColumns,
  filtersStore: Filters,
}

export interface Grouping {
  id: TQueryValuesKey,
  value: string,
}

const queryParamsAtom = atom<ISettings>({
  key: 'queryParamsStore',
  default: selector({
    key: 'queryStore',
    get: ({ get }) => {
      const defaultView = get(defaultViewPresetSelector);
      const {
        multiGrouping: { groups, warnings, tagsFlagsNotes },
        permanentFiltersSettings, 
      } = defaultView;

      const config = getConfigArr(getConfigFromView(defaultView, false));

      return {
        filtersStore: setFiltersFromPresetFiltersList(permanentFiltersSettings),
        groupingStore: {
          multiGrouping: setGroupingsFromPresetGroupsList(groups),
          warnings,
          tagsFlagsNotes, 
        },
        columnsStore: {
          ...getActiveColumnsForPreset(config),
        },
      };
    },
  }),
});

export default queryParamsAtom;
