import React from 'react';
import { values } from 'lodash';
import { IHierarchicalItems } from '../state/atoms/advancedFiltersConstructorAtom';
import {
  TLogicalOperator,
  predicateDictionaryValues,
  singlePickerIds,
  singlePickerOptions, useCreateDataSource, 
} from '../const/ADVANCED_FILTERS';
import useUserProfile from './useUserProfile';

export const useAdvancedFilters = () => {
  const { getAdvancedFilters } = useCreateDataSource();
  const { formatDate } = useUserProfile();

  const createFilterConstructorString = (
    data: IHierarchicalItems,
    isSubgroup = false,
    parentOperator?: TLogicalOperator,
  ) => {
    let string = '';
    const getOperatorText = (operatorId: TLogicalOperator) => ` ${operatorId === 'Or' ? 'OR' : 'AND'} `;

    data.forEach((item, itemIndex) => {
      if (isSubgroup) {
        string += '[';
      }
      values(item.filters).forEach((filter, index, array) => {
        const advancedFilter = getAdvancedFilters().filter((el) => filter.key === el.field)[0];
        const correctTitle = advancedFilter.type === 'datePicker' ? advancedFilter.title.split(' ').slice(0, -1).join(' ') : advancedFilter.title;

        const predicateIndex = 0;

        string += `[${
          correctTitle
        } ${
          predicateDictionaryValues.filter((el) => filter.operator === el.id)[predicateIndex].name
        } `;
        
        if (Array.isArray(filter.value)) {
          // Multi Picker
          if (filter.value.length > 3) {
            string += `${filter.value.length} items`;
          } else {
            string += filter.value.filter((element) => element).map((el) => el.name).join();
          }
        } else if (
          // Empty value
          filter.value === undefined 
          || (singlePickerIds.includes(filter.key) && (!filter.value && filter.value !== 0))
        ) {
          string += 'All';
        } else if (
          // Single Picker
          singlePickerIds.includes(filter.key) && (filter.value || filter.value === 0)
        ) {
          string += singlePickerOptions[filter.key].filter((el) => el.id === filter.value)[0].name;
        } else if (
          // Date Picker
          advancedFilter.type === 'datePicker' && typeof filter.value === 'string'
        ) {
          string += formatDate(filter.value, { isShort: true });
        } else {
          // Numeric
          string += filter.value;
        }
        string += ']';

        if (index !== array.length - 1) {
          string += getOperatorText(item.operator);
        }
      });
      if (item.children && item.children.length) {
        if (values(item.filters).length) {
          string += getOperatorText(item.operator);
        }
        string += createFilterConstructorString(item.children, true, item.operator);
      }
      if (isSubgroup) {
        string += ']';
      }
      if (itemIndex !== data.length - 1 && parentOperator) {
        string += getOperatorText(parentOperator);
      }
    });
    return string;
  };

  const getFilterConstructorStringLayout = (data: IHierarchicalItems) => {
    const string = createFilterConstructorString(data);

    const getColor = (str: string) => {
      if (str === 'OR') {
        return '#77B300';
      }
      if (str === 'AND') {
        return '#008ACE';
      }
      return '#303240';
    };

    const arrayString = string.split(' ');
    return arrayString.map((str, index) => (
      <span key={`${str + index}`} style={{ color: getColor(str), whiteSpace: 'nowrap' }}>
        {str}
        &nbsp;
      </span>
    ));
  };
  
  return { getFilterConstructorStringLayout };
};
