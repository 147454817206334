import {
  Button,
  Dropdown, DropdownContainer, FlexRow, FlexSpacer, MultiSwitch, Panel, IconContainer,
} from '@epam/promo';
import { PickerTogglerProps } from '@epam/uui-components';
import {
  DataRowOptions,
  DataRowProps, DropdownBodyProps, IDropdownToggler, TableFiltersConfig,
} from '@epam/uui-core';
import React, { FC, useState } from 'react';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/action-deleteforever-12.svg';
import { ReactComponent as DragNDropIcon } from '@epam/assets/icons/common/action-drag_indicator-18.svg';
import useUserProfile from '../../../../../hooks/useUserProfile';
import { FilterItemBody } from './FilterbarItemBody';
import { FilterToolbarItemToggler } from './FilterbarItemToggler';
import css from './filterbar.module.scss';
import { IFilterbarItem, TSinglePickerOptionList, predicateDictionary } from '../../../../../const/ADVANCED_FILTERS';
import { IAdvancedFilter, IAdvancedFilterValue } from '../../../../../api/query';

export interface IFilterInputProps {
  value: IAdvancedFilter,
  onValueChange: (val: IAdvancedFilter) => void,
  onRemove?: (val: string) => void,
  filter: IFilterbarItem,
  renderRow?: (props: DataRowProps<TableFiltersConfig<unknown>, string>) => React.ReactNode,
  renderToggler?: (
    props: PickerTogglerProps<TableFiltersConfig<unknown>, string>
  ) => React.ReactNode,
  renderFooter?: (props: unknown) => React.ReactNode,
  getRowOptions?: (item: TableFiltersConfig<unknown>) => DataRowOptions<unknown, unknown>,
  direction: 'row' | 'column',
  isDisabled?: boolean,
  options?: TSinglePickerOptionList,
  isDraggable: boolean,
  dndRef?: React.Ref<any>,
  dndEventHandlers?: any,
  setFiltersOpeningValue?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
  filtersOpeningValue?: { [key: string]: boolean },
  showSearch?: boolean,
  bodyCx?: string,
  containerWidth?: number;
}

const FilterInput: FC<IFilterInputProps> = ({ value, filter, ...props }) => {
  const {
    predicates,
    title,
    type,
  } = filter;

  const correctTitle = type === 'datePicker' ? title.split(' ').slice(0, -1).join(' ') : title;

  const getPredicate = () => {
    if (value?.operator) {
      return Object.values(predicateDictionary)
        .filter((el) => el.id === value.operator
          && predicates.some((i) => i.predicate === el.predicate))[0].predicate;
    }
    return predicates
      .filter((el) => el.isDefault)[0].predicate;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [predicate, setPredicate] = useState(getPredicate());

  const { formatDate } = useUserProfile();

  const getTogglerValue = () => {
    const optionValue = value.value;

    switch (type) {
      case 'multiPicker': {
        const postfix = Array.isArray(optionValue)
          && optionValue.length > 2 ? ` ${optionValue.length}` : null;

        const valuesForSelection = Array.isArray(optionValue)
          && optionValue.slice(0, 2)
            .filter((element) => element)
            .map((el: IAdvancedFilterValue) => el.name);

        const selection = valuesForSelection && valuesForSelection.length ? valuesForSelection : ['Not selected'];

        const selectionText = selection.join(', ');
        return { selection: selectionText, postfix };
      }
      case 'singlePicker': {
        const selection = (value.value || value.value === 0) && props.options
          ? props.options.filter((el) => el.id === value.value)[0].name : 'Not selected';
        return { selection };
      }
      case 'datePicker': {
        const selection = typeof value.value === 'string'
          ? formatDate(value.value, { isShort: true })
          : 'Not selected';

        return {
          selection,
        };
      }
      case 'numeric': {
        const selection = typeof value.value === 'number'
          ? value.value.toString() : 'Not selected';
        return { selection };
      }
      default: {
        return { selection: null };
      }
    }
  };

  const renderTarget = (togglerProps: IDropdownToggler) => {
    if (props.filtersOpeningValue && props.filtersOpeningValue[`${value.subgroupId}${value.key}`] !== !!togglerProps.isOpen) {
      props.setFiltersOpeningValue && props.setFiltersOpeningValue((prevState) => ({
        ...prevState,
        [`${value.subgroupId}${value.key}`]: !!togglerProps.isOpen,
      }));
    }
    const renderFilterToolbarItemToggler = () => (
      <FilterToolbarItemToggler
        {...togglerProps}
        {...getTogglerValue()}
        title={correctTitle}
        predicate={predicate || ':'}
        predicates={predicates}
        maxWidth={props.direction === 'column' ? '375' : '275'}
        direction={props.direction}
        isDisabled={props.isDisabled}
        id={filter.field}
      />
    );
    
    if (props.isDraggable && !props.isDisabled) {
      return (
        <div
          style={{ width: '100%' }}
          {...props.dndEventHandlers}
          ref={props.dndRef}
        >
          <div className={css.dndFilterContainer}>
            <IconContainer icon={DragNDropIcon} />
            {renderFilterToolbarItemToggler()}
          </div>
        </div>
      );
    }

    return renderFilterToolbarItemToggler();
  };

  const removeFilter = () => {
    if (!props.onRemove) return;
    props.onRemove(filter.field);
    props.setFiltersOpeningValue && props.setFiltersOpeningValue((prevState) => {
      const { [`${value.subgroupId}${filter.field}`]: omitted, ...rest } = prevState;
      return {
        ...rest,
      };
    });
  };

  const renderBody = (dropdownProps: DropdownBodyProps) => (
    <DropdownContainer width={props?.containerWidth || 360}>
      <Panel background="white">
        <FlexRow padding="12">
          <MultiSwitch
            value={predicate}
            onValueChange={(val) => {
              setPredicate(val);
              if (value.value === undefined) return;

              props.onValueChange({
                key: filter.field,
                operator: predicateDictionary[val].id,
                value: value.value,
              });
            }}
            items={predicates.map((i) => ({ id: i.predicate, caption: i.name }))}
            size="24"
          />
          <FlexSpacer />
          {props.onRemove && (
            <Button
              caption="Remove filter"
              color="blue"
              fill="light"
              onClick={removeFilter}
              size="24"
              icon={deleteIcon}
              cx={css.removeFilterBtn}
            />
          )}
        </FlexRow>
        <FilterItemBody
          {...props}
          {...dropdownProps}
          {...filter}
          value={value.value}
          onValueChange={(val: IAdvancedFilter['value']) => {
            props.onValueChange({
              key: filter.field,
              operator: predicateDictionary[predicate].id,
              value: val ?? undefined,
            });
          }}
        />
      </Panel>
    </DropdownContainer>
  );

  return (
    <Dropdown
      renderBody={renderBody}
      renderTarget={renderTarget}
      value={isOpen}
      onValueChange={setIsOpen}
    />
  );
};

export default React.memo(FilterInput);
