/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { IDataSource, IDropdownBodyProps } from '@epam/uui-core';
import { FilterPickerBody } from './filters/FilterPickerBody';
import { FilterDataPickerBody } from './filters/FilterDatePickerBody';
import FilterNumericBody from './filters/FilterNumericBody';
import { useHierarchyPickerInput } from '../../../../../hooks/useHierarchyPickerInput';
import { IFilterbarItem } from '../../../../../const/ADVANCED_FILTERS';
import { IFilterInputProps } from './FilterInput';
import { IAdvancedFilter } from '../../../../../api/query';
import { usePickerParentOptionsRemove } from '../../../../../hooks/usePickerParentOptionsRemove';
import css from './filterbar.module.scss';

type TFilterInputProps = Pick<IFilterInputProps, | 'onRemove' | 'options' | 'isDisabled' | 'direction' >;

interface BaseProps extends IDropdownBodyProps, IFilterbarItem, TFilterInputProps {}

interface SinglePickerProps extends BaseProps {
  type: 'singlePicker';
  value: IAdvancedFilter['value'];
  onValueChange: (value: IAdvancedFilter['value']) => void;
  dataSource: IDataSource<unknown, unknown, unknown>;
  showSearch?:boolean;
  bodyCx?: string;
}
interface MultiPickerProps extends BaseProps {
  type: 'multiPicker';
  value: unknown[];
  onValueChange: (value: unknown[]) => void;
  dataSource: IDataSource<unknown, unknown, unknown>;
  showSearch?:boolean;
  bodyCx?: string;
}
interface DatePickerProps extends BaseProps {
  type: 'datePicker';
  value: string;
  onValueChange: (value: string) => void;
  bodyCx?: string;
}

interface NumericProps extends BaseProps {
  type: 'numeric';
  value: unknown;
  onValueChange: (value: number | undefined) => void;
  bodyCx?: string;
}

type Props = MultiPickerProps | SinglePickerProps | DatePickerProps | NumericProps ;

export const FilterItemBody = (props: Props) => {
  const { type, field } = props;
  const [isShowSelected, setIsShowSelected] = useState(false);
  const { isHierarchy, getRowOptions } = useHierarchyPickerInput();

  usePickerParentOptionsRemove({ isHierarchy: isHierarchy(field), fieldProps: props });

  // eslint-disable-next-line no-nested-ternary
  const bodyClassName = isHierarchy(field) && !isShowSelected
    ? (
      field === 'oppLeadPractice' || field === 'oppPractices'
        ? `${css.pickerInputWithHierarchy} ${css.pickerInputWithMultilevelHierarchy} ${props?.bodyCx}`
        : `${css.pickerInputWithHierarchy}`
    )
    : '';

  switch (type) {
    case 'singlePicker':
      return (
        <FilterPickerBody
          {...props}
          selectionMode="single"
          valueType="id"
          containerCx={props.bodyCx}
        />
      );
    case 'multiPicker':
      return (
        <FilterPickerBody
          {...props}
          selectionMode="multi"
          valueType="entity"
          cascadeSelection={isHierarchy(field)}
          rawProps={{
            body: {
              className: bodyClassName,
            },
          }}
          containerCx={props.bodyCx}
          searchPosition={props?.showSearch === false ? 'none' : 'body'}
          getRowOptions={isHierarchy(field) ? getRowOptions as any : undefined}
          setIsShowSelected={setIsShowSelected}
          searchDebounceDelay={500}
        />
      );
    case 'datePicker':
      return (
        <FilterDataPickerBody
          {...props}
          format="DD/MM/YYYY"
        />
      );
    case 'numeric': 
      return (
        <FilterNumericBody {...props} />
      );
    default: 
      return (<div />);
  }
};
