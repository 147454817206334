import React from 'react';
import {
  Button, FlexRow, IconContainer, Text, 
} from '@epam/promo';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/delete-outline-36.svg';
import { ReactComponent as DoneIcon } from '@epam/assets/icons/common/notification-done-18.svg';

import css from '../ImportActualModal.module.scss';

interface ImportHistoryStatusProps {
  type: 'inProgress' | 'completed' | null;
  onDone: () => void,
}

const ImportHistoryStatus = (props: ImportHistoryStatusProps) => {
  const { type, onDone } = props;

  if (type === 'inProgress') {
    return (
      <div className={css.activeStatusContainer}>
        <FlexRow cx={css.activeStatusWrapper}>
          <IconContainer size={24} icon={DeleteIcon} color="red" />
          <div className={css.activeStatusDescription}>
            <Text>
              Removing last month record(s)...
            </Text>
            <Text cx={css.activeStatusSubtitle}>
              Please wait
            </Text>
          </div>
        </FlexRow>
      </div>
    );
  }

  if (type === 'completed') {
    return (
      <div className={css.activeStatusContainer}>
        <FlexRow cx={css.activeStatusWrapper}>
          <IconContainer size={24} icon={DoneIcon} color="green" />
          <div className={css.activeStatusDescription}>
            <Text cx={css.successColor}>
              Last month record(s) successfully removed
            </Text>
          </div>
          <Button caption="Done" onClick={onDone} cx={css.doneBtn} />
        </FlexRow>
      </div>
    );
  }

  return null;
};

export default ImportHistoryStatus;
