import React, { FC } from 'react';
import Footer from './Footer';
import Header from './Header';
import css from './SlidingPanel.module.scss';

interface Props {
  onApply: () => void,
  onClose: () => void,
  onReset?: () => void,
  isApplyDissabled: boolean,
  caption: string,
  cx?: string,
  isError?: boolean,
  errorMessage?: string,
}

const PanelWithBtns: FC<Props> = ({
  children, onApply, onClose, isApplyDissabled, caption, cx, isError, errorMessage, onReset,
}) => (
  <div className={`${css.root} ${cx}`}>
    <Header
      title={caption}
      onClose={onClose}
    />
    { children }
    <Footer
      onApply={onApply}
      onClose={onClose}
      onReset={onReset}
      isApplyDisabled={isApplyDissabled}
      isError={isError}
      errorMessage={errorMessage}
    />
  </div>
);

export default PanelWithBtns;
