import {
  Badge, FlexRow, Text, TextInput,
} from '@epam/promo';
import React, { memo, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  DropParams, IColumnConfig, getOrderBetween,
} from '@epam/uui-core';
import { ReactComponent as SearchIcon } from '@epam/assets/icons/common/action-search-18.svg';
import _ from 'lodash';
import useColumnsSettings from '../../../../../hooks/useColumnsSettings';
import ColumnAccordition from './components/ColumnAccordition';
import css from './ColumnsSettings.module.scss';
import queryParamsAtom from '../../../../../state/atoms/queryParamsAtom';
import {
  accAttrIds,
  checkboxesDictionary,
  colsIds,
  moveOrderToNextStep,
  numericColumnsIds,
  oppAttrIds,
  sortCheckboxesByOrder,
} from '../../../../../const/COLUMNS';
import { IQueryResponseValues } from '../../../../../api/query';
import DraggableCheckbox from './components/DraggableCheckbox';
import useSettingsSlideout from '../../../../../hooks/useSettingsSlideout';
import { getOrderStr } from '../../../../../state/atoms/viewStateAtom';

type IColumn = IColumnConfig & { id: string };

export type ConfigArrayItem = {
  id: string;
  isVisible?: boolean;
  order?: string;
  width?: number;
  fix?: 'left' | 'right';
};

interface ColumnsSettingsProps {
  config: ConfigArrayItem[];
  grouping: (keyof IQueryResponseValues)[];
  onConfigChange: (key: keyof IQueryResponseValues, value: IColumnConfig) => void;
}

export const getNewOrder = (item: IColumn, arr: IColumn[]) => {
  if (item.isVisible && !item.fix) {
    const order = getOrderStr(arr.length - 1, 'c');
    return order;
  }
  if (item.isVisible && item.fix) {
    const order = getOrderStr(arr.length - 1, 'b');
    return order;
  }

  return undefined;
};

export const sortCheckboxesByAlphabet = (a: IColumn, b: IColumn) => {
  const aName = checkboxesDictionary[a.id];
  const bName = checkboxesDictionary[b.id];
  if (aName === bName) return 0;
  return (aName > bName ? 1 : -1);
};

const ColumnsSettings = (props: ColumnsSettingsProps) => {
  const { config, grouping, onConfigChange } = props;
  const { setColumnsFromIdList } = useColumnsSettings();
  const { toggleChanged } = useSettingsSlideout();
  const queryStore = useRecoilValue(queryParamsAtom);

  const [search, setSearch] = useState('');

  const displayColumns = config
    .filter((el) => el.isVisible && checkboxesDictionary[el.id] && !el.fix)
    .sort(sortCheckboxesByOrder);

  const pinnedColumns = config
    .filter((el) => el.fix && el.isVisible && el.id !== 'columnsConfig' && el.id !== 'currName')
    .filter((el) => el.id.toLowerCase().includes(search.toLowerCase())
      || checkboxesDictionary[el.id].toLowerCase().includes(search.toLowerCase()))
    .sort(sortCheckboxesByOrder);

  const col = config.filter((el) => colsIds.includes(el.id)
    && !displayColumns.some((i) => i.id === el.id) && !grouping.includes(el.id))
    .filter((el) => checkboxesDictionary[el.id].toLowerCase().includes(search.toLowerCase()));
  const accAttr = config.filter((el) => accAttrIds.includes(el.id)
    && !displayColumns.some((i) => i.id === el.id) && !grouping.includes(el.id))
    .filter((el) => checkboxesDictionary[el.id].toLowerCase().includes(search.toLowerCase()));
  const oppAttr = config.filter((el) => oppAttrIds.includes(el.id)
    && !displayColumns.some((i) => i.id === el.id) && !grouping.includes(el.id))
    .filter((el) => checkboxesDictionary[el.id].toLowerCase().includes(search.toLowerCase()));

  const accCols = accAttr
    .filter((el) => !displayColumns.some((i) => el.id === i.id) && !el.fix)
    .map((el) => ({
      id: el.id,
      isVisible: false,
      order: el.order,
      width: el.width,
    }));

  const oppCols = oppAttr
    .filter((el) => !displayColumns.some((i) => el.id === i.id) && !el.fix)
    .map((el) => ({
      id: el.id,
      isVisible: false,
      order: el.order,
      width: el.width,
    }));

  useEffect(() => {
    setColumnsFromIdList(queryStore.columnsStore);
  }, [queryStore]);

  // const handleChange = (type: string, newItem: IColumnCheckbox) => {
  //   setColumn(type, newItem);
  //   toggleChanged();
  // };

  const handleDrop = (
    item: DropParams<IColumn, IColumn>,
    prevItem: IColumn,
    nextItem: IColumn,
  ) => {
    const { dstData, srcData, position } = item;

    const isDstOrder = dstData ? dstData.order : null;

    const { id, ...column } = srcData;

    onConfigChange(srcData.id, {
      ...column,
      order: position === 'bottom' || position === 'right'
        ? getOrderBetween(isDstOrder || 'f', nextItem && nextItem?.order ? nextItem?.order : null)
        : getOrderBetween(prevItem && prevItem?.order ? prevItem?.order : null, isDstOrder || 'f'),
    });

    toggleChanged();
  };

  const groupingColumns = [...(config
    .filter((el) => grouping.includes(el.id))),
  ...(grouping.includes('oppName') ? [{
    isVisible: false,
    order: '1',
    id: 'oppName',
  }] : []),
  ]
    .filter((el) => el.id.toLowerCase().includes(search.toLowerCase())
      || checkboxesDictionary[el.id].toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => grouping.findIndex((i) => i === a.id) - grouping.findIndex((i) => i === b.id))
    .map((el, idx, arr) => (
      <DraggableCheckbox
        key={el.id}
        item={{
          ...el,
          isVisible: true,
        }}
        nextItem={arr[idx - 1]}
        prevItem={arr[idx + 1]}
        disabled
        draggable
        pinnable
        handleOnDrop={() => {}}
        handleChange={() => {}}
      />
    ));

  const handleChange = (val: IColumn, el: IColumn) => {
    const arrayToMoveInto = val.fix ? pinnedColumns : displayColumns;
    const newOrder = val.fix ? 'b' : 'c';
    const updatedItem = {
      ...val,
      order: val.isVisible
        ? getOrderStr(
          arrayToMoveInto.length,
          arrayToMoveInto[arrayToMoveInto.length - 1]?.order || newOrder,
        )
        : undefined,
    };
    onConfigChange(el.id, updatedItem);
    toggleChanged();
  };

  const getDraggableCheckbox = (el: IColumn, idx: number, arr: IColumn[], disabled: boolean) => (
    <DraggableCheckbox
      key={el.id}
      item={el}
      nextItem={arr[idx - 1]}
      prevItem={arr[idx + 1]}
      disabled={disabled}
      draggable={false}
      handleOnDrop={handleDrop}
      handleChange={(val: IColumn) => handleChange(val, el)}
      pinnable
    />
  );

  const columnsCheckboxes = col.filter((c) => !c.fix)
    .sort(sortCheckboxesByAlphabet)
    .map((el: IColumn, idx: number, arr: IColumn[]) => {
      if (numericColumnsIds.includes(el.id)) {
        return getDraggableCheckbox(el, idx, arr, grouping.includes(el.id));
      }

      const disabled = grouping.includes(el.id)
      || (el.id === 'prob' && !grouping.includes('oppName'))
      || (el.id === 'accName' && !grouping.some((i) => i === 'accName' || i === 'oppName'));

      return getDraggableCheckbox(el, idx, arr, disabled);
    });

  return (
    <div className={css.columns}>
      <div className={css.searchInputWrapper}>
        <TextInput
          icon={SearchIcon}
          placeholder="Search by column name"
          value={search}
          onValueChange={(val) => { setSearch(val || ''); }}
          size="30"
        />
      </div>

      <FlexRow size="24" alignItems="center">
        <Text cx={css.header}>Displayed in table</Text>
        <Badge
          caption={displayColumns.length + groupingColumns.length + pinnedColumns.length}
          size="18"
          rawProps={{
            style: {
              height: '1.125rem',
              width: '1.125rem',
            },
          }}
          color="gray30"
        />
      </FlexRow>
      <div className={css.checkboxes}>
        {groupingColumns}
        {pinnedColumns.map((el, idx, arr) => (
          <DraggableCheckbox
            key={el.id}
            item={el}
            nextItem={arr[idx - 1]}
            prevItem={arr[idx + 1]}
            disabled={grouping.includes(el.id)}
            draggable={!grouping.includes(el.id)}
            pinnable
            handleOnDrop={handleDrop}
            handleChange={(newVal: IColumn) => {
              const minOrderValue = 'c';
              let firstInListOrder = 'ca';
              let newOrderValue = getOrderBetween(minOrderValue, firstInListOrder);
              
              if (newOrderValue >= firstInListOrder) {
                let newArr = _.cloneDeep(displayColumns);
                newArr.forEach((item) => {
                  item.order = moveOrderToNextStep(item.order || '');
                });
                firstInListOrder = displayColumns[0]?.order!;

                newOrderValue = !Number.isNaN(firstInListOrder)
                  ? firstInListOrder
                  : getOrderBetween(firstInListOrder.at(0)!, firstInListOrder);

                const updatedItem = {
                  ...newVal,
                  order: !newVal.fix
                    ? newOrderValue
                    : getNewOrder(newVal, arr),
                };

                newArr = [updatedItem, ...newArr];
                newArr.forEach((item) => {
                  onConfigChange(item.id, item);
                });
                toggleChanged();
                return;
              }

              const updatedItem = {
                ...newVal,
                order: !newVal.fix
                  ? newOrderValue
                  : getNewOrder(newVal, arr),
              };
              onConfigChange(el.id, updatedItem);
              toggleChanged();
            }}
          />
        ))}
        <div style={{
          borderBottom: '1px solid #D9D9D9',
        }}
        />
        {displayColumns
          .filter((el) => !grouping.includes(el.id) && !el.fix)
          .filter((el) => el.id.toLowerCase().includes(search.toLowerCase())
            || checkboxesDictionary[el.id].toLowerCase().includes(search.toLowerCase()))
          .map((el, idx, arr) => (
            <DraggableCheckbox
              key={el.id}
              item={el}
              nextItem={arr[idx - 1]}
              prevItem={arr[idx + 1]}
              disabled={grouping.includes(el.id)}
              draggable={!grouping.includes(el.id)}
              pinnable
              handleOnDrop={handleDrop}
              handleChange={(newVal: IColumn) => {
                const updatedItem = {
                  ...newVal,
                  order: newVal.fix
                    ? getOrderBetween(pinnedColumns[pinnedColumns.length - 1]?.order || 'b', 'c')
                    : getNewOrder(newVal, displayColumns),
                };

                onConfigChange(el.id, updatedItem);
                toggleChanged();
              }}
            />
          ))}
      </div>

      <FlexRow alignItems="center">
        <Text cx={css.header}>Hidden in table</Text>
        <Badge
          caption={
            columnsCheckboxes.length + oppCols.length + accCols.length
          }
          size="18"
          rawProps={{
            style: {
              height: '1.125rem',
              width: '1.125rem',
            },
          }}
          color="gray30"
        />
      </FlexRow>

      {columnsCheckboxes}
      {!!accCols.length && (
        <ColumnAccordition
          items={accCols}
          onChange={(key, val) => {
            const arrayToMoveInto = val.fix ? pinnedColumns : displayColumns;
            const newOrder = val.fix ? 'b' : 'c';
            const updatedItem = {
              ...val,
              order: val.isVisible
                ? getOrderStr(
                  arrayToMoveInto.length - 1,
                  arrayToMoveInto[arrayToMoveInto.length - 1]?.order || newOrder,
                )
                : undefined,
            };
            onConfigChange(key, updatedItem);
            toggleChanged();
          }}
          disabled={!grouping.includes('oppName') && !grouping.includes('accName')}
          label="Account Attributes"
          isIndeterminate={accAttrIds.some((el) => displayColumns.some((i) => i.id === el)
            || pinnedColumns.some((i) => i.id === el))}
        />
      )}
      {!!oppCols.length && (
        <ColumnAccordition
          items={oppCols}
          disabled={!grouping.includes('oppName')}
          onChange={(key, val) => {
            const arrayToMoveInto = val.fix ? pinnedColumns : displayColumns;
            const newOrder = val.fix ? 'b' : 'c';
            const updatedItem = {
              ...val,
              order: val.isVisible
                ? getOrderStr(
                  arrayToMoveInto.length - 1,
                  arrayToMoveInto[arrayToMoveInto.length - 1]?.order || newOrder,
                )
                : undefined,
            };
            onConfigChange(key, updatedItem);
            toggleChanged();
          }}
          label="Opportunity Attributes"
          isIndeterminate={oppAttrIds.some((el) => displayColumns.some((i) => i.id === el))}
        />
      )}
    </div>
  );
};

export default memo(ColumnsSettings);
