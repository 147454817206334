/* eslint-disable react/destructuring-assignment */
import React, { FC, useState } from 'react';
import { IModal, useLazyDataSource } from '@epam/uui-core';
import {
  ModalBlocker, ModalWindow, FlexSpacer, ModalHeader, FlexRow, LabeledInput,
  Button, ScrollBars, ModalFooter, useForm, Panel, FlexCell, PickerInput,
} from '@epam/promo';
import { ReactComponent as loadingIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import { useNotification } from '../../../hooks/useNotification';
import { viewsAPI } from '../../../api/views';
import { fetchAdvancedFilterOptions } from '../../../api/filters';
import css from './SharePresetModal.module.scss';
import useUserProfile from '../../../hooks/useUserProfile';

type TSharingModalFieldsData = {
  team?: Array<string>,
  user?: Array<string>,
};

interface IUserItem {
  id: string,
  name: string,
}

interface SharePresetModalProps extends IModal<TSharingModalFieldsData> {
  presetId: string,
  presetName: string,
}

const SharePresetModal:FC<SharePresetModalProps> = (modalProps) => {
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useUserProfile();

  const userDataSource = useLazyDataSource({
    api: ({ search }) => fetchAdvancedFilterOptions('user', search)
      .then((res) => ({ items: res.items.filter((el: IUserItem) => el.id !== userId) })),
    selectAll: false,
  }, []);

  const teamDataSource = useLazyDataSource({
    api: ({ search }) => fetchAdvancedFilterOptions('team', search),
    selectAll: false,
  }, []);

  const { lens, save } = useForm<TSharingModalFieldsData>({
    value: {},
    onSave: (data) => {
      setIsLoading(true);

      return viewsAPI.shareView(
        modalProps.presetId,
        data.user ?? null,
        data.team ?? null,
      ).then(() => {
        setIsLoading(false);
        showNotification('View is shared', undefined, 'green');
        modalProps.success(data);
      })
        .catch((error) => {
          setIsLoading(false);
          showNotification(
            error.response?.data?.message
              ? error.response.data.message
              : 'View cannot be shared',
            undefined,
            'red',
          );
        });
    },
    onSuccess: (data) => modalProps.success(data),
    beforeLeave: null,
    getMetadata: (state: TSharingModalFieldsData) => ({
      props: {
        team: { isRequired: !state.user },
        user: { isRequired: !state.team },
      },
    }),
  });

  return (
    <ModalBlocker {...modalProps} abort={() => modalProps.abort()} disallowClickOutside>
      <ModalWindow>
        <ModalHeader
          cx={css.modalHeader}
          borderBottom
          title={`Share Preset "${modalProps.presetName}"`}
          onClose={() => modalProps.abort()}
        />
        <ScrollBars>
          <Panel cx={css.formContentWrapper}>
            <FlexRow padding="24" vPadding="12">
              <FlexCell grow={1}>
                <LabeledInput
                  cx={css.fieldLabel}
                  label="Users"
                  {...lens.prop('user').toProps()}
                >
                  <PickerInput
                    {...lens.prop('user').toProps()}
                    valueType="id"
                    dataSource={userDataSource}
                    selectionMode="multi"
                  />
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            <FlexRow padding="24" vPadding="12">
              <FlexCell grow={1}>
                <LabeledInput
                  cx={css.fieldLabel}
                  label="Teams"
                  {...lens.prop('team').toProps()}
                >
                  <PickerInput
                    {...lens.prop('team').toProps()}
                    valueType="id"
                    dataSource={teamDataSource}
                    selectionMode="multi"
                  />
                </LabeledInput>
              </FlexCell>
            </FlexRow>
          </Panel>
        </ScrollBars>
        <ModalFooter borderTop>
          <FlexSpacer />
          <Button 
            color="gray50" 
            fill="white" 
            onClick={() => modalProps.abort()} 
            caption="Cancel" 
            isDisabled={isLoading} 
          />
          <Button 
            color="green" 
            caption="Send" 
            onClick={save} 
            isDisabled={isLoading} 
            icon={isLoading ? loadingIcon : undefined}
          />
        </ModalFooter>
        <FlexSpacer />
      </ModalWindow>
    </ModalBlocker>
  );
};

export default SharePresetModal;
