import React, { useEffect } from 'react';
import { FlexRow, IconButton, Text } from '@epam/promo';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ReactComponent as NextIcon } from '@epam/assets/icons/common/navigation-chevron-right-18.svg';
import { ReactComponent as PrevIcon } from '@epam/assets/icons/common/navigation-chevron-left-18.svg';

import css from './ExportDatePicker.module.scss';

interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  year: number,
}

export const DatePickerHeader = (props: DatePickerHeaderProps) => {
  const {
    date, year, increaseYear, decreaseYear, changeYear,
  } = props;

  useEffect(() => {
    changeYear(year);
  }, [year]);

  return (
    <FlexRow cx={css.yearControlsContainer}>
      <IconButton
        cx={css.yearControlsBtn}
        color="blue"
        icon={PrevIcon}
        onClick={() => decreaseYear()}
      />
      <Text cx={css.year}>{date.getFullYear()}</Text>
      <IconButton cx={css.yearControlsBtn} color="blue" icon={NextIcon} onClick={() => increaseYear()} />
    </FlexRow>
    
  );
};
