import api from './api';

export interface IAuditRequestBody {
  opportunityId: string,
  range: {
    from: string | null,
    to: string | null,
  },
  filter: Array<string> | null,
  search: string
  take: number,
  skip: number,
}

interface IAuditChange {
  fieldName: string,
  oldValue: string,
  oldValueComment: string,
  newValue: string,
  newValueComment: string,
}

export interface IAuditData {
  id: string,
  userId: string,
  userName: string,
  actedOn: string,
  actionType: 'update' | 'create',
  appName: 'Pipeline' | 'CRM',
  changes: Array<IAuditChange>
}

const API_AUDIT = '/audit';

const fetchAudit = (reqBody: IAuditRequestBody) => api
  .post<Array<IAuditData>>(
  API_AUDIT,
  reqBody,
).then(({ data }) => ({
  items: data.map((item) => ({
    ...item,
    id: `${item.id}${item.userId}${item.actedOn}`,
  })), 
}));

export default fetchAudit;
