import React from 'react';
import { Text, Tooltip } from '@epam/promo';
import classNames from 'classnames';
import css from './OneCrmGrid.module.scss';

interface OneCrmGridCellProps {
  value: number | null;
  isChanged?: boolean;
  isBold?: boolean;
}

const formatValue = (val: number | null): string | null => {
  if (typeof val === 'number') {
    if (val?.toString().includes('.')) {
      return `${val?.toFixed(2)}`;
    }
    return `${val}`;
  }
  return null;
};

const OneCrmGridCell = ({ value, isChanged, isBold }: OneCrmGridCellProps) => {
  const formatedValue = formatValue(value);
  return (
    <Tooltip content={formatedValue && formatedValue.length > 4 ? formatedValue : ''}>
      <Text
        fontSize="10"
        cx={classNames({
          [css.value]: true,
          [css.valueChanged]: isChanged,
          [css.valueBold]: isBold,
        })}
      >
        {formatedValue}
      </Text>
    </Tooltip>
  );
};

export default OneCrmGridCell;
