import { atom } from 'recoil';

interface IUserProfile {
  canEdit: boolean,
  roles: {
    name: string
  }[],
  userId: string,
  userSettings: {
    dateFormatString: string,
    timeFormatString: string,
    timeZoneBias: 0
  }
  businessUnitId: string,
  upsaEmployeeId: string,
  canImportActuals: boolean,
}

const userProfileAtom = atom<IUserProfile>({
  key: 'userProfileStore',
  default: {
    canEdit: true,
    roles: [],
    userId: '',
    userSettings: {
      dateFormatString: '',
      timeFormatString: '',
      timeZoneBias: 0,
    },
    businessUnitId: '',
    upsaEmployeeId: '',
    canImportActuals: false,
  },
});

export default userProfileAtom;
