import * as React from 'react';
import {
  DataRowProps, 
  DataSourceListProps, 
  DropdownBodyProps, 
  isMobile,
} from '@epam/uui-core';
import {
  PickerBodyBaseProps,
  PickerInputBaseProps, usePickerInput, 
} from '@epam/uui-components';
import {
  DataPickerRow, 
  PickerItem, 
  DataPickerBody, 
  DataPickerFooter, 
} from '@epam/promo'; 
import { PickerInputProps } from '@epam/uui';

const pickerWidth = 360;
interface Props {
  setIsShowSelected?: (val: boolean) => void,
  containerCx?: string;
}

const pickerHeight = 200;

type FilterPickerBodyProps<TItem, TId> = DropdownBodyProps & PickerInputBaseProps<TItem, TId> & {
  showSearch?: boolean;
} & Props;

export function FilterPickerBody<TItem, TId>(props: FilterPickerBodyProps<TItem, TId>) {
  const shouldShowBody = () => !!props.isOpen;

  const {
    getName,
    getRows,
    dataSourceState,
    getFooterProps,
    getPickerBodyProps,
    getListProps,
  } = usePickerInput<TItem, TId, PickerInputProps>({ ...props, shouldShowBody });

  const renderItem = (
    item: TItem & { name?: string; },
    rowProps: DataRowProps<TItem, TId>,
  ) => <PickerItem title={getName(item)} size="36" {...rowProps} />;

  const renderRow = (rowProps: DataRowProps<TItem, TId>) => (props.renderRow ? (
    props.renderRow(rowProps, dataSourceState)
  ) : (
    <DataPickerRow {...rowProps} key={rowProps.rowKey} borderBottom="none" size="36" padding="12" renderItem={renderItem} />
  ));

  const renderFooter = () => (
    <DataPickerFooter
      {...getFooterProps()}
      view={{
        ...getFooterProps().view,
        selectAll: Number(getListProps().knownRowsCount) < 2100 && !getRows()[0]?.rowKey.includes('loading')
          ? getFooterProps().view.selectAll 
          : undefined,
      }}
      size="36"
      showSelected={{
        ...getFooterProps().showSelected,
        onValueChange: (val) => {
          props?.setIsShowSelected?.(val);
          getFooterProps().showSelected.onValueChange(val);
        },
      }}
    />
  );

  const renderBody = (bodyProps: DataSourceListProps & Omit<PickerBodyBaseProps, 'rows'>, rows: DataRowProps<TItem, TId>[]) => {
    const renderedDataRows = rows.map((rowProps) => renderRow(rowProps));
    const maxHeight = isMobile() 
      ? document.documentElement.clientHeight 
      : props.dropdownHeight || pickerHeight;
    const maxWidth = isMobile() ? undefined : pickerWidth;

    return (
      <div className={props.containerCx}>
        <DataPickerBody
          {...bodyProps}
          selectionMode={props.selectionMode}
          rows={renderedDataRows}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          searchSize="36"
          editMode="dropdown"
          searchDebounceDelay={500}
        />
        {renderFooter()}
      </div>
    );
  };

  const rows = getRows();

  return renderBody({
    ...getPickerBodyProps(rows),
    ...getListProps(),
  }, rows);
}
