export const pickerItems = [
  { name: '%', id: 'prob' },
  { name: 'Continuum Offerings', id: 'oppContinuumOfferings' },
  { name: 'Currency', id: 'currName' },
  { name: 'Description', id: 'oppDescription' },
  { name: 'Engagement Type', id: 'oppEngagementType' },
  { name: 'FRCST/% Updated By', id: 'modBy' },
  { name: 'FRCST/% Updated On', id: 'modDate' },
  { name: 'Linked Projects', id: 'projstr' },
  { name: 'Lead Practice', id: 'oppLeadPractice' },
  { name: 'Opportunity AM', id: 'oppAM' },
  { name: 'Opportunity Created By', id: 'oppCreatedBy' },
  { name: 'Opportunity Created On', id: 'oppCreatedOn' },
  { name: 'Opportunity Status', id: 'oppState' },
  { name: 'Partner Accounts', id: 'oppPartnerAccounts' },
  { name: 'Platforms', id: 'oppPlatforms' },
  { name: 'Practices', id: 'oppPractices' },
  { name: 'Revenue Type', id: 'RevenueTypeName' },
  { name: 'Outdated', id: 'oppOutdated' },
  { name: 'Low Probability', id: 'oppUnexpectedProbability' },
  { name: 'Flag', id: 'oppTagFlag' },
  { name: 'GTTL', id: 'gttl' },
  { name: 'YTD', id: 'ytd' },
  { name: 'FRCST', id: 'frcst' },
  { name: 'Primary Project', id: 'oplPrimaryProject' },
  { name: 'Show Actuals', id: 'oplShowActuals' },
  { name: 'Show UPSA', id: 'oplShowUPSA' },
  { name: 'Project name', id: 'oplProjectName' },
  { name: 'Staffing Code', id: 'solStaffingCode' },
  { name: 'Status', id: 'solStatusCode' },
  { name: 'Externally Updated', id: 'solExternallyUpdated' },
  { name: 'Deal Type', id: 'oppDealType' },
  { name: 'Commercial Model', id: 'commercialModelCode' },
  { name: 'Deal is Partner Related', id: 'dealIsPartnerRelated' },
  { name: 'Operational Labels', id: 'oppTags' },
  { name: 'GTTL (Current FY), 1K', id: 'oppGttlCY' },
  { name: 'GTTL (Next FY), 1K', id: 'oppGttlNY' },
];

export const flagsData: { [key: string]: number } = {
  Red: 895450001,
  Yellow: 895450002,
  Green: 895450003,
  Empty: 895450004,
};
