import React, { FC } from 'react';

interface Props {
  fill: string,
}

const MarkUpdatedIcon: FC<Props> = ({ fill }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.625 11.75L12.875 15.5L10.25 12.875L11.375 11.75L12.875 13.25L15.5 10.625L16.625 11.75ZM8.825 13.925C8.525 14 8.3 14 8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 8.3 14 8.525 13.925 8.825C14.45 8.9 14.9 9.05 15.35 9.275C15.425 8.825 15.5 8.45 15.5 8C15.5 3.875 12.125 0.5 8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C8.45 15.5 8.9 15.425 9.275 15.35C9.05 14.975 8.9 14.45 8.825 13.925ZM10.7 9.575L8.375 8.225V4.25H7.25V8.75L9.875 10.325C10.1 10.025 10.4 9.8 10.7 9.575Z" fill={fill} />
  </svg>
);

export default MarkUpdatedIcon;
