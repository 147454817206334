import React, { useState, FC } from 'react';
import { useRecoilValue } from 'recoil';
import cx from 'classnames';
import {
  FlexRow, IconButton, LinkButton,
} from '@epam/promo';
import { ReactComponent as UnfoldedIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import { ReactComponent as FoldedIcon } from '@epam/assets/icons/common/navigation-chevron-up-18.svg';
import { configState } from '../../../../providers/ConfigProvider';
import { IAuditData } from '../../../../api/audit';
import { flagsData, pickerItems } from './Fields';
import css from './AuditTab.module.scss';
import useUserProfile from '../../../../hooks/useUserProfile';
import FlagIcon from '../../../../assets/components/flagIcon';

const AuditItem:FC<Omit<IAuditData, 'id'>> = ({
  userId, userName, actedOn, actionType, changes, appName,
}) => {
  const config = useRecoilValue(configState);
  const { formatDate } = useUserProfile();
  const [isFolded, setIsFolded] = useState(true);

  const renderValue = (fieldName: string, value: string, isNewValue: boolean) => {
    if (fieldName === 'oppTagFlag') {
      const renderFlagIcon = () => <FlagIcon color={flagsData[value]} />;
      return (
        <IconButton icon={renderFlagIcon} />
      );
    }
    let formattedValue = value;
    if (
      fieldName === 'oppCreatedOn'
      || fieldName === 'modDate'
      || fieldName === 'oppTargetCloseDate'
      || fieldName === 'estimatedCloseDate'
      || fieldName === 'closeDate'
    ) {
      formattedValue = formatDate(value, { isAuditUTC: true });
    }
    return (
      <>
        <span className={isNewValue ? '' : cx(css.lightText, css.lineThroughText)}>
          {formattedValue}
        </span>
        &nbsp;&nbsp;&nbsp;
      </>
    );
  };

  return (
    <div className={cx(css.auditItem, { [css.auditItemNotFolded]: !isFolded })}>
      <FlexRow cx={css.header} onClick={() => setIsFolded(!isFolded)}>
        <IconButton icon={isFolded ? UnfoldedIcon : FoldedIcon} />
        <div className={css.headerContent}>
          <div>
            <LinkButton
              caption={userName}
              href={`${config?.baseCrmUrl}main.aspx?etn=systemuser&pagetype=entityrecord&id=${userId}`}
              target="_blank"
              size="24"
              cx={css.link}
            />
            &nbsp;
            <span>{`${actionType}d ${changes.length} changes on ${appName}`}</span>
          </div>
          <span className={css.lightText}>
            {formatDate(actedOn)}
          </span>
        </div>
      </FlexRow>
      {
        !isFolded && (
        <div className={css.content}>
          {
            changes.map(({
              fieldName, oldValue, oldValueComment, newValue, newValueComment, 
            }) => (
              <FlexRow key={`${fieldName}${newValue}`} cx={cx(css.contentRow, { [css.contentRowTwoCols]: actionType === 'create' })}>
                <div>{pickerItems.find((elem) => elem.id === fieldName)?.name ?? fieldName}</div>
                {
                  oldValue
                  && (
                    <div>
                      {renderValue(fieldName, oldValue, false)}
                      {
                        oldValueComment
                        && (
                          <span className={cx(css.lightText, css.lineThroughText, css.smallText)}>
                            (
                              {oldValueComment}
                            )
                          </span>
                        )
                      }
                    </div>
                  )
                }
                <div>
                  {renderValue(fieldName, newValue, true)}
                  {
                    newValueComment
                    && (
                      <span className={cx(css.smallText)}>
                        (
                          {newValueComment}
                        )
                      </span>
                    )
                  }
                </div>
              </FlexRow>
            ))
          }
        </div>
        )
      }
    </div>
  );
};

export default AuditItem;
