import React, { FC, useEffect } from 'react';
import { IconButton } from '@epam/promo';
import { useUuiContext } from '@epam/uui-core';
import { useRecoilState } from 'recoil';
import NotesModal from '../../../modals/NotesModal/NotesModal';
import { OppEmptyNotesIcon, OppNotesIcon } from '../../../../assets/components/OppNotesIcon';
import css from '../VirtualList.module.scss';
import oppTagsFlagNotesAtom from '../../../../state/atoms/oppTagsFlagNotesAtom';

interface Props {
  oppId?: string,
  hasNotes?: number,
  oppName?: string,
}

const OppNotesButton: FC<Props> = ({ oppId, hasNotes, oppName }) => {
  const [state, setState] = useRecoilState(oppTagsFlagNotesAtom);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      [oppId!]: {
        ...prev[oppId!],
        hasNotes: !!hasNotes,
      },
    }));
  }, []);

  const isNotesFilled = state?.[oppId!]?.hasNotes || !!hasNotes;
  const { uuiModals } = useUuiContext();

  const handleNotesIconClick = () => uuiModals.show((modalProps: any) => (
    <NotesModal
      oppId={oppId}
      oppName={oppName}
      setIsNotesFilled={(val) => {
        setState((prev) => ({
          ...prev,
          [oppId!]: {
            ...prev[oppId!],
            hasNotes: val,
          },
        }));
      }}
      {...modalProps}
    />
  ));

  return (
    <IconButton
      icon={isNotesFilled ? OppNotesIcon : OppEmptyNotesIcon}
      onClick={handleNotesIconClick}
      cx={css.noteIconBtn}
    />
  );
};

export default OppNotesButton;
