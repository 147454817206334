import React, { FC, useState, useEffect } from 'react';
import cx from 'classnames';
import {
  FlexRow, IconButton, IconContainer, Text, Tooltip,
} from '@epam/promo';
import {
  DndActor, DndActorRenderParams, DropParams, IColumnConfig, getOrderBetween,
} from '@epam/uui-core';
import { ReactComponent as arrowUpIcon } from '@epam/assets/icons/common/table-sort_asc-18.svg';
import { ReactComponent as arrowDownIcon } from '@epam/assets/icons/common/table-sort_desc-18.svg';
import { ReactComponent as collapseIcon } from '@epam/assets/icons/common/navigation-chevron-up-24.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import sortingAtom from '../../../../state/atoms/sortingAtom';
import css from '../VirtualList.module.scss';
import {
  accAttrColumnIds, generalColumns,
  getActiveColumnsForPreset, getConfigArr, numericColumnsIds,
  oppAttrColumnIds, sortCheckboxesByOrder,
} from '../../../../const/COLUMNS';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';
import defaultViewPresetSelector from '../../../../state/selectors/defaultViewPresetSelector';
import { useViewPresets } from '../../../../hooks/useViewPresets';
import { foldedAtom } from '../../../../state/atoms/foldedAtom';
import { viewStateAtom } from '../../../../state/atoms/viewStateAtom';
import { ISortingItem } from '../../../../api/query';
import { View } from '../../../../api/views';

type IColumn = IColumnConfig & { id: string };
interface Props {
  caption: string,
  column: string,
  disableSorting?: boolean,
}

const ColumnHeader: FC<Props> = ({ caption, column, disableSorting }) => {
  const [viewState, setViewState] = useRecoilState(viewStateAtom);
  const [sortingStore, setSortingStore] = useRecoilState(sortingAtom);
  const [{ filtersStore }, setQueryParams] = useRecoilState(queryParamsAtom);
  const { mode } = useRecoilValue(viewModeAtom);
  const isAttr = accAttrColumnIds.includes(column) || oppAttrColumnIds.includes(column);
  const sortedData = sortingStore.filter((el) => el.columnName === column)[0];
  const yearPlus = (filtersStore.revenueYear < 0 && generalColumns.includes(column)) ? '+' : '';

  const { id } = useRecoilValue(defaultViewPresetSelector);
  const [foldedArr, setFoldedArr] = useRecoilState(foldedAtom);

  // for DnD
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  const { updateViewPresetInList } = useViewPresets();
  const [dndItem, setDndItem] = useState<any | null>(null);
  // needed to avoid multiple calls to queryApi
  const [updateFlag, setUpdateFlag] = useState(0);
  const [isBlockedDrop, setIsBlockedDrop] = useState(false);

  const configArr = getConfigArr(viewState.columnsConfig || {});
  const displayedCols: IColumn[] = getConfigArr(viewState?.columnsConfig || {})
    .filter((el) => el.isVisible)
    .sort(sortCheckboxesByOrder);

  useEffect(() => {
    setDndItem({
      id: column,
      ...viewState?.columnsConfig?.[column],
    });
  }, [JSON.stringify(viewState.columnsConfig)]);

  useEffect(() => {
    const updateColsOrder = async () => {
      setQueryParams((prev) => ({
        ...prev,
        columnsStore: {
          ...prev.columnsStore,
          ...getActiveColumnsForPreset(configArr),
        },
      }));

      if (defaultViewPreset.isViewAutosaved) {
        const updatedPreset = {
          ...defaultViewPreset,
          columnSettings: {
            ...defaultViewPreset.columnSettings,
            columns: getActiveColumnsForPreset(configArr),
          },
        };
        await updateViewPresetInList(updatedPreset);
      }
    };

    if (updateFlag > 0) {
      updateColsOrder();
    }
  }, [updateFlag]);

  const canAcceptDrop = () => {
    if (column === 'grouping') {
      return {
        left: false,
        right: false,
      };
    }
    setIsBlockedDrop(false);
    return {
      left: true,
      right: true,
    };
  };

  const onDrop = (params: DropParams<IColumn, IColumn>) => {
    const { position, srcData, dstData } = params;
    if (srcData.id === 'grouping') return;
    const isDstOrder = dstData ? dstData.order : null;

    const colIndex = params.dstData
      ? displayedCols.findIndex((el) => params.dstData?.id === el.id)
      : -1;
    const prevItem = params.dstData && colIndex > -1 && displayedCols[colIndex - 1]
      ? displayedCols[colIndex - 1]
      : undefined;
    const nextItem = params.dstData && colIndex > -1 && displayedCols[colIndex + 1]
      ? displayedCols[colIndex + 1]
      : undefined;

    setViewState((prev) => ({
      ...prev,
      columnsConfig: {
        ...prev.columnsConfig,
        [srcData.id]: {
          ...srcData,
          order: position === 'bottom' || position === 'right'
            ? getOrderBetween(isDstOrder || 'f', nextItem && nextItem?.order ? nextItem?.order : null)
            : getOrderBetween(prevItem && prevItem?.order ? prevItem?.order : null, isDstOrder || 'f'),
        },
      },
    }));
    setTimeout(() => {
      setUpdateFlag((prev) => prev + 1);
    });
  };

  const getIcon = () => {
    if (sortedData) {
      if (sortedData.direction) return arrowDownIcon;
      return arrowUpIcon;
    }
    return undefined;
  };

  const handleClick = async () => {
    if (disableSorting) return;
    if (mode === 'view') {
      let sort: ISortingItem[] = [];
      const getNewView = (newSort: ISortingItem[]): View => ({
        ...defaultViewPreset,
        columnSettings: {
          ...defaultViewPreset.columnSettings,
          sort: newSort,
        },
      });
      if (sortedData) {
        sort = sortingStore.map((el) => {
          if (el.columnName === column) {
            return {
              ...el,
              direction: el.direction === 1 ? 0 : 1,
            };
          }
          return el;
        });
        setSortingStore(sort);
        if (defaultViewPreset.isViewAutosaved) {
          await updateViewPresetInList(getNewView(sort));
        }
        return;
      }
      if (isAttr) {
        sort = [
          {
            columnName: 'grouping',
            direction: 0,
          },
          {
            columnName: column,
            direction: 0,
          },
        ];
        setSortingStore(sort);
        if (defaultViewPreset.isViewAutosaved) {
          await updateViewPresetInList(getNewView(sort));
        }
        return;
      }
      sort = [
        {
          columnName: 'grouping',
          direction: 0,
        },
        {
          columnName: column,
          direction: 0,
        },
      ];
      setSortingStore(sort);
      if (defaultViewPreset.isViewAutosaved) {
        await updateViewPresetInList(getNewView(sort));
      }
    }
  };

  const getHeaderClass = () => {
    if (mode === 'edit') return css.editModeColumnHeader;
    if (disableSorting) return css.noHoverHeader;
    return css.columnHeader;
  };

  return (
    <DndActor
      key={column}
      srcData={dndItem}
      dstData={dndItem}
      canAcceptDrop={canAcceptDrop}
      onDrop={onDrop}
      render={(params: DndActorRenderParams) => (

        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="hideTooltip">
          <div
            {...params.eventHandlers}
            onPointerLeave={(e) => {
              params.eventHandlers
            && params.eventHandlers.onPointerLeave
            && params.eventHandlers.onPointerLeave(e);
              setIsBlockedDrop(false);
            }}
            onPointerUp={(e) => {
              params.eventHandlers
            && params.eventHandlers.onPointerUp
            && params.eventHandlers.onPointerUp(e);
              setIsBlockedDrop(false);
            }}
            ref={params.ref}
            className={cx(
              params.isDraggedOut && css.draggedOutElement,
              params.isDragGhost && css.dragGhostElement,
            )}
          >
            <div className={isBlockedDrop ? `${css.blockedDrop}` : `${css.allowedDrop}`} />
            <FlexRow
              cx={!disableSorting ? `${css.columnHeaderRoot} ${numericColumnsIds.includes(column) || column === 'prob' ? css.numericColumnHeader : ''}` : undefined}
              rawProps={{
                style: {
                  paddingRight: mode === 'edit' && column === 'prob' ? '8px' : undefined,
                },
              }}
            >
              {column === 'grouping' && (
              <Tooltip content="Collapse All" placement="bottom-start">
                <IconButton
                  icon={collapseIcon}
                  isDisabled={!foldedArr.length}
                  rawProps={{
                    style: {
                      minWidth: '1.5rem',
                    },
                  }}
                  onClick={() => {
                    setFoldedArr([]);
                    window.localStorage.removeItem(id);
                    setViewState((prev) => ({
                      ...prev, scrollTo: { index: 0, behavior: 'smooth' },
                    }));
                  }}
                />
              </Tooltip>
              )}

              <FlexRow
                alignItems="center"
                onClick={handleClick}
                cx={getHeaderClass()}
              >
                <Text cx={css.columnCaption}>{`${caption.toUpperCase()}${yearPlus}`}</Text>
              </FlexRow>
              {!disableSorting && getIcon() && (
              <div style={{ width: '1.125rem' }}>
                <IconContainer
                  icon={getIcon()}
                  cx={
                    mode === 'edit'
                      ? css['sortingIcon-disabled']
                      : css.sortingIcon
                    }
                />
              </div>
              )}

            </FlexRow>
          </div>
        </div>
      )}
    />
  );
};

export default ColumnHeader;
