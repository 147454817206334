import { useRecoilValue } from 'recoil';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { configState } from '../providers/ConfigProvider';
import userProfileAtom from '../state/atoms/userProfileAtom';

const eventCache = new Map();

export const useAppInsight = () => {
  const config = useRecoilValue(configState);
  const { businessUnitId, upsaEmployeeId } = useRecoilValue(userProfileAtom);
  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: config?.appInsightConnectionString,
    },
  });

  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.data = envelope.data || [];
    envelope.data.businessUnitId = businessUnitId;
    envelope.data.upsaEmployeeId = upsaEmployeeId;
  
    if (envelope.baseData) {
      const url = envelope.data.name;

      const apmUrl = 'apm-sandbox.cloudapp.epam.com';

      if (envelope.baseData && envelope.baseData && envelope.baseData.name.includes(apmUrl)) {
        return false;
      }

      // Deduplication logic
      const eventKey = `${envelope.name}-${url}-${envelope.time}`;
      if (eventCache.has(eventKey)) {
        const lastTimestamp = eventCache.get(eventKey);
        const currentTimestamp = new Date().getTime();
        if (currentTimestamp - lastTimestamp < 100) { // 100ms threshold for duplicate events
          return false;
        }
      }
      eventCache.set(eventKey, new Date().getTime());

      // Clean up old entries in the cache
      eventCache.forEach((timestamp, key) => {
        if (new Date().getTime() - timestamp > 60000) { // 60 seconds
          eventCache.delete(key);
        }
      });
    }
  });

  return { reactPlugin, appInsights };
};
