import React, { FC } from 'react';
import { Button } from '@epam/promo';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import cx from 'classnames';
import css from '../GridHeader.module.scss';
import AddMonthLeftIcon from '../../../../assets/components/AddMonthLeftIcon';
import AddMonthRightIcon from '../../../../assets/components/AddMonthRightIcon';
import breakdownAtom from '../../../../state/atoms/breakdownAtom';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import { getLocalTime, viewStateAtom } from '../../../../state/atoms/viewStateAtom';
import { getBreakdownColumns } from '../../../../hooks/useVirtualListColumns';
import { IBreakdownItem } from '../../VirtualList/VirtualListColumns';

interface Props {
  isEditMode?: boolean,
}

const MonthsToolbar: FC<Props> = ({ isEditMode }) => {
  const setViewState = useSetRecoilState(viewStateAtom);
  const [monthsStore, setMonths] = useRecoilState(breakdownAtom);
  const { filtersStore } = useRecoilValue(queryParamsAtom);
  const isQuaterly = filtersStore.breakdown === 'Quarterly';
  const currYear = new Date().getFullYear();
  const { year: firstMonthYear, index: firstMonthNum } = monthsStore[0];

  const isPrevControlsDisabled = isEditMode && (
    firstMonthYear < currYear 
    || (firstMonthYear === currYear && firstMonthNum === 1)
  );

  const updateConfig = (newBreakdowns: IBreakdownItem[]) => {
    const newBreakdownCols = getBreakdownColumns(getLocalTime())(newBreakdowns, isQuaterly ? 'q' : 'm', 'view')
      .reduce((acc, el) => ({
        ...acc,
        [el.key]: {
          isVisible: true,
          width: el.width,
          order: el.key,
        },
      }), {});

    setViewState((prev) => ({
      ...prev,
      columnsConfig: {
        ...prev.columnsConfig,
        ...newBreakdownCols,
      },
    }));
  };

  const addNextMonth = () => {
    const res = [...monthsStore];
    const lastMonth = res[res.length - 1].index;
    const lastYear = res[res.length - 1].year;
    if (lastMonth === 12 || (isQuaterly && lastMonth === 4)) {
      const newBreakdowns: IBreakdownItem[] = [{
        index: 1,
        year: lastYear + 1,
        type: isQuaterly ? 'q' : 'm',
      }];
      res.push(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    const newBreakdowns: IBreakdownItem[] = [{
      index: lastMonth + 1,
      year: lastYear,
      type: isQuaterly ? 'q' : 'm',
    }];
    res.push(...newBreakdowns);
    updateConfig(newBreakdowns);
    return setMonths(res);
  };

  const addPrevMonth = () => {
    const res = [...monthsStore];
    const firstMonth = monthsStore[0].index;
    const firstYear = monthsStore[0].year;
    if (firstMonth === 1) {
      const newBreakdowns: IBreakdownItem[] = [{
        index: isQuaterly ? 4 : 12,
        year: firstYear - 1,
        type: isQuaterly ? 'q' : 'm',
      }];
      res.unshift(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    const newBreakdowns: IBreakdownItem[] = [{
      index: firstMonth - 1,
      year: firstYear,
      type: isQuaterly ? 'q' : 'm',
    }];
    res.unshift(...newBreakdowns);
    updateConfig(newBreakdowns);
    return setMonths(res);
  };

  const addNextQuater = () => {
    const res = [...monthsStore];
    const lastMonth = res[res.length - 1].index;
    const lastYear = res[res.length - 1].year;
    if (lastMonth % 3 === 1) {
      const newBreakdowns: IBreakdownItem[] = [
        {
          index: lastMonth + 1,
          year: lastYear,
          type: 'm',
        },
        {
          index: lastMonth + 2,
          year: lastYear,
          type: 'm',
        },
      ];
      res.push(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    if (lastMonth % 3 === 2) {
      const newBreakdowns: IBreakdownItem[] = [{
        index: lastMonth + 1,
        year: lastYear,
        type: 'm',
      }];
      res.push(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    if (lastMonth === 12) {
      const newBreakdowns: IBreakdownItem[] = [
        {
          index: 1,
          year: lastYear + 1,
          type: 'm',
        },
        {
          index: 2,
          year: lastYear + 1,
          type: 'm',
        },
        {
          index: 3,
          year: lastYear + 1,
          type: 'm',
        },
      ];
      res.push(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    const newBreakdowns: IBreakdownItem[] = [
      {
        index: lastMonth + 1,
        year: lastYear,
        type: 'm',
      },
      {
        index: lastMonth + 2,
        year: lastYear,
        type: 'm',
      },
      {
        index: lastMonth + 3,
        year: lastYear,
        type: 'm',
      },
    ];
    res.push(...newBreakdowns);
    updateConfig(newBreakdowns);
    return setMonths(res);
  };

  const addPrevQuater = () => {
    const res = [...monthsStore];
    const firstMonth = res[0].index;
    const firstYear = res[0].year;
    if (
      !(firstMonth % 3)
    ) {
      const newBreakdowns: IBreakdownItem[] = [
        {
          index: firstMonth - 2,
          year: firstYear,
          type: 'm',
        },
        {
          index: firstMonth - 1,
          year: firstYear,
          type: 'm',
        },
      ];
      res.unshift(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }
    if (firstMonth % 3 === 2) {
      const newBreakdowns: IBreakdownItem[] = [{
        index: firstMonth - 1,
        year: firstYear,
        type: 'm',
      }];
      res.unshift(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    if (firstMonth === 1) {
      const newBreakdowns: IBreakdownItem[] = [
        {
          index: 10,
          year: firstYear - 1,
          type: 'm',
        },
        {
          index: 11,
          year: firstYear - 1,
          type: 'm',
        },
        {
          index: 12,
          year: firstYear - 1,
          type: 'm',
        },
      ];
      res.unshift(...newBreakdowns);
      updateConfig(newBreakdowns);
      return setMonths(res);
    }

    const newBreakdowns: IBreakdownItem[] = [
      {
        index: firstMonth - 3,
        year: firstYear,
        type: 'm',
      },
      {
        index: firstMonth - 2,
        year: firstYear,
        type: 'm',
      },
      {
        index: firstMonth - 1,
        year: firstYear,
        type: 'm',
      },
    ];
    res.unshift(...newBreakdowns);
    updateConfig(newBreakdowns);
    return setMonths(res);
  };

  return (
    <div className={css.monthsPicker}>
      {!isQuaterly && (
        <Button 
          caption="Quarter"
          color="gray"
          fill="none"
          size="30"
          cx={cx(
            css.addMonthColBtn,
            isPrevControlsDisabled && css.disabledAddMonthColBtn,
          )}
          icon={AddMonthLeftIcon}
          onClick={addPrevQuater}
          isDisabled={isPrevControlsDisabled}
          rawProps={{ 'aria-label': 'add-prevQuater' }}
        />
      )}
      <Button 
        caption={isQuaterly ? 'Quarter' : 'Month'}
        color="gray"
        fill="none"
        size="30"
        cx={cx(
          css.addMonthColBtn,
          isPrevControlsDisabled && css.disabledAddMonthColBtn,
        )}
        icon={AddMonthLeftIcon}
        onClick={addPrevMonth}
        isDisabled={isPrevControlsDisabled}
        rawProps={{ 'aria-label': 'add-prevMonth' }}
      />
      <Button 
        caption={isQuaterly ? 'Quarter' : 'Month'}
        color="gray"
        fill="none"
        size="30"
        cx={css.addMonthColBtn}
        icon={AddMonthRightIcon}
        iconPosition="right"
        onClick={addNextMonth}
        rawProps={{ 'aria-label': 'add-nextMonth' }}
      />
      {!isQuaterly 
      && (
        <Button 
          caption="Quarter"
          color="gray"
          fill="none"
          size="30"
          cx={css.addMonthColBtn}
          icon={AddMonthRightIcon}
          iconPosition="right"
          onClick={addNextQuater}
          rawProps={{ 'aria-label': 'add-nextQuater' }}
        />
      )}
    </div>
  );
};

export default MonthsToolbar;
