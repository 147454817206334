import React, { useState } from 'react';
import { DataTable } from '@epam/promo';
import { useArrayDataSource } from '@epam/uui-core';
import { IOppRevenueHistoryResponseItem } from '../../../api/revenueHistory';
import { IProcessedItem, OppHistoryColumnsDictionary } from './OppHistoryColumns';

interface IOppHistoryGridProps {
  items: IProcessedItem[];
  year: number;
  isLoading: boolean;
}

export const BREAKDOWN_IDS = ['m1', 'm2', 'm3', 'm4', 'm5', 'm6', 'm7', 'm8', 'm9', 'm10', 'm11', 'm12'] as (keyof IOppRevenueHistoryResponseItem)[];

const COLUMNS_IDS = ['record', ...BREAKDOWN_IDS, 'sum', 'sumPlus'];

const OppHistoryGrid = (props: IOppHistoryGridProps) => {
  const { items, year, isLoading } = props;
  const columns = COLUMNS_IDS.map((el) => OppHistoryColumnsDictionary[el](year % 100));
  const dataSource = useArrayDataSource({
    items,
  }, [year, isLoading]);

  const [value, setValue] = useState({});

  const view = dataSource.useView(value, setValue);
  
  return (
    <DataTable
      {...view.getListProps()} 
      columns={columns}
      getRows={view.getVisibleRows}
      value={value}
      onValueChange={setValue}
    />
  );
};

export default OppHistoryGrid;
