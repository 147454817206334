import {
  Checkbox, FlexRow, IconButton, IconContainer, Tooltip, 
} from '@epam/promo';
import cx from 'classnames';
import React, { FC, memo } from 'react';
import { ReactComponent as DragNDropIcon } from '@epam/assets/icons/common/action-drag_indicator-18.svg';
import { ReactComponent as PinIcon } from '@epam/assets/icons/common/action-pin_on-18.svg';
import { ReactComponent as LockIcon } from '@epam/assets/icons/common/action-lock-fill-18.svg';
// import { ReactComponent as UnpinIcon } from '@epam/assets/icons/common/action-pin_off-18.svg';
import { IColumnConfig } from '@epam/uui-core';
import css from '../ColumnsSettings.module.scss';
import { checkboxesDictionary } from '../../../../../../const/COLUMNS';

type IColumn = IColumnConfig & { id: string };

interface Props {
  item: IColumn,
  onChange: (newItem: IColumn, isUnpin?: boolean) => void,
  draggable?: boolean;
  disabled?: boolean;
  pinnable?: boolean;
}

const ColumnCheckbox: FC<Props> = ({
  item, onChange, draggable, disabled, pinnable,
}) => {
  const {
    id, isVisible: show, 
  } = item;

  const handleChange = (newVal:boolean) => {
    onChange({
      ...item,
      isVisible: newVal,
      fix: !newVal ? undefined : item.fix,
    });
  };

  const getPinIcon = () => {
    if (disabled) return LockIcon;
    return PinIcon;
  };

  const getTooltip = () => {
    if (item.fix) return 'Unpin column';
    if (disabled) return 'You cannot unpin this column';
    return 'Pin column';
  };

  const getVisibleOnPin = () => {
    if (!item.isVisible) return true;
    return item.isVisible;
  };

  return (
    <div className={css.checkboxItem}>
      <FlexRow>
        {draggable && <IconContainer icon={DragNDropIcon} />}
        <Checkbox
          cx={cx(
            draggable && css.draggableCheckbox,
            !draggable && css.regularCheckbox,
            disabled && css.disabledCheckbox,
          )}
          label={checkboxesDictionary[id]}
          key={id}
          value={!!show}
          onValueChange={handleChange}
          isDisabled={disabled}
        />
      </FlexRow>
      {pinnable && (
        <Tooltip content={getTooltip()}>
          <IconButton
            icon={getPinIcon()}
            isDisabled={disabled}
            cx={item.fix || disabled ? css.pinnedCol : css.unpinnedCol}
            color={item.fix ? 'blue' : undefined}
            onClick={() => {
              onChange({
                ...item,
                isVisible: getVisibleOnPin(),
                fix: item.fix ? undefined : 'left', 
              }, !item.fix);
            }}
          />
        </Tooltip>
      )}
    </div>  
  );
};

export default memo(ColumnCheckbox);
