import { TQueryValuesKey } from '../api/query';

interface Grouping {
  id: TQueryValuesKey,
  value: string,
}

export enum Levels {
  one = 'levelOne',
  two = 'levelTwo',
  three = 'levelThree',
  four = 'levelFour',
}

export const accAttributes: Grouping[] = [
  { value: 'GBU', id: 'GBU' },
  { value: 'Account AM', id: 'accAM' },
  { value: 'Account SM', id: 'accSM' },
  { value: 'Digital SM', id: 'accDigitalSM' },
  { value: 'Reporting Country', id: 'accExtRepCountry' },
  { value: 'Reporting Industry', id: 'accExtRepInd' },
  { value: 'Account Status', id: 'accState' },
  { value: 'Global Account', id: 'globalAccName' },
  { value: 'GBU Portfolio', id: 'GBUPortfolio' },
  { value: 'GBU Regional', id: 'RegionalGBU' },
  { value: 'Acc Acquisition Name', id: 'accAcquisitionName' },
  { value: 'Supporting AM', id: 'accSuppAM' },
  { value: 'Supporting GBU', id: 'SuppGBU' },
  { value: 'Supporting GBU Portfolio', id: 'SuppGBUPortfolio' },
  { value: 'Supporting GBU Regional', id: 'SuppRegionalGBU' },
];

export const oppAttributes: Grouping[] = [
  { value: 'Probability', id: 'prob' },
  { value: 'Sales Stage', id: 'SS' },
  { value: 'Opportunity AM', id: 'oppAM' },
  { value: 'Opportunity SM', id: 'oppSM' },
  { value: 'Secondary SM', id: 'oppSecondarySM' },
  { value: 'Opportunity Status', id: 'oppState' },
  { value: 'Linked Projects', id: 'projstr' },
  { value: 'Stream', id: 'oppStream' },
  { value: 'Opp Acquisition Name', id: 'oppAcquisitionName' },
  { value: 'Advisory AM', id: 'oppAdvisoryAM' },
  { value: 'Engagement Type', id: 'oppEngagementType' },
  { value: 'Lead Practice', id: 'oppLeadPractice' },
  { value: 'Industry', id: 'oppIndustry' },
  { value: 'Partner Sales Manager', id: 'oppPartnerSalesManagers' },
  { value: 'Engagement Lead', id: 'oppEngagementLeads' },
];

export const multiGroupingAttributes: Grouping[] = [
  { value: 'Account', id: 'accName' },
  { value: 'Opportunity', id: 'oppName' },
  { value: 'Revenue Type', id: 'RevenueTypeName' },
  ...accAttributes,
  ...oppAttributes,
];

export const getGrouping = (value: TQueryValuesKey, acc: Grouping[]) => multiGroupingAttributes
  .forEach((grouping) => {
    if (grouping.id === value) acc.push(grouping);
  });
