import React from 'react';
import { FlexRow, Tooltip } from '@epam/promo';
import { probabilityDictionary } from '../../EditModeGrid/const';
import useUserProfile from '../../../hooks/useUserProfile';
import css from './OppHistoryModal.module.scss';
import { IProcessedItem } from './OppHistoryColumns';
import { getWidthForText } from '../../../helpers/getWidthForText';

interface OppHistoryRecordProps {
  item: IProcessedItem,
}

const OppHistoryRecord = (props: OppHistoryRecordProps) => {
  const { item } = props;
  const prob = Object.values(probabilityDictionary)
    .find((el) => el.id === item.probability);
  const { formatDate } = useUserProfile();

  return (
    <FlexRow cx={css.recordContainer} size="48">
      <div className={css.recordMainData}>
        <div>{formatDate(item.modifiedOn, { isWithSec: true })}</div>
        <Tooltip content={getWidthForText(item.modifiedByName) > 169 ? item.modifiedByName : ''}>
          <div className={css.name}>
            {item.modifiedByName}
          </div>
        </Tooltip>
      </div>
      <div className={css.recordData}>
        <div className={
          item.changedFields.includes('probability')
            ? css.recordDataChanged
            : css.recordData
          }
        >
          {prob?.name}
        </div>
        <div
          className={
            item.changedFields.includes('transactionCurrencyName')
              ? css.recordDataChanged
              : css.recordData
            }
          style={{
            textAlign: 'center',
          }}
        >
          {item.transactionCurrencyName}
        </div>
      </div>
    </FlexRow>
  );
};

export default OppHistoryRecord;
