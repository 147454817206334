import {
  Button, FlexRow, ModalBlocker, ModalHeader, ModalWindow, Panel,
} from '@epam/promo';
import React, { FC, useState } from 'react';
import { IModal } from '@epam/uui-core';
import { useRecoilValue } from 'recoil';
import NotesList from './components/NotesList';
import css from './NotesModal.module.scss';
import NotesForm, { IForm } from './components/NotesForm';
import userProfileAtom from '../../../state/atoms/userProfileAtom';

import { editNote, postNote } from '../../../api/notes';
import { useNotification } from '../../../hooks/useNotification';

interface Props extends IModal<string> {
  oppId: string,
  oppName: string,
  setIsNotesFilled: (value: boolean) => void,
}

const NotesModal: FC<Props> = ({
  oppId, oppName, setIsNotesFilled, ...modalProps 
}) => {
  const emptyForm: IForm = {
    oppId,
    subject: '',
    noteText: '',
  };

  const { abort } = modalProps;
  const [sorting, setSorting] = useState(0);
  const [form, setForm] = useState(emptyForm);
  const [isChanged, setIsChanged] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const profile = useRecoilValue(userProfileAtom);
  const isAdmin = !!profile.roles.filter((el) => el.name === 'System Administrator').length;

  const { showNotification } = useNotification();

  const handleSend = () => {
    const { subject, noteText } = form;
    
    setIsLoading(true);

    if (form.noteId) {
      editNote(form.noteId, {
        subject,
        noteText,
      })
        .then(() => {
          setForm(emptyForm);
          setIsChanged((prev) => prev + 1);
          showNotification('Note is set', undefined, 'green');
        })
        .catch(() => {
          setIsLoading(false);
          showNotification('Note cannot be set', undefined, 'red');
        });
      return;
    }
    
    postNote(oppId, {
      subject,
      noteText,
    })
      .then(() => {
        setForm(emptyForm);
        setIsChanged((prev) => prev + 1);
        showNotification('Note is set', undefined, 'green');
      })
      .catch(() => {
        setIsLoading(false);
        showNotification('Note cannot be set', undefined, 'red');
      });
  };

  return (
    <ModalBlocker {...modalProps}>
      <ModalWindow width="600" height="auto">
        <Panel background="white" cx={css.notesPanel}>
          <ModalHeader 
            cx={css.NotesModalHeader} 
            title={oppName} 
            onClose={() => abort()}
            borderBottom
          />
          <FlexRow cx={css.sortingBtnsRow}>
            <Button 
              caption="New on top" 
              fill={sorting ? 'white' : 'solid'} 
              size="24" 
              onClick={() => setSorting(0)}
            />
            <Button 
              caption="Old on top"
              fill={!sorting ? 'white' : 'solid'} 
              size="24" 
              onClick={() => setSorting(1)}
            />
          </FlexRow>
          <FlexRow cx={css.listContainer}>
            <NotesList 
              oppId={oppId} 
              isChanged={isChanged} 
              setIsChanged={setIsChanged} 
              isLoading={isLoading} 
              setIsLoading={setIsLoading}
              form={form}
              setForm={setForm}
              sorting={sorting}
              isAdmin={isAdmin}
              userId={profile.userId}
              setIsNotesFilled={setIsNotesFilled}
            />
          </FlexRow> 
          <NotesForm 
            initialSubject={form.subject ?? ''} 
            initialText={form.noteText ?? ''} 
            handleSend={handleSend}
            setForm={setForm}
            form={form}
            isLoading={isLoading}
          />
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default NotesModal;
