import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  IColumn, OmittedView, View, viewsAPI, 
} from '../api/views';
import { viewLoadingAtom, viewPresetsAtom } from '../state/atoms/viewPresetsAtom';
import currentGroupingIdsSelector from '../state/selectors/currentGroupingIdsSelector';
import queryParamsAtom, {
  setGroupingsFromPresetGroupsList, 
} from '../state/atoms/queryParamsAtom';
import { setFiltersFromPresetFiltersList } from '../const/FILTERS';
import defaultViewPresetSelector from '../state/selectors/defaultViewPresetSelector';
import sortedViewPresetsSelector from '../state/selectors/sortedViewPresetsSelector';
import { createInitialBreakdown } from '../state/atoms/breakdownAtom';
import { defaultView } from '../components/FiltersPanel/ViewPresetsMenu/const';
import updateAtomsForQuerySelector, { IAtoms } from '../state/selectors/updateAtomsForQuerySelector';
import sortingAtom, { initialSorting } from '../state/atoms/sortingAtom';
import settingsSlideoutAtom from '../state/atoms/settingsSlideoutAtom';
import advancedFiltersConstructorAtom from '../state/atoms/advancedFiltersConstructorAtom';
import { parseAdvancedFiltersFromView, parseAdvancedFiltersToView } from '../const/ADVANCED_FILTERS';
import viewModeAtom from '../state/atoms/viewModeAtom';
import {
  getConfigFromView, viewStateAtom,
  viewStateSnapshotAtom, 
} from '../state/atoms/viewStateAtom';
import { getColumnWidthFromDictionary } from './useVirtualListColumns';
import columnsSettingsAtom from '../state/atoms/columnsSettingsAtom';
import { listOfCheckboxes, setColumnsFromPresetColsList } from '../const/COLUMNS';
import { TQueryValuesKey } from '../api/query';

// TODO: Provide types for all 'any'

export const useViewPresets = () => {
  const { mode } = useRecoilValue(viewModeAtom);
  const setViewPresets = useSetRecoilState(viewPresetsAtom);
  const setViewModeAtom = useSetRecoilState(viewModeAtom);
  const viewPresets = useRecoilValue(sortedViewPresetsSelector);
  const currentGroupingIds = useRecoilValue(currentGroupingIdsSelector);
  const { columnsStore: currentColumnsIds } = useRecoilValue(queryParamsAtom);
  const [loading, setLoading] = useRecoilState(viewLoadingAtom);
  const [
    { filtersStore: currentFilters },
  ] = useRecoilState(queryParamsAtom);
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  const [, setAtoms] = useRecoilState(updateAtomsForQuerySelector);
  const [{ columnsConfig: config }, setViewState] = useRecoilState(viewStateAtom);
  const [
    { columnsConfig: snapshotConfig }, setViewStateSnapshot,
  ] = useRecoilState(viewStateSnapshotAtom);
  const { tagsFlagsNotes, warnings } = useRecoilValue(settingsSlideoutAtom);
  const [
    advancedFilters,
    setAdvancedFiltersConstructor,
  ] = useRecoilState(advancedFiltersConstructorAtom);
  const setColumnsSettingsStore = useSetRecoilState(columnsSettingsAtom);

  const { constructor: advancedFiltersItems } = advancedFilters;
  const sortingStore = useRecoilValue(sortingAtom);

  const updateViewState = (preset: View) => {
    const currentConfig = getConfigFromView(preset as View, mode === 'edit');
    setViewState((prev) => ({
      ...prev,
      columnsConfig: currentConfig,
    }));
    setViewStateSnapshot((prev) => ({
      ...prev,
      columnsConfig: currentConfig,
    }));
  };

  const getColumnWidth = (columnId: TQueryValuesKey): number => (
    config?.[columnId]?.width 
      ? Math.floor(config[columnId].width as number) 
      : getColumnWidthFromDictionary(columnId)
  );
  
  const setSettingsFromPreset = (preset: View | OmittedView) => {
    const { advancedFiltersSettings } = preset;
    const parsedAdvancedFilters = parseAdvancedFiltersFromView(advancedFiltersSettings);
    const { col, accAttr, oppAttr } = preset.columnSettings.columns;
    const defineVisible = (el: IColumn) => ({
      ...el,
      visible: !preset.multiGrouping.groups.includes(el.id),
    });
    const colWithVisible = col.map(defineVisible);
    const accAttrWithVisible = accAttr.map(defineVisible);
    const oppAttrWithVisible = oppAttr.map(defineVisible);
    
    const columns = {
      col: colWithVisible,
      accAttr: accAttrWithVisible,
      oppAttr: oppAttrWithVisible,
    };

    setAtoms((prev: IAtoms) => ({
      ...prev,
      queryParamsAtom: {
        filtersStore: {
          ...setFiltersFromPresetFiltersList(preset.permanentFiltersSettings),
          search: '',
        },
        groupingStore: {
          multiGrouping: setGroupingsFromPresetGroupsList(preset.multiGrouping.groups),
          tagsFlagsNotes: preset.multiGrouping.tagsFlagsNotes,
          warnings: preset.multiGrouping.warnings,
        },
        columnsStore: columns,
      },
      breakdownAtom: createInitialBreakdown(
        preset.permanentFiltersSettings.year, 
        preset.permanentFiltersSettings.breakdown,
      ),
      advancedFiltersConstructorAtom: {
        changeCounter: 0,
        isConstructor: !!parsedAdvancedFilters.children?.length
          || (parsedAdvancedFilters.operator === 'Or' && !!Object.keys(parsedAdvancedFilters.filters).length),
        constructor: [parsedAdvancedFilters],
        cache: [parsedAdvancedFilters],
      },
      sortingAtom: preset.columnSettings.sort.length
        ? preset.columnSettings.sort.map((el) => ({
          ...el,
          direction: el.direction === 'Descending' || el.direction === 1 ? 1 : 0,
        }))
        : initialSorting,
      searchAtom: '',
      settingsSlideoutAtom: {
        ...prev.settingsSlideoutAtom,
        tagsFlagsNotes: preset.multiGrouping.tagsFlagsNotes,
        warnings: preset.multiGrouping.warnings,
      },
    }));
    setColumnsSettingsStore(setColumnsFromPresetColsList(listOfCheckboxes, columns));
    setViewModeAtom((prev) => ({
      ...prev,
      isRefreshed: prev.isRefreshed + 1,
    }));
  };
  
  const currentFiltersSettings = {
    year: currentFilters.revenueYear,
    currency: currentFilters.currency,
    breakdown: currentFilters.breakdown,
    forecastGranularity: currentFilters.forecastGranularity,
    ownership: currentFilters.ownership,
    probabilityScenario: currentFilters.probabilityScenario,
    revenueScenario: currentFilters.revenueScenario,
  };

  // Complex types are needed to infer the type of callback function arguments
  const withLoading = <T extends (...args: any[]) => Promise<any>>
    (callback: T) => async (...args: Parameters<T>): Promise<void> => {
      setLoading(true);
      await callback(...args);
      setLoading(false);
    };

  // eslint-disable-next-line max-len
  const changeViewPresetField = async (preset: View, field: keyof View, newValue: string | boolean) => {
    let changedPresetIdx = 0;
    const newViewPresetList = viewPresets.map((currentPreset, index) => {
      if (currentPreset.id === preset.id) {
        changedPresetIdx = index;
        // if the field is complex object, you can handle each case here
        if (field === 'isViewAutosaved' && newValue) {
          return {
            ...currentPreset,
            columnSettings: {
              ...currentPreset.columnSettings,
              sort: sortingStore,
              columns: {
                ...currentPreset.columnSettings.columns,
                col: currentColumnsIds.col.map((el) => ({
                  ...el,
                  width: getColumnWidth(el.id),
                })),
                accAttr: currentColumnsIds.accAttr.map((el) => ({
                  ...el,
                  width: getColumnWidth(el.id),
                })),
                oppAttr: currentColumnsIds.oppAttr.map((el) => ({
                  ...el,
                  width: getColumnWidth(el.id),
                })),
              },
            },
            multiGrouping: {
              ...currentPreset.multiGrouping,
              width: getColumnWidth('grouping'),
              groups: currentGroupingIds,
            },
            permanentFiltersSettings: currentFiltersSettings,
            advancedFiltersSettings: parseAdvancedFiltersToView(advancedFiltersItems),
            ...(typeof newValue === 'boolean' && { isViewAutosaved: newValue }),
          };
        }
        if (field === 'isDefault' && typeof newValue === 'boolean') {
          return { ...currentPreset, [field]: newValue, isNew: false };
        }
        return { ...currentPreset, [field]: newValue };
      }
      if (field === 'isDefault' && newValue && currentPreset.isDefault) {
        return { ...currentPreset, isDefault: false };
      } 
      return currentPreset;
    });
    
    if (field !== 'isDefault') {
      await viewsAPI.updateView(preset.id, newViewPresetList[changedPresetIdx]);
    }

    setViewPresets(newViewPresetList);
  };

  const updateViewPresetInList = async (preset: View) => {
    await viewsAPI.updateView(preset.id, preset);
    setViewPresets((oldViewPresetsList) => [
      ...oldViewPresetsList.map((currentPreset) => {
        if (currentPreset.id === preset.id) {
          return preset;
        } 
        return currentPreset;
      }),
    ]);
  };

  const saveInCurrentView = async (preset: View) => {
    const updatedPreset = {
      ...preset,
      columnSettings: {
        ...preset.columnSettings,
        sort: sortingStore,
        columns: {
          ...preset.columnSettings.columns,
          col: currentColumnsIds.col.map((el) => ({
            ...el,
            width: getColumnWidth(el.id),
          })),
          accAttr: currentColumnsIds.accAttr.map((el) => ({
            ...el,
            width: getColumnWidth(el.id),
          })),
          oppAttr: currentColumnsIds.oppAttr.map((el) => ({
            ...el,
            width: getColumnWidth(el.id),
          })),
        },
      },
      multiGrouping: {
        ...preset.multiGrouping,
        width: getColumnWidth('grouping'),
        groups: currentGroupingIds,
        tagsFlagsNotes,
        warnings,
      },
      permanentFiltersSettings: currentFiltersSettings,
      advancedFiltersSettings: parseAdvancedFiltersToView(advancedFiltersItems),
    };

    await updateViewPresetInList(updatedPreset);
    setViewStateSnapshot((prev) => ({
      ...prev,
      columnsConfig: config,
    }));
  };

  const addNewPreset = async (name: string) => {
    const missingColumns = [];
    if (!currentColumnsIds.oppAttr.find((item) => item.id === 'oppTagFlag')) {
      missingColumns.push({ id: 'oppTagFlag', show: tagsFlagsNotes, order: 'yyy' });
    }
    if (!currentColumnsIds.oppAttr.find((item) => item.id === 'oppTags')) {
      missingColumns.push({ id: 'oppTags', show: tagsFlagsNotes, order: 'yyyy' });
    }
    if (!currentColumnsIds.oppAttr.find((item) => item.id === 'oppCorrections')) {
      missingColumns.push({ id: 'oppCorrections', show: warnings, order: 'yyyyy' });
    }
    const newPreset: OmittedView = {
      name,
      isViewAutosaved: defaultViewPreset.isViewAutosaved,
      gridType: 'RevenueForecast',
      columnSettings: {
        columns: {
          col: currentColumnsIds.col.map((el) => ({
            ...el,
            width: getColumnWidth(el.id),
          })),
          accAttr: currentColumnsIds.accAttr.map((el) => ({
            ...el,
            width: getColumnWidth(el.id),
          })),
          oppAttr: [
            ...currentColumnsIds.oppAttr,
            ...missingColumns,
          ].map((el) => ({
            ...el,
            width: getColumnWidth(el.id),
          })),
        },
        sort: sortingStore,
      },
      multiGrouping: {
        groups: currentGroupingIds,
        collapse: true,
        tagsFlagsNotes,
        warnings,
        width: getColumnWidth('grouping'),
      },
      permanentFiltersSettings: currentFiltersSettings,
      advancedFiltersSettings: parseAdvancedFiltersToView(advancedFiltersItems),
      state: 'Active',
    };

    const newId = await viewsAPI.saveNewView(newPreset);
    
    setViewPresets((oldViewPresetsList) => [
      ...oldViewPresetsList.map((preset) => {
        if (preset.isDefault) {
          return { ...preset, isDefault: false };
        } 
        return preset;
      }),
      {
        ...newPreset, id: newId, isDefault: true, isPersonalView: true, isNew: false, 
      },
    ]);
  };

  const deletePreset = async (preset: View) => {
    await viewsAPI.deleteView(preset.id);
    const newViewPresetList = viewPresets.filter(
      (currentPreset) => currentPreset.id !== preset.id,
    );
    if (preset.isDefault) {
      newViewPresetList[0] = { ...newViewPresetList[0], isDefault: true };
      await viewsAPI.markAsDefault(newViewPresetList[0].id);
      setSettingsFromPreset(newViewPresetList[0]);
      updateViewState(newViewPresetList[0]);
      setViewPresets(newViewPresetList);
    } else setViewPresets(newViewPresetList);
  };

  const applyPreset = async (preset: View) => {
    setSettingsFromPreset(preset);
    if (!preset.isDefault){
      changeViewPresetField(preset, 'isDefault', true);
      await viewsAPI.markAsDefault(preset.id);
    }

    updateViewState(preset);
  };

  const revertToLastSaved = (preset: View) => {
    setSettingsFromPreset(preset);
    setViewState((prev) => ({
      ...prev,
      columnsConfig: snapshotConfig,
    }));
  };

  const resetChanges = async (preset: View) => {
    const newPreset: OmittedView = {
      ...defaultView,
      name: preset.name,
    };

    await updateViewPresetInList({
      ...newPreset,
      id: preset.id,
      isDefault: preset.isDefault,
      isPersonalView: preset.isPersonalView,
      isNew: false,
    });
    
    if (preset.isDefault) {
      const newConstructor = [parseAdvancedFiltersFromView(newPreset.advancedFiltersSettings)];
      setAdvancedFiltersConstructor({
        changeCounter: 0,
        constructor: newConstructor,
        cache: newConstructor,
        isConstructor: false,
      });
      setSettingsFromPreset(newPreset);
      updateViewState(newPreset as View);
    }
  };

  const resetColumnsWidth = async (preset: View) => {
    const updatedPreset = {
      ...preset,
      columnSettings: {
        ...preset.columnSettings,
        columns: {
          ...preset.columnSettings.columns,
          col: preset.columnSettings.columns.col.map((el) => ({
            ...el,
            width: getColumnWidthFromDictionary(el.id),
          })),
          accAttr: preset.columnSettings.columns.accAttr.map((el) => ({
            ...el,
            width: getColumnWidthFromDictionary(el.id),
          })),
          oppAttr: preset.columnSettings.columns.oppAttr.map((el) => ({
            ...el,
            width: getColumnWidthFromDictionary(el.id),
          })),
        },
      },
      multiGrouping: {
        ...preset.multiGrouping,
        width: 400,
      },
    };

    if (preset.isViewAutosaved) {
      await updateViewPresetInList(updatedPreset);
      updateViewState(updatedPreset);
    } else {
      setViewState((prev) => ({
        ...prev,
        columnsConfig: getConfigFromView(updatedPreset as View, mode === 'edit'),
      }));
    }
  };

  const restoreResetedView = async (preset: View) => {
    await updateViewPresetInList(preset);
    if (preset.isDefault) {
      setSettingsFromPreset(preset);
      updateViewState(preset);
    }
  };

  const restoreDeletedView = async (preset: View, oldIndex: number) => {
    await viewsAPI.updateView(preset.id, preset);
    if (preset.isDefault) {
      await viewsAPI.markAsDefault(preset.id);
      setSettingsFromPreset(preset);
      updateViewState(preset);
    }

    setViewPresets((prev) => {
      const tempArray = preset.isDefault 
        ? prev.map((el) => ({ ...el, isDefault: false }))
        : [...prev];

      return [
        ...tempArray.slice(0, oldIndex),
        preset,
        ...tempArray.slice(oldIndex),
      ];
    });
  };

  const switchAutosave = async () => {
    await changeViewPresetField(defaultViewPreset, 'isViewAutosaved', !defaultViewPreset.isViewAutosaved);
  };

  const renamePreset = async (preset: View, newName: string) => {
    await changeViewPresetField(preset, 'name', newName);
  };
  
  return {
    viewPresets,
    loading,
    addNewPreset: withLoading(addNewPreset),
    deletePreset: withLoading(deletePreset),
    applyPreset: withLoading(applyPreset),
    resetChanges: withLoading(resetChanges),
    updateViewPresetInList: withLoading(updateViewPresetInList),
    restoreResetedView,
    restoreDeletedView,
    switchAutosave: withLoading(switchAutosave),
    renamePreset: withLoading(renamePreset),
    currentFiltersSettings,
    revertToLastSaved,
    saveInCurrentView: withLoading(saveInCurrentView),
    resetColumnsWidth: withLoading(resetColumnsWidth),
  };
};
