import api from './api';
import { ImportHistoryItemInfo } from './importHistory';

const BASE_URL = '/actual-revenue-import';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const importAPI = {
  async validate(file: FormData): Promise<ImportHistoryItemInfo> {
    const res = await api.post<ImportHistoryItemInfo>(
      `${BASE_URL}/validate`,
      file,
      { headers },
    );
    return res.data;
  },
  async upload(file: FormData): Promise<string> {
    const res = await api.post(
      `${BASE_URL}/upload`,
      file,
      { headers },
    );
    return res.data;
  },
};
