import { selector } from 'recoil';
import { View } from '../../api/views';
import { viewPresetsAtom } from '../atoms/viewPresetsAtom';

const sortedViewPresetsSelector = selector({
  key: 'sortedViewPresets',
  get: ({ get }) => {
    const presets = get(viewPresetsAtom);
    const presetsDeepCopy: Array<View> = JSON.parse(JSON.stringify(presets));
    return presetsDeepCopy.sort((a, b) => a.name.localeCompare(b.name));
  },
});

export default sortedViewPresetsSelector;
