import React from 'react';
import { Text } from '@epam/promo';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import css from './Loader.module.scss';

const Loader = () => (
  <div className={css.root}>
    <LoaderIcon />
    <Text cx={css.text}>Loading...</Text>
  </div>
);

export default Loader;
