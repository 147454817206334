import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import filtersOptionsAtom from '../../../state/atoms/filtersOptionsAtom';
import PermanentFilterPicker from './components/PermanentFilterPicker';
import { getPermanentFilters } from './const';
import viewModeAtom from '../../../state/atoms/viewModeAtom';

const PermanentFilters:FC = () => {
  const filtersOptions = useRecoilValue(filtersOptionsAtom);
  const filters = getPermanentFilters(filtersOptions);
  const { mode } = useRecoilValue(viewModeAtom);

  return (
    <>
      {filters.map(({
        key, listWidth, footer, title, label, items, dropdownHeight,
      }) => {
        let changedItems;
        if (label && footer) {
          changedItems = [{ id: 'label', name: label }, ...items, { id: 'footer', name: footer }];
        } else if (label) {
          changedItems = [{ id: 'label', name: label }, ...items];
        } else if (footer) {
          changedItems = [...items, { id: 'footer', name: footer }];
        } else {
          changedItems = items;
        }

        if (key === 'currency' && mode === 'edit') {
          return null;
        }

        return (
          <PermanentFilterPicker
            key={key}
            id={key}
            listWidth={listWidth}
            footer={footer}
            title={title}
            items={changedItems}
            label={label}
            dropdownHeight={dropdownHeight}
          />
        );
      })}
    </>
  );
};

export default PermanentFilters;
