import React from 'react';
import { Text } from '@epam/promo';
import img from '../../assets/svg/errors/oneCrmError.svg';
import css from './OneCrmErrorPage.module.scss';

export enum OneCrmErrorMessages {
  NoRevenueData = 'You do not have permissions to view revenue data',
  NoOppData = 'Opportunity is not available in Pipeline 2.0',
  _ = 'Unknown error.',
}

interface OneCrmErrorPageProps {
  msg: OneCrmErrorMessages,
}

const OneCrmErrorPage = (props: OneCrmErrorPageProps) => {
  const { msg } = props;

  return (
    <div className={css.root}>
      <img src={img} alt={msg} />
      <Text cx={css.text} font="museo-sans">{msg}</Text>
    </div>
  );
};

export default OneCrmErrorPage;
