import { ColumnsConfig, DataTableState } from '@epam/uui-core';
import { atom, selector } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';
import { getBreakdownColumns } from '../../hooks/useVirtualListColumns';
import { createInitialBreakdown } from './breakdownAtom';
import viewModeAtom from './viewModeAtom';
import { columnsDictionary } from '../../components/Grid/VirtualList/VirtualListColumns';
import { IColumn, View } from '../../api/views';
import { getConfigArr, sortCheckboxesByOrder } from '../../const/COLUMNS';

export interface TableDataSource extends DataTableState<any, any> {
  isFolded?: boolean,
}

export const getLocalTime = (): Dayjs => {
  const utcTime = new Date().toUTCString();
  return dayjs(utcTime);
};

const abc = 'abcdefghijklmnopqrstuvwx';

export const getOrderStr = (idx: number, accStr?: string): string => {
  const acc = accStr || '';
  if (idx > 22) {
    return getOrderStr(idx - 22, acc + abc[idx % 22]) as string;
  }
  return (acc + abc[idx]) as string;
};

export const getConfigFromView = (
  view: View, 
  isEdit: boolean, 
  prevView?: ColumnsConfig,
): ColumnsConfig => {
  const { columnSettings: { columns }, multiGrouping } = view;
  
  const breakdownType = view.permanentFiltersSettings.breakdown === 'Monthly' ? 'm' : 'q';
  const breakdownCols = getBreakdownColumns(
    getLocalTime(),
  )(createInitialBreakdown(
    view.permanentFiltersSettings.year,
    view.permanentFiltersSettings.breakdown,
  ), breakdownType, 'view')
    .reduce((acc, el) => ({
      ...acc,
      [el.key]: {
        isVisible: true,
        width: el.width,
        order: el.key,
      },
    }), {});

  const processColumns = (columnsArray: IColumn[]) => columnsArray.reduce((acc, el) => ({
    ...acc,
    [el.id]: {
      isVisible: multiGrouping.groups.includes(el.id) ? false 
        : (prevView?.[el.id].isVisible || el.show),
      width: prevView?.[el.id].width
        || el.width
        || columnsDictionary[el.id](new Date().getFullYear()).width,
      order: (prevView?.[el.id].order || el.order),
      fix: (prevView?.[el.id].fix || el.fix),
    },
  }), {});

  const unprocessedConfig: ColumnsConfig = {
    ...processColumns(columns.accAttr),
    ...processColumns(columns.col),
    ...processColumns(columns.oppAttr),
    ...breakdownCols,
  };

  const arr = getConfigArr(unprocessedConfig)
    .sort(sortCheckboxesByOrder).map((el, idx) => ({
      ...el,
      order: el.isVisible || prevView ? el.order : getOrderStr(idx, 'f') as string,
    }));

  const visible = arr.filter((el) => el.isVisible 
    && !el.fix 
    && !el.id.startsWith('yyyyyy'))
    .map((el, idx) => ({
      ...el,
      order: getOrderStr(idx, 'c'),
    }));
  
  const pinned = arr.filter((el) => el.isVisible && el.fix && el.id !== 'grouping')
    .map((el, idx) => ({
      ...el,
      order: getOrderStr(idx, 'b'),
    }));

  const res = [...arr, ...pinned, ...visible];

  return res.reduce((acc, { id, ...el }) => ({
    ...acc,
    [id]: {
      ...el,
    },
  }), {
    grouping: {
      order: '0',
      width: prevView?.grouping.width
        || multiGrouping.width
        || 400,
      fix: 'left',
      isVisible: true,
    },
    currName: {
      order: '1',
      width: 110,
      isVisible: isEdit,
      fix: 'left',
    },
    columnsConfig: {
      order: '2',
      width: 54,
      fix: 'right',
      isVisible: true,
    },
  });
};

export const viewStateAtom = atom<TableDataSource>({
  key: 'viewState',
  default: selector({
    key: 'viewState/selector',
    get: ({ get }) => {
      const view = get(defaultViewPresetSelector);

      const { mode } = get(viewModeAtom);

      const config: TableDataSource['columnsConfig'] = getConfigFromView(view, mode === 'edit');

      return {
        topIndex: 0,
        visibleCount: 10,
        isFolded: true,
        pageSize: 10,
        columnsConfig: config,
      };
    },
  }),
});

export const viewStateSnapshotAtom = atom<TableDataSource>({
  key: 'viewStateSnapshot',
  default: {},
});
