import React, { useEffect } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { viewPresetsAtom } from './state/atoms/viewPresetsAtom';
import css from './App.module.scss';
import Loader from './components/Loader';
import OneCrmPage from './pages/OneCrmPage';
import OneCrmErrorPage, { OneCrmErrorMessages } from './components/OneCrmErrorPage/OneCrmErrorPage';

export default function OneCrmApp() {
  useEffect(() => {
    window.escapeEpamAccessHash();
  });
  
  const viewPresetsLoadable = useRecoilValueLoadable(viewPresetsAtom);

  if (viewPresetsLoadable.state === 'hasError' && viewPresetsLoadable.contents.response.status === 403) {
    return (
      <div>
        <OneCrmErrorPage msg={OneCrmErrorMessages.NoRevenueData} />
      </div>
    );
  }

  return (
    <main className={css.oneCrmApp}>
      <React.Suspense fallback={<Loader />}>
        <OneCrmPage />
      </React.Suspense>
    </main>
  );
}
