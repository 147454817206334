import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Text, PickerInput, FlexRow, Panel, useForm, Button, Checkbox,
} from '@epam/promo';
import {
  DataColumnProps, Metadata, useArrayDataSource,
} from '@epam/uui-core';
import { ReactComponent as ErrorIcon } from '@epam/assets/icons/common/notification-error-fill-18.svg';
import { ReactComponent as CancelIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
import { ReactComponent as CheckIcon } from '@epam/assets/icons/common/notification-done-18.svg';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import { useRecoilValue } from 'recoil';
import MarkUpdatedIcon from '../assets/components/UpdateMarkedIcon';
import css from '../components/OneCrmGrid/OneCrmGrid.module.scss';
import Loader from '../components/Loader';
import {
  IBsrvRevenueItem, IOppRevenueGroup, OneCrmSectionType, getForecastsForOneCrm,
  markAsUpdatedForOneCrm, saveForecastsForOneCrm, saveLinkedProjects,
} from '../api/oneCrm';
import OneCrmGrid from '../components/OneCrmGrid/OneCrmGrid';
import { IForecastsItem } from '../components/OneCrmGrid/OneCrmGridColumns';
import { IOppRevenueHistoryResponseItem } from '../api/revenueHistory';
import OneCrmErrorPage, { OneCrmErrorMessages } from '../components/OneCrmErrorPage/OneCrmErrorPage';
import { OneCrmProjectsGrid } from '../components/OneCrmGrid/OneCrmProjectsGrid';
import { configState } from '../providers/ConfigProvider';

const MIN_VALUE = -50000;
const MAX_VALUE = 50000;

interface FormState {
  items: IForecastsItem[];
}

const validateCell = (val: number | null) => [
  Number(val) >= MAX_VALUE && 'Value must be less than 50000',
  Number(val) < MIN_VALUE && 'Value must be greater than -50000',
];

const getMetadata = (): Metadata<FormState> => ({
  props: {
    items: {
      all: {
        props: {
          m1: {
            validators: [
              validateCell,
            ],
          },
          m2: {
            validators: [
              validateCell,
            ],
          },
          m3: {
            validators: [
              validateCell,
            ],
          },
          m4: {
            validators: [
              validateCell,
            ],
          },
          m5: {
            validators: [
              validateCell,
            ],
          },
          m6: {
            validators: [
              validateCell,
            ],
          },
          m7: {
            validators: [
              validateCell,
            ],
          },
          m8: {
            validators: [
              validateCell,
            ],
          },
          m9: {
            validators: [
              validateCell,
            ],
          },
          m10: {
            validators: [
              validateCell,
            ],
          },
          m11: {
            validators: [
              validateCell,
            ],
          },
          m12: {
            validators: [
              validateCell,
            ],
          },
        },
      },
    },
  },
});

const OneCrmPage = () => {
  const config = useRecoilValue(configState);
  const [searchParams] = useSearchParams();
  const getIdFromParams = () => searchParams.get('id') ?? 'null';

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const [isUpdateBtnLoading, setIsUpdateBtnLoading] = useState(false);

  const [isNoRevenueDataError, setIsNoRevenueDataError] = useState(false);
  const [isNoOppError, setIsNoOppError] = useState(false);

  const [currency, setCurrency] = useState('');
  const [canEdit, setCanEdit] = useState(false);
  const [year, setYear] = useState(Math.abs(new Date().getFullYear()));
  const [errorMessage, setErrorMessage] = useState('');

  const [id, setId] = useState(getIdFromParams());

  const [
    forecasts, setForecasts,
  ] = useState<Record<number, IForecastsItem[]> | null>(null);
  const [isReqiredUpdate, setReqiredUpdate] = useState(false);
  const buildForecasts = (group: IOppRevenueGroup, idx: number) => {
    const forecastRootId: string | null = group.forecast?.id || `f${idx}`;

    return (group.forecast != null)
      ? ({
        ...group.forecast,
        gttlPlus: 0, // TODO: Calc and use real
        parentId: null,
        id: forecastRootId,
        type: OneCrmSectionType.Forecast,
      }) as IForecastsItem : ({}) as IForecastsItem;
  };

  const buildHistory = (group: IOppRevenueGroup, idx: number): IForecastsItem[] => {
    if (group.history != null) {
      return group.history.map((fi, ind) => ({
        ...fi,
        id: fi.id || `h${idx}_${ind}`,
        type: OneCrmSectionType.History,
      } as IForecastsItem)).sort((a, b) => {
        if (a.modifiedOn < b.modifiedOn) return 1;
        return -1;
      });
    }

    return [];
  };

  const buildActual = (group: IOppRevenueGroup, idx: number) => {
    const actualRootId: string | null = group.actual?.id || `a${idx}`;

    return (group.actual)
      ? ({
        ...group.actual,
        gttlPlus: 0, // TODO: Calc and use real
        parentId: null,
        id: actualRootId,
        type: OneCrmSectionType.Actual,
      }) as IForecastsItem : ({}) as IForecastsItem;
  };

  const buildWLB = (group: IOppRevenueGroup): IForecastsItem[] => {
    const calcBsrvSum = (key: keyof IBsrvRevenueItem) => group.bsrvProject
      .concat(group.bsrvStaffing)
      .reduce((acc: null | number, el) => {
        if (el[key]) {
          if (typeof acc !== 'number') {
            acc = el[key] as number;
          } else {
            acc += el[key] as number;
          }
        }
        return acc;
      }, null);

    let wlb: IForecastsItem[] = [
      ({
        id: 'wlb-root',
        type: OneCrmSectionType.BsrvRoot,
        m1: calcBsrvSum('m1'),
        m2: calcBsrvSum('m2'),
        m3: calcBsrvSum('m3'),
        m4: calcBsrvSum('m4'),
        m5: calcBsrvSum('m5'),
        m6: calcBsrvSum('m6'),
        m7: calcBsrvSum('m7'),
        m8: calcBsrvSum('m8'),
        m9: calcBsrvSum('m9'),
        m10: calcBsrvSum('m10'),
        m11: calcBsrvSum('m11'),
        m12: calcBsrvSum('m12'),
        gttl: calcBsrvSum('gttl'),
        ytd: calcBsrvSum('ytd'),
        frcst: calcBsrvSum('frcst'),
      }) as IForecastsItem,
    ];

    if (group.bsrvProject) {
      wlb = wlb.concat(group.bsrvProject.map((bp) => ({
        ...bp,
        id: bp.projectId,
        type: OneCrmSectionType.BsrvProject,
      } as IForecastsItem)));
    }

    if (group.bsrvStaffing) {
      wlb = wlb.concat(group.bsrvStaffing.map((bs) => ({
        ...bs,
        id: bs.staffingOpportunityCode,
        type: OneCrmSectionType.BsrvStaffing,
      } as IForecastsItem)));
    }

    return wlb;
  };

  const getForecasts = async () => {
    try {
      const res = await getForecastsForOneCrm(id);
      setCurrency(res.transactionCurrencyName);
      setCanEdit(res.canEdit);

      const processedRes = res.breakdowns.reduce((
        acc: Record<number, IForecastsItem[]>,
        revGroup: any,
        idx: any,
      ) => {
        if (!acc[revGroup.year]) {
          acc[revGroup.year] = [];
        }

        const forecastItem = buildForecasts(revGroup, idx);
        const historyItems = buildHistory(revGroup, idx);
        const actualItem = buildActual(revGroup, idx);
        const wlbItems = buildWLB(revGroup);

        acc[revGroup.year] = acc[revGroup.year]
          .concat(forecastItem)
          .concat(historyItems)
          .concat(actualItem)
          .concat(wlbItems);

        return acc;
      }, {});

      setForecasts(processedRes);
      setIsLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setIsNoRevenueDataError(true);
      }

      if (error?.response?.status === 404) {
        setIsNoOppError(true);
      }

      if (error?.response?.data?.message) {
        const { message } = error.response.data;
        const processedMsg = message.split(':')[0];
        setErrorMessage(error.response.data.message
          ? processedMsg
          : 'Failed to get a response from OneCRM endpoint. The data has not been fetched.');
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setId(getIdFromParams());
    setErrorMessage('');

    if (id === 'null') {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getForecasts();
  }, [isReqiredUpdate]);

  const preparedForecastsData = forecasts && forecasts[year]
    ? forecasts[year].map((el, idx, arr) => {
      const keys = Object.keys(el) as (keyof IOppRevenueHistoryResponseItem)[];
      const years = Object.keys(forecasts).filter((i) => Number(i) >= year);

      const newEl: IForecastsItem = {
        ...el,
        gttlPlus: el.gttl,
        changedFields: [],
      };

      if ((el.type === OneCrmSectionType.History) && (idx < arr.length - 1)) {
        keys.forEach((key) => {
          if (el[key] !== arr[idx + 1][key]) {
            newEl.changedFields.push(key);
          }
        });
      }

      years.forEach((i) => {
        if (Number(i) === year) {
          return;
        }

        const dataForType = forecasts[Number(i)].filter((f) => f.type === newEl.type);

        const recordsWithSumPlus = (newEl.type === OneCrmSectionType.History)
          ? dataForType.filter((y) => y.modifiedOn <= newEl.modifiedOn)
          : dataForType;

        if (recordsWithSumPlus.length) {
          if (recordsWithSumPlus[0].gttl) {
            newEl.gttlPlus ??= 0;
            newEl.gttlPlus += recordsWithSumPlus[0].gttl;
          }
        }
      });

      return newEl;
    }) : [];

  const options = forecasts && Object.keys(forecasts).length
    ? Object.keys(forecasts).map((el) => ({ id: Number(el), name: el }))
    : [{ id: year, name: year.toString() }];

  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;

  const extraYears = [
    {
      id: todayYear,
      name: todayYear.toString(),
    },
    {
      id: todayYear + 1,
      name: (todayYear + 1).toString(),
    },
    {
      id: todayYear + 2,
      name: (todayYear + 2).toString(),
    },
    {
      id: todayYear + 3,
      name: (todayYear + 3).toString(),
    },
  ];

  const dataSource = useArrayDataSource({
    items: options.reduce((acc: { id: number, name: string }[], el, idx) => {
      if (idx === options.length - 1) {
        extraYears.forEach((i) => {
          if (!options.some((elem) => elem.id === i.id)) {
            acc.push(i);
          }
        });
        return [...acc, el];
      }
      return [...acc, el];
    }, []).sort((a, b) => a.id - b.id),
  }, []);

  const [isProjectsChanged, setIsProjectsChanged] = useState(false);

  const [projectsState, setProjectsState] = useState<{
    id: string;
    showActuals: boolean;
    showWlb: boolean;
  }[]>([]);

  const lnkprjCols: DataColumnProps[] = [{
    key: 'name',
    caption: 'Name',
    render: (item: any) => (
      <Text>{item.name}</Text>
    ),
    grow: 1,
    width: 110,
    minWidth: 110,
    textAlign: 'left',
  }, {
    key: 'showActuals',
    caption: 'Show Actuals',
    render: (item: any) => (
      <Checkbox
        value={item.showActuals}
        onValueChange={(newVal) => {
          setProjectsState((prev) => [
            ...prev.filter((el) => el.id !== item.id),
            {
              id: item.id,
              showActuals: newVal,
              showWlb: prev.find((el) => el.id === item.id)?.showWlb || item.showWlb,
            },
          ]);
          setIsProjectsChanged(true);
        }}
        isDisabled={!item.canEditActuals || item.showActuals}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: 110,
    textAlign: 'left',
  }, {
    key: 'showWlb',
    caption: 'Show Workload Based',
    render: (item: any) => (
      <Checkbox
        value={projectsState.find((el) => el.id === item.id)?.showWlb || item.showWlb}
        onValueChange={(newVal) => {
          setProjectsState((prev) => [
            ...prev.filter((el) => el.id !== item.id),
            {
              id: item.id,
              showActuals: prev.find((el) => el.id === item.id)?.showActuals || item.showActuals,
              showWlb: newVal,
            },
          ]);
          setIsProjectsChanged(true);
        }}
        isDisabled={!item.canEditWlb || item.showWlb}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: 110,
    textAlign: 'left',
  }];

  const initialFormVal = preparedForecastsData
    .filter((el) => el.type === OneCrmSectionType.Forecast);

  const form = useForm({
    value: { items: initialFormVal },
    onSave: async (data) => {
      if (data.items.length === 0) {
        setIsSaveBtnLoading(false);
        return Promise.resolve();
      }

      const stateKeys = Object.keys(data.items[0]).filter((el) => el !== 'changedFields');

      const filteredKeys = stateKeys.filter((el) => (preparedForecastsData
        .filter((i) => i.type === OneCrmSectionType.Forecast)?.[0] as any)?.[el]
        !== (data.items[0] as any)?.[el]);

      const breakdown = filteredKeys.reduce((acc: Record<string, number>, el) => {
        if (
          year > todayYear
          || (year === todayYear && Number(el.slice(1)) >= todayMonth)
        ) {
          return {
            ...acc,
            [`${el}_${year}`]: (data.items[0] as any)[el] as number,
          };
        }
        return acc;
      }, {});

      if (Object.keys(breakdown).length === 0) {
        setIsSaveBtnLoading(false);
        return Promise.resolve();
      }

      try {
        const res = await saveForecastsForOneCrm(id, { breakdown });
        if (res.data.successfullySaved) {
          setErrorMessage('');
          setReqiredUpdate(!isReqiredUpdate);
          setIsSaveBtnLoading(false);
        } else {
          setIsSaveBtnLoading(false);
          setErrorMessage(res.data.errors?.[0] || 'Data is not synced yet, please try again later');
        }
      } catch (error: any) {
        setIsSaveBtnLoading(false);
        if (error?.response?.data?.message) {
          const { message } = error.response.data;
          const processedMsg = message.split(':')[0];
          const msg = processedMsg === 'Validation failed' ? 'Validation failed. Cannot save forecast revenue, please fill all mandatory opportunity attributes' : processedMsg;
          setErrorMessage(error.response.data.message
            ? msg
            : 'Failed to get a response from CRM. The data has not been saved');
        }
      }
    },
    getMetadata,
    validationOn: 'change',
  });

  const renderUpdateMarkedIcon = () => <MarkUpdatedIcon fill={canEdit ? '#008ACE' : '#ACAFBF'} />;

  if (isNoRevenueDataError) {
    return <OneCrmErrorPage msg={OneCrmErrorMessages.NoRevenueData} />;
  }

  if (isNoOppError) {
    return <OneCrmErrorPage msg={OneCrmErrorMessages.NoOppData} />;
  }

  return (
    <div>
      <Panel background="white" cx={css.pagePanel}>
        <FlexRow cx={css.pageHeader}>
          <FlexRow cx={css.yearPickerContainer}>
            {!isLoading ? (
              <Text cx={css.title}>
                {'Revenue 1k, '}
                {currency}
              </Text>
            ) : null}
          </FlexRow>
          <FlexRow>
            {!isLoading && (
            <div className={css.yearPicker}>
              <PickerInput
                size="30"
                dataSource={dataSource}
                value={year}
                onValueChange={(newVal: number) => setYear(newVal)}
                selectionMode="single"
                valueType="id"
                disableClear
                minBodyWidth={90}
                searchPosition="none"
              />
            </div>
            )}
          </FlexRow>
        </FlexRow>
        {!isLoading ? (
          <>
            <div className={css.contentContainer}>
              <OneCrmGrid
                items={preparedForecastsData}
                year={year}
                form={form}
                canEdit={canEdit}
              />
            </div>
            <FlexRow cx={css.bottomContainer}>
              {(errorMessage && errorMessage.length > 0) ? (
                <FlexRow>
                  <ErrorIcon fill="#E54322" />
                  <Text cx={css.errorMessage}>{errorMessage}</Text>
                </FlexRow>
              ) : <div />}

              <FlexRow cx={css.controlsContainer}>
                <Button
                  size="30"
                  color="blue"
                  fill="white"
                  icon={renderUpdateMarkedIcon}
                  caption="Mark as Updated"
                  isDisabled={!canEdit || isSaveBtnLoading || isUpdateBtnLoading}
                  onClick={() => {
                    setIsUpdateBtnLoading(true);
                    setErrorMessage('');

                    markAsUpdatedForOneCrm(id)
                      .catch((error) => {
                        setErrorMessage(error.response?.data?.message
                          ? error.response.data.message
                          : 'Failed to Mark As Updated.');
                      })
                      .finally(() => {
                        setIsUpdateBtnLoading(false);
                      });
                  }}
                />
              </FlexRow>
            </FlexRow>
          </>
        ) : <Loader />}   
      </Panel>
      <FlexRow rawProps={{
        style: {
          width: '100%', marginTop: '1rem', justifyContent: 'space-between', alignItems: 'flex-end', 
        }, 
      }}
      >
        {config?.oneCrmProjectLink ? (
          <Panel
            background="white"
            cx={css.pagePanel}
            style={{
              maxWidth: '768px',
              maxHeight: '212px',
              overflow: 'hidden',
            }}
          >
            <OneCrmProjectsGrid id={id} columns={lnkprjCols} />
          </Panel>
        ) : <div />}
        <FlexRow rawProps={{
          style: {
            gap: '8px',
          }, 
        }}
        >
          <Button
            size="30"
            color="gray50"
            fill="none"
            icon={CancelIcon}
            caption="Cancel"
            isDisabled={!canEdit || isSaveBtnLoading || isUpdateBtnLoading}
            onClick={() => {
              setErrorMessage('');
              form.setValue({ items: initialFormVal });
            }}
          />
          <Button
            size="30"
            color="blue"
            icon={!isSaveBtnLoading ? CheckIcon : LoaderIcon}
            caption="Save"
            isDisabled={!canEdit || isSaveBtnLoading || isUpdateBtnLoading || form.isInvalid}
            onClick={async () => {
              setErrorMessage('');
              setIsSaveBtnLoading(true);
              form.save();
              if (isProjectsChanged && config?.oneCrmProjectLink) {
                setIsSaveBtnLoading(true);
                try {
                  await saveLinkedProjects(id, projectsState);
                } catch (error: any) {
                  if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                  }
                } finally {
                  setIsSaveBtnLoading(false);
                }
              }
            }}
          />
        </FlexRow>
      </FlexRow>
    </div>
  );
};

export default OneCrmPage;
