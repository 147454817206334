import { Checkbox } from '@epam/promo';
import React, { FC } from 'react';
import css from '../GroupingSettings.module.scss';

interface Props {
  value: boolean,
  onChange: (val: boolean) => void,
  paddingLeft: string,
  label: string,
  withTopMargin?: boolean,
}

const OppCheckbox: FC<Props> = ({
  value, onChange, paddingLeft, label, withTopMargin: withTopPadding = false,
}) => (
  <div
    className={css.oppCheckboxesRow}
    style={{
      paddingLeft,
      marginTop: withTopPadding ? '10px' : 0,
    }}
  >
    <Checkbox
      label={label}
      value={value}
      onValueChange={onChange}
    />
  </div>
);

export default OppCheckbox;
