import { Button, Text } from '@epam/promo';
import React, { FC } from 'react';
import { ReactComponent as CrossIcon } from '@epam/assets/icons/common/navigation-close-24.svg';
import css from './SlidingPanel.module.scss';

interface Props {
  title: string,
  onClose: () => void,
}

const Header: FC<Props> = ({ onClose, title }) => (
  <div className={css.header}>
    <Text fontSize="12" fontWeight="600" font="museo-sans">{title}</Text>
    <Button
      icon={CrossIcon}
      color="gray" 
      fill="light" 
      size="18"
      onClick={onClose}
      cx={css.closeBtn}
    />
  </div>
);

export default Header;
