import { atom } from 'recoil';
import { ISettings } from './queryParamsAtom';

type TMode = 'view' | 'edit';

export interface IViewModeAtom {
  mode: TMode,
  isRefreshed: number,
  paramsBeforeEdit?: ISettings,
  isLoading: boolean,
}

const viewModeAtom = atom<IViewModeAtom>({
  key: 'viewModeAtom',
  default: {
    mode: 'view',
    isRefreshed: 0,
    isLoading: false,
  },
});

export default viewModeAtom;
