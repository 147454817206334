import { atom } from 'recoil';
import { FileWithPath } from 'react-dropzone';
import { ImportHistoryItemInfo } from '../../../api/importHistory';

const importAtom = atom<ImportHistoryItemInfo | null>({
  key: 'importAtom',
  default: null,
});

export const acceptedFileAtom = atom<FileWithPath | null>({
  key: 'acceptedFile',
  default: null,
});

export const defaultImportStatus = {
  isLoading: false,
  fileForImport: null,
  errorMessage: 'Error',
  isError: false,
  isImporting: false,
  isImportSuccessful: false,
  importInProgress: '',
  fileSize: '',
  fileExtention: '',
  fileName: '',
};

export const importStatusAtom = atom<{
  fileForImport: FormData | null,
  isError: boolean,
  errorMessage: string,
  isLoading: boolean,
  isImporting: boolean,
  isImportSuccessful: boolean,
  importInProgress: string,
  fileSize: string,
  fileExtention: string,
  fileName: string,
}>({
  key: 'importStatusAtom',
  default: defaultImportStatus, 
});

export default importAtom;
