import { IColumns, OmittedView } from '../../../api/views';
import { initialFilters } from '../../../const/FILTERS';
import { initialSorting } from '../../../state/atoms/sortingAtom';

export const defaultColumns: IColumns = {
  col: [
    {
      id: 'accName',
      show: false,
      order: 'a',
      visible: true,
    },
    {
      id: 'prob',
      show: true,
      order: 'ca',
      visible: true,
    },
    {
      id: 'GBU',
      show: false,
      order: 'c',
      visible: true,
    },
    {
      id: 'GBUPortfolio',
      show: false,
      order: 'd',
      visible: true,
    },
    {
      id: 'RegionalGBU',
      show: false,
      order: 'e',
      visible: true,
    },
    {
      id: 'gttl',
      show: true,
      order: 'cd',
      visible: true,
    },
    {
      id: 'ytd',
      show: true,
      order: 'ce',
      visible: true,
    },
    {
      id: 'frcst',
      show: true,
      order: 'cf',
      visible: true,
    },
  ],
  accAttr: [
    {
      id: 'accAcquisitionName',
      show: false,
      order: 'a',
      visible: true,
    },
    {
      id: 'accAM',
      show: false,
      order: 'b',
      visible: true,
    },
    {
      id: 'accSM',
      show: false,
      order: 'c',
      visible: true,
    },
    {
      id: 'accState',
      show: false,
      order: 'd',
      visible: true,
    },
    {
      id: 'AnnualRevenue',
      show: false,
      order: 'e',
      visible: true,
    },
    {
      id: 'accDigitalSM',
      show: false,
      order: 'f',
      visible: true,
    },
    {
      id: 'EPAMShare1KUSD',
      show: false,
      order: 'g',
      visible: true,
    },
    {
      id: 'EPAMWallet1KUSD',
      show: false,
      order: 'h',
      visible: true,
    },
    {
      id: 'globalAccName',
      show: false,
      order: 'i',
      visible: true,
    },
    {
      id: 'ITServices1KUSD',
      show: false,
      order: 'j',
      visible: true,
    },
    {
      id: 'accExtRepCountry',
      show: false,
      order: 'k',
      visible: true,
    },
    {
      id: 'accExtRepInd',
      show: false,
      order: 'l',
      visible: true,
    },
    {
      id: 'oppSalesType',
      show: false,
      order: 'm',
      visible: true,
    },
    {
      id: 'accSuppAM',
      show: false,
      order: 'n',
      visible: true,
    },
    {
      id: 'SuppGBU',
      show: false,
      order: 'o',
      visible: true,
    },
    {
      id: 'SuppGBUPortfolio',
      show: false,
      order: 'p',
      visible: true,
    },
    {
      id: 'SuppRegionalGBU',
      show: false,
      order: 'q',
      visible: true,
    },
  ],
  oppAttr: [
    {
      id: 'OSC',
      show: false,
      order: 'a',
      visible: true,
    },
    {
      id: 'oppAdvisoryAM',
      show: false,
      order: 'b',
      visible: true,
    },
    {
      id: 'commercialModel',
      show: false,
      order: 'ba',
      visible: true,
    },
    {
      id: 'commisionable',
      show: false,
      order: 'c',
      visible: true,
    },
    {
      id: 'oppContinuumOfferings',
      show: false,
      order: 'd',
      visible: true,
    },
    {
      id: 'oppAging',
      show: false,
      order: 'e',
      visible: true,
    },
    {
      id: 'oppDealType',
      show: false,
      order: 'ea',
      visible: true,
    },
    {
      id: 'oppDescription',
      show: false,
      order: 'f',
      visible: true,
    },
    {
      id: 'oppEngagementLeads',
      show: false,
      order: 'fa',
      visible: true,
    },
    {
      id: 'oppEngagementType',
      show: false,
      order: 'g',
      visible: true,
    },
    {
      id: 'modBy',
      show: false,
      order: 'h',
      visible: true,
    },
    {
      id: 'modDate',
      show: false,
      order: 'i',
      visible: true,
    },
    {
      id: 'oppIndustry',
      show: false,
      order: 'j',
      visible: true,
    },
    {
      id: 'oppLeadPractice',
      show: false,
      order: 'k',
      visible: true,
    },
    {
      id: 'oppLeadSourceCode',
      show: false,
      order: 'l',
      visible: true,
    },
    {
      id: 'projstr',
      show: true,
      order: 'cb',
      visible: true,
    },
    {
      id: 'MergedToOppName',
      show: false,
      order: 'n',
      visible: true,
    },
    {
      id: 'oppAcquisitionName',
      show: false,
      order: 'o',
      visible: true,
    },
    {
      id: 'oppAM',
      show: false,
      order: 'p',
      visible: true,
    },
    {
      id: 'oppCreatedBy',
      show: false,
      order: 'q',
      visible: true,
    },
    {
      id: 'oppCreatedOn',
      show: false,
      order: 'r',
      visible: true,
    },
    {
      id: 'oppOrigin',
      show: false,
      order: 's',
      visible: true,
    },
    {
      id: 'oppSM',
      show: false,
      order: 't',
      visible: true,
    },
    {
      id: 'oppState',
      show: false,
      order: 'u',
      visible: true,
    },
    {
      id: 'oppPartnerAccounts',
      show: false,
      order: 'v',
      visible: true,
    },
    {
      id: 'oppPartnerSalesManagers',
      show: false,
      order: 'va',
      visible: true,
    },
    {
      id: 'oppPlatforms',
      show: false,
      order: 'w',
      visible: true,
    },
    {
      id: 'oppPractices',
      show: false,
      order: 'x',
      visible: true,
    },
    {
      id: 'RevenueTypeName',
      show: false,
      order: 'y',
      visible: true,
    },
    {
      id: 'SS',
      show: false,
      order: 'ya',
      visible: true,
    },
    {
      id: 'SU',
      show: false,
      order: 'yb',
      visible: true,
    },
    {
      id: 'oppSecondarySM',
      show: false,
      order: 'yc',
      visible: true,
    },
    {
      id: 'closeDate',
      show: true,
      order: 'cc',
      visible: true,
    },
    {
      id: 'oppStream',
      show: false,
      order: 'ye',
      visible: true,
    },
    {
      id: 'oppTargetCloseDate',
      show: false,
      order: 'yf',
      visible: true,
    },
    {
      id: 'oppTargetCloseQuarter',
      show: false,
      order: 'yg',
      visible: true,
    },
    {
      id: 'oppTotalContractValue',
      show: false,
      order: 'yh',
      visible: true,
    },
    {
      id: 'oppCorrections',
      show: false,
      order: 'yi',
      visible: true,
    },
    {
      id: 'oppTags',
      show: false,
      order: 'yw',
      visible: true,
    },
    {
      id: 'oppTagFlag',
      show: false,
      order: 'yx',
      visible: true,
    },
    {
      id: 'hasNotes',
      show: false,
      order: 'yy',
      visible: true,
    },
  ],
};

export const defaultColumnsIds = {
  col: defaultColumns.col.map((el) => el.id),
  accAttr: defaultColumns.accAttr.map((el) => el.id),
  oppAttr: defaultColumns.oppAttr.map((el) => el.id),
};

const { revenueYear: year, ...permanentFilters } = initialFilters;

export const defaultView: OmittedView = {
  name: 'My Pipeline',
  isViewAutosaved: true,
  gridType: 'RevenueForecast',
  columnSettings: {
    columns: defaultColumns,
    sort: initialSorting,
  },
  multiGrouping: {
    groups: ['GBU', 'accName', 'oppName'],
    collapse: true,
    tagsFlagsNotes: false,
    warnings: false,
    width: 400,
  },
  permanentFiltersSettings: {
    year,
    ...permanentFilters,
  },
  advancedFiltersSettings: {
    operator: 'And',
    predicates: [
      {
        key: 'oppState',
        operator: 'Equal',
        value: [
          {
            id: 2,
            name: 'Won',
          },
          {
            id: 1,
            name: 'Open',
          },
          {
            id: 4,
            name: 'Closed',
          },
        ],
      },
      {
        key: 'gttl',
        operator: 'NotEqual',
        value: 0,
      },
    ],
  },
  state: 'Active',
};

export const accountView: OmittedView = {
  ...defaultView,
  name: 'Account SM/AM Pipeline',
  gridType: 'Goals',
  permanentFiltersSettings: {
    ...defaultView.permanentFiltersSettings,
    year: new Date().getFullYear(),
    ownership: 'All',
  },
};
