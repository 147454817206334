import React from 'react';
import { ArrayDataSource, DataColumnProps } from '@epam/uui-core';
import {
  DataTableCell, PickerInput, Text, TextInput, IconButton,
} from '@epam/promo';
import { ReactComponent as deleteIcon } from '@epam/assets/icons/common/delete-outline-36.svg';
import { ISettingsItem } from './ImportActualSettings';
import css from './ImportActualSettings.module.scss';

type TTableColumns = (
  pickerDataSource: ArrayDataSource,
  handleItemDelete: (itemId: number) => void
) => DataColumnProps<ISettingsItem>[];

export const tableColumns: TTableColumns = (pickerDataSource, handleItemDelete) => [
  {
    cx: `${css.headerCell} ${css.firstHeaderCell}`,
    key: 'groupAccNumber',
    info: 'Group acc number',
    caption: <Text cx={`${css.headerCaption} hideTooltip`}>Group acc number</Text>,
    renderCell: (props) => (
      <DataTableCell
        {...props.rowLens.prop('groupAccNumber').toProps()}
        renderEditor={(cellProps) => <TextInput key="groupAccNumber" {...cellProps} />}
        {...props}
      />
    ),
    grow: 1,
    width: 150,
  },
  {
    cx: `${css.headerCell}`,
    key: 'varSubGroupping',
    info: 'VAR Sub-grouping',
    caption: <Text cx={`${css.headerCaption} hideTooltip`}>VAR Sub-grouping</Text>,
    renderCell: (props) => (
      <DataTableCell
        {...props.rowLens.prop('varSubGrouping').toProps()}
        renderEditor={(cellProps) => <TextInput key="varSubGrouping" {...cellProps} />}
        {...props}
      />
    ),
    grow: 1,
    width: 180,
  },
  {
    cx: `${css.headerCell}`,
    key: 'varGrouping',
    info: 'VAR Grouping',
    caption: <Text cx={`${css.headerCaption} hideTooltip`}>VAR Grouping</Text>,
    renderCell: (props) => (
      <DataTableCell
        {...props.rowLens.prop('varGrouping').toProps()}
        renderEditor={(cellProps) => <TextInput key="varGrouping" {...cellProps} />}
        {...props}
      />
    ),
    grow: 1,
    width: 180,
  },
  {
    cx: `${css.headerCell}`,
    key: 'type',
    info: 'Type',
    caption: <Text cx={`${css.headerCaption} hideTooltip`}>Type</Text>,
    renderCell: (props) => (
      <DataTableCell
        {...props.rowLens.prop('type').toProps()}
        renderEditor={(cellProps) => <PickerInput key="type" disableClear {...cellProps} selectionMode="single" dataSource={pickerDataSource} searchPosition="none" minBodyWidth={116} />}
        {...props}
      />
    ),
    grow: 1,
    width: 116,
  },
  {
    key: 'delete',
    renderCell: (props) => {
      const itemId = props.rowLens.prop('id').toProps().value;

      return (
        <IconButton key="delete" cx={css.deleteButton} icon={deleteIcon} onClick={() => handleItemDelete(itemId)} />
      );
    },
    grow: 0,
    width: 30,
  },
];
