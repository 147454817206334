import React from 'react';
import { DataColumnProps, ILens, RenderCellProps } from '@epam/uui-core';
import {
  DataTableCell,
  NumericInput,
  Text,
  Tooltip,
} from '@epam/promo';
import classNames from 'classnames';
import css from './OneCrmGrid.module.scss';
import { IOppRevenueHistoryResponseItem } from '../../api/revenueHistory';
import OneCrmGridRecord from './OneCrmGridRecord';
import { OneCrmSectionType } from '../../api/oneCrm';
import OneCrmGridCell from './OneCrmGridCell';

export interface IForecastsItem extends IOppRevenueHistoryResponseItem {
  changedFields: (keyof IOppRevenueHistoryResponseItem)[],
  gttlPlus: number | null,
  projectId: string | null,
  projectName: string | null,
  staffingOpportunityCode: string | null,
  parentId: string | null,
  type: OneCrmSectionType,
  isDisabled?: boolean,
  canEdit?: boolean,
}
interface IRenderCellProps {
  columnKey: keyof IOppRevenueHistoryResponseItem,
  renderProps: RenderCellProps<IForecastsItem, any>,
  lens: ILens<{ items: IForecastsItem[] }>,
}

const RenderCell = ({
  columnKey,
  renderProps,
  lens,
}: IRenderCellProps) => {
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const cellValue = lens?.prop('items')?.prop(0)?.prop(columnKey)?.toProps()?.value ? `${lens?.prop('items')?.prop(0)?.prop(columnKey)?.toProps()?.value}` : null;
  if (
    lens && renderProps.rowProps?.value?.canEdit
    && (
      Number(renderProps?.rowProps?.value?.year) > todayYear
      || (
        Number(renderProps?.rowProps?.value?.year) === todayYear
        && Number(columnKey.slice(1)) >= todayMonth + 1
      )
    )
  ) {
    const cellProp = lens.prop('items').prop(0).prop(columnKey);
    const originalVal = renderProps?.rowProps?.value?.[columnKey];
    const proppedCell = cellProp.toProps();

    return (
      <Tooltip content={cellValue && cellValue.length > 4 ? cellValue : ''}>
        <div className={css.renderCellWrapper}>
          <DataTableCell
            {...cellProp.toProps()}
            renderEditor={(cellProps: any) => (
              <NumericInput
                align="left"
                {...cellProps}
                formatOptions={{
                  maximumFractionDigits: 2,
                }}
              />
            )}
            {...renderProps}
            cx={
              classNames({
                [css.editorChanged]: cellProp.get() !== originalVal && !proppedCell.isInvalid,
                [css.editorCell]: true,
              })
            }
          />
        </div>
      </Tooltip>
    );
  }
  return (
    <Tooltip content={cellValue && cellValue.length > 4 ? cellValue : ''}>
      <div className={css.renderCellWrapper}>
        <DataTableCell
          {...renderProps}
          cx={
            classNames({
              [css.valueChanged]: renderProps.rowProps.value?.changedFields.includes(columnKey),
            })
          }
        />
      </div>
    </Tooltip>
  );
};

interface ICaptionWithTooltipProps {
  caption: string; className?: string;
}
/* eslint-disable-next-line max-len */
const CaptionWithTooltip = ({ caption, className = css.columnCaption }: ICaptionWithTooltipProps): JSX.Element => (
  <Tooltip content={caption?.length >= 5 ? caption : ''}>
    <Text cx={className}>{caption}</Text>
  </Tooltip>
);

export const OneCrmColumnsDictionary: {
  [key: string]: (year: number, lens?: any) => DataColumnProps<IForecastsItem>
} = {
  record: (year, lens) => ({
    key: 'record',
    caption: null,
    render: (item) => <OneCrmGridRecord item={item} lens={lens} />,
    grow: 1,
    width: 160,
    textAlign: 'left',
  }),
  m1: (year, lens) => ({
    key: `jan${year}`,
    caption: <CaptionWithTooltip caption={`Jan - ${year}`} />,
    render: (item): JSX.Element => (
      <OneCrmGridCell
        value={item?.m1}
        isChanged={item?.changedFields?.includes('m1')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m1" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m2: (year, lens) => ({
    key: `feb${year}`,
    caption: <CaptionWithTooltip caption={`Feb - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m2}
        isChanged={item?.changedFields?.includes('m2')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m2" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m3: (year, lens) => ({
    key: `mar${year}`,
    caption: <CaptionWithTooltip caption={`Mar - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m3}
        isChanged={item?.changedFields?.includes('m3')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m3" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m4: (year, lens) => ({
    key: `apr${year}`,
    caption: <CaptionWithTooltip caption={`Apr - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m4}
        isChanged={item?.changedFields?.includes('m4')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m4" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m5: (year, lens) => ({
    key: `may${year}`,
    caption: <CaptionWithTooltip caption={`May - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m5}
        isChanged={item?.changedFields?.includes('m5')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m5" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m6: (year, lens) => ({
    key: `jun${year}`,
    caption: <CaptionWithTooltip caption={`Jun - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m6}
        isChanged={item?.changedFields?.includes('m6')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m6" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m7: (year, lens) => ({
    key: `jul${year}`,
    caption: <CaptionWithTooltip caption={`Jul - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m7}
        isChanged={item?.changedFields?.includes('m7')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m7" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m8: (year, lens) => ({
    key: `aug${year}`,
    caption: <CaptionWithTooltip caption={`Aug - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m8}
        isChanged={item?.changedFields?.includes('m8')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m8" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m9: (year, lens) => ({
    key: `sep${year}`,
    caption: <CaptionWithTooltip caption={`Sep - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m9}
        isChanged={item?.changedFields?.includes('m9')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m9" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m10: (year, lens) => ({
    key: `oct${year}`,
    caption: <CaptionWithTooltip caption={`Oct - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m10}
        isChanged={item?.changedFields?.includes('m10')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m10" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m11: (year, lens) => ({
    key: `nov${year}`,
    caption: <CaptionWithTooltip caption={`Nov - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m11}
        isChanged={item?.changedFields?.includes('m11')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m11" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  m12: (year, lens) => ({
    key: `dec${year}`,
    caption: <CaptionWithTooltip caption={`Dec - ${year}`} />,
    render: (item) => (
      <OneCrmGridCell
        value={item?.m12}
        isChanged={item?.changedFields?.includes('m12')}
      />
    ),
    renderCell: (props) => <RenderCell renderProps={props} columnKey="m12" lens={lens} />,
    grow: 1,
    width: 44,
    textAlign: 'left',
  }),
  gttl: (year) => ({
    key: 'gttl',
    caption: <CaptionWithTooltip caption={`GTTL ${year}`} className={css.columnCaptionBold} />,
    render: (item) => <OneCrmGridCell value={item?.gttl} isBold />,
    grow: 2,
    width: 44,
    textAlign: 'left',
  }),
  ytd: (year) => ({
    key: 'ytd',
    caption: <CaptionWithTooltip caption={`YTD ${year}`} className={css.columnCaptionBold} />,
    render: (item) => <OneCrmGridCell value={item?.ytd} isBold />,
    grow: 2,
    width: 44,
    textAlign: 'left',
  }),
  frcst: (year) => ({
    key: 'frcst',
    caption: <CaptionWithTooltip caption={`FRCST ${year}`} className={css.columnCaptionBold} />,
    render: (item) => <OneCrmGridCell value={item?.frcst} isBold />,
    grow: 2,
    width: 44,
    textAlign: 'left',
  }),
  gttlPlus: (year) => ({
    key: 'gttlPlus',
    caption: <CaptionWithTooltip caption={`GTTL ${year}+`} className={css.columnCaptionBold} />,
    render: (item) => <OneCrmGridCell value={item?.gttlPlus} isBold />,
    grow: 2.5,
    width: 44,
    textAlign: 'left',
  }),
};
