import React, {
  useCallback, useState, useRef, 
} from 'react';
import { useRecoilValue } from 'recoil';
import { isEqual, mapValues, isObject } from 'lodash';
import { FlexCell, TabButton, Tooltip } from '@epam/promo';
import { PresetActionsDropdown } from './PresetActionsDropdown';
import { PresetInput } from './PresetInput';
import css from './Preset.module.scss';
import { View } from '../../../api/views';
import { useViewPresets } from '../../../hooks/useViewPresets';
import currentGroupingIdsSelector from '../../../state/selectors/currentGroupingIdsSelector';
import { parseAdvancedFiltersFromView } from '../../../const/ADVANCED_FILTERS';
import advancedFiltersConstructorAtom, { IHierarchicalItem } from '../../../state/atoms/advancedFiltersConstructorAtom';
import { viewStateAtom, viewStateSnapshotAtom } from '../../../state/atoms/viewStateAtom';
import { Filters } from '../../../const/FILTERS';
import sortingAtom, { initialSorting } from '../../../state/atoms/sortingAtom';

interface PresetProps {
  preset: View;
  onPresetApply: (preset?: View) => Promise<any>;
  onPresetDelete: (preset: View) => Promise<any>;
  onPresetCreate: (name: string) => Promise<any>;
  addPreset: () => void;
}

export function Preset({
  preset, onPresetApply, onPresetDelete, onPresetCreate, addPreset,
}: PresetProps) {
  const ref = useRef<HTMLDivElement>(null);
  const currentGroupingIds = useRecoilValue(currentGroupingIdsSelector);
  const advancedFilters = useRecoilValue(advancedFiltersConstructorAtom);
  const sortingStore = useRecoilValue(sortingAtom);
  const { columnsConfig: config } = useRecoilValue(viewStateAtom);
  const { columnsConfig: configSnapshot } = useRecoilValue(viewStateSnapshotAtom);
  const { constructor: advancedFiltersItems } = advancedFilters;
  const { renamePreset, currentFiltersSettings, loading } = useViewPresets();
  const [isRenamePreset, setIsRenamePreset] = useState(false);

  const getTooltipContent = () => {
    let isOverflowName = false;
    if (ref.current) {
      const nameRef = ref.current.children[0].children[0];
      isOverflowName = nameRef.scrollWidth > nameRef.clientWidth;
    }
    return isOverflowName ? preset.name : '';
  };

  const hasPresetChanged = useCallback(() => {
    const mapAdvancedFilters = ({ operator, filters, children }: IHierarchicalItem): Object => ({
      operator,
      filters: mapValues(filters, (filter) => ({
        key: filter.key,
        operator: filter.operator,
        order: filter.order,
        // eslint-disable-next-line no-nested-ternary
        value: Array.isArray(filter.value)
          ? filter.value.map((val) => ({ id: val.id, name: val.name }))
          : isObject(filter.value)
            ? { id: filter.value.id, name: filter.value.name }
            : filter.value,
      })),
      children: children && children.length
        ? children.map((child) => mapAdvancedFilters(child))
        : [],
    });

    const {
      search, ...presetPermanentFiltersSettings
    } = preset.permanentFiltersSettings as unknown as Filters;

    return !isEqual(config, configSnapshot)
    || !isEqual(preset.multiGrouping.groups, currentGroupingIds)
    || !isEqual(presetPermanentFiltersSettings, currentFiltersSettings)
    || !isEqual(
      preset.columnSettings.sort.length 
        ? preset.columnSettings.sort.map((el) => ({
          ...el,
          direction: el.direction === 'Descending' || el.direction === 1 ? 1 : 0,
        }))
        : initialSorting,
      sortingStore,
    )
    || !isEqual(
      mapAdvancedFilters(parseAdvancedFiltersFromView(preset.advancedFiltersSettings)),
      mapAdvancedFilters(advancedFiltersItems[0]),
    );
  }, [
    preset,
    currentGroupingIds,
    currentFiltersSettings,
    advancedFiltersItems,
    config,
    configSnapshot,
  ]);

  const cancelRenamePreset = useCallback(() => {
    setIsRenamePreset(() => false);
  }, []);

  const setPresetForRename = useCallback(() => {
    if (!isRenamePreset) {
      setIsRenamePreset(() => true);
    }
  }, []);

  const onPresetRename = async (name: string) => {
    await renamePreset(preset, name);
  };

  const renderIcon = () => (
    <PresetActionsDropdown
      preset={preset}
      onPresetDelete={onPresetDelete}
      renamePreset={setPresetForRename}
      onPresetCreate={onPresetCreate}
      hasPresetChanged={hasPresetChanged}
      addPreset={addPreset}
    />
  );

  const withNotify = preset.isNew || (
    preset.isDefault && hasPresetChanged() && !preset.isViewAutosaved
  );

  return (
    <FlexCell key={preset.id} alignSelf="center" width="auto" ref={ref}>
      {isRenamePreset ? (
        <PresetInput
          onCancel={cancelRenamePreset}
          onSuccess={onPresetRename}
          preset={preset}
        />
      ) : (
        <Tooltip content={getTooltipContent()} placement="bottom" color="white">
          <TabButton
            caption={preset.name}
            onClick={() => !preset.isDefault && !loading && onPresetApply(preset)}
            cx={[css.preset, preset.isDefault && css.activePreset]}
            size="60"
            withNotify={withNotify}
            icon={renderIcon}
            iconPosition="right"
            isLinkActive={preset.isDefault}
          />
        </Tooltip>
      )}
    </FlexCell>
  );
}
