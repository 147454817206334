import {
  SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState, 
} from 'recoil';
import React, {
  useEffect,
} from 'react';
import {
  ColumnsConfig, DataColumnProps, DataTableRowProps, LazyListView, 
} from '@epam/uui-core';
import { DataTableHeaderRow, VirtualList, VirtualListRenderRowsParams } from '@epam/uui';
import { QueryItem } from '../../../hooks/useQueryApi';
import VirtualListError from './components/VirtualListError';
import { virtualListErrorsAtom } from '../../../state/atoms/virtualListErrorsAtom';
import { TableRow } from './components/TableRow';
import defaultViewPresetSelector from '../../../state/selectors/defaultViewPresetSelector';
import { foldedAtom } from '../../../state/atoms/foldedAtom';
import { View } from '../../../api/views';
import { useViewPresets } from '../../../hooks/useViewPresets';
import useDebounce from '../../../hooks/useDebounce';
import { columnsDictionary } from './VirtualListColumns';
import css from './VirtualList.module.scss';
import GridFooter from './components/GridFooter';
import { TableDataSource, viewStateSnapshotAtom } from '../../../state/atoms/viewStateAtom';

interface VirtualListTableProps {
  lazyView: LazyListView<QueryItem, string, unknown>; 
  viewState: TableDataSource; 
  setViewState: SetterOrUpdater<TableDataSource>;
  config: ColumnsConfig;
  columns: DataColumnProps<any, any, any>[];
}

const VirtualListTable = (props: VirtualListTableProps) => {
  const {
    lazyView, viewState, setViewState, config, columns, 
  } = props;
  const setViewStateSnapshot = useSetRecoilState(viewStateSnapshotAtom);
  const error = useRecoilValue(virtualListErrorsAtom);
  const [foldedArr, setFoldedArr] = useRecoilState(foldedAtom);
  const view = useRecoilValue(defaultViewPresetSelector);
  const { id: viewId } = view;

  const { updateViewPresetInList } = useViewPresets();

  const { rowsCount: asyncRowsCount } = lazyView.getListProps();

  const saveConfig = useDebounce(() => {
    const updatedView: View = {
      ...view,
      isDefault: true,
      multiGrouping: {
        ...view.multiGrouping,
        width: config?.grouping?.width
          ? Math.floor(config.grouping.width as number)
          : 400,
      },
      columnSettings: {
        ...view.columnSettings,
        columns: {
          col: view.columnSettings.columns.col.map((el) => ({
            ...el,
            width: config?.[el.id]?.width
              ? Math.floor(config[el.id].width as number)
              : columnsDictionary[el.id](new Date().getFullYear()).width,
          })),
          oppAttr: view.columnSettings.columns.oppAttr.map((el) => ({
            ...el,
            width: config?.[el.id]?.width
              ? Math.floor(config[el.id].width as number)
              : columnsDictionary[el.id](new Date().getFullYear()).width,
          })),
          accAttr: view.columnSettings.columns.accAttr.map((el) => ({
            ...el,
            width: config?.[el.id]?.width
              ? Math.floor(config[el.id].width as number)
              : columnsDictionary[el.id](new Date().getFullYear()).width,
          })),
        },
      },
    };
    updateViewPresetInList(updatedView);
  });

  useEffect(() => {
    if (view.isViewAutosaved) saveConfig();
  }, [JSON.stringify(config)]);

  // take a snapshot of viewState 
  useEffect(() => {
    setViewStateSnapshot(viewState);
  }, [view.id]);

  const renderRow = (row: DataTableRowProps) => {
    if (row?.value?.breakdown) {
      Object.entries(row.value.breakdown).forEach(([key, value]) => {
        if (value === 0) row.value.breakdown[key] = '0';
      });
    }
    return (
      <TableRow
        {...row}
        key={row.id}
        foldedArr={foldedArr}
        setFoldedArr={setFoldedArr}
        columns={columns}
      />
    );
  };

  const visibleRows = lazyView.getVisibleRows().map(renderRow);

  useEffect(() => {
    setFoldedArr(JSON.parse(localStorage.getItem(viewId) || '[]'));
  }, [viewId]);

  useEffect(() => {
    window.localStorage.setItem(viewId, JSON.stringify(foldedArr));
  }, [foldedArr]);

  const renderRowsContainer = (
    {
      listContainerRef,
      offsetY,
    }: VirtualListRenderRowsParams,
  ) => (
    <>
      <DataTableHeaderRow
        key="header"
        cx={css.header}
        columns={columns}
        value={viewState}
        onValueChange={setViewState}
        allowColumnsResizing
      />
      { asyncRowsCount !== 0 && (
        <div>
          <div
            ref={listContainerRef}
            role="rowgroup"
            style={{ marginTop: offsetY, paddingBottom: '1.25rem' }}
            children={visibleRows}
          />

        </div>
      ) }
      <GridFooter config={viewState?.columnsConfig || {}} />
    </>
  );

  if (error) {
    return <VirtualListError error={error} />;
  }
  
  return (
    <VirtualList
      cx={css.root}
      rows={visibleRows}
      rowsCount={asyncRowsCount}
      renderRows={renderRowsContainer}
      value={viewState}
      onValueChange={setViewState}
      role="table"
      rawProps={{
        style: {
          maxHeight: '100%',
          position: 'relative',
          overflowY: 'scroll',
        },
      }}
    />
  );
};

export default VirtualListTable;
