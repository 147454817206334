import api from './api';
import { IAdvancedFilters, TQueryValuesKey } from './query';
import { FilterSettings } from './views';

export interface ITotalRequestBody {
  columns: TQueryValuesKey[],
  aggregate: {
    groupColumnName: TQueryValuesKey,
    groupColumnNames: TQueryValuesKey[],
    currentGroupColumnName: TQueryValuesKey,
  },
  advancedFilters?: IAdvancedFilters,
  parameters: FilterSettings,
  search?: {
    value: string,
    columns: TQueryValuesKey[],
  },
}

const API_QUERY = '/query/total';

const fetchTotals = (reqBody: ITotalRequestBody) => api
  .post(API_QUERY, reqBody)
  .then(({ data }) => data);

export default fetchTotals;
