import React, { FC } from 'react';

const SubgroupIcon: FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3333 0.666748V3.33341H5.33333V0.666748H11.3333Z" fill="#6C6F80" />
    <path d="M5.33333 7.33341V4.66675H11.3333V7.33341H5.33333Z" fill="#6C6F80" />
    <path d="M5.33333 8.66675V11.3334H11.3333V8.66675H5.33333Z" fill="#6C6F80" />
    <path d="M1.33333 11.3334H2V8.66675H1.33333V11.3334Z" fill="#6C6F80" />
    <path d="M1.33333 10.6667V11.3334H4V10.6667H1.33333Z" fill="#6C6F80" />
    <path d="M2 1.33341V3.33341H1.33333V0.666748L4 0.666748V1.33341H2Z" fill="#6C6F80" />
    <path d="M1.33334 4.00008H2.66667V5.33342H4V6.66676L2.66667 6.66675V8.00008H1.33334L1.33333 6.66675L0 6.66676L5.82818e-08 5.33342L1.33333 5.33341L1.33334 4.00008Z" fill="#6C6F80" />
  </svg>
);

export default SubgroupIcon;
