import React, {
  memo, useEffect, useState, 
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Blocker } from '@epam/promo';
import { IColumnConfig } from '@epam/uui-core';
import css from './SettingsSlideOut.module.scss';
import GroupingSettings from './components/GroupingSettings/GroupingSettings';
import { getGrouping } from '../../../const/GROUPINGS';
import settingsSlideoutAtom from '../../../state/atoms/settingsSlideoutAtom';
import queryParamsAtom, { Grouping } from '../../../state/atoms/queryParamsAtom';
import ColumnsSettings from './components/ColumnsSettings/ColumnsSettings';
import useColumnsSettings from '../../../hooks/useColumnsSettings';
import columnsSettingsAtom from '../../../state/atoms/columnsSettingsAtom';
import { useViewPresets } from '../../../hooks/useViewPresets';
import defaultViewPresetSelector from '../../../state/selectors/defaultViewPresetSelector';
import { SlidingPanel } from '../common/SlidingPanel/SlidingPanel';
import {
  accAttrColumnIds, colsIds, 
  getActiveColumnsForPreset, getColumnsWithRules, getConfigArray, oppAttrColumnIds, 
} from '../../../const/COLUMNS';
import sortingAtom from '../../../state/atoms/sortingAtom';
import PanelWithBtns from '../common/SlidingPanel/PanelWithBtns';
import useSlideout from '../../../hooks/useSlideout';
import { viewStateAtom } from '../../../state/atoms/viewStateAtom';
import { IQueryResponseValues, ISortingItem } from '../../../api/query';

const getNewSorting = (
  prev: ISortingItem[],
  columnIds: (keyof IQueryResponseValues)[],
): ISortingItem[] => {
  if (!prev.every((el) => columnIds.includes(el.columnName))) {
    return [
      {
        columnName: 'grouping',
        direction: 0,
      },
    ];
  }
  return prev;
};

const SettingsSlideOut = () => {
  // Slideout state
  const [{ tagsFlagsNotes, changed, warnings }, setSlideout] = useRecoilState(settingsSlideoutAtom);
  const { currentSlideout, closeSlideout } = useSlideout();
  const [,setColumnSettings] = useRecoilState(columnsSettingsAtom);
  const {
    setColumnsFromIdList,
  } = useColumnsSettings();
  const [{
    groupingStore: { multiGrouping: grouping },
    columnsStore,
  }, setQueryParams] = useRecoilState(queryParamsAtom);
  const { updateViewPresetInList, loading } = useViewPresets();
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);
  const [sortingStore, setSortingStore] = useRecoilState(sortingAtom);
  const [viewState, setViewState] = useRecoilState(viewStateAtom);

  const [columnsConfig, setColumnsConfig] = useState(viewState.columnsConfig);

  // Groupings state
  const [groupPickers, setGroupPickers] = useState(
    () => grouping.map((el) => el.id),
  );
  const [saveCheckedCheckboxes, setSaveCheckedCheckboxes] = useState(false);

  const configArr = getConfigArray(columnsConfig || {}, groupPickers);

  const preparedArr = configArr.map((el) => ({
    ...el,
    isVisible: (el.isVisible 
      && ((
        (accAttrColumnIds.includes(el.id) 
        && (groupPickers.includes('accName')
          || groupPickers.includes('oppName')
        ))
        || (
          oppAttrColumnIds.includes(el.id) && groupPickers.includes('oppName')
        )
        || ((el.id === 'prob' || el.id === 'accName') && groupPickers.includes('oppName'))
      ) || (
        el.id !== 'prob'
        && el.id !== 'accName'
        && colsIds.includes(el.id)
      ))) || el.id === 'columnsConfig' || el.id === 'grouping',
  }));

  const visibleArr = preparedArr.filter((el) => el.isVisible);
  const columnIds = visibleArr.map((el) => el.id) as (keyof IQueryResponseValues)[];

  useEffect(() => {
    const isOppLast = groupPickers[groupPickers.length - 1] === 'oppName';
    // Apply grouping rules to columns
    setColumnSettings((prev) => getColumnsWithRules(
      prev,
      groupPickers,
      isOppLast,
      saveCheckedCheckboxes,
    ));
    setSaveCheckedCheckboxes(false);
  }, [groupPickers]);

  // effect to handle grouping internal state change by view reset
  useEffect(() => {
    setGroupPickers(grouping.map((el) => el.id));
  }, [JSON.stringify(grouping)]);

  const handleApply = async () => {
    setQueryParams((prev) => ({
      ...prev,
      columnsStore: getActiveColumnsForPreset(visibleArr),
      groupingStore: {
        multiGrouping: groupPickers
          .reduce((acc: Grouping[], group) => {
            getGrouping(group, acc);
            return acc;
          }, []),
        tagsFlagsNotes,
        warnings,
      },
    }));
    setSortingStore((prev) => getNewSorting(prev, columnIds));

    closeSlideout();

    setSlideout((prev) => ({
      ...prev,
      changed: false, 
    }));

    const ungroupedColsFromView: { [key: string] : any } = {
      ...defaultViewPreset.columnSettings.columns.accAttr.reduce((acc, el) => ({
        ...acc,
        [el.id]: el,
      }), {}),
      ...defaultViewPreset.columnSettings.columns.col.reduce((acc, el) => ({
        ...acc,
        [el.id]: el,
      }), {}),
      ...defaultViewPreset.columnSettings.columns.oppAttr.reduce((acc, el) => ({
        ...acc,
        [el.id]: el,
      }), {}),
    };

    const columnsForView = getActiveColumnsForPreset(preparedArr);
    const columnsForViewWithTags = {
      col: [
        ...columnsForView.col,
      ].map((el) => ({ ...el, width: ungroupedColsFromView[el.id].width })),
      accAttr: [
        ...columnsForView.accAttr,
      ].map((el) => ({ ...el, width: ungroupedColsFromView[el.id].width })),
      oppAttr: [ 
        ...columnsForView.oppAttr,
      ].map((el) => ({ ...el, width: ungroupedColsFromView[el.id].width })),
    };

    if (defaultViewPreset.isViewAutosaved) {
      const updatedPreset = {
        ...defaultViewPreset,
        columnSettings: {
          ...defaultViewPreset.columnSettings,
          columns: columnsForViewWithTags,
          sort: getNewSorting(sortingStore, columnIds),
        },
        multiGrouping: {
          ...defaultViewPreset.multiGrouping,
          groups: groupPickers,
          tagsFlagsNotes,
          warnings,
        },
      };
      await updateViewPresetInList(updatedPreset);
    }

    setViewState((prev) => ({
      ...prev,
      columnsConfig: {
        ...prev.columnsConfig,
        ...preparedArr.reduce((acc, { id, ...el }) => ({
          ...acc,
          [id]: el,
        }), {
          grouping: {
            ...viewState?.columnsConfig?.grouping,
          },
        }), 
      },
    }));
    setColumnsConfig(preparedArr.reduce((acc, { id, ...el }) => ({
      ...acc,
      [id]: el,
    }), {
      grouping: {
        ...viewState?.columnsConfig?.grouping,
      },
    }));

    if (
      JSON.stringify(defaultViewPreset.multiGrouping.groups) 
    !== window.localStorage.getItem(defaultViewPreset.id)
    ) {
      window.localStorage.removeItem(defaultViewPreset.id);
    }
  };

  const handleClose = () => {
    setSaveCheckedCheckboxes(true);
    setGroupPickers(() => grouping.map((el) => el.id));
    setColumnsFromIdList(columnsStore);
    setColumnsConfig(viewState.columnsConfig);
    closeSlideout();
    setSlideout((prev) => ({
      ...prev,
      changed: false, 
    }));
  };

  const handleColumnsConfigChange = (key: keyof IQueryResponseValues, value: IColumnConfig) => {
    setColumnsConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleReset = () => {
    setColumnsConfig(viewState.columnsConfig);
    setSlideout((prev) => ({
      ...prev,
      changed: false,
    }));
  };

  return (
    <SlidingPanel
      width={360}
      position="right"
      isVisible={currentSlideout === 'columnsSettings'}
      overflow="auto"
      withBlocker={false}
    >
      <PanelWithBtns
        onReset={handleReset}
        onClose={handleClose}
        onApply={handleApply}
        isApplyDissabled={!changed || !groupPickers.length}
        caption="COLUMNS AND GROUPING SETTINGS"
      >
        <div className={css.settingsContainer}>
          <GroupingSettings 
            data={groupPickers} 
            setState={setGroupPickers}
          />
          <ColumnsSettings 
            config={configArr} 
            grouping={groupPickers} 
            onConfigChange={handleColumnsConfigChange}
          />
          <Blocker isEnabled={loading} />
        </div>
      </PanelWithBtns>  
    </SlidingPanel>
  );
};

export default memo(SettingsSlideOut);
