import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useColumnsConfig } from '@epam/uui-core';
import EditModeGrid from '../components/EditModeGrid/EditModeGrid';
import EditModePanel from '../components/EditModePanel/EditModePanel';
import FiltersPanel from '../components/FiltersPanel/FiltersPanel';
import Grid from '../components/Grid/Grid';
import viewModeAtom from '../state/atoms/viewModeAtom';
import advancedFiltersConstructorAtom from '../state/atoms/advancedFiltersConstructorAtom';
import defaultViewPresetSelector from '../state/selectors/defaultViewPresetSelector';
import useVirtualListColumns from '../hooks/useVirtualListColumns';
import useQueryApi from '../hooks/useQueryApi';
import queryParamsAtom from '../state/atoms/queryParamsAtom';
import { getConfigArray, sortColumnsByConfig } from '../const/COLUMNS';

const setGridHeight = () => {
  const filterPanelHeight = document.querySelector('#filterPanel')?.clientHeight ?? 0;
  document.documentElement.style.setProperty('--headerHeight', `${filterPanelHeight + 60}px`);
};

const MainPage = () => {
  const { mode } = useRecoilValue(viewModeAtom);
  const { constructor, isConstructor } = useRecoilValue(advancedFiltersConstructorAtom);
  const { id } = useRecoilValue(defaultViewPresetSelector);
  const [{
    groupingStore: { multiGrouping: grouping },
  }] = useRecoilState(queryParamsAtom);

  useEffect(() => {
    setTimeout(() => {
      setGridHeight();
    }, 1000);
    window.addEventListener('resize', setGridHeight);
    return () => window.removeEventListener('resize', setGridHeight);
  }, []);

  useEffect(() => {
    setGridHeight();
  }, [Object.keys(constructor[0].filters ?? {}).length, isConstructor, id, mode]);

  const { getListColumns } = useVirtualListColumns();
  const listColumns = getListColumns();

  const {
    lazyView, viewState, setViewState, refreshGrid, saveLens,
  } = useQueryApi();

  const { columns, config } = useColumnsConfig(listColumns, viewState.columnsConfig
    || {});

  const configArr = getConfigArray(config, grouping.map((it) => it.id), true);
  
  const sortedColumns = [
    ...sortColumnsByConfig(columns.filter((col) => !col.key.startsWith('yyyyyy')), configArr), 
    ...columns.filter((col) => col.key.startsWith('yyyyyy')).sort((a, b) => (a.key > b.key ? 1 : -1)),
  ];

  const gridProps = {
    lazyView,
    viewState,
    setViewState,
    columns: sortedColumns,
    config,
    refreshGrid,
  };
  
  const isViewMode = mode === 'view';
  const renderPanel = isViewMode ? <FiltersPanel /> : <EditModePanel saveLens={saveLens} />;
  const renderGrid = isViewMode
    ? <Grid {...gridProps} />
    : <EditModeGrid {...gridProps} />;

  return (
    <>
      {renderPanel}
      {renderGrid}
    </>
  );
};

export default MainPage;
