import { atom, selector } from 'recoil';
import { IBreakdownItem } from '../../components/Grid/VirtualList/VirtualListColumns';
import defaultViewPresetSelector from '../selectors/defaultViewPresetSelector';
import { TBreakdown } from '../../const/FILTERS';

export const createInitialBreakdown = (year: number, breakdown: TBreakdown): IBreakdownItem[] => {
  const res: IBreakdownItem[] = [];
  const absYear = Math.abs(year);
  if (breakdown === 'Quarterly') {
    for (let i = 1; i < 13; i++) {
      if (i <= 4) {
        res.push(
          {
            index: i,
            year: absYear,
            type: 'q',
            
          },
        );
      }
      if (i > 4 && i < 9) {
        res.push(
          {
            index: i - 4,
            year: absYear + 1,
            type: 'q',
          },
        );
      }
      if (i > 8 && i < 13) {
        res.push(
          {
            index: i - 8,
            year: absYear + 2,
            type: 'q',
          },
        );
      }
    }
  } 
  if (breakdown === 'Monthly'){
    for (let i = 1; i < 13; i++) {
      res.push(
        {
          index: i,
          year: absYear,
          type: 'm',
        },
      );
    }
  }
  return res;
};

const breakdownAtom = atom({
  key: 'breakdownStore',
  default: selector({
    key: 'breakdownStore/default',
    get: ({ get }) => {
      const view = get(defaultViewPresetSelector);
      const { breakdown, year } = view.permanentFiltersSettings;
      return createInitialBreakdown(year, breakdown);
    },
  }),
});

export default breakdownAtom;
