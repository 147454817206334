import { useEffect, useRef, useState } from 'react';

export const useAdvancedFiltersDropdownHeight = (trigger: boolean) => {
  const [dropdownHeight, setDropdownHeight] = useState<number>(0);
  const targetRef = useRef<HTMLDivElement>(null);

  const calculateHeight = () => {
    setTimeout(() => {
      if (!targetRef.current) return;
      const rect = targetRef.current.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - rect.top;
      setDropdownHeight(distanceToBottom);
    }, 0);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, [trigger]);

  return { dropdownHeight, targetRef };
};
