import {
  ArrayDataSource, ArrayDataSourceProps, AsyncDataSource,
  AsyncDataSourceProps, FilterPredicateName, IFilterPredicate,
  LazyDataSource, LazyDataSourceApiRequest, LazyDataSourceApiResponse,
} from '@epam/uui-core';
import { sortBy } from 'lodash';
import { useHierarchyPickerInput } from '../hooks/useHierarchyPickerInput';
import { fetchAdvancedFilterOptions } from '../api/filters';
import { IAdvancedFilter, IAdvancedFilters, TQueryValuesKey } from '../api/query';
import { IHierarchicalItem } from '../state/atoms/advancedFiltersConstructorAtom';
import { provideOrderPropertyToArr } from '../helpers/provideOrderValue';

// TODO Provide types for all 'any'

export type ExtendedFilterPredicate = Omit<IFilterPredicate, 'predicate'> & {
  predicate: FilterPredicateName | 'on' | 'before' | 'after';
};

export interface IFilterbarItem {
  field: string,
  columnKey: string,
  title: string,
  type: any, // 'multiPicker' | 'singlePicker' | 'datePicker' | 'numeric',
  isAlwaysVisible: boolean,
  dataSource?: any,
  predicates: ExtendedFilterPredicate[],
}

export interface IAdvancedFilterState {
  [key: string]: {
    [predicate: TQueryValuesKey]: string | number | string [],
  }
}

interface IPredicate {
  predicate: string,
  id: string,
  name: string,
}

interface IMappedDictionary {
  [key: string]: IPredicate,
}

export type TLogicalOperator = 'And' | 'Or';

export const singlePickerIds: TQueryValuesKey[] = ['closeDate', 'prob'];

export type TSinglePickerOptionList = {
  id: number,
  name: string,
}[];

export interface ISinglePickerOptions {
  [key: TQueryValuesKey]: TSinglePickerOptionList,
}

export const singlePickerOptions: ISinglePickerOptions = {
  closeDate: [
    {
      id: 1,
      name: 'Next Month',
    },
    {
      id: 3,
      name: 'Next Quarter',
    },
    {
      id: 0,
      name: 'This Month',
    },
    {
      id: 2,
      name: 'This Quarter',
    },
  ],
  prob: [
    {
      id: 895450000,
      name: '0% - Non-qualified',
    },
    {
      id: 895450010,
      name: '10% - Qualified',
    },
    {
      id: 895450020,
      name: '20% - Proposal submitted',
    },
    {
      id: 895450040,
      name: '40% - Solution accepted / EPAM shortlisted',
    },
    {
      id: 895450060,
      name: '60% - Verbal win notification',
    },
    {
      id: 895450080,
      name: '80% - Written win notification',
    },
    {
      id: 895450090,
      name: '90% - Contract Negotiation',
    },
    {
      id: 895450100,
      name: '100% - Contract Signed',
    },
  ],
};

export const hierarchicalPickers = ['oppContinuumOfferings', 'oppIndustry', 'oppLeadPractice', 'oppPractices'];

export const predicateDictionary: IMappedDictionary = {
  eq: { predicate: 'eq', id: 'Equal', name: '=' },
  neq: { predicate: 'neq', id: 'NotEqual', name: '≠' },
  gt: { predicate: 'gt', id: 'Greater', name: '>' },
  gte: { predicate: 'gte', id: 'GreaterEqual', name: '≥' },
  lt: { predicate: 'lt', id: 'Lower', name: '<' },
  lte: { predicate: 'lte', id: 'LowerEqual', name: '≤' },
  on: { predicate: 'on', id: 'On', name: 'on' },
  before: { predicate: 'before', id: 'Before', name: 'before' },
  after: { predicate: 'after', id: 'After', name: 'after' },
};

export const predicateDictionaryValues = Object.values(predicateDictionary);

// ID follows 1.1.1 pattern  to keep elements linked throught the entire tree structure
export const getNewId = (parentId?: string, prevId?: string) => {
  if (prevId) {
    const idArr = prevId.split('.').map((el) => Number(el));
    const lastNumber = idArr[idArr.length - 1] + 1;
    const newIdArr = idArr.map((number, idx) => {
      if (idx === idArr.length - 1) return lastNumber;
      return number;
    });
    return newIdArr.join('.');
  }

  if (!parentId) return '1';

  return `${parentId}.1`;
};

// Parses Advanced Filters BE appropriate format to Constructor format
// TODO: Provide typing for every ANY
export const parseAdvancedFiltersFromView = (
  { operator, predicates }: any,
  parentId?:string,
  prevId?:string,
) => {
  const id = getNewId(parentId, prevId);
  const noFaultyValuesArr = predicates.filter((el: any) => el);
  const res: IHierarchicalItem = {
    operator,
    id,
    kind: 'subgroup',
    filters: provideOrderPropertyToArr(
      noFaultyValuesArr
        .filter((el: any) => Object.keys(el).includes('key')),
      id,
    ).reduce((acc, el: any) => ({
      ...acc,
      [el.key]: el,
    }), {}),
    children: noFaultyValuesArr
      .filter((el: any) => !Object.keys(el).includes('key'))
      .reduce((acc: any[], el: any, idx: number) => [
        ...acc,
        parseAdvancedFiltersFromView(el, id, acc[idx - 1]?.id),
      ], []),
  };

  return res;
};

const processFilters = (arr: IAdvancedFilter[]) => arr.map((el) => {
  if (singlePickerIds.includes(el.key) 
  && (typeof el.value !== 'number' 
    && typeof el.value !== 'string' && !Array.isArray(el.value)
  )
  ) {
    return {
      ...el,
      value: el.value?.id,
    };
  }
  if (Array.isArray(el.value)) {
    return {
      ...el,
      value: el.value.filter((element) => element),
    };
  }
  return el;
});

// Parses Advanced Filters Constructor to BE appropriate format
export const parseAdvancedFiltersToView = (
  advancedFilters: IHierarchicalItem[],
) => {
  const parseSubgroup = (subgroup: IHierarchicalItem): IAdvancedFilters => {
    if (!subgroup.children?.length) {
      return {
        operator: subgroup.operator,
        predicates: sortBy(processFilters(Object.values(subgroup.filters))
          .filter((el) => {
            const isArrayWithValue = Array.isArray(el.value) && el.value.length;
            const isPrimitive = !Array.isArray(el.value) && el.value !== undefined;

            return isArrayWithValue || isPrimitive;
          }), ['order']),
      };
    }
    return {
      operator: subgroup.operator,
      predicates: [
        ...sortBy(processFilters(Object.values(subgroup.filters)), ['order']),
        ...subgroup.children.map(parseSubgroup),
      ],
    };
  };
  return advancedFilters.map(parseSubgroup)[0];
};

let options: Record<string, any> = {};

// Notes Updated on and Any Practice are not synced with BE
export const useCreateDataSource = () => {
  // const [state, setState] = useState<any>({});
  const { isHierarchy } = useHierarchyPickerInput();

  const createDataSource = (
    key: string,
    params?: ArrayDataSourceProps<any, any, any> | AsyncDataSourceProps<any, any, any>,
  ) => {
    if (singlePickerIds.includes(key)) {
      return new ArrayDataSource({
        ...params,
        items: singlePickerOptions[key],
        getId: (i) => i.id,
      });
    }

    if (options[key]) {
      return new ArrayDataSource({
        ...params,
        items: options[key],
        getId: (i) => i.id || 'null',
      });
    }

    return new AsyncDataSource({
      api() {
        return fetchAdvancedFilterOptions(
          key,
          undefined,
          isHierarchy(key),
        ).then((res) => {
          options = {
            ...options,
            [key]: res.items,
          };
          return res.items;
        });
      },
      getId: (i) => i.id || i.name,
      getParentId: (i) => i.parentId,
    });
  };

  const createLazyDataSource = (
    key: string,
  ) => new LazyDataSource({
    api: (
      request: LazyDataSourceApiRequest<any, any, any>,
    ): Promise<LazyDataSourceApiResponse<any>> => fetchAdvancedFilterOptions(
      key,
      request.search,
      isHierarchy(key),
      false,
      request.range!.from,
      request.range!.count,
    ).then((res) => {
      options = {
        ...options,
        [key]: res.items,
      };
      return {
        items: res.items,
      };
    }),
    getId: (i) => i.id || i.name,
    getParentId: (i) => i.parentId,
  });

  const getAdvancedFilters = (): IFilterbarItem[] => [
    {
      field: 'accAcquisitionName',
      columnKey: 'accAcquisitionName',
      title: 'Acc Acquisition Name',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accAcquisitionName'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accAM',
      columnKey: 'accAM',
      title: 'Account AM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accAM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accName',
      columnKey: 'accName',
      title: 'Account Name',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accName'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accPursuitTeam',
      columnKey: 'accPursuitTeam',
      title: 'Account Pursuit Team',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accPursuitTeam'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accSM',
      columnKey: 'accSM',
      title: 'Account SM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accSM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accState',
      columnKey: 'accState',
      title: 'Account Status',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accState'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accTag',
      columnKey: 'accTag',
      title: 'Acc. Operational Labels',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accTag'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'anyGBU',
      columnKey: 'anyGBU',
      title: 'Any GBU',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('anyGBU'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accDigitalSM',
      columnKey: 'accDigitalSM',
      title: 'Digital SM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accDigitalSM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'GBU',
      columnKey: 'GBU',
      title: 'GBU',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('GBU'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'GBUPortfolio',
      columnKey: 'GBUPortfolio',
      title: 'GBU Portfolio',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('GBUPortfolio'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'RegionalGBU',
      columnKey: 'RegionalGBU',
      title: 'GBU Regional',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('RegionalGBU'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'globalAccName',
      columnKey: 'globalAccName',
      title: 'Global Account Name',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('globalAccName'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accExtRepCountry',
      columnKey: 'accExtRepCountry',
      title: 'Reporting Country',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accExtRepCountry'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accExtRepInd',
      columnKey: 'accExtRepInd',
      title: 'Reporting Industry',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accExtRepInd'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppSalesType',
      columnKey: 'oppSalesType',
      title: 'Sales Type',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppSalesType'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'accSuppAM',
      columnKey: 'accSuppAM',
      title: 'Supporting AM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('accSuppAM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'SuppGBU',
      columnKey: 'SuppGBU',
      title: 'Supporting GBU',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('SuppGBU'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'SuppGBUPortfolio',
      columnKey: 'SuppGBUPortfolio',
      title: 'Supporting GBU Portfolio',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('SuppGBUPortfolio'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'SuppRegionalGBU',
      columnKey: 'SuppRegionalGBU',
      title: 'Supporting GBU Regional',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('SuppRegionalGBU'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'modBy',
      columnKey: 'modBy',
      title: 'FRCST/% Updated By',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('modBy'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'modDate',
      columnKey: 'modDate',
      title: 'FRCST/% Updated On',
      type: 'datePicker',
      isAlwaysVisible: false,
      predicates: [
        { predicate: 'on', name: 'on', isDefault: true },
        { predicate: 'before', name: 'before' },
        { predicate: 'after', name: 'after' },
      ],
    },
    {
      field: 'gttl',
      columnKey: 'gttl',
      title: 'GTTL',
      type: 'numeric',
      isAlwaysVisible: false,
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
        { predicate: 'lte', name: '≤' },
        { predicate: 'lt', name: '<' },
        { predicate: 'gt', name: '>' },
        { predicate: 'gte', name: '≥' },
      ],
    },
    {
      field: 'OSC',
      columnKey: 'OSC',
      title: 'Additional Competencies',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('OSC'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'commercialModel',
      columnKey: 'commercialModel',
      title: 'Commercial Model',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('commercialModel'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'commisionable',
      columnKey: 'commisionable',
      title: 'Commissionable',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('commisionable'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppAging',
      columnKey: 'oppAging',
      title: 'Days w/o update',
      type: 'numeric',
      isAlwaysVisible: false,
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
        { predicate: 'lte', name: '≤' },
        { predicate: 'lt', name: '<' },
        { predicate: 'gt', name: '>' },
        { predicate: 'gte', name: '≥' },
      ],
    },
    {
      field: 'oppDealType',
      columnKey: 'oppDealType',
      title: 'Deal Type',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppDealType'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppEngagementLeads',
      columnKey: 'oppEngagementLeads',
      title: 'Engagement Lead',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createLazyDataSource('oppEngagementLeads'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'closeDate',
      columnKey: 'closeDate',
      title: 'Est. Start Date',
      type: 'singlePicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('closeDate'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppTagFlag',
      columnKey: 'oppTagFlag',
      title: 'Flags',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppTagFlag'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppNotesLastUpdatedOn',
      columnKey: 'oppNotesLastUpdatedOn',
      title: 'Notes Updated On',
      type: 'datePicker',
      isAlwaysVisible: false,
      predicates: [
        { predicate: 'on', name: 'on', isDefault: true },
        { predicate: 'before', name: 'before' },
        { predicate: 'after', name: 'after' },
      ],
    },
    {
      field: 'oppAcquisitionName',
      columnKey: 'oppAcquisitionName',
      title: 'Opp Acquisition Name',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppAcquisitionName'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppAM',
      columnKey: 'oppAM',
      title: 'Opportunity AM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppAM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppCreatedBy',
      columnKey: 'oppCreatedBy',
      title: 'Opportunity Created By',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppCreatedBy'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppCreatedOn',
      columnKey: 'oppCreatedOn',
      title: 'Opportunity Created On',
      type: 'datePicker',
      isAlwaysVisible: false,
      predicates: [
        { predicate: 'on', name: 'on', isDefault: true },
        { predicate: 'before', name: 'before' },
        { predicate: 'after', name: 'after' },
      ],
    },
    {
      field: 'oppName',
      columnKey: 'oppName',
      title: 'Opportunity Name',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppName'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppPursuitTeam',
      columnKey: 'oppPursuitTeam',
      title: 'Opportunity Pursuit Team',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppPursuitTeam'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppSM',
      columnKey: 'oppSM',
      title: 'Opportunity SM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppSM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppState',
      columnKey: 'oppState',
      title: 'Opportunity Status',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppState'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppTags',
      columnKey: 'oppTags',
      title: 'Operational Labels',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppTags'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppPartnerAccounts',
      columnKey: 'oppPartnerAccounts',
      title: 'Partner Accounts',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppPartnerAccounts'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppPartnerSalesManagers',
      columnKey: 'oppPartnerSalesManagers',
      title: 'Partner Sales Manager',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppPartnerSalesManagers'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'prob',
      columnKey: 'prob',
      title: 'Probability',
      type: 'singlePicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('prob'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
        { predicate: 'lte', name: '≤' },
        { predicate: 'lt', name: '<' },
        { predicate: 'gt', name: '>' },
        { predicate: 'gte', name: '≥' },
      ],
    },
    {
      field: 'projstr',
      columnKey: 'projstr',
      title: 'Projects linked',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('projstr'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'SS',
      columnKey: 'SS',
      title: 'Sales Stage',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('SS'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'SU',
      columnKey: 'SU',
      title: 'Sales Unit',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('SU'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppSecondarySM',
      columnKey: 'oppSecondarySM',
      title: 'Secondary SM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppSecondarySM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppStream',
      columnKey: 'oppStream',
      title: 'Stream',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppStream'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppCorrections',
      columnKey: 'oppCorrections',
      title: 'Warnings',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppCorrections'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppAdvisoryAM',
      columnKey: 'oppAdvisoryAM',
      title: 'Advisory AM',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppAdvisoryAM'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppEngagementType',
      columnKey: 'oppEngagementType',
      title: 'Engagement Type',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppEngagementType'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppPractices',
      columnKey: 'oppPractices',
      title: 'Practices',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppPractices'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppLeadPractice',
      columnKey: 'oppLeadPractice',
      title: 'Lead Practice',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppLeadPractice'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppIndustry',
      columnKey: 'oppIndustry',
      title: 'Industry',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppIndustry'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppPlatforms',
      columnKey: 'oppPlatforms',
      title: 'Platforms',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppPlatforms'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
    {
      field: 'oppContinuumOfferings',
      columnKey: 'oppContinuumOfferings',
      title: 'Continuum Offerings',
      type: 'multiPicker',
      isAlwaysVisible: false,
      dataSource: createDataSource('oppContinuumOfferings'),
      predicates: [
        { predicate: 'eq', name: '=', isDefault: true },
        { predicate: 'neq', name: '≠' },
      ],
    },
  ];

  return {
    createDataSource,
    getAdvancedFilters,
  };
};
