import { DataTableCell } from '@epam/promo';
import { RenderCellProps, RenderEditorProps } from '@epam/uui-core';
import { debounce } from 'lodash';
import React, {
  FC,
  useEffect,
  useState,
  memo,
  useCallback,
} from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import editedCellsAtom, { addToEdited } from '../../../state/atoms/editedCellsAtom';
import gridFormAtom from '../../../state/atoms/gridFormAtom';
import viewModeAtom from '../../../state/atoms/viewModeAtom';
import updateAtomsForQuerySelector from '../../../state/selectors/updateAtomsForQuerySelector';
import { probabilityDictionary } from '../const';
import css from '../editModeGrid.module.scss';
import NumericEditor from './NumericEditor';
import editModeErrorsAtom, { VALUE_ERROR_MSG } from '../../../state/atoms/editModeErrorsAtom';
import { formatData } from '../../Grid/VirtualList/components/GridDataCell';

const getIsNumber = (val: any): boolean => typeof val === 'number';

const getIsValueError = ({
  newVal,
  exchangeRate,
}: {
  newVal: number | null,
  exchangeRate: number,
}) => (newVal !== null
  && getIsNumber(newVal)
  && (newVal / exchangeRate > 50000 || newVal / exchangeRate < -50000));

interface Props extends RenderCellProps<any, any> {
  cellId: string,
  year: number,
  columnName: string,
}

const EditableGridCell: FC<Props> = ({ cellId, year, ...props }) => {
  const uniqueId = cellId === 'gttl' ? 'gttl_p' : `${cellId}_p_${year}`;
  const [isFocused, setIsFocused] = useState(false);
  const editedCells = useRecoilValue(editedCellsAtom);
  const setAtoms = useSetRecoilState(updateAtomsForQuerySelector);
  const [errors, setErrors] = useRecoilState(editModeErrorsAtom);
  const { isLoading } = useRecoilValue(viewModeAtom);
  const [isRed, setIsRed] = useState(false);
  const { 
    isLastColumn,
    columnName,
    rowProps: {
      value: {
        values: {
          oppId, oppName, isBaseRequiredFieldsSet, currName, prob, exchangeRate,
        }, 
      }, 
    }, 
  } = props;

  const idxWithErrors = errors.findIndex((el) => el.oppId === oppId);
  const isWithValueErrorMessage = idxWithErrors >= 0 
    && errors[idxWithErrors].message.includes(VALUE_ERROR_MSG);

  const form = useRecoilValue(gridFormAtom);
  const probId = form.items[oppId].prob 
    ? probabilityDictionary[form.items[oppId].prob].id 
    : probabilityDictionary[prob].id;

  const originalVal = cellId === 'gttl' ? (form.items[oppId].values.gttl_p as number | null) : form.items[oppId].breakdown[uniqueId];
  const originalValState = useState(originalVal);

  const item = {
    cellId,
    uniqueColumnName: columnName,
    uniqueId,
    oppId,
    probId,
    currName,
    originalValState,
    isLastColumn,
    minVal: Number.NEGATIVE_INFINITY,
    isFocused,
  };

  const getIsDisabled = (): boolean => {
    if (!isBaseRequiredFieldsSet) return true;

    if (cellId === 'gttl') {
      if (year + 2000 > new Date().getFullYear() + 1) return true;

      const { hasForecastRevenue, hasActualRevenue } = props.rowProps.value.values;

      return !!hasForecastRevenue || !!hasActualRevenue;
    }
    
    return isLoading;
  };

  const setErrMsg = () => {
    setIsRed(true);
    setErrors((prev) => {
      if (idxWithErrors >= 0 && !prev[idxWithErrors].message.includes(VALUE_ERROR_MSG)) {
        const itemToUpdate = prev[idxWithErrors];

        return [
          ...prev.slice(0, idxWithErrors),
          {
            ...itemToUpdate,
            message: [...itemToUpdate.message, VALUE_ERROR_MSG],
          },
          ...prev.slice(idxWithErrors + 1),
        ];
      }

      return [
        ...prev,
        {
          oppId,
          oppName,
          message: [
            VALUE_ERROR_MSG,
          ],
        },
      ];
    });
  };

  const removeErrMsg = () => {
    setIsRed(false);
    setErrors((prev) => {
      const itemToUpdate = prev[idxWithErrors];
      if (prev[idxWithErrors]?.message.length > 1) {
        return [
          ...prev.slice(0, idxWithErrors),
          {
            ...itemToUpdate,
            message: itemToUpdate.message.filter((el) => el !== VALUE_ERROR_MSG),
          },
          ...prev.slice(idxWithErrors + 1),
        ];
      }
      return [
        ...prev.slice(0, idxWithErrors),
        ...prev.slice(idxWithErrors + 1),
      ];
    });
  };

  const updateAtomDebounce = useCallback(
    debounce(({ newVal, stringUniqueId, isReverted }) => {
      setAtoms(({
        gridFormAtom: gridFormAtoms,
        editedCellsAtom: prevEditedCellsAtom,
        ...prev
      }) => ({
        ...prev,
        gridFormAtom: {
          items: {
            ...gridFormAtoms.items,
            [oppId]: {
              ...gridFormAtoms.items[oppId],
              [stringUniqueId ?? uniqueId]: newVal,
            },
          },
        },
        editedCellsAtom: !isReverted && item.originalValState[0] !== newVal
          ? addToEdited(prevEditedCellsAtom, oppId, uniqueId)
          : {
            ...prevEditedCellsAtom,
            [oppId]: prevEditedCellsAtom[oppId].filter((el) => el !== (stringUniqueId ?? uniqueId)),
          },
      }));
    }, 300),
    [],
  );

  const changeCellVal = (
    newVal: number | null,
    editorProps?: RenderEditorProps<any, any, any>,
    isReverted?: boolean,
    stringUniqueId?: string,
  ) => {
    const isValueError = getIsValueError({ newVal, exchangeRate });

    updateAtomDebounce({ newVal, stringUniqueId, isReverted });

    editorProps && editorProps.onValueChange(newVal);

    if (isValueError && newVal !== originalVal) {
      setErrMsg();
    }
    // Remove error message if value is fixed
    if (errors.length && ((!isValueError && isWithValueErrorMessage) || originalVal === newVal)) {
      removeErrMsg();
    }
  };

  useEffect(() => {
    const newVal = props.rowLens.prop(uniqueId).toProps().value;
    const isValueError = getIsValueError({ newVal, exchangeRate });

    if (newVal === originalVal) {
      return;
    }

    if (isValueError) {
      setErrMsg();
    }
    // Remove error message if value is fixed
    if (!isValueError && isWithValueErrorMessage) {
      removeErrMsg();
    }
  }, [props.rowLens.prop(uniqueId).toProps().value]);

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const getCellBackGround = () => {
    if (isRed) return 'red';

    if (editedCells[oppId]
      && editedCells[oppId].includes(uniqueId)) {
      return 'blue';
    }

    return undefined;
  };

  const cellProps = props.rowLens.prop(uniqueId).toProps();

  return (
    <DataTableCell
      {...cellProps}
      renderEditor={(editorProps) => (
        <NumericEditor
          editorProps={editorProps}
          getIsDisabled={getIsDisabled}
          item={item}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          changeCellVal={changeCellVal}
          tooltipContent={formatData(cellProps.value, true, currName)}
        />
      )}
      {...props}
      isLastColumn={false}
      cx={[
        cellId === 'gttl' ? css.gttlCell : css.breakdownCell,
        getIsDisabled() && css.disabledCell,
        getCellBackGround() === 'blue' && css.changedCell,
        getCellBackGround() === 'red' && css.errorCell,
      ]}
    />
  );
};

export default memo(EditableGridCell);
