import React from 'react';
import { Text } from '@epam/promo';
import noPermessions from '../../assets/svg/errors/noPermessions.svg';
import css from './AccessDeniedErrorPage.module.scss';

const AccessDeniedErrorPage = () => {
  const text = 'You have no permissions for Pipeline application';
  return (
    <div className={css.root}>
      <img src={noPermessions} alt={text} />
      <Text cx={css.text} font="museo-sans">{text}</Text>
    </div>
  );
};

export default AccessDeniedErrorPage;
