import {
  DataTable, FlexRow, Text, 
} from '@epam/promo';
import { DataColumnProps, useAsyncDataSource } from '@epam/uui-core';
import React, { useState } from 'react';
import { getLinkedProjects } from '../../api/oneCrm';

import css from './OneCrmGrid.module.scss';

interface Props {
  id: string;
  columns: DataColumnProps[];
}

export const OneCrmProjectsGrid = (props: Props) => {
  const { id, columns } = props;

  const [projectVal, setProjectVal] = useState({});

  const projectsDataSource = useAsyncDataSource({
    api: () => getLinkedProjects(id).then((res) => res.data.items),
  }, []);

  const projectsView = projectsDataSource.useView(projectVal, setProjectVal);

  return (
    <>
      <FlexRow cx={css.pageHeader}>
        <Text cx={css.title}>
          Linked Projects
        </Text>
      </FlexRow> 
      <DataTable
        {...projectsView.getListProps()}
        getRows={projectsView.getVisibleRows}
        columns={columns}
        value={projectVal}
        onValueChange={setProjectVal}
      />
    </>
  );
};
