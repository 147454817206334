import { atom } from 'recoil';

export type TSlideout = 'columnsSettings' | 'oppDetails' | 'advFiltersConstructor';

const slideoutStateAtom = atom<TSlideout | ''>({
  key: 'slideoutStateStore',
  default: '',
});

export default slideoutStateAtom;
