/* eslint-disable react/destructuring-assignment */
import React, { FC, useEffect, useState } from 'react';
import { mapValues } from 'lodash';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { IModal } from '@epam/uui-core';
import {
  ModalBlocker, ModalWindow, FlexSpacer, ModalHeader, FlexRow, LabeledInput,
  Button, ScrollBars, ModalFooter, useForm, NumericInput, TextArea,
  Panel, FlexCell, ControlWrapper, RadioGroup, DatePicker,
} from '@epam/promo';
import { ReactComponent as loadingIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import {
  editProbabilityAPI, TModalData, TModalFieldsData, TModalFieldsDataKeys, 
} from '../../../api/editProbability';
import { fieldsLabels, TProbIds } from './const';
import PickerInputField from './PickerInputField';
import css from './EditProbabilityModal.module.scss';
import { useNotification } from '../../../hooks/useNotification';
import gridFormAtom from '../../../state/atoms/gridFormAtom';
import queryParamsAtom from '../../../state/atoms/queryParamsAtom';

interface RequiredFields {
  [key: string]: {
    isRequired: boolean
  };
}

interface DataObject {
  [key: string]: any;
}

const areAllRequiredFieldsFilled = (
  requiredFields: RequiredFields,
  dataObject: DataObject,
):boolean => Object
  .keys(requiredFields)
  .filter((key) => requiredFields[key].isRequired)
  .every((key) => dataObject[key] && dataObject[key] !== null);

interface EditProbabilityModalProps extends IModal<TModalFieldsData> {
  modalData: TModalData,
  probId: TProbIds,
  setOldVal?: React.Dispatch<React.SetStateAction<number>>,
  setValBack?: () => void,
  apply?: () => void,
  canEdit: boolean,
}

const SUCCESS_MSG = 'Mandatory opportunity attributes have been updated. Please save probability and revenue forecasts after edit';

const EditProbabilityModal:FC<EditProbabilityModalProps> = ({
  setOldVal, setValBack, apply: onApply, canEdit, ...modalProps
}) => {
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const metaData = mapValues(modalProps.modalData.fields, () => ({ isRequired: true }));

  const { filtersStore } = useRecoilValue(queryParamsAtom);
  const { revenueYear } = filtersStore;
  const setForm = useSetRecoilState(gridFormAtom);

  const {
    lens,
    save,
    setValue,
    value,
  } = useForm<TModalFieldsData>({
    value: {},
    onSave: (data) => {
      setIsLoading(true);

      const dataForApi = mapValues(data, (obj: any) => {
        if (obj) {
          if (obj.id) return obj.id;
          if (Array.isArray(obj)) {
            return obj.map((item) => item.id);
          }
        }
        return obj;
      });
      
      return editProbabilityAPI.saveModalData({
        ...modalProps.modalData,
        prob: modalProps.probId,
        fields: dataForApi,
      }).then(() => {
        setIsLoading(false);
        showNotification(SUCCESS_MSG, undefined, 'green');
        onApply && onApply();
        modalProps.success(data);

        const changedValues: any = {};
        let gttl = 0;
        Object.keys(data).forEach((key) => {
          if (key === 'gttl' || key === 'gttl1k') {
            const currentYear = new Date().getFullYear();
            if (data.gttl && revenueYear === currentYear) {
              gttl = data.gttl;
            }
            if (data.gttl1k && revenueYear === currentYear + 1) {
              gttl = data.gttl1k;
            }
          } else {
            const field: any = data[key as keyof TModalFieldsData];
            if (field && Array.isArray(field)) {
              changedValues[key] = field.map((item) => item.name);
            } else {
              changedValues[key] = field && field.name ? field.name : field;
            }
          }
        });

        setForm((prev) => ({
          items: {
            ...prev.items,
            [modalProps.modalData.oppId]: {
              ...prev.items[modalProps.modalData.oppId],
              gttl_p: data.gttl || data.gttl1k
                ? gttl
                : prev.items[modalProps.modalData.oppId].gttl_p,
              values: {
                ...prev.items[modalProps.modalData.oppId].values,
                ...changedValues,
              },
            },
          },
        }));
      })
        .catch((error) => {
          setIsLoading(false);
          showNotification(
            error.response?.data?.message
              ? error.response.data.message
              : 'Failed to get a response from CRM. The data has not been saved',
            undefined,
            'red',
          );
        });
    },
    onSuccess: (data) => {
      setOldVal && setOldVal(modalProps.probId);
      modalProps.success(data);
    },
    beforeLeave: null,
    getMetadata: (state: TModalFieldsData) => ({
      props: {
        ...metaData,
        oppPartnerAccounts: { isRequired: state.dealIsPartnerRelated === 895450001 },
        gttl: { isRequired: ('gttl' in metaData) && !state.gttl1k },
        gttl1k: { isRequired: ('gttl1k' in metaData) && !state.gttl },
      },
    }),
  });

  // @ts-ignore
  const isSaveEnabled = areAllRequiredFieldsFilled(lens.lens.getMetadata().props, value);

  useEffect(() => {
    const { oppPractices, oppLeadPractice } = lens.get();
    if (!oppPractices?.length 
      || (oppLeadPractice && !(oppPractices as any).some(
        (item: any) => item.id === (oppLeadPractice as any).id,
      ))) {
      setValue(({ oppLeadPractice: toDelete, ...rest }) => rest);
    }
  }, [lens.get().oppPractices, lens.get().oppLeadPractice]);

  const getFieldByKey = (fieldKey: TModalFieldsDataKeys) => {
    switch (fieldKey) {
      case 'oppDescription':
        return (
          <TextArea
            {...lens.prop(fieldKey).toProps()}
            placeholder="Type something"
            rows={3}
          />
        );
      case 'closeDate':
        return (
          <DatePicker
            {...lens.prop(fieldKey).toProps()}
            format="MMM D, YYYY"
          />
        );
      case 'durationMonths':
        return (
          <NumericInput
            {...lens.prop(fieldKey).toProps()}
            formatOptions={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            disableArrows
          />
        );
      case 'gttl':
      case 'gttl1k':
        return (
          <div>
            <div className={css.numericInputWarapper}>
              <div>{modalProps.modalData.currency}</div>
              <NumericInput
                {...lens.prop(fieldKey).toProps()}
                formatOptions={{ style: 'decimal' }}
                disableArrows
              />
            </div>
            <span className={css.exchangeRateBlock}>
              {
                !lens.prop(fieldKey).toProps().value
                  ? `USD 1 = ${modalProps.modalData.currency} ${modalProps.modalData.exchangeRate}`
                  : `USD ${lens.prop(fieldKey).toProps().value / (modalProps.modalData.exchangeRate ? modalProps.modalData.exchangeRate : 1)}`
              }
            </span>
          </div>
        );
      case 'oppEngagementType':
      case 'oppContinuumOfferings':
      case 'oppPlatforms':
      case 'oppPractices':
      case 'oppDealType':
      case 'commercialModel':
      case 'oppPartnerAccounts':
      case 'oppAM':
      case 'oppIndustry':
        return (
          <PickerInputField
            fieldProps={lens.prop(fieldKey).toProps()}
            fieldKey={fieldKey}
          />
        );
      case 'oppLeadPractice': {
        let optionsToKeep: Array<string>;
        if (lens.get().oppPractices) {
          optionsToKeep = (lens.get().oppPractices as any)?.map((item: any) => item.id);
        } else if (modalProps.modalData.fields.oppPractices) {
          optionsToKeep = modalProps.modalData.fields.oppPractices;
        } else {
          optionsToKeep = [];
        }
        return (
          <PickerInputField
            fieldProps={lens.prop(fieldKey).toProps()}
            fieldKey={fieldKey}
            optionsToKeep={optionsToKeep}
          />
        );
      }
      case 'dealIsPartnerRelated':
        return (
          <ControlWrapper size="24">
            <RadioGroup
              items={[{ id: 895450001, name: 'Yes' }, { id: 895450000, name: 'No' }]}
              {...lens.prop(fieldKey).toProps()}
              direction="horizontal"
            />
          </ControlWrapper>
        );
      default: 
        return null;
    }
  };

  const renderFields = () => {
    const fieldsArray = canEdit 
      ? Object.keys(modalProps.modalData.fields) 
      : Object.keys(modalProps.modalData.fields)
        .filter((el) => ![
          'oppEngagementType',
          'oppContinuumOfferings',
          'oppPlatforms',
          'oppPractices',
          'oppIndustry',
        ].includes(el));

    const fieldsLength = fieldsArray.length;

    const renderField = (fieldKey: TModalFieldsDataKeys) => {
      if (fieldKey === 'oppPartnerAccounts' && (
        !lens.prop('dealIsPartnerRelated').toProps().value
        || lens.prop('dealIsPartnerRelated').toProps().value === 895450000
      )) {
        return null;
      }
      return (
        <FlexRow key={fieldKey} padding="24" vPadding="12">
          <FlexCell grow={1}>
            <LabeledInput
              cx={css.fieldLabel}
              label={fieldsLabels[fieldKey]}
              {...lens.prop(fieldKey).toProps()}
            >
              {getFieldByKey(fieldKey)}
            </LabeledInput>
          </FlexCell>
        </FlexRow>
      );
    };

    if (fieldsLength < 7) {
      return fieldsArray.map(
        (fieldKey: string) => renderField(fieldKey as TModalFieldsDataKeys),
      );
    }

    const index = Math.ceil(fieldsLength / 2);
    const dividerIndex = fieldsArray[index - 1] === 'dealIsPartnerRelated' && fieldsArray[index] === 'oppPartnerAccounts'
      ? index + 1
      : index;
    const firstColFields = fieldsArray.slice(0, dividerIndex);
    const secondColFields = fieldsArray.slice(dividerIndex);
    
    return (
      <div className={css.fieldsContainer}>
        <div>
          {firstColFields.map((fieldKey: string) => renderField(fieldKey as TModalFieldsDataKeys))}
        </div>
        <div>
          {secondColFields.map((fieldKey: string) => renderField(fieldKey as TModalFieldsDataKeys))}
        </div>
      </div>
    );
  };
  return (
    <ModalBlocker
      disallowClickOutside
      {...modalProps}
      abort={() => {
        modalProps.abort();
        setValBack && setValBack();
      }}
    >
      <ModalWindow width={Object.keys(modalProps.modalData.fields).length >= 7 ? '900' : undefined}>
        <ModalHeader
          cx={css.modalHeader}
          borderBottom
          title={modalProps.modalData.oppName}
          onClose={() => {
            modalProps.abort();
            setValBack && setValBack();
          }}
        />
        <ScrollBars>
          <Panel>
            {renderFields()}
          </Panel>
        </ScrollBars>
        <ModalFooter borderTop>
          <FlexSpacer />
          <Button 
            color="gray50" 
            fill="white" 
            onClick={() => {
              modalProps.abort();
              setValBack && setValBack();
            }} 
            caption="Cancel" 
            isDisabled={isLoading} 
            cx={css.cancelButton}
          />
          <Button 
            color="green" 
            caption="Save" 
            onClick={save} 
            isDisabled={isLoading || !isSaveEnabled}
            icon={isLoading ? loadingIcon : undefined} 
          />
        </ModalFooter>
        <FlexSpacer />
      </ModalWindow>
    </ModalBlocker>
  );
};

export default EditProbabilityModal;
