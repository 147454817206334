import React, { FC, useEffect, useState } from 'react';
import { IModal, useUuiContext } from '@epam/uui-core';
import {
  ModalBlocker, ModalWindow, ModalHeader, ScrollBars, Panel,
  TabButton, FlexCell, FlexRow, Button,
} from '@epam/promo';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/delete-outline-36.svg';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ReactComponent as LoaderIcon } from '@epam/assets/icons/loaders/circle_loader_30.svg';
import ImportTab from './ImportTab/ImportTab';
import css from './ImportActualModal.module.scss';
import ImportActualSettings from './ImportActualSettings/ImportActualSettings';
import ImportHistoryTab from './ImportHistoryTab/ImportHistoryTab';
import { deleteImport } from '../../../api/importDelete';
import activeImportsAtom, { refreshIncrementAtom } from '../../../state/atoms/activeImportsAtoms';
import { useNotification } from '../../../hooks/useNotification';
import importAtom, { acceptedFileAtom, defaultImportStatus, importStatusAtom } from '../../../state/atoms/importActual/importAtom';
import ConfirmDeleteModal from './components/ConfirmDeleteModal/ConfirmDeleteModal';
import { getActiveImport } from '../../../api/importGetActive';
import { useMaintenanceNotificationState } from '../../../hooks/useMaintanceNotificationState';

type TTimerState = {
  type: 'ACTIVE' | 'INACTIVE',
  data: typeof activeImportsAtom | {},
};

const ImportActualModal:FC<IModal<string>> = (modalProps) => {
  const { abort } = modalProps;
  const { uuiModals } = useUuiContext();
  const [tab, setTab] = useState<'import' | 'history' | 'settings'>('import');
  const [timerState, setTimerState] = useState<TTimerState>({ type: 'INACTIVE', data: {} });
  const [activeImports, setActiveImports] = useRecoilState(activeImportsAtom);
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const setIncrementRefresh = useSetRecoilState(refreshIncrementAtom);
  const setImportFile = useSetRecoilState(importAtom);
  const setAcceptedFile = useSetRecoilState(acceptedFileAtom);
  const [importStatus, setImportStatus] = useRecoilState(importStatusAtom);
  const { showNotification } = useNotification();
  const { toggleMaintenanceNotification } = useMaintenanceNotificationState();

  useEffect(() => {
    getActiveImport().then(({ data }) => {
      if (data.deleting.length && !data.importing.length) {
        setActiveImports((prev) => ({
          ...prev,
          isActive: true,
        }));
      }
      setActiveImports(data);
    });
  }, []);

  useEffect(() => {
    const interval: NodeJS.Timeout | null = setInterval(async () => {
      const { data: newData } = await getActiveImport();

      if (newData.deleting.length && !newData.importing.length) {
        return setTimerState({ type: 'ACTIVE', data: newData });
      }

      if (!newData.deleting.length) {
        setTimerState({ type: 'INACTIVE', data: newData });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [activeImports]);

  useEffect(() => {
    switch (timerState.type) {
      case 'ACTIVE':
        setActiveImports((prev) => ({
          ...prev,
          ...timerState.data,
          isActive: true,
        }));
        break;
      case 'INACTIVE':
        setActiveImports((prev) => ({
          ...prev,
          ...timerState.data,
          isActive: false,
          isDeleteComplete: !!prev.deleting.length,
        }));

        if (activeImports.deleting.length) {
          showNotification('Imports have been successfully deleted', undefined, 'green');
        }
        break;
      default:
        break;
    }
  }, [timerState]);

  const renderTab = () => {
    if (tab === 'import') {
      return (
        <ImportTab navigateToHistory={() => setTab('history')} />
      );
    }
    if (tab === 'history') {
      return (
        <ImportHistoryTab setCanBeDeleted={(val) => setCanBeDeleted(val)} />
      );
    }
    if (tab === 'settings') {
      return (
        <ImportActualSettings />
      );
    }
  };

  return (
    <ModalBlocker
      cx={css.modalBlocker}
      {...modalProps}
      abort={() => abort()}
      disallowClickOutside
    >
      <ModalWindow cx={css.modalRoot}>
        <ModalHeader
          cx={css.modalHeader}
          title="Import Actual"
          onClose={() => {
            if (!importStatus.isImporting && !importStatus.isImportSuccessful) {
              setImportStatus(defaultImportStatus);
              setImportFile(null);
              setAcceptedFile(null);
            }
            abort();
          }}
        />
        <ScrollBars>
          <Panel style={{ height: '100%' }}>
            <FlexCell grow={1}>
              <FlexRow borderBottom background="none">
                <TabButton
                  cx={`${css.tabButton} ${css.firstTabButton}`}
                  caption="Import"
                  isLinkActive={tab === 'import'}
                  onClick={() => setTab('import')}
                />
                <TabButton
                  cx={css.tabButton}
                  caption="History"
                  isLinkActive={tab === 'history'}
                  onClick={() => setTab('history')}
                />
                <TabButton
                  cx={css.tabButton}
                  caption="Settings"
                  isLinkActive={tab === 'settings'}
                  onClick={() => setTab('settings')}
                />
                {tab === 'history' && (
                <Button
                  fill="light"
                  caption="Remove last month record(s)"
                  icon={activeImports.deleting.length || activeImports.importing.length 
                    ? LoaderIcon : DeleteIcon}
                  onClick={() => {
                    // eslint-disable-next-line react/no-unstable-nested-components
                    uuiModals.show((deleteModalProps) => (
                      <ConfirmDeleteModal
                        {...deleteModalProps}
                        onConfirm={() => {
                          setActiveImports((prev) => ({
                            ...prev,
                            isActive: true,
                            isDeleteComplete: false,
                          }));
                          toggleMaintenanceNotification(true);
                          deleteImport()
                            .then(() => setIncrementRefresh((prev) => prev + 1))
                            .catch(() => {
                              showNotification('Something went wrong while trying to delete imports. Try again later!', undefined, 'red');
                            });
                        }}
                      />
                    ));
                  }}
                  cx={css.deleteBtn}
                  isDisabled={Boolean(
                    activeImports.deleting.length || activeImports.importing.length,
                  ) || activeImports.isActive || !canBeDeleted}
                />
                )}
              </FlexRow>
            </FlexCell>
            <div
              className={css.tabWrapper}
              style={{
                padding: tab === 'history' ? '0' : '1.5rem',
              }}
            >
              {renderTab()}
            </div>
          </Panel>
        </ScrollBars>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default ImportActualModal;
