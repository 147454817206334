import { Text, Tooltip } from '@epam/promo';
import { CX } from '@epam/uui-core';
import React, {
  FC, memo, useEffect, useRef,
} from 'react';
import { useRecoilValue } from 'recoil';
import { IQueryResponseItem, ITagData, IWarningData } from '../../../../api/query';
import queryParamsAtom from '../../../../state/atoms/queryParamsAtom';
import viewModeAtom from '../../../../state/atoms/viewModeAtom';
import css from '../VirtualList.module.scss';

interface Props {
  item: IQueryResponseItem,
  cellId: string,
  year?: number,
  type: 'values' | 'breakdown',
  columnName?: string,
}

const nonAttrValues = ['gttl', 'ytd', 'frcst'];
const numericAttr = ['EPAMShare1KUSD', 'EPAMWallet1KUSD', 'ITServices1KUSD', 'oppTotalContractValue', 'AnnualRevenue'];

export type TData = string | number | IWarningData[] | ITagData[] | null | undefined;

export const formatData = (data: TData, fullData = false, currencyName = '') => {
  if (typeof data === 'number') {
    if (fullData) return `${(data * 1000).toLocaleString()} ${currencyName}`;
    return Math.round(data).toLocaleString();
  }
  return data;
};

const GridDataCell: FC<Props> = ({
  item, cellId, type, year, columnName,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    filtersStore: {
      revenueScenario,
      currency,
    },
  } = useRecoilValue(queryParamsAtom);
  const { mode } = useRecoilValue(viewModeAtom);

  const isUSDCell = (
    cellId === 'AnnualRevenue'
    || cellId === 'EPAMShare1KUSD'
    || cellId === 'EPAMWallet1KUSD'
    || cellId === 'ITServices1KUSD'
  );
  const isTotalContract = cellId === 'oppTotalContractValue';

  const getCurrencyForTooltip = () => {
    if (isUSDCell) return 'USD';
    if (mode === 'edit') return item.values.currName;
    return currency;
  };

  const isSeparately = revenueScenario === 'ActualForecastSeparately';
  const isPercent = revenueScenario === 'ActualVsForecastPercent';
  const isVs = revenueScenario === 'ActualVsForecastDollars' || isPercent;
  const isNonAttrValue = nonAttrValues.includes(cellId);
  const isNumericAttr = numericAttr.includes(cellId);

  // ytd, gttl, frcst etc.

  const valuesKeyFrcst = isNonAttrValue ? `${cellId}_p` : cellId;
  const valuesKeyAct = isNonAttrValue ? `${cellId}_a` : cellId;
  const valuesContentFrcst = item.values[valuesKeyFrcst];
  const valuesContentAct = item.values[valuesKeyAct];
  const vsValuesContent = item.values[cellId] === 0 ? null : item.values[cellId];

  // months and quaters
  const breakdownKeyFrcst = `${cellId}_p_${year}`;
  const breakdownKeyAct = `${cellId}_a_${year}`;
  const breakdownContentFrcst = item.breakdown[breakdownKeyFrcst];
  const breakdownContentAct = item.breakdown[breakdownKeyAct];
  const vsBreakdownKey = `${cellId}_${year}`;
  const vsBreakdownContent = (
    item.breakdown[vsBreakdownKey] === 0
    && isPercent
  ) ? null : item.breakdown[vsBreakdownKey];

  const getVsColor = () => {
    if ((vsValuesContent && typeof vsValuesContent === 'number' && (vsValuesContent > 0))
    || (vsBreakdownContent && (vsBreakdownContent > 0))) {
      return css.vsPositive;
    }
    if ((vsValuesContent && typeof vsValuesContent === 'number' && (vsValuesContent < 0))
    || (vsBreakdownContent && (vsBreakdownContent < 0))) {
      return css.vsNegative;
    }

    return false;
  };

  useEffect(() => {
    ref.current?.parentElement?.setAttribute(
      'cell-for',
      columnName && type === 'breakdown' ? columnName : cellId,
    );
  }, []);

  const renderValue = (cx: CX, value: any, withTooltip = true) => {
    const isValueShown = isNonAttrValue && value;

    // eslint-disable-next-line eqeqeq
    if (value == null || value == undefined) {
      return null;
    }

    return (
      withTooltip
        ? (
          <Tooltip
            content={isTotalContract
              ? formatData(value, true, item.values.currName)
              : formatData(value, true, getCurrencyForTooltip())}
            placement={!item.values.isBaseRequiredFieldsSet && mode === 'edit'
              ? 'top' : 'bottom'}
          >
            <Text cx={cx}>
              {isValueShown || !isNonAttrValue ? formatData(value) : null}
            </Text>
          </Tooltip>
        )
        : (
          <Text cx={cx}>
            {isValueShown || !isNonAttrValue ? formatData(value) : null}
          </Text>
        )
    );
  };

  const renderCell = type === 'values' ? (
    <>
      {(!!valuesContentFrcst || isSeparately) && (
        renderValue(css.textFrcst, valuesContentFrcst, !isPercent)
      )}
      {(!!valuesContentAct || isSeparately) && mode !== 'edit' && !isNumericAttr && (
        renderValue(css.textActual, valuesContentAct, !isPercent)
      )}
    </>
  ) : (
    <>
      {(!!breakdownContentFrcst || isSeparately) && (
        renderValue(css.textFrcst, breakdownContentFrcst, !isPercent)
      )}
      {(!!breakdownContentAct || isSeparately) && mode !== 'edit' && (
        renderValue(css.breakdownTextActual, breakdownContentAct, !isPercent)
      )}
    </>
  );

  const renderVsCell = type === 'values' ? (
    renderValue(
      getVsColor(),
      vsValuesContent,
      !isPercent,
    )
  ) : (
    renderValue(
      getVsColor(),
      vsBreakdownContent,
      !isPercent,
    )
  );

  return (
    <div
      className={type === 'breakdown' ? `${css.gridBreakdownCell}` : `${css.gridDataCell}`}
      ref={ref}
    >
      { !isVs ? renderCell : renderVsCell }
    </div>
  );
};

export default memo(GridDataCell);
