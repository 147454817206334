import React, { useEffect, useState } from 'react';
import {
  Text, PickerInput, 
  FlexRow, ModalBlocker, ModalWindow, Panel, IconButton,
} from '@epam/promo';
import { IModal, useArrayDataSource } from '@epam/uui-core';
import { useRecoilValue } from 'recoil';
import { ReactComponent as CloseIcon } from '@epam/assets/icons/common/navigation-close-18.svg';
import css from './OppHistoryModal.module.scss';
import queryParamsAtom from '../../../state/atoms/queryParamsAtom';
import OppHistoryGrid, { BREAKDOWN_IDS } from './OppHistoryGrid';
import getOppRevenueHistory, { IOppRevenueHistoryResponseItem } from '../../../api/revenueHistory';
import { IProcessedItem } from './OppHistoryColumns';
import Loader from '../../Loader';

interface Props extends IModal<string> {
  id: string,
}

const OppHistoryModal = (modalProps: Props) => {
  const { id, abort } = modalProps;
  const { filtersStore: { revenueYear } } = useRecoilValue(queryParamsAtom);

  const [year, setYear] = useState(Math.abs(revenueYear));

  const [
    history, setHistory,
  ] = useState<{
    year: number,
    history: IProcessedItem[],
  }[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getOppRevenueHistory(id)
      .then((res) => {
        const processedHistory = res.map((yearItem) => ({
          ...yearItem,
          history: yearItem.history.map((el) => {
            const calculatedSum = Math.round((BREAKDOWN_IDS
              .reduce((sum, month) => sum += Number(el[month]), 0) + Number.EPSILON) * 1000) / 1000;

            const newEl = {
              ...el,
              sum: calculatedSum,
              sumPlus: calculatedSum,
            } as IProcessedItem;

            return newEl;
          }),
        }));
        
        if (!processedHistory.some((el) => el.year === year)) {
          setYear(processedHistory[0].year);
        }
        setHistory(processedHistory);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const options = history 
    ? history.map((el) => ({ id: Number(el.year), name: el.year.toString() }))
      .sort((a, b) => a.id - b.id) 
    : [{ id: year, name: year.toString() }];

  const preparedData = history ? history.filter((el) => el.year === year)[0].history
    .map((el, idx, arr) => {
      const keys = Object.keys(el) as (keyof IOppRevenueHistoryResponseItem)[];
      const years = history.map((i) => i.year).filter((i) => Number(i) >= year);

      const newEl: IProcessedItem = {
        ...el,
        id: idx.toString(),
        changedFields: [],
      };
    
      if (idx < arr.length - 1) {
        keys.forEach((key) => {
          if (el[key] !== arr[idx + 1][key]) {
            newEl.changedFields.push(key);
          }
        });
      }

      years.forEach((i) => {
        if (Number(i) !== year) {
          const recordsWithSumPlus = history.find((item) => item?.year === i)?.history
            .filter((y) => y.modifiedOn <= newEl.modifiedOn);
          if (recordsWithSumPlus?.length) {
            newEl.sumPlus += recordsWithSumPlus[0].sum;
          }
        }
      });

      return newEl;
    }) : [];

  const dataSource = useArrayDataSource({
    items: options,
  }, []);

  return (
    <ModalBlocker {...modalProps}>
      <ModalWindow height="auto" cx={css.modalWindow}>
        <Panel background="white" cx={css.modalPanel}>
          <FlexRow cx={css.modalHeader}>
            <FlexRow cx={css.yearPickerContainer}>
              <Text cx={css.title}>Forecast Log History</Text>
              {!!options.length && !isLoading && (
                <div className={css.yearPicker}>
                  <PickerInput
                    size="30"
                    dataSource={dataSource}
                    value={year}
                    onValueChange={(newVal: number) => setYear(newVal)}
                    selectionMode="single"
                    valueType="id"
                    disableClear
                    minBodyWidth={90}
                  />
                </div>
              )}
            </FlexRow>
            <IconButton icon={CloseIcon} onClick={abort} />
          </FlexRow>
          {!isLoading ? (
            <div className={css.contentContainer}>
              <OppHistoryGrid
                items={preparedData}
                year={year}
                isLoading={isLoading}
              />
            </div>
          ) : <Loader />}
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
};

export default OppHistoryModal;
