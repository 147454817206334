import { FilterSettings } from '../api/views';

export type TBreakdown = 'Monthly' | 'Quarterly';

export interface Filters {
  revenueYear: number,
  currency: 'CAD' | 'EUR' | 'GBP' | 'KZT' | 'RUB' | 'USD',
  breakdown: TBreakdown,
  forecastGranularity: 'WithMonthlyForecastOnly' | 'WithoutMonthlyForecastOnly' | 'WithAndWithoutMonthlyForecast',
  ownership: 'All' | 'MyOpportunitiesOnly' | 'MyAccountsOnly',
  probabilityScenario: 'Pessimistic' | 'Standard' | 'Optimistic' | 'Weighted' | 'Monthly',
  revenueScenario: 'ForecastOnly' | 'ActualForecastSeparately' | 'ActualForecastMixed' | 'ActualVsForecastDollars' | 'ActualVsForecastPercent',
  search: string,
}

const currentYear = new Date().getFullYear();

export const initialFilters: Filters = {
  revenueYear: currentYear,
  currency: 'USD',
  breakdown: 'Monthly',
  forecastGranularity: 'WithAndWithoutMonthlyForecast',
  ownership: 'MyOpportunitiesOnly',
  probabilityScenario: 'Optimistic',
  revenueScenario: 'ActualForecastMixed',
  search: '',
};

const getYear = (year: number) => {
  if (year === -2004) {
    return year;
  }

  if (year < 0) {
    return 0 - currentYear;
  }
  return year;
};

export function setFiltersFromPresetFiltersList(
  presetFilters: FilterSettings | undefined,
): Filters {
  if (presetFilters) {
    return {
      revenueYear: getYear(presetFilters.year),
      currency: presetFilters.currency,
      breakdown: presetFilters.breakdown,
      forecastGranularity: presetFilters.forecastGranularity,
      ownership: presetFilters.ownership,
      probabilityScenario: presetFilters.probabilityScenario,
      revenueScenario: presetFilters.revenueScenario,
      search: '',
    };
  }
  return initialFilters;
}
