import React from 'react';
import { CX, DataColumnProps, RenderCellProps } from '@epam/uui-core';
import { DataTableCell } from '@epam/promo';
import { Dayjs } from 'dayjs';
import OppColumnItem from './components/OppColumnItem';
import { IQueryResponseItem, ProcessedResponseItem } from '../../../api/query';
import GridDataCell from './components/GridDataCell';
import GridTextCell from './components/GridTextCell';
import ColumnHeader from './components/ColumnHeader';
import css from './VirtualList.module.scss';
import EditableGridCell from '../../EditModeGrid/components/EditableGridCell';
import ProbabilityGridCell from '../../EditModeGrid/components/ProbabilityGridCell';
import BsrvCell from '../../EditModeGrid/components/BSRVCell';
import GridDateCell from './components/GridDateCell';
import OppTagPicker from './components/OppTagPicker';
import OppFlagPicker from './components/OppFlagPicker';
import OppWarning from './components/OppWarning';
import OppNotesButton from './components/OppNotesButton';
import { getColumnsNameTooltip } from '../../../const/COLUMNS_NAME_TOOLTIPS';

interface Generic<T> { [key: string]: T }

export interface Summary extends Generic<number> {}

export interface Row extends Generic<string | boolean | null> {}

interface IDictionary<T> { [key: string] : ((arg: T, mode?: 'edit' | 'view', cx?: CX) => DataColumnProps) }

export interface IBreakdownItem {
  index: number,
  year: number,
  type: 'q' | 'm',
}

const formateYear = (year: number) => year % 100;

const checkEditability = (month: number, year: number, userLocalDate: Dayjs): boolean => {
  const currentMonth = userLocalDate.month() + 1;
  const currentYear = userLocalDate.year();

  return (currentYear < year || (currentYear === year && currentMonth <= month));
};

export const renderEditableCell = (
  props: RenderCellProps<any, any>,
  cellId: string,
  year: number,
  columnName: string,
) => {
  if (props.rowProps.value?.type && props.rowProps.value?.type === 'oppName') {
    if (cellId === 'prob') {
      return <ProbabilityGridCell {...props} value={props.rowProps.value.values.prob} />;
    }

    if (cellId === 'configCell') {
      return <BsrvCell {...props} />;
    }

    return (
      <EditableGridCell {...props} cellId={cellId} year={year} columnName={columnName} />
    );
  }

  if (props.rowProps.value) {
    return (
      <DataTableCell {...props} />
    );
  }
};

const minColWidth = 80;
export const currCol: DataColumnProps = {
  key: 'currName',
  caption: <ColumnHeader caption="Currency" column="currName" />,
  render: (item:IQueryResponseItem) => (
    <GridTextCell
      data={item.values.currName ?? ''}
      isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
      cellId="currName"
      textContainerWidth={110}
      isPermanentTooltip
    />
  ),
  grow: 1,
  width: 110,
  minWidth: minColWidth,
  fix: 'left',
  textAlign: 'left',
  info: getColumnsNameTooltip('Currency'),
  cx: css.columnHeaderWrapper,
};

export const columnsDictionary: IDictionary<any> = {
  oppName: () => ({
    key: 'oppName',
    caption: <ColumnHeader caption="Opportunity" column="oppName" />,
    render: (item: ProcessedResponseItem, props) => <OppColumnItem item={item} rowProps={props} />,
    isSortable: true,
    grow: 1,
    width: 344,
    minWidth: minColWidth,
    fix: 'left',
    cx: css.columnHeaderWrapper,
  }),
  accName: () => ({
    key: 'accName',
    caption: <ColumnHeader caption="Account" column="accName" />,
    render: (item) => (
      <GridTextCell
        data={item.values.accName ?? ''}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accName"
        textContainerWidth={110}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Account'),
    cx: css.columnHeaderWrapper,
  }),
  closeDate: () => ({
    key: 'closeDate',
    caption: <ColumnHeader caption="Start Date" column="closeDate" />,
    render: (item:IQueryResponseItem) => (
      <GridDateCell
        date={[item.values.estimatedCloseDate, item.values.actualCloseDate]}
        cellId="closeDate"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: `${getColumnsNameTooltip('Start Date')}`,
    cx: css.columnHeaderWrapper,
  }),
  oppState: () => ({
    key: 'oppState',
    caption: <ColumnHeader caption="Opportunity Status" column="oppState" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppState ?? ''}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppState"
        textContainerWidth={110}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opportunity Status'),
    cx: css.columnHeaderWrapper,
  }),
  RevenueTypeName: () => ({
    key: 'RevenueTypeName',
    caption: <ColumnHeader caption="Revenue Type" column="RevenueTypeName" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.RevenueTypeName ?? ''}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="RevenueTypeName"
        textContainerWidth={110}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Revenue Type'),
    cx: css.columnHeaderWrapper,
  }),
  MergedToOppName: () => ({
    key: 'MergedToOppName',
    caption: <ColumnHeader caption="Merged To" column="MergedToOppName" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.MergedToOppName ?? ''}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="MergedToOppName"
        textContainerWidth={110}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Merged To'),
    cx: css.columnHeaderWrapper,
  }),
  AnnualRevenue: () => ({
    key: 'AnnualRevenue',
    caption: <ColumnHeader caption="Annual Revenue, 1K USD" column="AnnualRevenue" />,
    render: (item:IQueryResponseItem) => (
      <GridDataCell item={item} type="values" cellId="AnnualRevenue" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Annual Revenue, 1K USD'),
    cx: css.columnHeaderWrapper,
  }),
  ITServices1KUSD: () => ({
    key: 'ITServices1KUSD',
    caption: <ColumnHeader caption="IT Services, 1K USD" column="ITServices1KUSD" />,
    render: (item:IQueryResponseItem) => (
      <GridDataCell item={item} type="values" cellId="ITServices1KUSD" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('IT Services, 1K USD'),
    cx: css.columnHeaderWrapper,
  }),
  EPAMWallet1KUSD: () => ({
    key: 'EPAMWallet1KUSD',
    caption: <ColumnHeader caption="EPAM Wallet, 1K USD" column="EPAMWallet1KUSD" />,
    render: (item:IQueryResponseItem) => (
      <GridDataCell item={item} type="values" cellId="EPAMWallet1KUSD" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('EPAM Wallet, 1K USD'),
    cx: css.columnHeaderWrapper,
  }),
  EPAMShare1KUSD: () => ({
    key: 'EPAMShare1KUSD',
    caption: <ColumnHeader caption="EPAM Share, 1K USD" column="EPAMShare1KUSD" />,
    render: (item:IQueryResponseItem) => (
      <GridDataCell item={item} type="values" cellId="EPAMShare1KUSD" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('EPAM Share, 1K USD'),
    cx: css.columnHeaderWrapper,
  }),
  accState: () => ({
    key: 'accState',
    caption: <ColumnHeader caption="Account Status" column="accState" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accState ?? ''}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accState"
        textContainerWidth={110}
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Account Status'),
    cx: css.columnHeaderWrapper,
  }),
  projstr: () => {
    const width = 110;
    return {
      key: 'projstr',
      caption: <ColumnHeader caption="Linked Projects" column="projstr" />,
      render: (item:IQueryResponseItem) => (
        <GridTextCell
          data={item.values.projstr ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="projstr"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Linked Projects'),
      cx: css.columnHeaderWrapper,
    };
  },
  modDate: () => ({
    key: 'modDate',
    caption: <ColumnHeader caption="FRCST/% Updated On" column="modDate" />,
    render: (item:IQueryResponseItem) => (
      <GridDateCell date={item?.values.modDate} cellId="modDate" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('FRCST/% Updated On'),
    cx: css.columnHeaderWrapper,
  }),
  modBy: () => ({
    key: 'modBy',
    caption: <ColumnHeader caption="FRCST/% Updated By" column="modBy" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.modBy ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="modBy"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('FRCST/% Updated By'),
    cx: css.columnHeaderWrapper,
  }),
  oppAM: () => ({
    key: 'oppAM',
    caption: <ColumnHeader caption="Opportunity AM" column="oppAM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppAM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppAM"
        isPermanentTooltip
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opportunity AM'),
    cx: css.columnHeaderWrapper,
  }),
  oppAcquisitionName: () => ({
    key: 'oppAcquisitionName',
    caption: <ColumnHeader caption="Opp Acquisition Name" column="oppAcquisitionName" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppAcquisitionName ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppAcquisitionName"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opp Acquisition Name'),
    cx: css.columnHeaderWrapper,
  }),
  oppSM: () => ({
    key: 'oppSM',
    caption: <ColumnHeader caption="Opportunity SM" column="oppSM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppSM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppSM"
        isPermanentTooltip
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opportunity SM'),
    cx: css.columnHeaderWrapper,
  }),
  oppStream: () => ({
    key: 'oppStream',
    caption: <ColumnHeader caption="Stream" column="oppStream" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppStream ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="Stream"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Stream'),
    cx: css.columnHeaderWrapper,
  }),
  accAM: () => ({
    key: 'accAM',
    caption: <ColumnHeader caption="Account AM" column="accAM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accAM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accAM"
        isPermanentTooltip
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Account AM'),
    cx: css.columnHeaderWrapper,
  }),
  accSM: () => ({
    key: 'accSM',
    caption: <ColumnHeader caption="Account SM" column="accSM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accSM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accSM"
        isPermanentTooltip
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Account SM'),
    cx: css.columnHeaderWrapper,
  }),
  oppSecondarySM: () => ({
    key: 'oppSecondarySM',
    caption: <ColumnHeader caption="Secondary SM" column="oppSecondarySM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppSecondarySM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppSecondarySM"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Secondary SM'),
    cx: css.columnHeaderWrapper,
  }),
  accDigitalSM: () => ({
    key: 'accDigitalSM',
    caption: <ColumnHeader caption="Digital SM" column="accDigitalSM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accDigitalSM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accDigitalSM"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Digital SM'),
    cx: css.columnHeaderWrapper,
  }),
  RegionalGBU: () => ({
    key: 'RegionalGBU',
    caption: <ColumnHeader caption="GBU Regional" column="RegionalGBU" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.RegionalGBU ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="RegionalGBU"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('GBU Regional'),
    cx: css.columnHeaderWrapper,
  }),
  GBUPortfolio: () => ({
    key: 'GBUPortfolio',
    caption: <ColumnHeader caption="GBU Portfolio" column="GBUPortfolio" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.GBUPortfolio ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="GBUPortfolio"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('GBU Portfolio'),
    cx: css.columnHeaderWrapper,
  }),
  GBU: () => ({
    key: 'GBU',
    caption: <ColumnHeader caption="GBU" column="GBU" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.GBU ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="GBU"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('GBU'),
    cx: css.columnHeaderWrapper,
  }),
  SuppRegionalGBU: () => ({
    key: 'SuppRegionalGBU',
    caption: <ColumnHeader caption="Supporting GBU Regional" column="SuppRegionalGBU" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.SuppRegionalGBU ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="SuppRegionalGBU"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Supporting GBU Regional'),
    cx: css.columnHeaderWrapper,
  }),
  SuppGBUPortfolio: () => ({
    key: 'SuppGBUPortfolio',
    caption: <ColumnHeader caption="Supporting GBU Portfolio" column="SuppGBUPortfolio" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.SuppGBUPortfolio ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="SuppGBUPortfolio"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Supporting GBU Portfolio'),
    cx: css.columnHeaderWrapper,
  }),
  SuppGBU: () => ({
    key: 'SuppGBU',
    caption: <ColumnHeader caption="Supporting GBU" column="SuppGBU" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.SuppGBU ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="SuppGBU"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Supporting GBU'),
    cx: css.columnHeaderWrapper,
  }),
  accSuppAM: () => ({
    key: 'accSuppAM',
    caption: (
      <ColumnHeader caption="Supporting AM" column="accSuppAM" />
    ),
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accSuppAM ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accSuppAM"
        isPermanentTooltip
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Supporting AM'),
    cx: css.columnHeaderWrapper,
  }),
  accExtRepCountry: () => ({
    key: 'accExtRepCountry',
    caption: <ColumnHeader caption="Reporting Country" column="accExtRepCountry" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accExtRepCountry ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accExtRepCountry"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Reporting Country'),
    cx: css.columnHeaderWrapper,
  }),
  accExtRepInd: () => ({
    key: 'accExtRepInd',
    caption: <ColumnHeader caption="Reporting Industry" column="accExtRepInd" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accExtRepInd ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accExtRepInd"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Reporting Industry'),
    cx: css.columnHeaderWrapper,
  }),
  SS: () => ({
    key: 'SS',
    caption: <ColumnHeader caption="Sales Stage" column="SS" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.SS ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="SS"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Sales Stage'),
    cx: css.columnHeaderWrapper,
  }),
  SU: () => ({
    key: 'SU',
    caption: <ColumnHeader caption="Sales Unit" column="SU" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.SU ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="SU"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Sales Unit'),
    cx: css.columnHeaderWrapper,
  }),
  prob: (year: number, mode: 'edit' | 'view' | undefined) => ({
    key: 'prob',
    caption: <ColumnHeader caption="%" column="prob" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={
          typeof item.values.prob !== 'undefined'
            ? `${item.values.prob}%`
            : ''
          }
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="prob"
      />
    ),
    renderCell: mode === 'edit'
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'prob', year, 'prob')
      : undefined,
    grow: 0,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
    info: getColumnsNameTooltip('Probability'),
    cx: css.columnHeaderWrapper,
  }),
  commisionable: () => ({
    key: 'commisionable',
    caption: <ColumnHeader caption="Commissionable" column="commisionable" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.commisionable ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="commisionable"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Commissionable'),
    cx: css.columnHeaderWrapper,
  }),
  oppSalesType: () => ({
    key: 'oppSalesType',
    caption: <ColumnHeader caption="Sales Type" column="oppSalesType" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppSalesType ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppSalesType"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Sales Type'),
    cx: css.columnHeaderWrapper,
  }),
  globalAccName: () => ({
    key: 'globalAccName',
    caption: <ColumnHeader caption="Global Account" column="globalAccName" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.globalAccName ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="globalAccName"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Global Account'),
    cx: css.columnHeaderWrapper,
  }),
  oppCreatedOn: () => ({
    key: 'oppCreatedOn',
    caption: <ColumnHeader caption="Opportunity Created On" column="oppCreatedOn" />,
    render: (item:IQueryResponseItem) => (
      <GridDateCell date={item.values.oppCreatedOn} cellId="oppCreatedOn" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opportunity Created On'),
    cx: css.columnHeaderWrapper,
  }),
  oppCreatedBy: () => ({
    key: 'oppCreatedBy',
    caption: <ColumnHeader caption="Opportunity Created By" column="oppCreatedBy" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppCreatedBy ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppCreatedBy"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opportunity Created By'),
    cx: css.columnHeaderWrapper,
  }),
  OSC: () => {
    const width = 110;
    return {
      key: 'OSC',
      caption: <ColumnHeader caption="Additional Competencies" column="OSC" />,
      render: (item: IQueryResponseItem) => (
        <GridTextCell
          data={item.values.OSC ?? ''}
          textContainerWidth={110}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="OSC"
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Additional Competencies'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppAging: () => ({
    key: 'oppAging',
    caption: <ColumnHeader caption="Days w/o update" column="oppAging" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppAging?.toString() ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppAging"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Days w/o update'),
    cx: css.columnHeaderWrapper,
  }),
  accAcquisitionName: () => ({
    key: 'accAcquisitionName',
    caption: <ColumnHeader caption="Acc Acquisition Name" column="accAcquisitionName" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.accAcquisitionName ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="accAcquisitionName"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Acc Acquisition Name'),
    cx: css.columnHeaderWrapper,
  }),
  oppTotalContractValue: () => ({
    key: 'oppTotalContractValue',
    caption: <ColumnHeader caption="Total Contract Value, 1K" column="oppTotalContractValue" />,
    render: (item:IQueryResponseItem) => (
      <GridDataCell item={item} type="values" cellId="oppTotalContractValue" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Total Contract Value, 1K'),
    cx: css.columnHeaderWrapper,
  }),
  oppTargetCloseDate: () => ({
    key: 'oppTargetCloseDate',
    caption: <ColumnHeader caption="Target Close Date" column="oppTargetCloseDate" />,
    render: (item:IQueryResponseItem) => (
      <GridDateCell date={item.values.oppTargetCloseDate} cellId="oppTargetCloseDate" />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Target Close Date'),
    cx: css.columnHeaderWrapper,
  }),
  oppTargetCloseQuarter: () => ({
    key: 'oppTargetCloseQuarter',
    caption: <ColumnHeader caption="Target Close Quarter" column="oppTargetCloseQuarter" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppTargetCloseQuarter ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppTargetCloseQuarter"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Target Close Quarter'),
    cx: css.columnHeaderWrapper,
  }),
  oppLeadSourceCode: () => ({
    key: 'oppLeadSourceCode',
    caption: <ColumnHeader caption="Lead/Opportunity Source" column="oppLeadSourceCode" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppLeadSourceCode ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppLeadSourceCode"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Lead/Opportunity Source'),
    cx: css.columnHeaderWrapper,
  }),
  oppOrigin: () => ({
    key: 'oppOrigin',
    caption: <ColumnHeader caption="Opportunity Origin" column="oppOrigin" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppOrigin ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppOrigin"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Opportunity Origin'),
    cx: css.columnHeaderWrapper,
  }),
  oppPartnerAccounts: () => {
    const width = 110;
    return {
      key: 'oppPartnerAccounts',
      caption: <ColumnHeader caption="Partner Accounts" column="oppPartnerAccounts" />,
      render: (item: IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppPartnerAccounts ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppPartnerAccounts"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Partner Accounts'),
      cx: css.columnHeaderWrapper,
    };
  },
  gttl: (year, mode, cx) => ({
    key: 'gttl',
    caption: <ColumnHeader caption={`GTTL-${year}`} column="gttl" />,
    render: (item: IQueryResponseItem) => <GridDataCell item={item} type="values" cellId="gttl" />,
    renderCell: mode === 'edit'
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'gttl', Number(year), 'gttl') : undefined,
    grow: 0,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
    info: getColumnsNameTooltip(`GTTL-${year}`),
    cx: `${cx} ${css.columnHeaderWrapper}`,
  }),
  ytd: (year, mode, cx) => ({
    key: 'ytd',
    caption: <ColumnHeader caption={`YTD-${year}`} column="ytd" />,
    render: (item: IQueryResponseItem) => <GridDataCell item={item} type="values" cellId="ytd" />,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
    info: getColumnsNameTooltip(`YTD-${year}`),
    cx: `${cx} ${css.columnHeaderWrapper}`,
  }),
  frcst: (year, mode, cx) => ({
    key: 'frcst',
    caption: <ColumnHeader caption={`FRCST-${year}`} column="frcst" />,
    render: (item:IQueryResponseItem) => <GridDataCell item={item} type="values" cellId="frcst" />,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
    info: getColumnsNameTooltip(`FRCST-${year}`),
    cx: `${cx} ${css.columnHeaderWrapper}`,
  }),
  oppAdvisoryAM: () => ({
    key: 'oppAdvisoryAM',
    caption: <ColumnHeader caption="Advisory AM" column="oppAdvisoryAM" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppAdvisoryAM?.toString() ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppAdvisoryAM"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Advisory AM'),
    cx: css.columnHeaderWrapper,
  }),
  oppEngagementType: () => ({
    key: 'oppEngagementType',
    caption: <ColumnHeader caption="Engagement Type" column="oppEngagementType" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppEngagementType?.toString() ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppEngagementType"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Engagement Type'),
    cx: css.columnHeaderWrapper,
  }),
  oppPractices: () => {
    const width = 110;
    return {
      key: 'oppPractices',
      caption: <ColumnHeader caption="Practices" column="oppPractices" />,
      render: (item: IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppPractices ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppPractices"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Practices'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppLeadPractice: () => {
    const width = 110;
    return {
      key: 'oppLeadPractice',
      caption: <ColumnHeader caption="Lead Practice" column="oppLeadPractice" />,
      render: (item:IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppLeadPractice?.toString() ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppLeadPractice"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Lead Practice'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppIndustry: () => ({
    key: 'oppIndustry',
    caption: <ColumnHeader caption="Industry" column="oppIndustry" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppIndustry ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppIndustry"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Industry'),
    cx: css.columnHeaderWrapper,
  }),
  oppPlatforms: () => {
    const width = 110;
    return {
      key: 'oppPlatforms',
      caption: <ColumnHeader caption="Platforms" column="oppPlatforms" />,
      render: (item: IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppPlatforms ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppPlatforms"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Platforms'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppContinuumOfferings: () => {
    const width = 110;
    return {
      key: 'oppContinuumOfferings',
      caption: (
        <ColumnHeader caption="Continuum Offerings" column="oppContinuumOfferings" />
      ),
      render: (item: IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppContinuumOfferings ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppContinuumOfferings"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Continuum Offerings'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppDescription: () => {
    const width = 110;
    return {
      key: 'oppDescription',
      caption: (
        <ColumnHeader caption="Description" column="oppDescription" />
      ),
      render: (item:IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppDescription ?? ''}
          textContainerWidth={width}
          isList={false}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppDescription"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Description'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppDealType: () => ({
    key: 'oppDealType',
    caption: <ColumnHeader caption="Deal Type" column="oppDealType" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.oppDealType?.toString() ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="oppDealType"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Deal Type'),
    cx: css.columnHeaderWrapper,
  }),
  commercialModel: () => ({
    key: 'commercialModel',
    caption: <ColumnHeader caption="Commercial Model" column="commercialModel" />,
    render: (item:IQueryResponseItem) => (
      <GridTextCell
        data={item.values.commercialModel?.toString() ?? ''}
        textContainerWidth={110}
        isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
        cellId="commercialModel"
      />
    ),
    grow: 1,
    width: 110,
    minWidth: minColWidth,
    textAlign: 'left',
    info: getColumnsNameTooltip('Commercial Model'),
    cx: css.columnHeaderWrapper,
  }),
  oppPartnerSalesManagers: () => {
    const width = 110;
    return {
      key: 'oppPartnerSalesManagers',
      caption: <ColumnHeader caption="Partner Sales Managers" column="oppPartnerSalesManagers" />,
      render: (item:IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppPartnerSalesManagers?.toString() ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppPartnerSalesManagers"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Partner Sales Managers'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppEngagementLeads: () => {
    const width = 110;
    return {
      key: 'oppEngagementLeads',
      caption: <ColumnHeader caption="Engagement Leads" column="oppEngagementLeads" />,
      render: (item:IQueryResponseItem) => (
        <GridTextCell
          data={item.values.oppEngagementLeads?.toString() ?? ''}
          textContainerWidth={width}
          isBaseRequiredFieldsSet={!!item.values.isBaseRequiredFieldsSet}
          cellId="oppEngagementLeads"
          isTooltip
        />
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Engagement Leads'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppTags: () => {
    const width = 110;
    return {
      key: 'oppTags',
      caption: <ColumnHeader caption="Operational Labels" column="oppTags" />,
      render: (item:IQueryResponseItem) => item?.values?.oppId && (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '.5rem',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        >
          <OppTagPicker
            iconOnly
            tags={item?.values?.oppTags || []}
            oppId={item.values.oppId ?? ''}
            disabled={
              item.values?.oppState === 'Closed' || item.values?.oppState === 'Lost' || item.values?.oppState === 'Merged'
            }
            canEditOpp={item.values.canEditOpp}
          />
        </div>
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Operational Labels'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppTagFlag: () => {
    const width = 110;
    return {
      key: 'oppTagFlag',
      caption: <ColumnHeader caption="Flag" column="oppTagFlag" />,
      render: (item:IQueryResponseItem) => item?.values?.oppId && (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '.5rem',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        >
          <OppFlagPicker
            color={item.values.oppTagFlag ?? 895450004}
            oppId={item.values.oppId ?? ''}
            disabled={
              item.values?.oppState === 'Closed' || item.values?.oppState === 'Lost' || item.values?.oppState === 'Merged'
            }
            canEditOpp={item.values.canEditOpp}
          />
        </div>
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Flag'),
      cx: css.columnHeaderWrapper,
    };
  },
  oppCorrections: () => {
    const width = 110;
    return {
      key: 'oppCorrections',
      caption: <ColumnHeader caption="Warning" column="oppCorrections" />,
      render: (item:IQueryResponseItem) => item?.values?.oppId
      && (item.values.oppOutdated
      || item.values.oppUnexpectedProbability)
      && (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '.5rem',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        >
          <OppWarning
            oppId={item.values.oppId}
            oppAging={item.values.oppAging}
            oppOutdated={item.values.oppOutdated}
            oppUnexpectedProbability={item.values.oppUnexpectedProbability}
          />
        </div>
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Warning'),
      cx: css.columnHeaderWrapper,
    };
  },
  hasNotes: () => {
    const width = 110;
    return {
      key: 'hasNotes',
      caption: <ColumnHeader caption="Notes" column="hasNotes" disableSorting />,
      render: (item:IQueryResponseItem) => item?.values?.oppId && (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '.5rem',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        >
          <OppNotesButton
            oppId={item.values.oppId}
            oppName={item.values.oppName}
            hasNotes={item.values.hasNotes}
          />
        </div>
      ),
      grow: 1,
      width,
      minWidth: minColWidth,
      textAlign: 'left',
      info: getColumnsNameTooltip('Notes'),
      cx: css.columnHeaderWrapper,
    };
  },
};

const getBreakdownCaption = (text: string) => (
  <span className={css.breakdownHeaderCaption}>{text}</span>
);

export const getBreakdownDictionary = (userLocalDate: Dayjs): IDictionary<number> => ({
  m1: (year, mode) => ({
    key: `yyyyyy${year}m1`,
    caption: getBreakdownCaption(`JAN-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m1"
        year={year}
        columnName={`JAN-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(1, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm1', year, `JAN-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m2: (year, mode) => ({
    key: `yyyyyy${year}m2`,
    caption: getBreakdownCaption(`FEB-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m2"
        year={year}
        columnName={`FEB-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(2, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm2', year, `FEB-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m3: (year, mode) => ({
    key: `yyyyyy${year}m3`,
    caption: getBreakdownCaption(`MAR-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m3"
        year={year}
        columnName={`MAR-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(3, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm3', year, `MAR-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m4: (year, mode) => ({
    key: `yyyyyy${year}m4`,
    caption: getBreakdownCaption(`APR-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m4"
        year={year}
        columnName={`APR-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(4, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm4', year, `APR-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m5: (year, mode) => ({
    key: `yyyyyy${year}m5`,
    caption: getBreakdownCaption(`MAY-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m5"
        year={year}
        columnName={`MAY-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(5, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm5', year, `MAY-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m6: (year, mode) => ({
    key: `yyyyyy${year}m6`,
    caption: getBreakdownCaption(`JUN-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m6"
        year={year}
        columnName={`JUN-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(6, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm6', year, `JUN-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m7: (year, mode) => ({
    key: `yyyyyy${year}m7`,
    caption: getBreakdownCaption(`JUL-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m7"
        year={year}
        columnName={`JUL-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(7, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm7', year, `JUL-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m8: (year, mode) => ({
    key: `yyyyyy${year}m8`,
    caption: getBreakdownCaption(`AUG-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m8"
        year={year}
        columnName={`AUG-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(8, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm8', year, `AUG-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m9: (year, mode) => ({
    key: `yyyyyy${year}m9`,
    caption: getBreakdownCaption(`SEP-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m9"
        year={year}
        columnName={`SEP-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(9, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm9', year, `SEP-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m10: (year, mode) => ({
    key: `yyyyyy${year}mm1`,
    caption: getBreakdownCaption(`OCT-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m10"
        year={year}
        columnName={`OCT-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(10, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm10', year, `OCT-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m11: (year, mode) => ({
    key: `yyyyyy${year}mm2`,
    caption: getBreakdownCaption(`NOV-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m11"
        year={year}
        columnName={`NOV-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(11, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm11', year, `NOV-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  m12: (year, mode) => ({
    key: `yyyyyy${year}mm3`,
    caption: getBreakdownCaption(`DEC-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="m12"
        year={year}
        columnName={`DEC-${formateYear(year)}`}
      />
    ),
    renderCell: mode === 'edit' && checkEditability(12, year, userLocalDate)
      ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'm12', year, `DEC-${formateYear(year)}`) : undefined,
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  q1: (year) => ({
    key: `yyyyyy${year}q1`,
    caption: getBreakdownCaption(`Q1-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="q1"
        year={year}
        columnName={`Q1-${formateYear(year)}`}
      />
    ),
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  q2: (year) => ({
    key: `yyyyyy${year}q2`,
    caption: getBreakdownCaption(`Q2-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="q2"
        year={year}
        columnName={`Q2-${formateYear(year)}`}
      />
    ),
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  q3: (year) => ({
    key: `yyyyyy${year}q3`,
    caption: getBreakdownCaption(`Q3-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="q3"
        year={year}
        columnName={`Q3-${formateYear(year)}`}
      />
    ),
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
  q4: (year) => ({
    key: `yyyyyy${year}q4`,
    caption: getBreakdownCaption(`Q4-${formateYear(year)}`),
    render: (item: IQueryResponseItem) => (
      <GridDataCell
        item={item}
        type="breakdown"
        cellId="q4"
        year={year}
        columnName={`Q4-${formateYear(year)}`}
      />
    ),
    grow: 1,
    width: 80,
    minWidth: 80,
    textAlign: 'left',
  }),
});
