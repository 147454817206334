import api from './api';

const exportPivot = async (reqBody: {
  from: string,
  to: string,
  oppStatus: {
    eq: string,
    values: number[]
  }
}): Promise<Blob> => {
  const res = await api.post<File>(
    'export/pivot',
    reqBody,
    {
      responseType: 'arraybuffer',
    },
  );
  const blob = new Blob([res.data], {
    type: res.headers['content-type'],
  });
  return blob;
};

export default exportPivot;
