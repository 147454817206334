import { atom } from 'recoil';

interface IEditModeErrorItem {
  oppId: string,
  oppName: string,
  message: string[],
}

export type TEditModeErrorsAtom = IEditModeErrorItem[] | [];

export const VALUE_ERROR_MSG = 'Monthly forecast revenue must be in range from -50 mln USD to 50 mln USD.';

const editModeErrorsAtom = atom<TEditModeErrorsAtom>({
  key: 'editModeErrorsAtom',
  default: [],
});

export default editModeErrorsAtom;
