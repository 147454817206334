import React, { FC, useRef, useState } from 'react';
import { Button, PickerInput } from '@epam/promo';
import { useArrayDataSource } from '@epam/uui-core';
import css from '../GroupingSettings.module.scss';
import { accAttributes, Levels, multiGroupingAttributes } from '../../../../../../const/GROUPINGS';
import { COLOR_NIGHT_600, COLOR_NIGHT_800, getPadding } from '../../../const/STYLES';
import LevelIcon from './LevelIcon';
import OppCheckbox from './OppCheckbox';
import { TQueryValuesKey } from '../../../../../../api/query';

interface Props {
  level: Levels,
  levelIndex: number,
  value: TQueryValuesKey,
  onChange: (val: TQueryValuesKey) => void,
  onClear: () => void,
  selected: TQueryValuesKey[],
  oppCheckbox: boolean,
  setOppCheckbox: (newVal: boolean) => void,
  oppWarningsCheckbox: boolean,
  setOppWarningsCheckbox: (newVal: boolean) => void,
}

const LevelPicker: FC<Props> = ({
  level, 
  value, 
  onChange, 
  onClear, 
  selected, 
  levelIndex, 
  setOppCheckbox, 
  oppCheckbox,
  setOppWarningsCheckbox,
  oppWarningsCheckbox,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const applyMultilevelGroupingRules = () => {
    let avoidedValues: Array<TQueryValuesKey> = [];
    selected.forEach((selectedItem, index) => {
      if (levelIndex > index) {
        avoidedValues = [
          ...avoidedValues,
          ...selected,
        ];
        // If 'Account' is selected for grouping,
        // the system makes not possible to select any of the ACC attributes on all LOWER levels.
        if (selectedItem === 'accName') {
          avoidedValues = [
            ...avoidedValues,
            ...accAttributes.map((item) => item.id),
          ];
        }
        // If 'GBU Portfolio' is specified in the multilevel grouping popup,
        // then 'GBU 'Regional ' shouldn't be shown for lowest levels.
        if (selectedItem === 'GBUPortfolio') {
          avoidedValues.push('RegionalGBU');
        }
        // If 'GBU' is specified in the multilevel grouping popup,
        // then  'GBU Portfolio' and 'GBU Regional' shouldn't be shown for lowest levels.
        if (selectedItem === 'GBU') {
          avoidedValues.push('RegionalGBU', 'GBUPortfolio');
        }
      }
    });
    return multiGroupingAttributes.filter((item) => !avoidedValues.includes(item.id));
  };

  const [data, setData] = useState(applyMultilevelGroupingRules());
  const dataSource = useArrayDataSource(
    {
      items: data,
    },
    [data],
  );

  const isAddLevel = !value;
  const isLevelOne = level === Levels.one;

  const displayedValue = !isAddLevel 
    ? multiGroupingAttributes.filter((item) => item.id === value)[0].value 
    : 'Add level';

  const caption = (
    <span 
      className={css.value}
      style={{
        color: value ? COLOR_NIGHT_800 : COLOR_NIGHT_600,
      }}
    >
      {displayedValue}
    </span>
  );

  const levelIcon = !isLevelOne 
  && (
  <div className={css.iconContainer}>
    <LevelIcon isLevelOne={isLevelOne} />
  </div>
  );

  const flagsTagsNotesCheckbox = value === 'oppName'
  && (
    <OppCheckbox 
      value={oppCheckbox} 
      onChange={setOppCheckbox} 
      paddingLeft={getPadding(level)}
      label="Flags, Operational Labels, Notes"
    />
  );

  const warningsCheckbox = value === 'oppName'
  && (
    <OppCheckbox 
      value={oppWarningsCheckbox} 
      onChange={setOppWarningsCheckbox} 
      paddingLeft={getPadding(level)} 
      label="Warnings"
      withTopMargin
    />
  );

  const handleClick = (onClick?: (e: MouseEvent) => void, e?: MouseEvent) => {
    if (onClick && e) onClick(e);
    setData(applyMultilevelGroupingRules());
  };

  return (
    <div className={css.levelPickerWrapper}>
      <div 
        ref={ref}
        className={css.levelPickerRow}
        style={{
          paddingLeft: getPadding(level),
        }}
      >
        {levelIcon}
        <PickerInput
          value={value}
          portalTarget={ref.current || undefined}
          renderToggler={({ onClick, ref: refToggler }) => (
            <Button
              onClear={!isAddLevel ? onClear : undefined}
              ref={refToggler}
              isDropdown
              onClick={(e) => handleClick(onClick, e)}
              caption={caption}
              fill="white"
              color="gray50"
              cx={css.picker}
              size="30"
            />
          )}
          sorting={{ field: 'value', direction: 'asc' }}
          onValueChange={onChange}
          dataSource={dataSource}
          getName={(item) => item.value}
          entityName="Group"
          selectionMode="single"
          valueType="id"
          searchPosition="none"
          disableClear
          size="30"
          minBodyWidth={360 - (30 * (levelIndex + 1))}
        />
      </div>
      {flagsTagsNotesCheckbox}
      {warningsCheckbox}
    </div>
  );
};

export default LevelPicker;
