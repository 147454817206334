import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorHandler } from '@epam/promo';
import '@epam/uui-components/styles.css';
import '@epam/promo/styles.css';
import { RecoilRoot } from 'recoil';
import { ContextProvider } from '@epam/uui-core';
import App from './App';
import OneCrmApp from './OneCrmApp';
import svc from './services';
import ConfigProvider from './providers/ConfigProvider';
import AuthProvider from './providers/AuthProvider';
import ApiProvider from './providers/ApiProvider';

const { createBrowserHistory } = require('history');

const history = createBrowserHistory();

function UuiEnhancedApp() {
  return (
    <RecoilRoot>
      <ConfigProvider>
        <ContextProvider
          onInitCompleted={(context) => Object.assign(svc, context)}
          history={history}
        >
          <ErrorHandler>
            <AuthProvider>
              <ApiProvider>
                <Routes>
                  <Route path="/" element={<App />} />
                  <Route path="/onecrm" element={<OneCrmApp />} />
                </Routes>
              </ApiProvider>
            </AuthProvider>
          </ErrorHandler>
        </ContextProvider>
      </ConfigProvider>
    </RecoilRoot>
  );
}

function RoutedApp() {
  return (
    <BrowserRouter>
      <UuiEnhancedApp />
    </BrowserRouter>
  );
}

ReactDOM.render(<RoutedApp />, document.getElementById('root'));
