import React from 'react';
import { Text } from '@epam/promo';
import css from './OppHistoryModal.module.scss';

interface OppHistoryCellProps {
  value: number | null;
  isChanged?: boolean;
}

const formatValue = (val: number | null) => {
  if (typeof val === 'number') {
    if (val?.toString().includes('.')) {
      return val?.toFixed(2);
    }
    return val;
  }
  return null;
};

const OppHistoryCell = (props: OppHistoryCellProps) => {
  const { value, isChanged } = props;
  
  return (
    <Text fontSize="12" cx={!isChanged ? css.value : css.valueChanged}>
      {formatValue(value)}
    </Text>
  );
};

export default OppHistoryCell;
