import React from 'react';
import { useRecoilValue } from 'recoil';
import { DataColumnProps, RenderCellProps } from '@epam/uui-core';
import { Text } from '@epam/promo';
import { Dayjs } from 'dayjs';
import {
  IQueryResponseItem, IQueryResponseValues, ProcessedResponseItem, TQueryValuesKey,
} from '../api/query';
import ColumnHeader from '../components/Grid/VirtualList/components/ColumnHeader';
import ColumnsBtn from '../components/Grid/VirtualList/components/ColumnsBtn';
import OppColumnItem from '../components/Grid/VirtualList/components/OppColumnItem';
import {
  columnsDictionary,
  IBreakdownItem,
  getBreakdownDictionary,
  renderEditableCell,
  currCol,
} from '../components/Grid/VirtualList/VirtualListColumns';
import queryParamsAtom from '../state/atoms/queryParamsAtom';
import css from '../components/Grid/VirtualList/VirtualList.module.scss';
import editCss from '../components/EditModeGrid/editModeGrid.module.scss';
import breakdownAtom from '../state/atoms/breakdownAtom';
import currentColumnsIdsSelector from '../state/selectors/currentColumnsIdsSelector';
import { ITotalsItem } from './useQueryApi';
import { getConfigArr, sortCheckboxesByOrder } from '../const/COLUMNS';
import viewModeAtom from '../state/atoms/viewModeAtom';
import useUserProfile from './useUserProfile';
import { viewStateAtom } from '../state/atoms/viewStateAtom';

const formateYear = (year: number) => Math.abs(year % 100);

export const formatPluralForm = (item: any) => {
  if (item[item.length - 1] === 's') return item;
  if (item[item.length - 1] === 'y') return `${item.slice(0, -1)}ies`;
  return `${item}s`;
};

const getVisibleColumns = (
  list: TQueryValuesKey[],
  data: number | IQueryResponseItem,
  mode?: 'edit' | 'view',
) => list.filter((el) => el).map((column) => columnsDictionary[column](data, mode));

const getGroupingName = (arr: TQueryValuesKey[]) => arr
  .reverse()
  .map((item, idx) => (
    idx
      ? item
      : formatPluralForm(item)
  ))
  .join(' by ')
  .toLocaleUpperCase();

export const getBreakdownColumns = (userLocalDate: Dayjs) => (arr: IBreakdownItem[], breakdown: 'm' | 'q', mode?: 'edit' | 'view') => arr
  .reduce((acc: DataColumnProps[], item) => {
    const dictionaryId = breakdown + item.index;
    if ((breakdown === 'q' && item.index < 5) || breakdown === 'm') {
      return [...acc, getBreakdownDictionary(userLocalDate)[dictionaryId](Number(item.year), mode)];
    } return acc;
  }, []);

export const getColumnWidthFromDictionary = (
  id: TQueryValuesKey,
) => columnsDictionary[id](new Date().getFullYear()).width;

const useVirtualListColumns = () => {
  const { filtersStore, groupingStore } = useRecoilValue(queryParamsAtom);
  const { columnsConfig } = useRecoilValue(viewStateAtom);
  const columnsIds = useRecoilValue(currentColumnsIdsSelector);
  const activeColumnsIds = columnsIds;
  const groupingNames = groupingStore.multiGrouping.map((el) => el.value);
  const { breakdown, revenueYear } = filtersStore;
  const breakdownType = breakdown === 'Monthly' ? 'm' : 'q';
  const breakdownStore = useRecoilValue(breakdownAtom);
  const { mode } = useRecoilValue(viewModeAtom);
  const { getLocalTime } = useUserProfile();

  const allActiveCols = getConfigArr(columnsConfig || {})
    .filter((el) => el.isVisible)
    .sort(sortCheckboxesByOrder);

  const activeColumns = allActiveCols.filter((el) => !el.fix);
  const pinnedCols = allActiveCols.filter((el) => !!el.fix).sort(sortCheckboxesByOrder);
  const pinnedWithoutDefault = pinnedCols.filter((el) => el.id !== 'grouping' && el.id !== 'columnsConfig' && el.id !== 'currName');

  const getNonAttrCellClass = (cellId: keyof IQueryResponseValues) => {
    const cellsWithBorder: (keyof IQueryResponseValues)[] = ['gttl', 'ytd', 'frcst'];

    const res: string[] = [];

    const idx = activeColumns.findIndex((el) => el.id === cellId);
    const idxPinned = pinnedCols.findIndex((el) => el.id === cellId);

    const isFirstNonAttr = cellsWithBorder.includes(cellId) && (
      (idx > 0 && !cellsWithBorder.includes(activeColumns[idx - 1]?.id))
      || (
        (idxPinned >= 0 && !cellsWithBorder.includes(pinnedCols[idxPinned - 1]?.id))
        || idxPinned === 0
      )
    );

    if (isFirstNonAttr) {
      res.push(css.firstNonAttrCell);
      if (mode === 'edit') {
        res.push(editCss.firstNonAttrCell);
      }
    }

    const isLastNonAttr = cellId === pinnedCols[pinnedCols.length - 1]?.id
      || (cellsWithBorder.includes(cellId) && (
        (idx >= 0 && !cellsWithBorder.includes(activeColumns[idx + 1]?.id))
        || (idxPinned >= 0 && !cellsWithBorder.includes(pinnedCols[idxPinned + 1]?.id)))
      )
      || (cellId === 'grouping' && !pinnedWithoutDefault.length && mode === 'view')
      || (cellId === 'currency' && !pinnedWithoutDefault.length && mode === 'edit');

    if (isLastNonAttr) {
      res.push(css.lastNonAttrCell);
      if (mode === 'edit') {
        res.push(editCss.lastNonAttrCell);
      }
    }

    return res;
  };

  const getListColumns = (): DataColumnProps[] => {
    const visibleCols = getVisibleColumns(
      activeColumnsIds,
      formateYear(revenueYear),
      mode,
    ).map((el) => ({
      ...el,
      cx: [el.cx, ...getNonAttrCellClass(el.key)],
    }));

    const monthsCols = getBreakdownColumns(getLocalTime())(breakdownStore, breakdownType, mode);

    const settingsCols: DataColumnProps[] = [
      {
        key: 'grouping',
        caption: <ColumnHeader
          caption={getGroupingName(groupingNames)}
          column="grouping"
        />,
        render: (item: ProcessedResponseItem, props) => (
          <OppColumnItem
            item={item}
            rowProps={props}
          />
        ),
        grow: 0,
        width: 300,
        minWidth: 300,
        alignSelf: 'left',
        fix: 'left',
        info: getGroupingName(groupingNames),
        cx: !pinnedWithoutDefault.length
          ? [css.columnHeaderWrapper, ...getNonAttrCellClass('grouping')]
          : css.columnHeaderWrapper,

      },
      {
        key: 'columnsConfig',
        caption: mode === 'view' && <ColumnsBtn />,
        render: () => null,
        renderCell: mode === 'edit'
          ? (props: RenderCellProps<any, any>) => renderEditableCell(props, 'configCell', 2022, 'BSRV')
          : undefined,
        grow: 0,
        width: 54,
        minWidth: 54,
        fix: 'right',
        textAlign: 'center',
        cx: css.configCell,
        info: 'Columns and Grouping Settings',
      },
    ];

    if (mode === 'edit') {
      return [
        ...settingsCols,
        ...[...visibleCols, {
          ...currCol,
          cx: [currCol.cx, ...getNonAttrCellClass('currency')],
        }],
        ...monthsCols,
      ];
    }

    return [
      ...settingsCols,
      ...visibleCols,
      ...monthsCols,
    ];
  };

  const getSummaryColumns = (summary: ITotalsItem): DataColumnProps[] => {
    const visibleCols = getVisibleColumns(activeColumnsIds, formateYear(revenueYear))
      .map((el) => ({
        ...el,
        cx: getNonAttrCellClass(el.key),
      }));
    const monthsCols = getBreakdownColumns(getLocalTime())(breakdownStore, breakdownType, mode);

    return [
      {
        key: 'grouping',
        caption: 'Total',
        render: () => (
          <Text color="gray80" cx={css.footerText}>
            <span className={css.footerTextPrimary}>Total</span>
            {` ${summary?.count} records`}
          </Text>
        ),
        fix: 'left',
        grow: 0,
        width: 300,
        minWidth: 54,
        cx: getNonAttrCellClass('grouping'),
      },
      {
        key: 'columnsConfig',
        caption: '',
        render: () => {},
        grow: 1,
        width: 54,
        fix: 'right',
        textAlign: 'center',
        cx: css.configCell,
      },
      ...visibleCols,

      ...monthsCols,
    ];
  };

  return {
    getListColumns,
    getSummaryColumns,
    getGroupingName,
  };
};

export default useVirtualListColumns;
