import React, { FC } from 'react';

interface Props {
  cx: string
}

const tagIcon: FC<Props> = ({ cx }) => (
  <svg width="12" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx}>
    <path d="M11.646 5.748L6.246 0.348C6.02101 0.124848 5.71689 -0.000252494 5.4 3.82628e-07H1.2C0.881741 3.82628e-07 0.576516 0.126429 0.351472 0.351472C0.126429 0.576516 3.76758e-07 0.881741 3.76758e-07 1.2V5.4C-0.000125095 5.55835 0.03109 5.71515 0.0918465 5.86138C0.152603 6.00761 0.241699 6.14037 0.354 6.252L5.754 11.652C5.97899 11.8752 6.28311 12.0003 6.6 12C6.91775 11.9987 7.22199 11.8714 7.446 11.646L11.646 7.446C11.8714 7.22199 11.9987 6.91775 12 6.6C12.0001 6.44165 11.9689 6.28485 11.9082 6.13862C11.8474 5.99239 11.7583 5.85963 11.646 5.748ZM6.6 10.8L1.2 5.4V1.2H5.4L10.8 6.6L6.6 10.8ZM2.7 1.8C2.878 1.8 3.05201 1.85278 3.20001 1.95168C3.34802 2.05057 3.46337 2.19113 3.53149 2.35559C3.59961 2.52004 3.61743 2.701 3.58271 2.87558C3.54798 3.05016 3.46226 3.21053 3.3364 3.3364C3.21053 3.46226 3.05016 3.54798 2.87558 3.58271C2.701 3.61743 2.52004 3.59961 2.35559 3.53149C2.19113 3.46337 2.05057 3.34802 1.95168 3.20001C1.85278 3.05201 1.8 2.878 1.8 2.7C1.8 2.46131 1.89482 2.23239 2.0636 2.0636C2.23239 1.89482 2.46131 1.8 2.7 1.8Z" fill="#6C6F80" />
  </svg>
);

const tagIconBig: FC<Props> = ({ cx }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx}>
    <path id="Vector" d="M16.0575 8.685L9.3075 1.935C9.02626 1.65606 8.64611 1.49968 8.25 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V8.25C1.49984 8.44793 1.53886 8.64394 1.61481 8.82673C1.69075 9.00951 1.80212 9.17546 1.9425 9.315L8.6925 16.065C8.97374 16.3439 9.35389 16.5003 9.75 16.5C10.1472 16.4983 10.5275 16.3392 10.8075 16.0575L16.0575 10.8075C16.3392 10.5275 16.4983 10.1472 16.5 9.75C16.5002 9.55207 16.4611 9.35606 16.3852 9.17327C16.3092 8.99049 16.1979 8.82454 16.0575 8.685ZM9.75 15L3 8.25V3H8.25L15 9.75L9.75 15ZM4.875 3.75C5.0975 3.75 5.31501 3.81598 5.50002 3.9396C5.68502 4.06321 5.82922 4.23891 5.91436 4.44448C5.99951 4.65005 6.02179 4.87625 5.97838 5.09448C5.93498 5.31271 5.82783 5.51316 5.6705 5.6705C5.51316 5.82783 5.31271 5.93498 5.09448 5.97838C4.87625 6.02179 4.65005 5.99951 4.44448 5.91436C4.23891 5.82922 4.06321 5.68502 3.9396 5.50002C3.81598 5.31501 3.75 5.0975 3.75 4.875C3.75 4.57663 3.86853 4.29048 4.07951 4.07951C4.29048 3.86853 4.57663 3.75 4.875 3.75Z" fill="#6C6F80" />
  </svg>
);

export { tagIcon, tagIconBig };
