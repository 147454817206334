/* eslint-disable react/destructuring-assignment */
import React, { FC, useEffect } from 'react';
import { IEditable, useAsyncDataSource } from '@epam/uui-core';
import {
  PickerInput,
} from '@epam/promo';
import { applyNotApplicableLogic } from './const';
import css from './EditProbabilityModal.module.scss';
import { fetchAdvancedFilterOptions } from '../../../api/filters';
import { useHierarchyPickerInput } from '../../../hooks/useHierarchyPickerInput';
import { usePickerParentOptionsRemove } from '../../../hooks/usePickerParentOptionsRemove';

interface EditProbabilityModalProps {
  fieldKey: string,
  fieldProps: IEditable<any>,
  optionsToKeep?: string[]
}

let number = 0;

const PickerInputField:FC<EditProbabilityModalProps> = ({ 
  fieldKey,
  fieldProps,
  optionsToKeep,
}) => {
  const selectionMode = (
    fieldKey === 'oppDealType'
    || fieldKey === 'oppEngagementType'
    || fieldKey === 'commercialModel'
    || fieldKey === 'oppAM'
    || fieldKey === 'oppLeadPractice'
    || fieldKey === 'oppIndustry'
  ) ? 'single' : 'multi';

  const { isHierarchy, getRowOptions } = useHierarchyPickerInput(selectionMode);
  usePickerParentOptionsRemove({ isHierarchy: isHierarchy(fieldKey), fieldProps });

  const dataSource: any = useAsyncDataSource({
    api: () => fetchAdvancedFilterOptions(fieldKey, undefined, isHierarchy(fieldKey) && fieldKey !== 'oppLeadPractice', true)
      .then((res) => {
        const result = res.items
          .filter((el: any) => (optionsToKeep ? optionsToKeep?.includes(el.id) : true))
          .filter((el: any) => (fieldKey === 'oppPractices' || fieldKey === 'oppIndustry' ? el.name !== 'Not Applicable' : true))
          .map((el: any) => ({
            ...el,
            id: el.id ?? (number += 1),
          }));
        return result;
      }),
  }, [JSON.stringify(optionsToKeep)]);

  useEffect(() => {
    if (fieldKey === 'oppPlatforms' || fieldKey === 'oppContinuumOfferings') {
      const notApplicableId = dataSource.props.items.length ? (dataSource.props.items[0].id as string) : '';
      applyNotApplicableLogic(fieldProps, notApplicableId);
    }
  }, [fieldProps.value]);

  const bodyClassName = (
    fieldKey === 'oppPlatforms'
    || fieldKey === 'oppContinuumOfferings'
    || fieldKey === 'oppPractices'
    || fieldKey === 'oppIndustry'
  ) ? `
    ${css.pickerInputWithRadioButton}
    ${isHierarchy(fieldKey) ? css.pickerInputWithHierarchy : ''}
    ${fieldKey === 'oppIndustry' ? css.pickerIndustry : ''}
    ${fieldKey === 'oppContinuumOfferings' ? css.pickerContinuumOfferings : ''}
    ` : '';

  return (
    <PickerInput
      {...fieldProps}
      searchPosition={fieldKey === 'oppLeadPractice' ? 'none' : 'input'}
      searchDebounceDelay={500}
      isDisabled={fieldKey === 'oppLeadPractice' && !optionsToKeep?.length}
      rawProps={{
        body: {
          className: bodyClassName,
        },
      }}
      renderFooter={() => null}
      valueType="entity"
      maxItems={1}
      dataSource={dataSource}
      selectionMode={selectionMode}
      cascadeSelection={isHierarchy(fieldKey) && fieldKey !== 'oppLeadPractice'}
      getRowOptions={isHierarchy(fieldKey) && fieldKey !== 'oppLeadPractice' ? getRowOptions : undefined}
    />
  );
};

export default PickerInputField;
