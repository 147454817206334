import * as React from 'react';
import cx from 'classnames';
import {
  IDropdownToggler, uuiElement, uuiMarkers, uuiMod, 
} from '@epam/uui-core';
import { FlexRow } from '@epam/uui-components';
import { IconContainer, Text } from '@epam/promo';
import { ReactComponent as arrowDownIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import css from './FilterbarItemToggler.module.scss';
import { ExtendedFilterPredicate, predicateDictionary } from '../../../../../const/ADVANCED_FILTERS';

const defaultSize = '30';

export interface FilterToolbarItemTogglerProps extends IDropdownToggler {
  id: string,
  selection: string | null | JSX.Element;
  postfix?: string | null | JSX.Element;
  title?: string;
  maxWidth?: string;
  size?: '24' | '30' | '36' | '42' | '48';
  cx?: string | string[];
  predicate: string;
  predicates: ExtendedFilterPredicate[];
  direction?: 'row' | 'column';
}

export const FilterToolbarItemToggler = React
  .forwardRef<HTMLDivElement, FilterToolbarItemTogglerProps>((props, ref) => {
  const togglerPickerOpened = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.isDisabled) return;
    e.preventDefault();
    props.onClick?.();
  };

  const predicateSign = predicateDictionary[props.predicate].name;

  const lastTwoLetters = props.title?.slice(props.title.length - 2);
  const title = lastTwoLetters === 'On' 
    ? props.title?.slice(0, props.title.length - 2) : props.title;

  return (
    <FlexRow
      {...props}
      rawProps={{ 
        style: {
          maxHeight: `${props.size || defaultSize}px`,
          maxWidth: `${props.maxWidth}px`,
          margin: '0 .375rem .375rem 0',
          flexGrow: props.direction === 'column' ? 1 : 0,
        },
        'aria-label': `${props.id}-advanced-filter`, 
      }}
      cx={cx(
        css.root,
        uuiElement.inputBox,
        uuiMarkers.clickable,
        props.isOpen ? uuiMod.opened : '',
        [`size-${props.size || defaultSize}`],
        { [css.disabled]: props.isDisabled },
      )}
      onClick={togglerPickerOpened}
      ref={ref}
    >
      <div className={css.titleWrapper}>
        <Text 
          color="gray60" 
          cx={css.title}
          rawProps={{ style: { marginRight: '.25rem' } }}
        >
          { `${title} ${predicateSign}` }
        </Text>
        <div style={{ display: 'flex', overflow: 'hidden' }}>
          { !props.postfix 
          && (
          <Text 
            color="gray90" 
            cx={
              props.direction === 'column' 
                ? css.selection 
                : [css.selection, css.selectionRow]
            }
          >
            { props.selection }
          </Text>
          )}
          { props.postfix && <Text color="gray90" cx={css.postfix}>{ props.postfix }</Text> }
        </div>
      </div>
      <IconContainer
        icon={arrowDownIcon}
        flipY={props.isOpen}
        cx="uui-icon-dropdown"
      />
    </FlexRow>
  );
});
