import { FlexRow, IconContainer, Text } from '@epam/promo';
import React from 'react';
import NotesIcon from '../../../../assets/components/NotesIcon';
import css from '../NotesModal.module.scss';

const EmptyNotesList = () => (
  <FlexRow cx={css.emptyNotesList}>
    <IconContainer icon={NotesIcon} size={64} cx={css.epmtyListIconContainer} />
    <Text cx={css.emptyListText}>No notes added</Text>
  </FlexRow>
);

export default EmptyNotesList;
