import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { Route, Routes } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Modals, Snackbar } from '@epam/uui-components';
import { init as initApm } from '@elastic/apm-rum';
import { viewPresetsAtom } from './state/atoms/viewPresetsAtom';
import filtersOptionsAtom from './state/atoms/filtersOptionsAtom';
import MainPage from './pages/MainPage';
import AppHeader from './components/AppHeader/AppHeader';
import css from './App.module.scss';
import Loader from './components/Loader';
import AccessDeniedErrorPage from './components/AccessDeniedErrorPage/AccessDeniedErrorPage';
import { configState } from './providers/ConfigProvider';
import viewModeAtom from './state/atoms/viewModeAtom';
import userProfileAtom from './state/atoms/userProfileAtom';
import getUserProfile from './api/profile';
import { useAppInsight } from './hooks/useAppInsight';
import useScript from './hooks/useScript';

import '@epam/uui/styles.css';

export default function App() {
  const { reactPlugin } = useAppInsight();
  const config = useRecoilValue(configState);
  useScript(config?.globalMenuUrl ?? '');

  const parsedParams = config?.apmInitParams ? JSON.parse(config.apmInitParams) : {};
  const initApmParams: Record<string, any> = {
    serviceName: 'pipeline-ui',
    serverUrl: config?.apmUrl,
    serviceVersion: '1.0.0',
    environment: config?.environment,
    breakdownMetrics: true,
    transactionSampleRate: config?.transactionSampleRate,
    ignoreTransactionsUrls: [config?.appInsightConnectionString.split(';')[1]],
    ...parsedParams,
  };

  const apm = initApm(initApmParams);

  const parsedLabels = config?.apmLabels ? JSON.parse(config.apmLabels) : {};

  apm.addLabels(parsedLabels);
  const viewPresetsLoadable = useRecoilValueLoadable(viewPresetsAtom);
  const filtersOptionsLoadable = useRecoilValueLoadable(filtersOptionsAtom);
  const { mode } = useRecoilValue(viewModeAtom);
  const setUserProfile = useSetRecoilState(userProfileAtom);
  const [isUserProfileLoading, setIsUserProfileLoading] = useState(true);

  useEffect(() => {
    window.breakoutOfIFrame();
    window.escapeEpamAccessHash();
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsUserProfileLoading(true);
      const data = await getUserProfile();
      const userProfileWithSettingsFallback = {
        ...data,
        ...(!data.userSettings && {
          userSettings: {
            dateFormatString: 'M/d/yyyy',
            timeFormatString: 'h:mm tt',
            timeZoneBias: 0,
          }, 
        }),
      };
      setUserProfile(userProfileWithSettingsFallback);
      setIsUserProfileLoading(false);
    };

    fetchUserProfile();
  }, []);

  if (
    (viewPresetsLoadable.state === 'hasError' && viewPresetsLoadable.contents.response?.status === 403)
    || (filtersOptionsLoadable.state === 'hasError' && filtersOptionsLoadable.contents.response?.status === 403)
  ) {
    return (
      <div>
        <AppHeader withAccessError />
        <AccessDeniedErrorPage />
      </div>
    );
  }
  if (viewPresetsLoadable.state === 'loading' || filtersOptionsLoadable.state === 'loading' || isUserProfileLoading) {
    return <Loader />;
  }
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div className={`${css.app} uui-theme-promo`}>
        <AppHeader />
        <main className={mode === 'edit' ? css.editModeMain : undefined}>
          <Routes>
            <Route path="/" element={<MainPage />} />
          </Routes>
        </main>
        <Snackbar />
        <React.Suspense fallback={<Loader />}>
          <Modals />
        </React.Suspense>
      </div>
    </AppInsightsContext.Provider>
  );
}
