import { selector } from 'recoil';
import advancedFiltersConstructorAtom from '../atoms/advancedFiltersConstructorAtom';

const BASIC_MODE_LIMIT = 20;

interface IBasicModeAvailabilitySelector {
  isBasicModeDisabled: boolean;
  selectedFiltersLimit: number;
}

const basicModeAvailabilitySelector = selector<IBasicModeAvailabilitySelector>({  
  key: 'basicModeAvailability/selector',
  get: ({ get }) => {
    const { constructor } = get(advancedFiltersConstructorAtom);

    const selectedFiltersCount = Object.keys(constructor[0].filters).length;

    const isBasicModeDisabled = selectedFiltersCount > BASIC_MODE_LIMIT;

    return {
      isBasicModeDisabled,
      selectedFiltersLimit: BASIC_MODE_LIMIT,
    };
  },
});

export default basicModeAvailabilitySelector;
