import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { fetchAdvancedFilterOptions } from '../../../api/filters';
import oppTagsOptionsAtom from '../../../state/atoms/oppTagsOptionsAtom';
import Filterbar from '../../slide-outs/AdvancedFiltersSlideOut/components/Filterbar/Filterbar';
import { parseAdvancedFiltersToView, predicateDictionary, useCreateDataSource } from '../../../const/ADVANCED_FILTERS';
import { provideOrderForNewItem } from '../../../helpers/provideOrderValue';
import { View } from '../../../api/views';
import advancedFiltersConstructorAtom from '../../../state/atoms/advancedFiltersConstructorAtom';
import defaultViewPresetSelector from '../../../state/selectors/defaultViewPresetSelector';
import { useViewPresets } from '../../../hooks/useViewPresets';
import viewModeAtom from '../../../state/atoms/viewModeAtom';
import { IAdvancedFilter } from '../../../api/query';

const AdvancedFilters = () => {
  const { getAdvancedFilters } = useCreateDataSource();
  const filters = getAdvancedFilters();
  const [value, setValue] = useRecoilState(advancedFiltersConstructorAtom);
  const setOppTagsOptions = useSetRecoilState(oppTagsOptionsAtom);
  const { mode } = useRecoilValue(viewModeAtom);
  const data = value.constructor[0];

  const { updateViewPresetInList } = useViewPresets();
  const defaultViewPreset = useRecoilValue(defaultViewPresetSelector);

  const handleAddFilter = async (filterIds: string[]) => {
    const newFilters = filterIds.reduce((acc, el) => {
      if (data.filters[el]) {
        acc = {
          ...acc,
          [el]: data.filters[el],
        };

        return acc;
      }
      
      const predicate = predicateDictionary[filters.filter((i) => i.field === el)[0]
        .predicates
        .filter((i) => i.isDefault)[0].predicate].id;
      
      const currentFilters = Object.values(data.filters);

      acc = {
        ...acc,
        [el]: {
          key: el,
          operator: predicate,
          order: currentFilters.length ? provideOrderForNewItem(currentFilters) : 'a',
          value: undefined,
        },
      };

      return acc;
    }, {});

    const isCleared = !Object.keys(data.filters).every((el) => filterIds.includes(el));

    const newConstructor = [{
      ...data,
      filters: newFilters,
    }];

    setValue((prev) => ({
      ...prev,
      changeCounter: isCleared ? prev.changeCounter + 1 : prev.changeCounter,
      constructor: newConstructor,
      cache: newConstructor,
    }));

    if (defaultViewPreset.isViewAutosaved && isCleared) {
      const updatedPreset: View = {
        ...defaultViewPreset,
        advancedFiltersSettings: parseAdvancedFiltersToView(newConstructor),
      };
      await updateViewPresetInList(updatedPreset);
    }
  };

  const handleFilterChange = async (filter: IAdvancedFilter) => {
    const newConstructor = [{
      ...data,
      filters: {
        ...data.filters,
        [filter.key]: filter,
      },
    }];

    setValue((prev) => ({
      ...prev,
      changeCounter: prev.changeCounter + 1,
      constructor: newConstructor,
      cache: newConstructor,
    }));

    if (defaultViewPreset.isViewAutosaved) {
      const updatedPreset: View = {
        ...defaultViewPreset,
        advancedFiltersSettings: parseAdvancedFiltersToView(newConstructor),
      };
      await updateViewPresetInList(updatedPreset);
    }
  };
  
  const handleRemoveFilter = async (field: string) => {
    const {
      filters: {
        [field]: filterToDelete,
        ...remainingFilters
      }, 
    } = data;

    const newConstructor = [{
      ...data,
      filters: remainingFilters,
    }];
    
    setValue((prev) => ({
      ...prev,
      changeCounter: prev.changeCounter + 1,
      constructor: newConstructor,
      cache: newConstructor,
    }));

    if (defaultViewPreset.isViewAutosaved) {
      const updatedPreset: View = {
        ...defaultViewPreset,
        advancedFiltersSettings: parseAdvancedFiltersToView([{
          ...data,
          filters: remainingFilters,
        }]),
      };
      await updateViewPresetInList(updatedPreset);
    }
  };

  useEffect(() => {
    fetchAdvancedFilterOptions('oppTags').then((res) => {
      setOppTagsOptions(res.items);
    });
  }, []);

  return (
    <Filterbar 
      filters={filters} 
      value={data.filters} 
      setValue={handleFilterChange}
      onAddFilter={handleAddFilter}
      removeFilter={handleRemoveFilter}
      direction="row"
      isWithConstructor
      isDisabled={mode === 'edit'}
    />
  );
};

export default AdvancedFilters;
