/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Checkbox, IconContainer } from '@epam/promo';
import React, { FC, useState } from 'react';
import { ReactComponent as ChevronRightIcon } from '@epam/assets/icons/common/navigation-chevron-right-18.svg';
import { ReactComponent as ChevronDownIcon } from '@epam/assets/icons/common/navigation-chevron-down-18.svg';
import { IColumnConfig } from '@epam/uui-core';
import css from '../ColumnsSettings.module.scss';
import { IQueryResponseValues } from '../../../../../../api/query';
import DraggableCheckbox from './DraggableCheckbox';
import { sortCheckboxesByAlphabet } from '../ColumnsSettings';

interface Props {
  label: string,
  items: (IColumnConfig & { id: string })[],
  onChange: (key: keyof IQueryResponseValues, newItem: IColumnConfig) => void,
  isIndeterminate?: boolean,
  disabled?: boolean,
  // onDrop: (
  //   checkbox: DropParams<IColumnCheckbox, IColumnCheckbox>,
  //   prev: IColumnCheckbox,
  //   next: IColumnCheckbox,
  // ) => void;
}

const ColumnAccordition: FC<Props> = ({
  label, items, onChange, isIndeterminate, disabled, // onDrop,
}) => {
  const [opened, setOpened] = useState(false);
  
  const openedIcon = opened ? <ChevronDownIcon /> : <IconContainer icon={ChevronRightIcon} />;
  const sortedItems = items.sort(sortCheckboxesByAlphabet);

  const renderedItems = sortedItems
    .map((el, idx, arr) => (
      <DraggableCheckbox
        key={el.id} 
        item={el}
        nextItem={arr[idx - 1]}
        prevItem={arr[idx + 1]}
        draggable={false}
        disabled={disabled}
        pinnable
        handleOnDrop={() => {}}
        handleChange={(newVal) => {
          onChange(el.id, newVal);
        }}
      />
    ));

  return (
    <div>
      <div
        className={css.checkboxGroup}
        onClick={() => setOpened(!opened)}
      >
        {openedIcon}
        <Checkbox
          value={false}
          onValueChange={() => setOpened(!opened)}
          indeterminate={isIndeterminate}
          cx={css.indeterminateCheckbox}
        />
        {label}
      </div>
      {opened && (
        <div className={css.accorditionContainer}>
          {renderedItems}
        </div>
      )}
    </div>
  );
};

export default ColumnAccordition;
