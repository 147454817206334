import api from './api';

export type TModalFieldsData = {
  oppDealType?: null | number,
  oppEngagementType?: null | number,
  oppPlatforms?: null | Array<string>,
  oppContinuumOfferings?: null | Array<string>,
  dealIsPartnerRelated?: null | number,
  oppPartnerAccounts?: null | Array<string>,
  gttl?: null | number,
  gttl1k?: null | number,
  oppDescription?: null | string,
  commercialModel?: null | number,
  closeDate?: null | string,
  durationMonths?: null | number,
  oppAM?: null | string,
  oppLeadPractice?: string,
  oppPractices?: string[],
  oppIndustry?: string,
};

export type TModalFieldsDataKeys = keyof TModalFieldsData;

export type TModalData = {
  canEditEngDetails: boolean,
  oppId: string,
  prob: number,
  oppName: string,
  currency: string,
  exchangeRate: number,
  fields: TModalFieldsData,
};

const API_EDIT = '/opportunities/edit';

export const editProbabilityAPI = {
  async getModalData(oppId: string): Promise<TModalData> {
    const res = await api.get<TModalData>(`${API_EDIT}?id=${oppId}`);
    return res.data;
  },
  async saveModalData(data: TModalData) {
    const res = await api.post<any>(API_EDIT, data);
    return res.data;
  },
};
